import * as React from 'react';
import { numberField, optionalNumberField, StateOf } from '../../forms';
import { InputGroup } from './InputGroup';

export interface Props {
  id: string;
  field: StateOf<typeof numberField> | StateOf<typeof optionalNumberField>;
  focusOnMount?: boolean;
  min?: number;
  max?: number;
  unit?: string;
  disabled?: boolean;
  decimalPlaces?: number;
}

function cutOffDecimals(decimalPlaces: number, value: number) {
  const format = Intl.NumberFormat('en-US', { useGrouping: false, maximumFractionDigits: decimalPlaces });
  return Number(format.format(value));
}

export function NumberInput(props: Props) {
  const inputRef = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    if (props.focusOnMount) {
      inputRef.current?.focus();
    }
  }, [props.focusOnMount]);

  let inputClassName = 'form-control';

  if (!props.field.status.valid) {
    inputClassName += ' is-invalid';
  }

  const decimalPlaces = props.decimalPlaces ?? 3;

  function onChange(e: React.FormEvent<HTMLInputElement>) {
    const value =
      e.currentTarget.value === '' || isNaN(e.currentTarget.valueAsNumber)
        ? null
        : cutOffDecimals(decimalPlaces, e.currentTarget.valueAsNumber);

    props.field.setValue(value);
  }

  return (
    <InputGroup field={props.field}>
      <input
        disabled={props.disabled}
        className={inputClassName}
        id={props.id}
        ref={(e: HTMLInputElement) => (inputRef.current = e)}
        type="number"
        value={props.field.value === null ? '' : props.field.value}
        onChange={onChange}
        onFocus={() => props.field.setTouched(true)}
        min={props.min}
        max={props.max}
        step={1 / 10 ** decimalPlaces}
      />
      {props.unit && <span className="input-group-text">{props.unit}</span>}
    </InputGroup>
  );
}
