﻿import {
  DeclarationResultAustria,
  ResponsibilityAustria,
  Responsible,
  tryGetDeclarationAustria,
} from '../../../../api';
import { Aggregation, filterAndAggregateWeights } from '../helpers';
import { useProducers } from '../../../producers/Producers';
import { stringParam, useSearchParams } from '../../../../shared/hooks/useSearchParams';
import { Actions } from '../Actions';
import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { useTranslation } from 'react-i18next';
import { Navigate, RouteObject, useOutletContext } from 'react-router';
import { NotFoundInfo } from '../../../../shared/components/NotFoundInfo';
import { ReviewList } from './ReviewList';
import { NavLink, useSearchParams as useSearchParamsRouter } from 'react-router-dom';
import { expectNever, getPeriodShortName } from '../../../../helpers';
import { usePeriodParam, useYearParam } from '../../../../shared/hooks/useParam';
import { useTenantQuery } from '../../../../shared/hooks/useTenantQuery';
import { useQueryValue } from '../../../../shared/hooks/useQueryValue';
import { useState } from 'react';
import { getPreviousPeriod } from '../../helpers';

export const reviewRoutes: RouteObject[] = [
  { path: 'review', element: <Navigate to="./licensing" /> },
  { path: 'review/licensing', element: <ReviewStep kind="licensing" /> },
];

type ResponsibilityKind = 'licensing';

function ResponsibilityKindNavItem({ kind }: { kind: ResponsibilityKind }) {
  const { t } = useTranslation('reports');
  const [search] = useSearchParamsRouter();
  return (
    <li className="nav-item">
      <NavLink
        className="nav-link nav-link-flex-height px-4"
        to={{ pathname: `../review/${kind}`, search: search.toString() }}>
        {t(`reviewStep.responsibilities.${kind}`)}
      </NavLink>
    </li>
  );
}

function getAggregationFunction(
  kind: ResponsibilityKind
): (responsibility: ResponsibilityAustria) => Responsible | null {
  if (kind === 'licensing') {
    return (responsibility: ResponsibilityAustria) => responsibility.licensing;
  }

  expectNever(kind);
}

type ComparisonMode = 'LastPeriod' | 'LastYear';

export function ReviewStep({ kind }: { kind: ResponsibilityKind }) {
  const { t } = useTranslation('reports');

  const [search, setSearch] = useSearchParams('filter', stringParam<string>(''));
  const [year, period] = [useYearParam(), usePeriodParam()];
  const declaration = useOutletContext<DeclarationResultAustria | null>();
  const { year: prevYear, period: prevPeriod } = getPreviousPeriod(year, period);

  const [comparisonMode, setComparisonMode] = useState<ComparisonMode>('LastPeriod');

  const { year: comparisonYear, period: comparisonPeriod } =
    comparisonMode === 'LastPeriod' ? getPreviousPeriod(year, period) : { year: year - 1, period };

  const comparisonQuery = useTenantQuery(
    tryGetDeclarationAustria,
    {
      year: comparisonYear,
      period: comparisonPeriod,
    },
    false
  );

  const comparisonValue = useQueryValue(comparisonQuery);

  const comparisonResult =
    comparisonValue !== 'Running' && comparisonValue.Case !== 'Failed' ? comparisonValue.Fields[0] : null;

  const producers = useProducers();

  if (declaration === null) {
    return <NotFoundInfo />;
  }

  const aggregations: Aggregation<Responsible>[] = filterAndAggregateWeights(
    getAggregationFunction(kind),
    declaration.responsibilities,
    comparisonResult?.responsibilities ?? []
  );

  const lowerCasedSearch = search.toLocaleLowerCase();

  function stringContainsSearch(value: string | null) {
    if (lowerCasedSearch === '') {
      return true;
    }

    return value?.toLocaleLowerCase().includes(lowerCasedSearch) ?? false;
  }

  function containsSearch(value: Responsible | null) {
    if (value === null) {
      return false;
    }

    if (value === 'Customers') {
      return false;
    }

    if (value.Case === 'Producer') {
      if (stringContainsSearch(value.Fields[0])) {
        return true;
      }

      const producer = producers.accessible()[value.Fields[0]];
      if (producer) {
        return stringContainsSearch(producer.name) || stringContainsSearch(producer.lucidNumber);
      }

      return false;
    }

    if (value.Case === 'Supplier') {
      return stringContainsSearch(value.Fields[0]);
    }

    if (value.Case === 'Customer') {
      return stringContainsSearch(value.Fields[0]);
    }

    expectNever(value);
  }

  const filtered = aggregations.filter(a => {
    return lowerCasedSearch === '' || containsSearch(a.key);
  });

  return (
    <div>
      <Actions year={year} period={period} declaration={declaration} />
      <div className="bg-light rounded mb-4">
        <InfoScreen icon="eye" color="primary" title={t('reviewStep.title')}>
          <p>{t('reviewStep.subtitle.at')}</p>
        </InfoScreen>
      </div>
      <ul className="nav nav-tabs">
        <li className="nav-item pe-2 pb-2 col-4">
          <input
            placeholder={t('global:search')}
            type="search"
            className="form-control"
            value={search}
            onChange={e => setSearch(e.currentTarget.value)}
          />
        </li>
        <ResponsibilityKindNavItem kind="licensing" />
        <li className="nav-item ms-auto">
          <span className="me-2 text-secondary">{t('reviewStep.compareWith')}</span>
          <div className="btn-group" role="group">
            {prevPeriod !== period || prevYear !== year - 1 ? (
              <>
                <input
                  type="radio"
                  className="btn-check"
                  name="comparison-mode"
                  id="comparison-mode-year"
                  autoComplete="off"
                  onChange={() => setComparisonMode('LastYear')}
                  checked={comparisonMode == 'LastYear'}
                />
                <label className="btn btn-outline-secondary" htmlFor="comparison-mode-year">
                  {getPeriodShortName(period)} {year - 1}
                </label>
              </>
            ) : (
              <></>
            )}
            <input
              type="radio"
              className="btn-check"
              name="comparison-mode"
              id="comparison-mode-period"
              autoComplete="off"
              onChange={() => setComparisonMode('LastPeriod')}
              checked={comparisonMode == 'LastPeriod'}
            />
            <label className="btn btn-outline-secondary" htmlFor="comparison-mode-period">
              {getPeriodShortName(prevPeriod)} {prevYear}
            </label>
          </div>
        </li>
      </ul>
      <ReviewList data={filtered} />
    </div>
  );
}
