import { Period } from '../../../api';
import { useContracts } from '../../contracts/Contracts';
import { isContractForPeriod } from '../helpers';
import { useMemo } from 'react';
import { useProducers } from '../../producers/Producers';

/**
 * Checks if the producer with the specified ID is obligated in the specified year and period.
 */
export function useContractFilterFunctions(period: Period) {
  const contracts = useContracts();

  return {
    hasContractForPeriod: (producerId: string) => {
      const contract = contracts.get(producerId);
      return contract !== null ? isContractForPeriod(contract, period) : false;
    },
    hasContractButNotForPeriod: (producerId: string) => {
      const contract = contracts.get(producerId);
      return contract !== null ? !isContractForPeriod(contract, period) : false;
    },
    hasNoContract: (producerId: string) => !contracts.has(producerId),
  };
}

export function useProducersWithContractForPeriod(period: Period) {
  const producers = useProducers().accessible();
  const contracts = useContracts();

  return useMemo(() => {
    return Object.keys(producers).filter(producerId => {
      const contract = contracts.get(producerId);
      return contract !== null ? isContractForPeriod(contract, period) : false;
    });
  }, [producers]);
}
