import { useEffect, useRef, useState } from 'react';
import { getToken } from './public/auth/shared';
import { useTenant } from './private/tenants/TenantDetail';
import { useIsMountedRef } from './shared/hooks/useIsMountedRef';
import { showError } from './GlobalErrorBoundary';

export class RemoteError extends Error {
  constructor(url: string, public readonly response: Response) {
    super(`RemoteError: ${url} -> ${response.status} ${response.statusText}`);
  }
}

function readCookie(name: string) {
  return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || null;
}

export async function invoke<TRequest, TResponse>(
  token: string | null,
  path: string,
  request: TRequest,
  signal?: AbortSignal
): Promise<TResponse> {
  const headers = new Headers({ 'Content-Type': 'application/json' });

  const xsrfToken = readCookie('x-xsrf-token');
  if (xsrfToken != null) {
    headers.append('x-xsrf-token', xsrfToken);
  }

  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  const response = await fetch(path, {
    headers,
    method: 'POST',
    body: request !== undefined ? JSON.stringify(request) : undefined,
    signal,
  });

  if (response.ok) {
    return (await response.json()) as TResponse;
  }

  throw new RemoteError(path, response);
}

export function invokePublic<TRequest, TResponse>(name: string, request: TRequest, signal?: AbortSignal) {
  return invoke<TRequest, TResponse>(null, `/api/remote/public/${name}`, request, signal);
}

export async function invokePrivate<TRequest, TResponse>(name: string, request: TRequest, signal?: AbortSignal) {
  return invoke<TRequest, TResponse>(await getToken(), `/api/remote/private/${name}`, request, signal);
}

export async function invokeTenant<TRequest, TResponse>(
  name: string,
  request: { tenantId: number; data: TRequest },
  signal?: AbortSignal
) {
  return invoke<TRequest, TResponse>(
    await getToken(),
    `/api/remote/tenant/${request.tenantId}/${name}`,
    request.data,
    signal
  );
}

export async function download<TRequest>(
  token: string | null,
  path: string,
  request: TRequest,
  signal?: AbortSignal
): Promise<Blob> {
  const headers = new Headers({ 'Content-Type': 'application/json' });

  const xsrfToken = readCookie('x-xsrf-token');
  if (xsrfToken != null) {
    headers.append('x-xsrf-token', xsrfToken);
  }

  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  const response = await fetch(path, {
    headers,
    method: 'POST',
    body: request !== undefined ? JSON.stringify(request) : undefined,
    signal,
  });

  if (response.ok) {
    return await response.blob();
  }

  throw new RemoteError(path, response);
}

export function downloadPublic<TRequest>(name: string, request: TRequest) {
  return download<TRequest>(null, `/api/remote/public/${name}`, request);
}

export async function downloadPrivate<TRequest>(name: string, request: TRequest) {
  return download<TRequest>(await getToken(), `/api/remote/private/${name}`, request);
}

export async function downloadTenant<TRequest>(name: string, request: { tenantId: number; data: TRequest }) {
  return download<TRequest>(await getToken(), `/api/remote/tenant/${request.tenantId}/${name}`, request.data);
}

export function useAbortSignal() {
  const signalRef = useRef<AbortSignal>();

  useEffect(() => {
    const controller = new AbortController();
    signalRef.current = controller.signal;
    // On unmount we abort all open API calls.
    return () => controller.abort();
  }, []);

  return signalRef;
}

interface MutateOptions<TResponse> {
  onSuccess?: (data: TResponse) => Promise<void>;
  onError?: (error: unknown) => Promise<void>;
}

type MutationState<TResponse> =
  | { readonly isPending: false; readonly isResolved: false; readonly isRejected: false }
  | {
      readonly isPending: true;
      readonly isResolved: false;
      readonly isRejected: false;
      readonly promise: Promise<void>;
    }
  | { readonly isPending: false; readonly isResolved: true; readonly isRejected: false; readonly data: TResponse }
  | { readonly isPending: false; readonly isResolved: false; readonly isRejected: true; readonly error: unknown };

export type MutationModel<TRequest, TResponse> = MutationState<TResponse> & {
  start(request: TRequest, options?: MutateOptions<TResponse>): void;
};

export function useMutation<TRequest, TResponse>(
  invoke: (request: TRequest) => Promise<TResponse>
): MutationModel<TRequest, TResponse> {
  const mounted = useIsMountedRef();
  const [state, setState] = useState<MutationState<TResponse>>({
    isPending: false,
    isResolved: false,
    isRejected: false,
  });

  function start(request: TRequest, options?: MutateOptions<TResponse>) {
    const promise = invoke(request).then(
      async data => {
        if (options?.onSuccess) {
          await options.onSuccess(data);
        }

        if (mounted.current) {
          setState({ isPending: false, isResolved: true, isRejected: false, data });
        }
      },
      async error => {
        if (error instanceof Error && error.name === 'AbortError') {
          return;
        }

        if (mounted.current) {
          setState({ isPending: false, isResolved: false, isRejected: true, error });
        }

        if (options?.onError) {
          await options.onError(error);
        } else {
          showError(error);
        }

        return error;
      }
    );

    setState({ isPending: true, isResolved: false, isRejected: false, promise });
  }

  return {
    ...state,
    start,
  };
}

export function useTenantMutation<TRequest, TResponse>(
  invoke: (request: { tenantId: number; data: TRequest }) => Promise<TResponse>
): MutationModel<TRequest, TResponse> {
  const tenant = useTenant();
  return useMutation(request => invoke({ tenantId: tenant.id, data: request }));
}
