import * as React from 'react';
import { field, InputRequiredError, StateOf } from '../../forms';
import { PackagingType } from '../../api';
import { getNameOfPackagingType } from '../../helpers';
import { InputGroup } from './InputGroup';

export const packagingTypeField = field<PackagingType | null, PackagingType>(value =>
  value === null ? { valid: false, error: <InputRequiredError /> } : { valid: true, value }
);

export interface Props {
  id: string;
  field: StateOf<typeof packagingTypeField>;
  focusOnMount?: boolean;
  disabled?: boolean;
}

export function PackagingTypeInput(props: Props) {
  const inputRef = React.useRef<HTMLSelectElement>();

  React.useEffect(() => {
    if (props.focusOnMount) {
      inputRef.current?.focus();
    }
  }, [props.focusOnMount]);

  let selectClassName = 'form-select';

  if (!props.field.status.valid) {
    selectClassName += ' is-invalid';
  }

  return (
    <InputGroup field={props.field}>
      <select
        disabled={props.disabled}
        className={selectClassName}
        id={props.id}
        ref={(e: HTMLSelectElement) => (inputRef.current = e)}
        value={props.field.value || ''}
        onChange={e =>
          props.field.setValue(e.currentTarget.value === '' ? null : (e.currentTarget.value as PackagingType))
        }
        onFocus={() => props.field.setTouched(true)}>
        <option value=""></option>
        <option value="Sale">{getNameOfPackagingType('Sale')}</option>
        <option value="Service">{getNameOfPackagingType('Service')}</option>
        <option value="Outer">{getNameOfPackagingType('Outer')}</option>
        <option value="Shipping">{getNameOfPackagingType('Shipping')}</option>
      </select>
    </InputGroup>
  );
}
