﻿import { StateOf } from '../../../forms';
import { contractForm } from './form';
import { Row } from '../../../shared/components/Row';
import { SystemField } from '../../../shared/components/SystemField';
import { NumberInput } from '../../../shared/components/NumberInput';
import { DateInput } from '../../../shared/components/DateInput';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { PriceField } from '../PriceField';
import { IntervalInput } from '../../../shared/components/IntervalInput';
import { Form } from 'react-bootstrap';

export interface Props {
  disabled?: boolean;
  form: StateOf<typeof contractForm>;
  year: number;
}

export function ContractForm({ disabled, form, year }: Props) {
  const { t } = useTranslation('contracts');

  const minDate = dayjs().year(year).add(1, 'year').date(1).month(0).format('YYYY-MM-DD');
  const maxDate = dayjs().year(year).add(1, 'year').date(31).month(11).format('YYYY-MM-DD');

  const priceFields = (
    <>
      <Row>
        <PriceField
          label={t('global:fractions.de.glass')}
          disabled={disabled}
          id="prices.glass"
          field={form.fields.prices.fields.glass}
        />
        <PriceField
          label={t('global:fractions.de.fiber')}
          disabled={disabled}
          id="prices.fiber"
          field={form.fields.prices.fields.fiber}
        />
      </Row>
      <Row>
        <PriceField
          label={t('global:fractions.de.ferrousMetal')}
          disabled={disabled}
          id="prices.ferrousMetal"
          field={form.fields.prices.fields.ferrousMetal}
        />
        <PriceField
          label={t('global:fractions.de.aluminum')}
          disabled={disabled}
          id="prices.aluminum"
          field={form.fields.prices.fields.aluminum}
        />
      </Row>
      <Row>
        <PriceField
          label={t('global:fractions.de.plastic')}
          disabled={disabled}
          id="prices.plastic"
          field={form.fields.prices.fields.plastic}
        />
        <PriceField
          label={t('global:fractions.de.beverageCarton')}
          disabled={disabled}
          id="prices.beverageCarton"
          field={form.fields.prices.fields.beverageCarton}
        />
      </Row>
      <Row>
        <PriceField
          label={t('global:fractions.de.otherComposite')}
          disabled={disabled}
          id="prices.otherComposite"
          field={form.fields.prices.fields.otherComposite}
        />
        <PriceField
          label={t('global:fractions.de.other')}
          disabled={disabled}
          id="prices.other"
          field={form.fields.prices.fields.other}
        />
      </Row>
    </>
  );

  return (
    <>
      <Row>
        <SystemField id="systemId" label={t('contractForm.systemProvider')} field={form.fields.systemId} />
      </Row>
      <hr />
      <Row>
        <Form.Group className="mb-3 d-flex justify-content-between gap-2 align-items-center">
          <div className="h4 mb-0">{t('contractForm.fees')}</div>
          <Form.Check
            type="switch"
            checked={form.fields.hasPrices.value}
            onChange={value => form.fields.hasPrices.setValue(value.target.checked)}
          />
        </Form.Group>
      </Row>
      {form.fields.hasPrices.value ? priceFields : <></>}
      <hr />
      <Row>
        <div className="col-12">
          <p className="h4">{t('contractForm.declarationPeriod')}</p>
          <Row>
            <div className="col-12 pt-2 pb-5">
              <div className="d-flex justify-content-start gap-3 align-items-center">
                <div>{t('contractForm.declarationPeriodDescription')}</div>
                <div className="w-50">
                  <IntervalInput id={'reminder.interval'} field={form.fields.interval}></IntervalInput>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </Row>
      <Row>
        <div className="col-12">
          <p className="h4">{t('contractForm.deadlines')}</p>
          <Row>
            <div className="col-12 pt-2">
              <div className="d-flex justify-content-start align-items-baseline">
                <span className="pe-3">{t('contractForm.annualReportingDueBy')}</span>
                <div className="col-4">
                  <DateInput
                    id="reminder.annual"
                    field={form.fields.deadlines.fields.annual}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-12 pt-4">
              <div className="d-flex justify-content-start align-items-baseline">
                <span className="text-nowrap pe-3">{t('contractForm.periodicalReportingDueBy')}</span>
                <div className="col-1">
                  <NumberInput
                    id="reminder.periodical.daysAfter"
                    field={form.fields.deadlines.fields.periodical}
                    min={1}
                    decimalPlaces={0}
                  />
                </div>
                <span className="text-nowrap ps-3">{t('contractForm.daysAfterEndOfPeriod')}</span>
              </div>
            </div>
          </Row>
        </div>
      </Row>
    </>
  );
}
