﻿import { useBranches } from './Branches';
import { BranchForm } from './BranchForm';
import { branchForm as FormDecl, emptyForm } from './form';
import { textField, useForm } from '../../forms';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../shared/components/Icon';
import { Modal } from '../../shared/components/Modal';
import { RouteObject, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useStringParams } from '../../shared/hooks/useParam';

export const branchEditRoute: RouteObject = {
  path: 'edit/:branchId',
  element: <BranchEdit />,
};

export function BranchEdit() {
  const { branchId } = useStringParams<'branchId'>();

  const branches = useBranches();
  const branch = branches.get(branchId);

  const { t } = useTranslation('branches');

  const branchForm = useForm(FormDecl(textField), branch ?? emptyForm);

  const navigate = useNavigate();
  const [search] = useSearchParams();

  function onClose() {
    navigate({ pathname: '..', search: search.toString() });
  }

  const onSave = async () => {
    if (branchForm.status.valid) {
      onClose();
      await branches.upsert(branchForm.status.value);
    }
  };

  const onDelete = async () => {
    if (confirm(t('branchModal.confirmDeletion'))) {
      onClose();
      await branches.delete(branchId);
    }
  };

  const footer = (
    <>
      <button className={'mx-2 btn btn-outline-danger'} type="button" onClick={() => onDelete()}>
        <Icon name="trash" /> {t('branchModal.delete')}
      </button>
      <button
        className={'btn btn-primary text-white'}
        type="submit"
        disabled={!branchForm.status.valid}
        onClick={onSave}>
        {t('branchModal.save')}
      </button>
    </>
  );

  return (
    <Modal
      visible={true}
      title={branch ? `${branch.branchId} - ${branch.name}` : t('branchModal.title')}
      onClose={onClose}
      footer={footer}
      size="lg">
      <BranchForm form={branchForm} newRecord={false} />
    </Modal>
  );
}
