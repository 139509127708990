import { Field } from './Field';
import { StateOf, numberField } from '../../forms';
import { ColSize } from './Col';
import { SystemInput } from './SystemInput';

export interface Props {
  id: string;
  label: string;
  field: StateOf<typeof numberField>;
  focusOnMount?: boolean;
  disabled?: boolean;
  size?: ColSize;
}

export function SystemField(props: Props) {
  return (
    <Field size={props.size}>
      <label className="form-label" htmlFor={props.id}>
        {props.label}
      </label>
      <SystemInput id={props.id} disabled={props.disabled} field={props.field} />
    </Field>
  );
}
