﻿import { ProducerInfo } from '../../../producers/ProducerInfo';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../../../shared/components/Icon';
import { useTranslation } from 'react-i18next';
import { getPlacings, PackagingType } from '../../../../api';
import { getNameOfPackagingType } from '../../../../helpers';
import { useTenantQuery } from '../../../../shared/hooks/useTenantQuery';
import { useQueryState } from '../../../../shared/hooks/useQueryState';

export interface Props {
  year: number;
  producerId: string;
}

const allPackagingTypes: PackagingType[] = ['Sale', 'Outer', 'Service', 'Shipping'];

export function AdjustListRow({ year, producerId }: Props) {
  const { t } = useTranslation('reports');

  const placingsQuery = useTenantQuery(getPlacings, year, false);
  const [placings] = useQueryState(placingsQuery);
  const packagingTypes = Object.entries(placings)
    .filter(([, p]) => p.producerId === producerId)
    .map(([, p]) => p.packagingType);

  const packagingTypesSet = new Set(packagingTypes);

  const packagingTypesInfo = allPackagingTypes.map(p => (
    <span key={p} className={`badge m-1 bg-${packagingTypesSet.has(p) ? 'success' : 'secondary'}`}>
      {getNameOfPackagingType(p)}
    </span>
  ));
  return (
    <tr>
      <td>
        <ProducerInfo producerId={producerId} />
      </td>
      <td className="align-middle">{packagingTypesInfo}</td>
      <td className="text-nowrap align-middle text-end">
        <NavLink to={`./${producerId}`} className="btn btn-outline-secondary">
          <>
            {t('adjustListRow.showPlacings')}
            <Icon name="chevron-right" className="ms-1" />
          </>
        </NavLink>
      </td>
    </tr>
  );
}
