﻿import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, RouteObject, useOutletContext } from 'react-router';
import { Button } from 'react-bootstrap';
import { DeclarationResultFrance, downloadDeclaration, LongRunningTask } from '../../../../api';
import { useTenant } from '../../../tenants/TenantDetail';
import { WithdrawButton } from '../WithdrawButton';
import { Icon } from '../../../../shared/components/Icon';
import { useState } from 'react';
import { useYearParam } from '../../../../shared/hooks/useParam';

export const finishRoute: RouteObject = {
  path: 'finish',
  element: <FinishStep />,
};

export function FinishStep() {
  const { t } = useTranslation('reports');

  const tenant = useTenant();
  const year = useYearParam();

  const [downloading, setDownloading] = useState(false);

  const waitUntilCreated = async (headUrl: string, timeoutMs: number, intervalMs: number) => {
    return new Promise(function (resolve, reject) {
      (function resolver(timePassedMs: number) {
        if (timePassedMs >= timeoutMs) {
          return reject();
        }
        fetch(headUrl, { method: 'HEAD' })
          .then(res => {
            if (res.status === 200) {
              resolve(res);
            } else {
              setTimeout(() => resolver(timePassedMs + intervalMs), intervalMs);
            }
          })
          .catch(reject);
      })(0);
    });
  };

  async function download() {
    const [headUrl, getUrl] = await downloadDeclaration({ tenantId: tenant.id, data: { year } });

    setDownloading(true);

    waitUntilCreated(headUrl, 5 * 60 * 1000, 5000)
      .then(() => {
        const link = document.createElement('a');
        link.setAttribute('href', getUrl);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(() => {
        console.error('Timeout reached');
      })
      .finally(() => setDownloading(false));
  }

  const task = useOutletContext<LongRunningTask<DeclarationResultFrance, string> | null>();

  if (task === null || task === 'Running' || task.Case === 'Failed') {
    return <Navigate to="../import" replace />;
  }

  const declaration = task.Fields[0];

  if (declaration.kind === 'Preview') {
    return <Navigate to="../validate" replace />;
  }

  return (
    <>
      <InfoScreen title={t('csuFinishStep.title')} bgColor="success" icon="check-circle" color="success">
        <p style={{ whiteSpace: 'pre-line' }}>
          <Trans t={t}>{t('csuFinishStep.successHint')}</Trans>
        </p>
      </InfoScreen>

      <div className="mt-2 d-flex justify-content-center">
        <WithdrawButton declarationId={declaration.declarationId} year={year} period="Year" />
        <Button className="me-2" onClick={download} disabled={downloading}>
          <span className={downloading ? 'spinner-border spinner-border-sm me-1' : 'd-none'}></span>
          <Icon name="download" className={downloading ? 'd-none' : 'me-1'} /> {t('csuFinishStep.download')}
        </Button>
      </div>
    </>
  );
}
