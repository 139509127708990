﻿import { DeclarationResultGermany, PackagingWeights, Responsible } from '../../../../api';
import { Outlet, RouteObject, useOutletContext } from 'react-router';
import {
  Aggregation,
  filterAndAggregateWeights,
  filterAndGroupResponsibilities,
  getEmptyWeights,
  getSystemForLicensing,
  isProducer,
  SystemForLicensing,
  tryGetIdOfProducerThatHasToLicense,
} from '../helpers';
import { useContracts } from '../../../contracts/Contracts';
import { useThirdPartyLicensing } from '../thirdPartyLicensing/ThirdPartyLicensing';
import { useProducers } from '../../../producers/Producers';
import { useCompareResponsible } from '../CompareResponsible';
import { useSorting } from '../../../../shared/hooks/useSorting';
import { Icon } from '../../../../shared/components/Icon';
import { SortableHeaderCell } from '../../../../shared/components/SortableHeaderCell';
import { SortableWeightsHeaderCells } from '../../../../shared/components/SortableWeightsHeaderCells';
import { SystemForLicensingInfo } from '../SystemForLicensingInfo';
import { ResponsibleView } from '../ResponsibleView';
import { WeightsRowCells } from '../../../../WeightsRowCells';
import { selectAndLocaleCompare } from '../selectAndLocaleCompare';
import { selectAndNumericCompare } from '../selectAndNumericCompare';
import { PackagingWeightsKeys } from '../PackagingWeightsKeys';
import { useReports } from '../../Reports';
import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { Trans, useTranslation } from 'react-i18next';
import { ContractEdit } from '../../../contracts/de/ContractEdit';
import { Actions } from '../Actions';
import { NavLink } from 'react-router-dom';
import { usePeriodParam, useStringParams, useYearParam } from '../../../../shared/hooks/useParam';
import { useProducersThatHaveToLicense } from './useProducersThatHaveToLicense';
import { useSystems } from '../../../systems/Systems';
import { ReportHistory } from '../ReportHistory';
import { AuditorExportButton } from '../export/AuditorExport';

function LicenseDetailRow({
  producerId,
  weights,
  system,
  reporting,
}: {
  producerId: string;
  weights: PackagingWeights;
  system: SystemForLicensing;
  reporting: Responsible;
}) {
  return (
    <tr>
      <td className="align-middle">
        <SystemForLicensingInfo system={system} producerId={producerId} />
      </td>
      <td className="align-middle">
        <ResponsibleView responsible={reporting} />
      </td>
      <WeightsRowCells weights={weights} />
    </tr>
  );
}

type Row = Aggregation<{ system: SystemForLicensing; reporting: Responsible }>;

export const licenseDetailRoute: RouteObject = {
  path: ':producerId',
  element: <LicenseDetail />,
  children: [
    {
      path: 'contracts/edit/:contractProducerId',
      element: <ContractEdit />,
    },
  ],
};

export function LicenseDetail() {
  const { t } = useTranslation('reports');

  const [period, year] = [usePeriodParam(), useYearParam()];
  const { producerId } = useStringParams<'producerId'>();

  const declaration = useOutletContext<DeclarationResultGermany>();

  const producersThatHaveToLicense = useProducersThatHaveToLicense(period, declaration, true);

  const groups = filterAndGroupResponsibilities(tryGetIdOfProducerThatHasToLicense, declaration.responsibilities);

  const responsibilities = groups.find(g => g.key === producerId)?.responsibilities ?? [];

  const contracts = useContracts();
  const thirdPartyLicensing = useThirdPartyLicensing();

  const rows: Row[] = filterAndAggregateWeights(row => {
    if (row.reporting === null || row.licensing === null || !isProducer(row.licensing)) {
      return null;
    }

    return {
      system: getSystemForLicensing(contracts.get, thirdPartyLicensing.get, row.reporting, row.licensing),
      reporting: row.reporting,
    };
  }, responsibilities);

  const producers = useProducers().all;
  const systems = useSystems();

  const reports = useReports();
  const report = reports.get(declaration.declarationId, producerId);

  const compareResponsible = useCompareResponsible();

  function compareReporting(a: Row, b: Row) {
    return compareResponsible(a.key.reporting, b.key.reporting);
  }

  function tryGetSystemName(systemForLicensing: SystemForLicensing) {
    if (systemForLicensing.type === 'System') {
      const system = systems.get(systemForLicensing.systemId);
      return system?.name ?? null;
    }

    return null;
  }

  const emptyResponsible: Responsible = {
    Case: 'Producer',
    Fields: [producerId],
  };

  const emptySystem: SystemForLicensing = {
    type: 'SystemOfProducer',
    producerId: producerId,
  };

  const emptyWeights: PackagingWeights = getEmptyWeights();

  const sorting = useSorting<Row, 'system' | 'reporting' | PackagingWeightsKeys>(
    rows,
    {
      system: selectAndLocaleCompare<Row>(r => tryGetSystemName(r.key.system)),
      reporting: compareReporting,
      glass: selectAndNumericCompare<Row>(r => r.weights.glass),
      fiber: selectAndNumericCompare<Row>(r => r.weights.fiber),
      ferrousMetal: selectAndNumericCompare<Row>(r => r.weights.ferrousMetal),
      aluminum: selectAndNumericCompare<Row>(r => r.weights.aluminum),
      plastic: selectAndNumericCompare<Row>(r => r.weights.plastic),
      beverageCarton: selectAndNumericCompare<Row>(r => r.weights.beverageCarton),
      otherComposite: selectAndNumericCompare<Row>(r => r.weights.otherComposite),
      other: selectAndNumericCompare<Row>(r => r.weights.other),
    },
    { key: 'system', direction: 'asc' }
  );

  const producer = producers[producerId];

  return (
    <>
      {producersThatHaveToLicense.length > 1 ? (
        <div className="my-4">
          <NavLink className="btn btn-outline-secondary" to=".." end>
            <>
              <Icon name="chevron-left" />
              {t('reportStep.showAllDistributors')}
            </>
          </NavLink>
        </div>
      ) : (
        <Actions year={year} period={period} declaration={declaration} />
      )}
      <div className="my-3 d-flex align-items-center">
        <div style={{ width: '5rem', lineHeight: '4rem' }} className="text-center">
          <Icon name="building" className="fs-1" />
        </div>
        <div className="flex-grow-1">
          <h1 className="h2 my-0">{producer?.name ?? producerId}</h1>
          {producer && (
            <div className="text-muted">
              ID: {producer.id} | LUCID: {producer.lucidNumber}
            </div>
          )}
        </div>
      </div>
      <div className="bg-light rounded mb-4">
        <InfoScreen icon="send" color="primary" title={t('licenseDetail.reportInfoTitle')}>
          <p>
            <Trans t={t}>{t('licenseDetail.reportInfoText', { producer: producer?.name ?? producerId })}</Trans>
          </p>
          <div className="form-check form-switch d-inline-block">
            <input
              className="form-check-input"
              id="cb-report"
              type="checkbox"
              checked={!!report?.proReportedAt}
              onChange={e => reports.report(declaration.declarationId, producerId, 'Pro', e.target.checked)}
            />
            <label className="form-check-label" htmlFor="cb-report">
              {t('licenseDetail.markAsReported')}
            </label>
          </div>
          <div className="mt-2">
            <ReportHistory
              producerId={producerId}
              type="Pro"
              renderDownloadButton={declarationId => <AuditorExportButton declarationId={declarationId} />}
            />
          </div>
        </InfoScreen>
      </div>
      <table className="table table-hover table-striped">
        <thead>
          <tr>
            <SortableHeaderCell sorting={sorting} sortKey="system">
              {t('licenseDetail.systemProvider')}
            </SortableHeaderCell>
            <SortableHeaderCell sorting={sorting} sortKey="reporting">
              {t('licenseDetail.distributedBy')}
            </SortableHeaderCell>
            <SortableWeightsHeaderCells sorting={sorting} />
          </tr>
        </thead>
        <tbody>
          {sorting.sorted.length > 0 ? (
            sorting.sorted.map(row => (
              <LicenseDetailRow
                key={row.keyAsString}
                system={row.key.system}
                reporting={row.key.reporting}
                producerId={producerId}
                weights={row.weights}
              />
            ))
          ) : (
            <LicenseDetailRow
              system={emptySystem}
              reporting={emptyResponsible}
              producerId={producerId}
              weights={emptyWeights}
            />
          )}
        </tbody>
      </table>
      <Outlet />
    </>
  );
}
