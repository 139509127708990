﻿import { RouteObject, useOutletContext } from 'react-router';
import { useProducers } from '../../../producers/Producers';
import { Icon } from '../../../../shared/components/Icon';
import { PlacingsOfProducer } from './placings/PlacingsOfProducer';
import { useTranslation } from 'react-i18next';
import { DeclarationResultGermany } from '../../../../api';
import { Actions } from '../Actions';
import { NavLink } from 'react-router-dom';
import { addModalRoute } from './placings/AddModal';
import { editModalRoute } from './placings/EditModal';
import { usePeriodParam, useStringParams, useYearParam } from '../../../../shared/hooks/useParam';
import { useVisibleProducers } from './useVisibleProducers';

export const adjustDetailRoute: RouteObject = {
  path: ':producerId',
  element: <AdjustDetail />,
  children: [addModalRoute, editModalRoute],
};

export function AdjustDetail() {
  const { producerId } = useStringParams<'producerId'>();
  const producers = useProducers();
  const producer = producers.get(producerId);

  const { t } = useTranslation('reports');

  const year = useYearParam();
  const period = usePeriodParam();
  const declaration = useOutletContext<DeclarationResultGermany | null>();

  const producerIds = useVisibleProducers(year, period);

  return (
    <>
      {producerIds.length === 1 ? (
        declaration && <Actions year={year} period={period} declaration={declaration} />
      ) : (
        <div className="my-4">
          <NavLink className="btn btn-outline-secondary" to=".." end>
            <>
              <Icon name="chevron-left" />
              {t('adjustStep.showAllProducers')}
            </>
          </NavLink>
        </div>
      )}
      <div className="my-3 d-flex align-items-center">
        <div style={{ width: '5rem', lineHeight: '4rem' }} className="text-center">
          <Icon name="building" className="fs-1" />
        </div>
        <div className="flex-grow-1">
          <h1 className="h2 my-0">{producer?.name ?? producerId}</h1>
          {producer && (
            <div className="text-muted">
              ID: {producer.id} | LUCID: {producer.lucidNumber}
            </div>
          )}
        </div>
      </div>
      <PlacingsOfProducer producerId={producerId} />
    </>
  );
}
