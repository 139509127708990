import { AUTH_RESPONSE, AUTH_RETURN_PATH, AUTH_STATE, tryGetAuthState, useAuthOptions } from './shared';
import { nanoid } from 'nanoid';
import { BrowserAuthOptions } from '../../api';
import { Icon } from '../../shared/components/Icon';

function ensureAndReadAuthState() {
  let state = tryGetAuthState();
  if (state == null) {
    state = nanoid();
    sessionStorage.setItem(AUTH_STATE, state);
  }
  return state;
}

function navigateToGoogleSignIn(authOptions: BrowserAuthOptions, returnPath: string) {
  // Remove infos from previous authentications
  sessionStorage.removeItem(AUTH_RESPONSE);
  sessionStorage.setItem(AUTH_RETURN_PATH, returnPath);

  // Navigate to Google sign in
  const params = new URLSearchParams();
  params.set('identity_provider', 'Google');
  params.set('response_type', 'CODE');
  params.set('client_id', authOptions.clientId);
  params.set('redirect_uri', authOptions.redirectUri);
  params.set('scope', 'email openid profile');
  params.set('state', ensureAndReadAuthState());
  window.location.href = `${authOptions.authorizeUrl}?${params.toString()}`;
}

export function GoogleSignInButton({ returnPath, label }: { returnPath: string; label: string }) {
  const authOptions = useAuthOptions();

  function onGoogleClick() {
    navigateToGoogleSignIn(authOptions, returnPath);
  }

  return (
    <button className="btn btn-secondary" onClick={onGoogleClick}>
      <Icon name="google" className="me-2" />
      {label}
    </button>
  );
}
