import { OptionalNumberField, StateOf } from '../../forms';
import { NumberField } from '../../shared/components/NumberField';

export interface Props {
  id: string;
  label: string;
  disabled?: boolean;
  field: StateOf<OptionalNumberField>;
}

export function PriceField({ id, label, field, disabled }: Props) {
  return (
    <NumberField
      label={label}
      disabled={disabled}
      decimalPlaces={5}
      unit="€ / kg"
      id={id}
      field={field}
      min={0}
      max={100}
    />
  );
}
