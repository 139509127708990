﻿import { useTranslation } from 'react-i18next';
import { stringParam, useSearchParams } from '../../../../shared/hooks/useSearchParams';
import { useSorting } from '../../../../shared/hooks/useSorting';
import { SortableHeaderCell } from '../../../../shared/components/SortableHeaderCell';
import { formatCurrency } from '../../../../helpers';
import i18n from '../../../../i18n';
import { useState } from 'react';
import { Pagination } from '../../../../shared/components/Pagination';

type ValidationTableProps = {
  data: [string, number][];
};

type Row = {
  membershipId: string;
  costs: number;
};

export function ValidationTable(props: ValidationTableProps) {
  const { t } = useTranslation('reports');

  const rowsPerPage = 10;

  const [search, setSearch] = useSearchParams<string>('search', stringParam(''));
  const rows: [string, Row][] = props.data.map(([membershipId, costs]: [string, number]) => [
    membershipId,
    {
      membershipId,
      costs,
    },
  ]);

  const resultsIncludes = ([, row]: [string, Row]) => {
    const lowerCaseSearch = search.toLocaleLowerCase();
    return row.membershipId.toLowerCase().includes(lowerCaseSearch);
  };

  const compareString = (field: keyof Pick<Row, 'membershipId'>) => (a: [string, Row], b: [string, Row]) =>
    (a[1][field] || '').localeCompare(b[1][field] || '');

  const compareNumber = (field: keyof Pick<Row, 'costs'>) => (a: [string, Row], b: [string, Row]) =>
    (a[1][field] || 0) - (b[1][field] || 0);

  const sorting = useSorting<[string, Row], 'membershipId' | 'costs'>(
    rows,
    {
      membershipId: compareString('membershipId'),
      costs: compareNumber('costs'),
    },
    { key: 'membershipId', direction: 'asc' }
  );
  const filteredRows = sorting.sorted.filter(resultsIncludes);

  const [currentPage, setCurrentPage] = useState<number>(0);

  return (
    <>
      <div className="row my-4">
        <div className="col-4">
          <input
            placeholder={t('global:search')}
            type="search"
            className="form-control"
            value={search}
            onChange={e => setSearch(e.currentTarget.value)}
          />
        </div>
      </div>
      <table className="table table-striped table-hover">
        <thead className="sticky-top bg-white">
          <tr>
            <SortableHeaderCell className="col-4" sorting={sorting} sortKey="membershipId">
              {t('validationTable.membershipId')}
            </SortableHeaderCell>
            <SortableHeaderCell className="col-4" sorting={sorting} sortKey="costs">
              {t('validationTable.costs')}
            </SortableHeaderCell>
          </tr>
        </thead>
        <tbody>
          {filteredRows.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map(([, row]) => (
            <tr key={row.membershipId}>
              <td>{row.membershipId}</td>
              <td>{formatCurrency(row.costs, i18n.language)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={2}>
              <Pagination
                totalItems={filteredRows.length}
                itemsPerPage={10}
                pagesToShow={6}
                currentPageIndex={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}
