{
  "navigation": {
    "notCreated": "pas encore calculé",
    "calculationPending": "calcul en attente",
    "inReview": "En revue",
    "released": "Publié",
    "notReleased": "Pas encore publié"
  },
  "support": {
    "hint": "Besoin d’aide ?"
  },
  "releaseDialog": {
    "notifyAboutRelease": "Notify responsible persons about release",
    "button": {
      "confirm": "Release",
      "cancel": "Cancel"
    }
  },
  "releaseButton": {
    "de": {
      "issueHint": "Not all issues have been fixed.<br />Do you want to release the placings nevertheless?",
      "hint": "Do you want to release the placings?",
      "release": "Release"
    },
    "at": {
      "issueHint": "Not all issues have been fixed.<br/ >Do you want to release the placings nevertheless?",
      "hint": "Do you want to release the placings?",
      "release": "Release"
    }
  },
  "csuReleaseButton": {
    "release": "Suivant"
  },
  "declarationLoader": {
    "loadingFailedHint": "Placements can't be displayed<br/> as they have not yet been released.",
    "notReleased": "Not Released"
  },
  "steps": {
    "de": {
      "import": "Import",
      "importAndCalculate": "Import et calcul",
      "thirdPartyLicensing": "Licences de tiers",
      "calculate": "Calcul",
      "issues": "Problèmes",
      "review": "Examen",
      "adjust": "Autres mises en marché",
      "report": "Remontée des données vers LUCID",
      "license": "Transfert des données vers le fournisseur de service",
      "finish": "Finalisation"
    },
    "at": {
      "import": "Import",
      "importAndCalculate": "Import et calcul",
      "calculate": "Calcul",
      "issues": "Problèmes",
      "review": "Examen",
      "adjust": "Autres mises en marché",
      "license": "Transfert des données vers le fournisseur de service",
      "finish": "Finalisation"
    },
    "fr": {
      "importAndCalculate": "Import et calcul",
      "review": "Examen",
      "finish": "Finalisation"
    }
  },
  "systemInfo": {
    "unknownProviderHint": "Unknown system provider with ID {{id}}"
  },
  "systemOfProducerInfo": {
    "company": "Company",
    "noContract": "<strong>{{producer}}</strong> has no contracts associated with any system providers. Please add a contract.",
    "addContract": "Add",
    "editContract": "Edit"
  },
  "systemOfSupplierInfo": {
    "noThirdPartyLicensing": "No information has been shared by the <strong>supplier (ID: {{supplier}})</strong>.",
    "noThirdPartyLicensingWithName": "No information has been shared by the supplier <strong>{{supplierName}}</strong>.",
    "addThirdPartyLicensing": "Add",
    "editThirdPartyLicensing": "Edit"
  },
  "systemOfCustomerInfo": {
    "noThirdPartyLicensing": "No information has been shared by the <strong>customer (ID: {{customer}})</strong>.",
    "addThirdPartyLicensing": "Add",
    "editThirdPartyLicensing": "Edit"
  },
  "withdrawButton": {
    "confirm": "Êtes-vous sûr de vouloir retirer ces classements ?<br />Les mises en marché seront alors perdues et ne pourront être recalculés qu'en fonction des données les plus récentes.",
    "csuConfirm": "Êtes-vous certain de vouloir abandonner la déclaration ?<br/>Le montant calculé sera perdu. Un nouvel import sera requis pour recalculer votre déclaration.",
    "withdraw": "Retirer"
  },
  "withdrawDialog": {
    "button": {
      "confirm": "Retirer",
      "cancel": "Annuler"
    }
  },
  "availabilityView": {
    "heading": "Disponibilité des données"
  },
  "createStep": {
    "pollingTitle": "Attente de calcul ...",
    "polling": "Les éléments sont en cours de calcul.<br/>Cela peut prendre un moment.",
    "csuPolling": "Les coûts sont en cours de calcul.<br/>Cela peut prendre un moment.",
    "noDataTitle": "Pas de donnée disponible",
    "noData": "Les éléments ne peuvent pas être calculés car les données pour cette période ne sont pas complètes.",
    "notReleasedTitle": "Non validé",
    "notReleased": "Les déclarations ne peuvent pas être affichées car elles n'ont pas encore été validées",
    "resultOk": "La déclaration a été créée."
  },
  "importButton": {
    "pendingLabel": "Traitement",
    "label": "Déposer le fichier Excel"
  },
  "importErrors": {
    "headers": {
      "row": "Ligne",
      "description": "Description",
      "column": "Colonne"
    },
    "downloadButtonText": "Télécharger une liste de toutes les erreurs (CSV)",
    "row": "Ligne",
    "inColumn": "en colonne",
    "Unexpected": "Le document importé ne contient pas le contenu attendu, veuillez utiliser notre modèle.",
    "SheetNotFound": "Le document importé ne contient pas le contenu attendu, veuillez utiliser notre modèle.",
    "Empty": "Tous les champs requis ne sont pas remplis.",
    "Row": {
      "FrUnexpectedDecimalValue": "Valeur impossible pour un champ décimal",
      "FrUnexpectedStringValue": "Valeur impossible pour le champ texte",
      "FrUnexpectedIntValue": "Valeur impossible pour un champ entier",
      "FrUnexpectedBoolValue": "Valeur impossible pour un champ de type oui/non",
      "FrRecycledPetRequiresPercentage": "Pourcentage de PET recylé manquant",
      "FrRecycledPetTraysRequiresPercentage": "Pourcentage manquant pour le PET recyclé des plaques et des pots",
      "FrRecycledFlexiblePeRequiresPercentage": "Pourcentage manquant pour le PE flexible recyclé",
      "FrRecycledRigidPeRequiresPercentage": "Pourcentage manquant pour le PE rigide recyclé",
      "FrRecycledRigidPpRequiresPercentage": "Pourcentage manquant pour le PP rigide recyclé",
      "FrRecycledRigidPsRequiresPercentage": "Pourcentage manquant pour le PS rigide recyclé",
      "FrRecycledPetExceedsTotal": "La quantité de PET recyclé dépasse la quantité totale de PET déclarée dans l'emballage",
      "FrRecycledFlexiblePeHouseholdPercentageExceedsTotal": "La quantité de PE recyclé flexible provenant des ménages dépasse la quantité totale de PE déclarée dans l'emballage",
      "FrRecycledFlexiblePeExceedsTotal": "La quantité de PE recyclé flexible dépasse la quantité totale de PE déclarée dans l'emballage",
      "FrRecycledRigidPeExceedsTotal": "La quantité de PE rigide recyclé dépasse la quantité totale de PE déclarée dans l'emballage",
      "FrRecycledRigidPpExceedsTotal": "La quantité de PP rigide recyclé dépasse la quantité totale de PP déclarée dans l'emballage",
      "FrRecycledRigidPsExceedsTotal": "La quantité de PS rigide recyclé dépasse la quantité totale de PS déclarée dans l'emballage",
      "FrPackagingElementRequired": "Au moins une unité d'emballage est requise",
      "FrSumOfWeightsIsZero": "La somme des poids de tous les matériaux ne peut pas être égale à 0",
      "FrSumOfWeightsExceedsMaximumPossibleWeight": "La somme des poids déclarés n'est pas compatible avec les quantités renseignées du nombre d'unités d'emballage",
      "FrRecycledCardboardRequiresCardboard": "Le bonus pour le carton recyclé exige la présence de carton dans l'emballage",
      "FrPenaltyRequiresPet": "Le malus sélectionné nécessite un poids de PET renseigné",
      "FrPenaltyRequiresGlassAndPet": "Le malus  sélectionné requiert un poids pour le verre et le PET",
      "FrPenaltyRequiresCardboard": "Le malus sélectionné requiert un poids pour le carton",
      "FrPenaltyRequiresRigidPlastic": "Le malus sélectionné requiert un poids pour le plastique rigide",
      "FrPenaltyRequiresGlassAndSteel": "Le malus sélectionné requiert un poids pour le verre et l'acier",
      "FrPenaltyRequiresPvc": "Le malus sélectionné requiert un poids pour le PVC",
      "FrPenaltyRequiresOpaquePet": "Le malus sélectionné requiert un poids pour le PET opaque",
      "FrPenaltyRequiresRigidPetAndAluPvcOrSilicone": "Le malus sélectionné exige un poids pour le PET rigide et au moins un élément parmi l'aluminium, le PVC ou le silicone",
      "FrPenaltyRequiresGlass": "Le malus sélectionné exige un poids pour le verre",
      "FrPenaltyRequiresGlassAndCeramics": "Le malus sélectionné requiert un poids pour le verre et la céramique",
      "FrPenaltyInvalid": "Le malus sélectionné n'a pas été reconnu",
      "FrPenaltyCategoryInvalid": "Le malus sélectionné n'est pas valable pour la catégorie sélectionnée",
      "FrAwarenessRaisingMeasureInvalid": "Le bonus sélectionné pour la sensibilisation n'a pas été reconnu",
      "FrReductionMeasureInvalid": "Le bonus sélectionné pour l'action de réduction n'a pas été reconnu",
      "FrReusedExceedsTotalQuantity": "Le nombre d’emballages réemployés dépasse la quantité totale d'UVC",
      "FrProductCodeInvalid": "La nomenclature de code sélectionnée n'a pas été reconnue",
      "FrProductDescriptionRequired": "Description du produit manquante product description",
      "FrProductCategoryCodeRequired": "Nomenclature de code manquante",
      "FrCsuPutOnMarketRequired": "Le nombre de csu mis sur le marché ne peut pas être 0",
      "FrPackagingElementAboveThresholdRequired": "Au moins une unité de conditionnement supérieure à 0,1 g est requise",
      "FrPercentageOutOfRange": "Les pourcentages de matériaux recyclés doivent être compris entre 0% et 100%",
      "FrPenaltyRequiresPetPePp": "La malus sélectionné nécessite au moins un poids pour le PET, le PE ou le PP",
      "FrRenewableResourcesInvalid": "Le bonus sélectionné pour les ressources renouvelables n'a pas été reconnu"
    }
  },
  "importStep": {
    "downloadText": "Cliquez ici pour télécharger le modèle de fichier Excel :",
    "downloadFrench": "langue française",
    "downloadEnglish": "langue anglaise",
    "noDataTitle": "Pas de donnée disponible",
    "noData": "Déposer votre fichier Excel s'il vous plait.<br />Le calcul se lancera automatiquement une fois les données chargées.",
    "pendingTitle": "En attente",
    "pending": "Vos données sont en cours de traitement",
    "successTitle": "Réussite",
    "success": "Vos données ont été téléchargées avec succès.<br/> Passez, s'il vous plait, à l'étape 2",
    "failedTitle": "Echec de l'import",
    "failed": "Vos données n'ont pas été téléchargées avec succès, car elles contiennent des erreurs<br/> Réglez le problème s'il vous plait et procédez à nouveau au téléchargement",
    "shortErrorListHint": "Un total de {{numberOfErrors}} erreurs ont été détectées.",
    "longErrorListHint": "Seules les {{limit}} premières erreurs sur un total de {{numberOfErrors}} sont affichées."
  },
  "calculateStep": {
    "pollingTitle": "Calcul en cours ...",
    "polling": "Votre déclaration détaillée est en cours de calcul.<br/>Cela peut prendre un moment.",
    "failedTitle": "Echec du calcul",
    "failed": "Il y a eu un problème pour préparer votre déclaration.<br/>Veuillez revoir vos fichiers et les réimporter lors de l'étape d'importation.",
    "notReleasedTitle": "Non validée",
    "notReleased": "La déclaration ne peut pas être affichée car elle n'a pas encore été validée",
    "successTitle": "Calcul terminé",
    "success": "Veuillez passer à l'étape 3 et passer en revue votre déclaration détaillée."
  },
  "notYetCreatedScreen": {
    "title": "Les mises en marché n'ont pas encore été calculés",
    "hint": "Les mises en marché peuvent être calculés maintenant. Veuillez vérifier que les données requises sont présentes.",
    "calculate": "Calculer maintenant"
  },
  "auditorExport": {
    "title": "Auditor Export",
    "subtitle": "All placings including those not subject to system participation, aggregated by",
    "responsibility": "Responsibility",
    "article": "Article",
    "obligation": "Obligation",
    "movementType": "Movement type",
    "packagingType": "Packaging type",
    "reason": "Reason",
    "creationPending": "Creation pending ...",
    "download": "Download"
  },
  "declarationExport": {
    "file": {
      "systemParticipation": {
        "header": "System participation",
        "values": {
          "Yes": "Yes",
          "No": "No",
          "Failed": "Failed"
        }
      },
      "reason": {
        "header": "Reason",
        "values": {
          "ProducerOnPackaging": "Initial distribution",
          "ProducerIsImporter": "Import (Purchaser bears risk)",
          "DelegationFromCustomer": "Service packaging (Delegation)",
          "Shipping": "Shipping package",
          "AdditionalPlacing": "Additional placing",
          "SaleFromBrandOwner": "Sale from brand owner"
        }
      },
      "reportingType": "Reporting (Type)",
      "reportingName": "Reporting (Name)",
      "reportingId": "Reporting (ID)",
      "reportingLUCID": "Reporting (LUCID)",
      "licensingType": "Licensing (Type)",
      "licensingName": "Licensing (Name)",
      "licensingId": "Licensing (ID)",
      "licensingLUCID": "Licensing (LUCID)",
      "paymentType": "Payment (Type)",
      "paymentName": "Payment (Name)",
      "paymentId": "Payment (ID)",
      "paymentLUCID": "Payment (LUCID)",
      "systemProvider": "System provider",
      "glass": "Glass (kg)",
      "fiber": "Fiber (kg)",
      "ferrousMetal": "Ferrous metal (kg)",
      "aluminum": "Aluminum (kg)",
      "plastic": "Plastic (kg)",
      "beverageCarton": "Beverage carton (kg)",
      "other": "Other (kg)",
      "otherComposite": "Other composite (kg)",
      "placings": "Placings",
      "preview": "[Preview]",
      "responsible_type": {
        "Customers": "Customers",
        "Producer": "Producer",
        "Supplier": "Supplier",
        "Customer": "Customer"
      }
    },
    "title": "Export by responsibility",
    "subtitle": "All placings, aggregated by",
    "producer": "Who brought placings into circulation?",
    "licenser": "Who is licensing the placings?",
    "payment": "Who pays the licensing costs?",
    "reason": "What is the reason for placing distribution?",
    "download": "Download",
    "volumes": "Volumes"
  },
  "exportDialog": {
    "title": "Export",
    "buttonText": "Exporter"
  },
  "finishStep": {
    "title": "Terminé",
    "successHint": "The declaration process has been completed.<br /> You can export the reported placings for further analysis or processing.",
    "thankYou": "Merci !"
  },
  "csuFinishStep": {
    "title": "Terminé",
    "successHint": "Le calcul de votre déclaration détaillée est terminé.\n\nVeuillez télécharger les résultats et poursuivre dans votre portail MyLéko.\nVotre fichier téléchargé contient également les résultats pour chaque ligne de votre déclaration.\n\nRemarque : Pour créer une déclaration supplémentaire, cliquez sur le bouton \"tous les producteurs\" ci-dessus et ajoutez un nouveau producteur ou modifiez une déclaration existante.\n\nMerci d’avoir utilisé Calculate !",
    "download": "Télécharger"
  },
  "licenseDetail": {
    "reportInfoTitle": "Please report the placings to the system providers",
    "reportInfoText": "<strong>{{producer}}</strong> licenses the following placings. The placings are licensed with one or more system providers.",
    "markAsReported": "Mark as reported",
    "systemProvider": "System provider",
    "distributedBy": "Distributed by"
  },
  "licenseList": {
    "licensedBy": "Entreprise",
    "status": "Status"
  },
  "licenseListRow": {
    "reported": "reported",
    "notReported": "not reported",
    "showReport": "Show report",
    "reportToSystemProvider": "Report to system provider",
    "noReportRequired": "Report not required",
    "addContract": "Add Contract"
  },
  "licenseStep": {
    "showAllDistributors": "Afficher toutes les entreprises"
  },
  "thirdPartyLicensingForm": {
    "systemProvider": "System provider",
    "delete": "Delete",
    "save": "Save",
    "confirmDeletion": "Are you sure that you want to delete this entry?",
    "hasWeightsLabel": "The licensee has shared the volumes that have been licensed"
  },
  "thirdPartyLicensingStep": {
    "reporting": "Reporting",
    "licensing": "Licensing",
    "systemProvider": "System provider",
    "source": "Source",
    "licensee": "Licensee",
    "calculate": "Calculate",
    "noSystem": "No system provider selected",
    "noValues": "No information has been reported by the licensee",
    "title": "Please compare the calculated weights with those reported by the licensing party",
    "subtitle": "Adjust the calculated weights in accordance with the weights provided to the system provider, taking into account the possible difference between the computed and licensed weights.\n\nSave the updated weights for further use in subsequent stages of the declaration process.",
    "thirdPartyLicensingReportingIntervalHint": "Please be advised that the reporting interval for these volumes is determined by the contracts of the licensees with their system providers."
  },
  "reviewStep": {
    "title": "Please check the calculated placings for plausibility.",
    "subtitle": "If the weights are not plausible you can withdraw the declaration, revise the data and create a new declaration.\nIf the weights are plausible you can release the declaration at this point.\nUpon release all weights will be permanently saved and can not be changed anymore.\nAll persons responsible for reporting to LUCID or system providers will be notified about the release.",
    "company": "Entreprise",
    "notObligated": "Aucune obligation pour la période sélectionnée",
    "compareWith": "Comparer avec"
  },
  "reportDetail": {
    "showDetails": "Show details"
  },
  "reportDetailPerLicenser": {
    "licensedBy": "Licensed by",
    "systemProvider": "System provider"
  },
  "reportDetailPerSystem": {
    "title": "Please report the weights to LUCID",
    "subtitle": "<strong>{{producer}}</strong> brought the following weights into circulation.<br />\nThe weights will be licensed with one or more system providers and have to be reported to LUCID per system provider as well.",
    "markAsReported": "Mark as reported",
    "systemProvider": "System provider",
    "thirdPartyLicensingReportingIntervalHint": "Please be advised that the reporting interval for these volumes is determined by the contracts of the licensees with their system providers."
  },
  "adjustStep": {
    "showAllProducers": "Afficher toutes les entreprises"
  },
  "adjustList": {
    "producer": "Entreprise"
  },
  "adjustListRow": {
    "showPlacings": "Manage placings",
    "noReportRequired": "Report not required",
    "addContract": "Add Contract"
  },
  "reportList": {
    "distributedBy": "Entreprise",
    "status": "Status"
  },
  "reportListRow": {
    "reported": "reported",
    "notReported": "not reported",
    "showReport": "Show report",
    "reportToLUCID": "Report to LUCID",
    "noReportRequired": "Report not required",
    "addContract": "Add Contract"
  },
  "reportStep": {
    "showAllDistributors": "Afficher toutes les entreprises"
  },
  "declarationErrors": {
    "download": {
      "description": "Description",
      "branchId": "Filière ID",
      "articleId": "Article ID",
      "supplierId": "Fournisseur ID",
      "quantity": "Quantité",
      "Issues": "Problèmes"
    },
    "highPriorityTitle": "Problèmes avec la plus haute priorité",
    "highPrioritySubtitle": "Il y a {{number}} problèmes de haute priorité.",
    "downloadTitle": "Télécharger tout",
    "downloadSubtitle": "Vous pouvez télécharger un fichier CSV (UTF-8) avec la liste complète des problèmes.",
    "downloadButtonText": "Télécharger le CSV (UTF-8)"
  },
  "declarationErrorsTable": {
    "issues": {
      "branchRequired": "The branch could not be found.",
      "producerIdRequired": "The ID of the producer is not defined on the branch.",
      "articleRequired": "Article could not be found.",
      "servicePackagingRequired": "Service packaging could not be found.",
      "supplierCountryRequired": "The Country of origin for the article could not be determined.",
      "supplierIdRequired": "The supplier could not be determined.",
      "supplierIncoTermRequired": "The supplier INCO Term could not be determined.",
      "delegationOfLicensingRequired": "Delegation of licensing could not be determined.",
      "delegationOfPaymentRequired": "Delegation of payment could not be determined.",
      "salePackagingRequired": "Sales packaging could not be found.",
      "salePackagingWeightsRequired": "Sales packaging weight is 0 kg.",
      "servicePackagingWeightsRequired": "Service packaging weight is 0 kg.",
      "articleQuantityRequired": "Article quantity was not defined.",
      "shipToCountryRequired": "The country to which delivery is made is missing for at least one movement.",
      "customerDelegatesObligationRequired": "Information about whether there is a delegation agreement for service packaging with the customer is missing for at least one movement.",
      "companyRequired": "Information about which company is responsible for the movement is missing.",
      "producerOnPackagingRequired": "The ID of the producer on the packaging is not defined.",
      "manufacturersRequired": "Information about the manufacturers of the article is missing to detect the first placement of the packaging on the market.",
      "supplierRequired": "Supplier could not be found.",
      "householdPackagingRequired": "",
      "householdPackagingWeightsRequired": "",
      "commercialPackagingRequired": "",
      "commercialPackagingWeightsRequired": ""
    },
    "description": "Description",
    "branchId": "Filière ID",
    "articleId": "Article ID",
    "supplierId": "Fournisseur ID",
    "quantity": "Quantité"
  },
  "issuesStep": {
    "issuesTitle": "Des problèmes ont été identifiés et empêchent la reconnaissance complète des pois des emballages",
    "issuesSubtitle": "les problèmes ont été priorisés automatiquement. Revoyez vos données afin de résoudre les problèmes.<br />\n Afin de résoudre tous les problèmes, les poids actuels doivent être supprimés et recalculés",
    "noIssuesTitle": "Aucun problème détecté",
    "noIssuesSubtitle": "Vous pouvez passer à l'étape de validation.",
    "totalCosts": "Total costs"
  },
  "csuValidateStep": {
    "noIssuesTitle": "Le calcul de la declaration détaillée est réussi.",
    "noIssuesSubtitle": "Cliquez Suivant pour continuer. Cliquez Annuler si vous voulez importer à nouveau.",
    "totalCosts": "coût total"
  },
  "validationTable": {
    "membershipId": "Numéro d'adhérent",
    "costs": "Coût"
  }
}