{
  "branchesTable": {
    "branchId": "ID",
    "name": "Name",
    "producers": "Inverkehrbringer",
    "country": "Land"
  },
  "branchForm": {
    "branchId": "ID",
    "name": "Name",
    "producer": "Inverkehrbringer",
    "country": "Land"
  },
  "branchModal": {
    "title": "Neue Niederlassung",
    "delete": "Löschen",
    "save": "Speichern",
    "confirmDeletion": "Wollen Sie die Niederlassung wirklich löschen?"
  },
  "newBranch": "Neue Niederlassung"
}