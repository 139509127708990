import { Navigate, Outlet, RouteObject } from 'react-router';
import { declarationDetailRoute } from '../../reports/fr/DeclarationDetail';
import { Suspense } from 'react';
import { TenantSwitcher } from '../TenantSwitcher';
import { Header } from '../../../Header';
import { TenantNavigation } from '../TenantNavigation';
import { Fallback } from '../../../Fallback';
import { Producers } from '../../producers/Producers';
import { branchIndexRoute } from '../../branches/BranchIndex';
import { producerIndexRoute } from '../../producers/ProducerIndex';
import { NotFoundInfo } from '../../../shared/components/NotFoundInfo';
import { useTenant } from '../TenantDetail';
import { useYearParam } from '../../../shared/hooks/useParam';

export const yearRoute: RouteObject = {
  path: ':year',
  element: <YearDetail />,
  children: [
    { index: true, element: <Navigate to="./declarations" replace /> },
    declarationDetailRoute,
    branchIndexRoute,
    producerIndexRoute,
  ],
};

function YearDetail() {
  const year = useYearParam();
  const tenant = useTenant();

  // Only 2022, 2023, 2024 are allowed
  if (![2022, 2023, 2024].includes(year)) {
    return <NotFoundInfo />;
  }

  if (!tenant.countries.includes('fr')) {
    return <Navigate to={'../../'} />;
  }

  const tenantNavigation = (
    <div className="d-flex flex-row">
      <TenantSwitcher />
    </div>
  );

  return (
    <>
      <Header tenantNavigation={tenantNavigation}>
        <TenantNavigation year={year} hideNavigation={true} minYear={2022} maxYear={2024} />
      </Header>
      <Suspense fallback={<Fallback />}>
        <Producers>
          <Outlet />
        </Producers>
      </Suspense>
    </>
  );
}
