import { Navigate, RouteObject, useOutletContext } from 'react-router';
import { DeclarationResultAustria } from '../../../../api';
import { Actions } from '../Actions';
import { filterAndGroupResponsibilities, tryGetIdOfProducerThatHasToLicense } from '../helpers';
import { LicenseList } from './LicenseList';
import { ContractEdit } from '../../../contracts/at/ContractEdit';
import { usePeriodParam, useYearParam } from '../../../../shared/hooks/useParam';

export const licenseIndexRoute: RouteObject = {
  children: [
    { index: true, element: <LicenseIndex /> },
    { path: 'contracts/edit/:contractProducerId', element: <ContractEdit /> },
  ],
};

function LicenseIndex() {
  const [period, year] = [usePeriodParam(), useYearParam()];
  const declaration = useOutletContext<DeclarationResultAustria>();

  const producerIds = filterAndGroupResponsibilities(
    tryGetIdOfProducerThatHasToLicense,
    declaration.responsibilities
  ).map(g => g.key);

  if (producerIds.length === 1) {
    return <Navigate to={`./${producerIds[0]}`} replace />;
  }

  return (
    <>
      <Actions year={year} period={period} declaration={declaration} />
      <LicenseList declarationId={declaration.declarationId} producerIds={producerIds} />
    </>
  );
}
