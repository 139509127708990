import { Header } from '../../Header';
import { Icon } from '../../shared/components/Icon';
import { useTranslation } from 'react-i18next';
import { Outlet, RouteObject } from 'react-router';
import * as React from 'react';
import { Suspense } from 'react';
import { stringParam, useSearchParams } from '../../shared/hooks/useSearchParams';
import { useDebouncedState } from '../../shared/hooks/useDebouncedState';
import { usePaging } from '../../shared/hooks/usePaging';
import { TenantListPage } from './TenantListPage';
import { LoadMoreItem } from './LoadMoreItem';
import { NavLink } from 'react-router-dom';
import { addRoute } from './AddDialog';
import { editRoute } from './EditDialog';
import { useModifiedPathname } from '../../shared/hooks/useModifiedPathname';
import { useUser } from '../../public/auth/shared';
import { InfoScreen } from '../../shared/components/InfoScreen';
import { Modal } from '../../shared/components/Modal';

export const tenantIndexRoute: RouteObject = {
  path: '/*',
  element: <TenantIndex />,
  children: [addRoute, editRoute],
};

export function TenantIndex() {
  const { t } = useTranslation('tenants');

  const user = useUser();
  const isLekoUser = user.permissions.leko;

  // Searching
  const [search, setSearch] = useSearchParams('search', stringParam<string>(''));
  const [debouncedSearch, setDebouncedSearch] = useDebouncedState(search);

  // Paging
  const pages = usePaging(0);

  // Adding
  const toAdd = useModifiedPathname('./add');

  // Info
  const [showInfo, setShowInfo] = React.useState(false);

  function clearSearch() {
    setSearch('');
    setDebouncedSearch('');
  }

  return (
    <>
      <Header />
      <div className="pt-2 pb-2 container d-flex flex-column align-items-center">
        <Icon name="diagram-3-fill" className="fs-1" />
        <h1 className="mb-4">{t('overview.heading')}</h1>
        <InfoScreen bgColor="info" color="info" icon="info-circle-fill" className="w-100 text-center">
          <p className="fs-5 fw-bold text-muted text-center mt-2 mb-4">{t('overview.hint')}</p>
          {isLekoUser && (
            <NavLink className="btn fs-5 btn-secondary text-nowrap ms-2" to={toAdd}>
              {t('tenantModal.add')}
            </NavLink>
          )}
          <br />
          <button className="bg-transparent border-0 mt-3 text-muted" onClick={() => setShowInfo(true)}>
            ({t('moreInformation.buttonLabel')})
          </button>
          {showInfo && (
            <Modal size="lg" title={t('moreInformation.modalTitle')} visible onClose={() => setShowInfo(false)}>
              <div>
                <h1 className="h5">{t('moreInformation.contentTitle')}</h1>
                <div dangerouslySetInnerHTML={{ __html: t('moreInformation.contentBody') }}></div>
              </div>
            </Modal>
          )}
        </InfoScreen>
        <div className="card col-12">
          <div className="card-header sticky-top bg-light d-flex">
            <input
              className="form-control"
              placeholder={t('global:search')}
              type="search"
              value={search}
              onChange={e => setSearch(e.currentTarget.value)}
            />
          </div>
          <nav className="list-group list-group-flush">
            {pages.map(p => {
              const page = (
                <TenantListPage
                  key={p.index}
                  pageIndex={p.index}
                  search={debouncedSearch}
                  onLoadMore={p.onLoadMore}
                  isLekoUser={isLekoUser}
                />
              );
              return p.initial ? (
                page
              ) : (
                <Suspense key={p.index} fallback={<LoadMoreItem disabled />}>
                  {page}
                </Suspense>
              );
            })}
          </nav>
        </div>
      </div>
      <Suspense>
        <Outlet context={clearSearch} />
      </Suspense>
    </>
  );
}
