﻿import { CompanyInfo } from './CompanyInfo';
import { useTranslation } from 'react-i18next';

export interface Props {
  name: string;
}

export function CustomerInfo({ name }: Props) {
  const { t } = useTranslation();

  return <CompanyInfo companyId={name} badge={t('global:customer')} />;
}
