﻿import { PeriodNavigationItem } from './PeriodNavigationItem';
import { getPeriods, Period, PeriodStatus } from '../../api';
import { useTenantQuery } from '../../shared/hooks/useTenantQuery';
import { useQueryState } from '../../shared/hooks/useQueryState';
import { Dropdown } from 'react-bootstrap';
import { getNameOfPeriod } from '../../helpers';
import { useParams } from 'react-router';
import { PeriodStatusBadge } from './PeriodStatusBadge';
import { useContracts } from '../contracts/Contracts';
import { useTranslation } from 'react-i18next';
import { useCountryParam } from '../../shared/hooks/useParam';
import { toDeclarationCountry } from './helpers';

export interface Props {
  year: number;
}

export function PeriodNavigation({ year }: Props) {
  const { period } = useParams<{ period: Period }>();
  const country = toDeclarationCountry(useCountryParam());
  const [periods] = useQueryState(useTenantQuery(getPeriods, { country: country, year }, false));
  const contracts = useContracts();
  const { t } = useTranslation();

  const statusOfPeriod = (p: Period): PeriodStatus => {
    const [, status] = periods.find(([other]: [Period, PeriodStatus]) => other === p) ?? [p, 'NotYetCreated'];
    return status;
  };

  const isMonth = ([p]: [Period, PeriodStatus]) =>
    ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].includes(p);
  const isYear = ([p]: [Period, PeriodStatus]) => p === 'Year';
  const isQuarter = ([p]: [Period, PeriodStatus]) => ['Q1', 'Q2', 'Q3', 'Q4'].includes(p);
  const isHalfYear = ([p]: [Period, PeriodStatus]) => ['FirstHalfYear', 'SecondHalfYear'].includes(p);

  const availablePeriods = periods.filter(period => {
    if (country === 'Austria') {
      return isMonth(period) || isQuarter(period) || isYear(period);
    }

    return (
      (isMonth(period) && contracts.some(contract => contract.interval === 'Monthly')) ||
      (isQuarter(period) && contracts.some(contract => contract.interval === 'Quarterly')) ||
      (isHalfYear(period) && contracts.some(contract => contract.interval === 'BiYearly')) ||
      isYear(period)
    );
  });

  const disabled = availablePeriods.length === 1 && availablePeriods[0][0] === period;

  return (
    <div className={'row ms-0'}>
      <div className={'ps-0 my-4'}>
        <Dropdown>
          <Dropdown.Toggle
            disabled={disabled}
            variant="outline-secondary"
            className="form-select period-selector text-start w-100"
            data-toggle="dropdown">
            <div className="d-flex">
              <span className="flex-grow-1">{getNameOfPeriod(period ?? 'Year')}</span>
              <span className="me-4">
                <PeriodStatusBadge status={statusOfPeriod(period ?? ('Year' as Period))}></PeriodStatusBadge>
              </span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            style={{ zIndex: 10000, minWidth: '240px', maxHeight: '50vh', overflowY: 'scroll', overflowX: 'hidden' }}>
            <div className="row px-2 h-50">
              <div className="col-12">
                {availablePeriods.some(isMonth) ? <span className="dropdown-header">{t('global:month')}</span> : <></>}
                {availablePeriods.filter(isMonth).map(([period, status], index) => (
                  <PeriodNavigationItem key={index} year={year} period={period} status={status} />
                ))}
                {availablePeriods.some(isQuarter) ? (
                  <span className="dropdown-header">{t('global:quarter')}</span>
                ) : (
                  <></>
                )}
                {availablePeriods.filter(isQuarter).map(([period, status], index) => (
                  <PeriodNavigationItem key={index} year={year} period={period} status={status} />
                ))}
                {availablePeriods.some(isHalfYear) ? (
                  <span className="dropdown-header">{t('global:halfYear')}</span>
                ) : (
                  <></>
                )}
                {availablePeriods.filter(isHalfYear).map(([period, status], index) => (
                  <PeriodNavigationItem key={index} year={year} period={period} status={status} />
                ))}
                {availablePeriods.some(isYear) ? <span className="dropdown-header">{t('global:year')}</span> : <></>}
                {availablePeriods.filter(isYear).map(([period, status]) => (
                  <PeriodNavigationItem key={period} year={year} period={period} status={status} />
                ))}
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}
