﻿import { DeclarationResultAustria, Period } from '../../../api';
import { Step } from './Step';
import { useTranslation } from 'react-i18next';
import { useTenant } from '../../tenants/TenantDetail';
import { useReports } from '../Reports';

export interface Props {
  declaration: DeclarationResultAustria | null;
  year: number;
  period: Period;
}

function StepsWithoutDeclaration() {
  const { t } = useTranslation('reports');
  const tenant = useTenant();
  const isCentralUser = tenant.permissions.central;
  return (
    <div className="list-group list-group-flush">
      {isCentralUser && (
        <>
          <Step name="create" title={t('steps.at.calculate')} />
          <Step disabled name="issues" title={t('steps.at.issues')} />
          <Step disabled name="review" title={t('steps.at.review')} />
        </>
      )}
      <Step disabled name="license" title={t('steps.at.license')} />
      <Step disabled name="finish" title={t('steps.at.finish')} />
    </div>
  );
}

function useReportsStatus(year: number, period: Period, declaration: DeclarationResultAustria) {
  const reports = useReports();

  const licensed = declaration.responsibilities.every(r => {
    return (
      typeof r.licensing === 'string' ||
      r.licensing?.Case !== 'Producer' ||
      reports.get(declaration.declarationId, r.licensing.Fields[0])?.proReportedAt != null
    );
  });

  return { licensed };
}

function StepsWithDeclaration({
  declaration,
  year,
  period,
}: {
  declaration: DeclarationResultAustria;
  year: number;
  period: Period;
}) {
  const status = useReportsStatus(year, period, declaration);
  const { t } = useTranslation('reports');

  const tenant = useTenant();
  const isCentralUser = tenant.permissions.central;

  return (
    <div className="list-group list-group-flush">
      <Step disabled name="create" title={t('steps.at.calculate')} />
      {isCentralUser && (
        <>
          <Step
            disabled={declaration.kind !== 'Preview' && declaration.kind !== 'Release'}
            name="issues"
            title={t('steps.at.issues')}
          />
          <Step
            disabled={declaration.kind !== 'Preview' && declaration.kind !== 'Release'}
            done={declaration.kind === 'Release'}
            name="review"
            title={t('steps.at.review')}
          />
        </>
      )}
      <Step
        done={status.licensed}
        disabled={declaration.kind !== 'Release'}
        name="license"
        title={t('steps.at.license')}
      />
      <Step
        done={status.licensed}
        disabled={declaration.kind !== 'Release' || !status.licensed}
        name="finish"
        title={t('steps.at.finish')}
      />
    </div>
  );
}

export function Steps({ declaration, year, period }: Props) {
  return declaration ? (
    <StepsWithDeclaration declaration={declaration} year={year} period={period} />
  ) : (
    <StepsWithoutDeclaration />
  );
}
