import { Field } from './Field';
import { localeField, StateOf } from '../../forms';
import { ColSize } from './Col';
import { Locale, LocaleEnumerable } from '../../api';
import { useTranslation } from 'react-i18next';
import { InputGroup } from './InputGroup';

export interface Props {
  id: string;
  label: string;
  field: StateOf<typeof localeField>;
  focusOnMount?: boolean;
  disabled?: boolean;
  size?: ColSize;
}

interface LocaleOption {
  label: string;
  value: Locale;
}

function compareCountryOptions(a: LocaleOption, b: LocaleOption) {
  return a.label.localeCompare(b.label);
}

export function LocaleField(props: Props) {
  const className = props.field.status.valid ? 'form-select' : 'form-select is-invalid';

  const { t } = useTranslation();

  const options = LocaleEnumerable.map(value => ({ label: t(`locales.${value}`), value })).sort(compareCountryOptions);

  function onChange(e: React.FormEvent<HTMLSelectElement>) {
    props.field.setValue(e.currentTarget.value === '' ? null : (e.currentTarget.value as Locale));
  }

  return (
    <Field size={props.size}>
      <label className="form-label" htmlFor={props.id}>
        {props.label}
      </label>
      <InputGroup field={props.field}>
        <select
          id={props.id}
          disabled={props.disabled}
          className={className}
          value={props.field.value ?? ''}
          onChange={onChange}
          onFocus={() => props.field.setTouched(true)}>
          <option key="" value=""></option>
          {options.map(o => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      </InputGroup>
    </Field>
  );
}
