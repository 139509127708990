﻿import { stringParam, useSearchParams } from '../../../../shared/hooks/useSearchParams';
import { useProducers } from '../../../producers/Producers';
import { LicenseListRow } from './LicenseListRow';
import { useSorting } from '../../../../shared/hooks/useSorting';
import { SortableHeaderCell } from '../../../../shared/components/SortableHeaderCell';
import { useReports } from '../../Reports';
import { useTranslation } from 'react-i18next';

export interface Props {
  declarationId: number;
  producerIds: string[];
}

export function LicenseList({ declarationId, producerIds }: Props) {
  const { t } = useTranslation('reports');
  const [search, setSearch] = useSearchParams<string>('search', stringParam(''));

  const lowerCasedSearch = search.toLocaleLowerCase();

  function containsSearch(value: string | null) {
    return value?.toLocaleLowerCase().includes(lowerCasedSearch) ?? false;
  }

  const producers = useProducers().accessible();
  const reports = useReports();

  const filtered = producerIds.filter(producerId => {
    if (containsSearch(producerId)) {
      return true;
    }

    const producer = producers[producerId];
    return (
      containsSearch(producerId) ||
      (producer != null && (containsSearch(producer.name) || containsSearch(producer.lucidNumber)))
    );
  });

  function compareName(a: string, b: string) {
    const namea = producers[a]?.name ?? a;
    const nameb = producers[b]?.name ?? b;
    return namea.localeCompare(nameb);
  }

  const compareReported = (a: string, b: string) => {
    const x = !!reports.get(declarationId, a)?.proReportedAt;
    const y = !!reports.get(declarationId, b)?.proReportedAt;
    return x === y ? 0 : x ? -1 : 1;
  };

  const sorting = useSorting<string, 'name' | 'reported'>(
    filtered,
    {
      name: compareName,
      reported: compareReported,
    },
    { key: 'name', direction: 'asc' }
  );

  return (
    <>
      <div className="row my-4">
        <div className="col-4">
          <input
            placeholder={t('global:search')}
            type="search"
            className="form-control"
            value={search}
            onChange={e => setSearch(e.currentTarget.value)}
          />
        </div>
      </div>
      <table className="table table-striped">
        <thead className="sticky-top bg-white">
          <tr>
            <SortableHeaderCell sorting={sorting} sortKey="name">
              {t('licenseList.licensedBy')}
            </SortableHeaderCell>
            <SortableHeaderCell sorting={sorting} sortKey="reported">
              {t('licenseList.status')}
            </SortableHeaderCell>
            <th />
          </tr>
        </thead>
        <tbody>
          {sorting.sorted.map(producerId => (
            <LicenseListRow key={producerId} declarationId={declarationId} producerId={producerId} />
          ))}
        </tbody>
      </table>
    </>
  );
}
