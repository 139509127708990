import * as React from 'react';
import { InfoScreen } from './InfoScreen';
import { useTranslation } from 'react-i18next';

export interface Props {
  children?: React.ReactNode;
}

export function ForbiddenInfo(props: Props) {
  const { t } = useTranslation();
  return (
    <InfoScreen icon="lock" title={t('global:forbidden.forbiddenTitle')}>
      <p>{t('global:forbidden.forbiddenSubtitle')}</p>
      {props.children}
    </InfoScreen>
  );
}
