﻿import { PackagingWeights } from '../../../../api';
import { SystemForLicensing } from '../helpers';
import { SystemInfo } from '../SystemInfo';
import { WeightsRowCells } from '../../../../WeightsRowCells';
import { expectNever } from '../../../../helpers';
import { SystemsOfCustomersInfo } from '../SystemsOfCustomersInfo';
import { SystemOfProducerInfo } from '../SystemOfProducerInfo';
import { SystemOfSupplierInfo } from '../SystemOfSupplierInfo';
import { SystemOfCustomerInfo } from '../SystemOfCustomerInfo';

export interface Props {
  producerId: string;
  system: SystemForLicensing;
  count: number;
  weights: PackagingWeights;
}

function SystemForLicensingInfo({
  producerId,
  system,
  count,
}: {
  producerId: string;
  system: SystemForLicensing;
  count: number;
}) {
  if (system.type === 'SystemsOfCustomers') {
    return <SystemsOfCustomersInfo />;
  }

  // System not defined for a supplier
  if (system.type === 'SystemOfSupplier') {
    return <SystemOfSupplierInfo producerId={producerId} supplierId={system.supplierId} allowUpdate />;
  }

  // System not defined for a customer
  if (system.type === 'SystemOfCustomer') {
    return <SystemOfCustomerInfo producerId={producerId} customerId={system.customerId} allowUpdate />;
  }

  // System not defined for a producer
  if (system.type === 'SystemOfProducer') {
    return <SystemOfProducerInfo producerId={system.producerId} allowUpdate />;
  }

  if (system.type === 'System') {
    return <SystemInfo systemId={system.systemId} count={count} />;
  }

  expectNever(system);
}

export function ReportDetailPerSystemRow({ producerId, system, count, weights }: Props) {
  return (
    <tr>
      <td className="align-middle">
        <SystemForLicensingInfo producerId={producerId} system={system} count={count} />
      </td>
      <WeightsRowCells weights={weights} />
    </tr>
  );
}
