import { Navigate, Outlet, RouteObject, useOutletContext } from 'react-router';
import { DeclarationResultGermany } from '../../../../api';
import { Actions } from '../Actions';
import { AdjustList } from './AdjustList';
import { ContractEdit } from '../../../contracts/de/ContractEdit';
import { usePeriodParam, useYearParam } from '../../../../shared/hooks/useParam';
import { useVisibleProducers } from './useVisibleProducers';

export const adjustIndexRoute: RouteObject = {
  element: <AdjustIndex />,
  children: [{ index: true }, { path: 'contracts/edit/:contractProducerId', element: <ContractEdit /> }],
};

export function AdjustIndex() {
  const year = useYearParam();
  const period = usePeriodParam();
  const declaration = useOutletContext<DeclarationResultGermany | null>();

  const producerIds = useVisibleProducers(year, period);

  if (producerIds.length === 1) {
    return <Navigate to={`./${producerIds[0]}`} replace />;
  }

  return (
    <>
      {declaration ? <Actions releaseButtonDisabled year={year} period={period} declaration={declaration} /> : <></>}
      <AdjustList year={year} period={period} producerIds={producerIds} />
      <Outlet />
    </>
  );
}
