import { useTranslation } from 'react-i18next';

export function SupportHint() {
  const { t } = useTranslation('reports');
  return (
    <div className="p-3">
      <p className="text-primary fw-bold h4">{t('support.hint')}</p>
      <p className="text-primary">
        <a href="mailto:declaration@leko-organisme.fr" target="_blank" rel="noreferrer">
          declaration@leko-organisme.fr
        </a>
      </p>
    </div>
  );
}
