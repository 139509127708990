﻿import { useTenant } from '../tenants/TenantDetail';
import { ContractValue, getContracts, upsertContract, deleteContract, DeclarationCountry } from '../../api';
import { createContext, ReactNode, useContext } from 'react';
import { useTenantQuery } from '../../shared/hooks/useTenantQuery';
import { useQueryState } from '../../shared/hooks/useQueryState';

export interface ContractsService {
  get(producerId: string): ContractValue | null;

  has(producerId: string): boolean;

  set(producerId: string, value: ContractValue): Promise<void>;

  remove(producerId: string): Promise<void>;

  all: Record<string, ContractValue>;

  some(predicate: (value: ContractValue, index: number) => boolean): boolean;
}

const ContractsContext = createContext<ContractsService | null>(null);

export function Contracts(props: { year: number; country: DeclarationCountry; children?: ReactNode }) {
  const tenant = useTenant();

  const query = useTenantQuery(getContracts, { year: props.year, country: props.country }, false);
  const [contracts, setContracts] = useQueryState(query);

  function get(producerId: string): ContractValue | null {
    return contracts[producerId] ?? null;
  }

  function has(producerId: string): boolean {
    return get(producerId) !== null;
  }

  function some(predicate: (value: ContractValue, index: number) => boolean): boolean {
    return Object.values(contracts).some(predicate);
  }

  async function remove(producerId: string) {
    try {
      const contractId = contracts[producerId].contractId;

      setContracts(prev => {
        const tmp = { ...prev };
        delete tmp[producerId];
        return tmp;
      });

      if (contractId) {
        await deleteContract({ tenantId: tenant.id, data: { contractId, producerId } });
      }
    } finally {
      await query.revalidate();
    }
  }

  async function set(producerId: string, value: ContractValue) {
    try {
      setContracts(prev => ({ ...prev, [producerId]: value }));

      // mutate remote
      await upsertContract({
        tenantId: tenant.id,
        data: {
          ...value,
          country: props.country,
        },
      });
    } finally {
      await query.revalidate();
    }
  }

  return (
    <ContractsContext.Provider value={{ get, set, remove, has, some, all: contracts }}>
      {props.children}
    </ContractsContext.Provider>
  );
}

export function useContracts(): ContractsService {
  const service = useContext(ContractsContext);

  if (service == null) {
    throw new Error('Contracts must be defined higher in tree.');
  }

  return service;
}
