﻿import { useTranslation } from 'react-i18next';
import { useSystems } from '../../systems/Systems';

export function SystemInfo(props: { systemId: number; count: number }) {
  const { t } = useTranslation('reports');
  const systems = useSystems();
  const system = systems.get(props.systemId);

  if (system == null) {
    return (
      <div className="d-flex flex-row">
        <div className="p-2">
          <span className="badge bg-danger me-2">{props.count}</span>
        </div>
        <div className="text-danger flex-grow-1">{t('systemInfo.unknownProviderHint', { id: props.systemId })}</div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-row align-items-center">
      <div className="flex-grow-1">{system.name}</div>
      {props.count > 1 && (
        <div className="p-2 ms-2">
          <span className="badge bg-primary">{props.count}</span>
        </div>
      )}
    </div>
  );
}
