import * as React from 'react';
import { Field } from './Field';
import { countryField, StateOf } from '../../forms';
import { ColSize } from './Col';
import { Country, CountryEnumerable } from '../../api';
import { useTranslation } from 'react-i18next';
import { InputGroup } from './InputGroup';

export interface Props {
  id: string;
  label: string;
  field: StateOf<typeof countryField>;
  focusOnMount?: boolean;
  disabled?: boolean;
  size?: ColSize;
}

interface CountryOption {
  label: string;
  value: Country;
}

function compareCountryOptions(a: CountryOption, b: CountryOption) {
  if (a.value === 'Unknown') {
    return 1;
  }

  if (b.value === 'Unknown') {
    return -1;
  }

  return a.label.localeCompare(b.label);
}

export function CountryField(props: Props) {
  const className = props.field.status.valid ? 'form-select' : 'form-select is-invalid';

  const { t } = useTranslation();

  const options = CountryEnumerable.map(value => ({ label: t(`countries.${value}`), value })).sort(
    compareCountryOptions
  );

  function onChange(e: React.FormEvent<HTMLSelectElement>) {
    props.field.setValue(e.currentTarget.value === '' ? null : (e.currentTarget.value as Country));
  }

  return (
    <Field size={props.size}>
      <label className="form-label" htmlFor={props.id}>
        {props.label}
      </label>
      <InputGroup field={props.field}>
        <select
          id={props.id}
          disabled={props.disabled}
          className={className}
          value={props.field.value ?? ''}
          onChange={onChange}
          onFocus={() => props.field.setTouched(true)}>
          <option key="" value=""></option>
          {options.map(o => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      </InputGroup>
    </Field>
  );
}
