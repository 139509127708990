﻿import { Navigate, Outlet, RouteObject, useOutletContext } from 'react-router';
import { DeclarationResultGermany, getPeriods, Period, tryGetDeclarationAustria } from '../../../api';
import { createRoute } from './create/CreateStep';
import { issuesRoute } from './issues/IssuesStep';
import { useTenant } from '../../tenants/TenantDetail';
import { expectNever } from '../../../helpers';
import { Trans, useTranslation } from 'react-i18next';
import { cache } from '../../../shared/hooks/useQuery';
import { usePolling } from '../../../shared/hooks/usePolling';
import { InfoScreen } from '../../../shared/components/InfoScreen';
import { Steps } from './Steps';
import { Suspense } from 'react';
import { Fallback } from '../../../Fallback';
import { licenseRoute } from './license/LicenseStep';
import { finishRoute } from './finish/FinishStep';
import { useTenantQuery } from '../../../shared/hooks/useTenantQuery';
import { useTenantRevalidate } from '../../../shared/hooks/useTenantRevalidate';
import { useQueryValue } from '../../../shared/hooks/useQueryValue';
import { PeriodNavigation } from '../PeriodNavigation';
import { usePeriodParam, useYearParam } from '../../../shared/hooks/useParam';
import { reviewRoutes } from './review/ReviewStep';
import { Reports } from '../Reports';

function NavigateToInitialStep() {
  const declaration = useOutletContext<DeclarationResultGermany>();
  const tenant = useTenant();

  function getRedirectTo() {
    if (!tenant.permissions.central) {
      return './license';
    }

    if (declaration === null) {
      return './create';
    }

    if (declaration.kind === 'Preview') {
      if (declaration.errors.length > 0) {
        return './issues';
      }

      return './review';
    }

    if (declaration.kind === 'Release') {
      return './license';
    }

    expectNever(declaration.kind);
  }

  return <Navigate to={getRedirectTo()} replace />;
}

export const declarationDetailRoute: RouteObject = {
  path: ':period',
  element: <DeclarationDetail />,
  children: [
    { index: true, element: <NavigateToInitialStep /> },
    createRoute,
    issuesRoute,
    ...reviewRoutes,
    licenseRoute,
    finishRoute,
  ],
  loader: ({ params }) => {
    const tenantId = parseInt(params.tenantId || '');
    const year = parseInt(params.year || '');
    const period = params.period as Period;
    const resource = cache.getResource(tryGetDeclarationAustria, { tenantId, data: { year, period } });
    if (resource.state.status !== 'pending' && resource.state.status !== 'revalidating') {
      resource.revalidate(false).then();
    }
  },
};

export function DeclarationDetail() {
  const [year, period] = [useYearParam(), usePeriodParam()];

  const { t } = useTranslation('reports');
  const revalidatePeriods = useTenantRevalidate(getPeriods, { country: 'Austria', year });
  const declarationQuery = useTenantQuery(tryGetDeclarationAustria, { year, period }, false);
  const declarationValue = useQueryValue(declarationQuery);

  async function revalidate() {
    await Promise.all([revalidatePeriods(), declarationQuery.revalidate()]);
  }

  usePolling(declarationValue, revalidate);

  const tenant = useTenant();

  const declaration =
    declarationValue !== 'Running' && declarationValue.Case !== 'Failed' ? declarationValue.Fields[0] : null;

  if (!tenant.permissions.central && declaration === null) {
    return (
      <div className="row mx-4 align-items-start">
        <div className="col-3 list-group list-group-flush sticky-top">
          <PeriodNavigation year={year} />
        </div>
        <InfoScreen title={t('declarationLoader.notReleased')} icon="x-circle">
          <div>
            <Trans t={t}>{t('declarationLoader.loadingFailedHint')}</Trans>
          </div>
        </InfoScreen>
      </div>
    );
  }

  return (
    <Reports country="Austria" year={year} period={period}>
      <div className="row mx-4 align-items-start">
        <div className="col-2 list-group list-group-flush sticky-top">
          <PeriodNavigation year={year} />
          <Steps declaration={declaration} year={year} period={period} />
        </div>
        <div className="col-10">
          <Suspense fallback={<Fallback />}>
            <Outlet context={declaration} />
          </Suspense>
        </div>
      </div>
    </Reports>
  );
}
