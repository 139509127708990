﻿import {
  ContractValue,
  DeclarationInterval,
  PackagingWeights,
  Period,
  Responsibility,
  Responsible,
  ThirdPartyLicensee,
  ThirdPartyLicensing,
} from '../../../api';
import { expectNever, notNullOrUndefined } from '../../../helpers';

export function getEmptyWeights(): PackagingWeights {
  return {
    glass: 0,
    fiber: 0,
    ferrousMetal: 0,
    aluminum: 0,
    plastic: 0,
    beverageCarton: 0,
    otherComposite: 0,
    other: 0,
  };
}

export function addWeights(a: PackagingWeights, b: PackagingWeights): PackagingWeights {
  return {
    glass: a.glass + b.glass,
    fiber: a.fiber + b.fiber,
    ferrousMetal: a.ferrousMetal + b.ferrousMetal,
    aluminum: a.aluminum + b.aluminum,
    plastic: a.plastic + b.plastic,
    beverageCarton: a.beverageCarton + b.beverageCarton,
    otherComposite: a.otherComposite + b.otherComposite,
    other: a.other + b.other,
  };
}

export function sumWeights(w: PackagingWeights[]): PackagingWeights {
  return w.reduce(addWeights, getEmptyWeights());
}

function serializeToKeyReplacer(key: string, value: any) {
  if (value === null) {
    return null;
  }

  if (typeof value === 'object') {
    const keys = Object.keys(value);
    keys.sort();
    const data: any = {};
    for (const key of keys) {
      data[key] = value[key];
    }
    return data;
  }

  return value;
}

export function serializeToKey(value: any) {
  return JSON.stringify(value, serializeToKeyReplacer);
}

export function addOrUpdateMap<K, V>(map: Map<K, V>, key: K, initialValue: V, update: (prev: V) => V) {
  const prev = map.get(key) ?? initialValue;
  const next = update(prev);
  map.set(key, next);
}

export function addOrUpdateRecord<V>(record: Record<string, V>, key: string, initialValue: V, update: (prev: V) => V) {
  const prev = key in record ? record[key] : initialValue;
  const next = update(prev);
  return {
    ...record,
    [key]: next,
  };
}

export function getKeyOfRow({ decision, reporting, licensing, payment }: Responsibility) {
  return serializeToKey({ decision, reporting, licensing, payment });
}

export interface Group<K> {
  key: K;
  keyAsString: string;
  responsibilities: Responsibility[];
}

export function groupResponsibilities<K>(
  getKey: (responsibility: Responsibility) => K,
  rows: Responsibility[]
): Group<K>[] {
  const result: Record<string, Group<K>> = {};

  for (const row of rows) {
    const key = getKey(row);
    const keyAsString = serializeToKey(key);

    if (keyAsString in result) {
      result[keyAsString].responsibilities.push(row);
    } else {
      result[keyAsString] = {
        key,
        keyAsString,
        responsibilities: [row],
      };
    }
  }

  return Object.values(result);
}

export function filterAndGroupResponsibilities<K>(
  getKey: (responsibility: Responsibility) => K | null,
  rows: Responsibility[]
): Group<K>[] {
  const result: Record<string, Group<K>> = {};

  for (const row of rows) {
    const key = getKey(row);

    if (key === null) {
      continue;
    }

    const keyAsString = serializeToKey(key);

    if (keyAsString in result) {
      result[keyAsString].responsibilities.push(row);
    } else {
      result[keyAsString] = {
        key,
        keyAsString,
        responsibilities: [row],
      };
    }
  }

  return Object.values(result);
}

export interface Aggregation<K> {
  key: K;
  keyAsString: string;
  weights: PackagingWeights;
  comparisonWeights: PackagingWeights | null;
}

export function aggregateWeights<K>(
  getKey: (row: Responsibility) => K,
  responsibilities: Responsibility[]
): Aggregation<K>[] {
  const result: Record<string, Aggregation<K>> = {};

  for (const responsibility of responsibilities) {
    const key = getKey(responsibility);
    const keyAsString = serializeToKey(key);

    if (keyAsString in result) {
      result[keyAsString].weights = addWeights(result[keyAsString].weights, responsibility.weights);
    } else {
      result[keyAsString] = {
        key,
        keyAsString,
        weights: responsibility.weights,
        comparisonWeights: null,
      };
    }
  }

  return Object.values(result);
}

/**
 * Maps a third party licensing to a responsibility, or returns null if the weights are defined.
 * @param licensing The third party licensing.
 */
function thirdPartyLicensingToResponsibilityOrNull(licensing: ThirdPartyLicensing): Responsibility | null {
  return licensing.weights == null
    ? null
    : {
        decision: { Case: 'Yes', Fields: ['AdditionalPlacing'] },
        reporting: { Case: 'Producer', Fields: [licensing.producerId] },
        payment: { Case: 'Producer', Fields: [licensing.producerId] },
        licensing: { Case: licensing.licensee.Case, Fields: licensing.licensee.Fields },
        weights: licensing.weights,
      };
}

export function replaceResponsibilitiesWithThirdPartyLicensing(
  responsibilities: Responsibility[],
  thirdPartyLicensings: Record<string, ThirdPartyLicensing>
): Responsibility[] {
  const thirdPartyResponsibilities: Responsibility[] = Object.values(thirdPartyLicensings)
    .map(thirdPartyLicensingToResponsibilityOrNull)
    .filter(notNullOrUndefined);

  const filtered = responsibilities.filter(responsibility => {
    if (typeof responsibility.reporting !== 'object' || responsibility.reporting === null) {
      return true;
    }

    if (typeof responsibility.licensing !== 'object' || responsibility.licensing === null) {
      return true;
    }

    if (responsibility.licensing.Case === 'Producer' || responsibility.reporting.Case !== 'Producer') {
      return true;
    }

    return !thirdPartyResponsibilities.some(
      thirdPartyLicensing =>
        JSON.stringify(thirdPartyLicensing.reporting) === JSON.stringify(responsibility.reporting) &&
        JSON.stringify(thirdPartyLicensing.licensing) === JSON.stringify(responsibility.licensing)
    );
  });

  return [...filtered, ...thirdPartyResponsibilities];
}

export function filterAndAggregateWeights<K>(
  getKey: (row: Responsibility) => K | null,
  responsibilities: Responsibility[],
  comparisonResponsibilities: Responsibility[] = []
): Aggregation<K>[] {
  const responsibilityMapper = (values: Responsibility[]) => {
    return values.reduce<{ [key: string]: Pick<Aggregation<K>, 'key' | 'keyAsString' | 'weights'> }>(
      (prev, current: Responsibility) => {
        const key = getKey(current);

        if (key === null) {
          return prev;
        }

        const keyAsString = serializeToKey(key);

        prev[keyAsString] = prev[keyAsString]
          ? { weights: addWeights(prev[keyAsString].weights, current.weights), key, keyAsString }
          : { weights: current.weights, key, keyAsString };

        return prev;
      },
      {}
    );
  };

  const mappedResponsibilities = responsibilityMapper(responsibilities);
  const mappedComparisonResponsibilities = responsibilityMapper(comparisonResponsibilities);

  return Object.entries(mappedResponsibilities).map(([key, items]) => {
    return {
      key: items.key,
      keyAsString: items.keyAsString,
      weights: items.weights,
      comparisonWeights: mappedComparisonResponsibilities[key] ? mappedComparisonResponsibilities[key].weights : null,
    };
  });
}

export interface AggregationWithSuppliers<K> {
  key: K;
  keyAsString: string;
  suppliers: string[];
  weights: PackagingWeights;
}

export interface AggregationWithThirdPartyLicensing<K> {
  key: K;
  keyAsString: string;
  producer: string;
  licensee: string;
  licenseeType: 'Customer' | 'Supplier';
  weights: PackagingWeights;
  thirdPartyLicensing: ThirdPartyLicensing | null;
}

export function aggregateWeightsWithSuppliers<K>(
  getKey: (row: Responsibility) => K,
  rows: Responsibility[]
): AggregationWithSuppliers<K>[] {
  const result: Record<string, AggregationWithSuppliers<K>> = {};

  for (const row of rows) {
    const key = getKey(row);
    const keyAsString = serializeToKey(key);

    if (keyAsString in result) {
      const aggregation = result[keyAsString];

      if (typeof row.licensing === 'object' && row.licensing?.Case === 'Supplier') {
        const suppliers = new Set(aggregation.suppliers);
        suppliers.add(row.licensing.Fields[0]);
        aggregation.suppliers = Array.from(suppliers);
      }

      aggregation.weights = addWeights(aggregation.weights, row.weights);
    } else {
      result[keyAsString] = {
        key,
        keyAsString,
        suppliers:
          typeof row.licensing === 'object' && row.licensing?.Case === 'Supplier' ? [row.licensing.Fields[0]] : [],
        weights: row.weights,
      };
    }
  }

  return Object.values(result);
}

export function filterAndAggregateWeightsForThirdPartyLicensing<K>(
  getKey: (row: Responsibility) => K,
  rows: Responsibility[]
): AggregationWithThirdPartyLicensing<K>[] {
  const result: Record<string, AggregationWithThirdPartyLicensing<K>> = {};

  for (const row of rows) {
    const key = getKey(row);

    if (key === null || row.licensing === null || row.licensing === 'Customers' || row.licensing.Case === 'Producer') {
      continue;
    }

    const keyAsString = serializeToKey(key);

    if (Object.hasOwn(result, keyAsString)) {
      const aggregation = result[keyAsString];

      if (row.licensing.Case === 'Supplier') {
        aggregation.licenseeType = 'Supplier';
        aggregation.licensee = row.licensing.Fields[0];
      }

      if (row.licensing.Case === 'Customer') {
        aggregation.licenseeType = 'Customer';
        aggregation.licensee = row.licensing.Fields[0];
      }

      aggregation.weights = addWeights(aggregation.weights, row.weights);
    } else {
      if (row.reporting === null || row.reporting === 'Customers' || row.reporting.Case !== 'Producer') {
        continue;
      }

      result[keyAsString] = {
        key,
        keyAsString,
        producer: row.reporting.Fields[0],
        licenseeType: row.licensing.Case,
        licensee: row.licensing.Fields[0],
        weights: row.weights,
        thirdPartyLicensing: null,
      };
    }
  }

  return Object.values(result);
}

export function producerHasToReport(producerId: string) {
  return (row: Responsibility) =>
    typeof row.reporting === 'object' && row.reporting?.Case === 'Producer' && row.reporting.Fields[0] === producerId;
}

export function producerHasToLicense(producerId: string) {
  return (row: Responsibility) =>
    typeof row.licensing === 'object' && row.licensing?.Case === 'Producer' && row.licensing.Fields[0] === producerId;
}

export function producerHasToPay(producerId: string) {
  return (row: Responsibility) =>
    typeof row.payment === 'object' && row.payment?.Case === 'Producer' && row.payment.Fields[0] === producerId;
}

export function producerHasToDo(producerId: string) {
  return (responsibility: Responsibility) =>
    producerHasToReport(producerId)(responsibility) ||
    producerHasToLicense(producerId)(responsibility) ||
    producerHasToPay(producerId)(responsibility);
}

export function anyProducerHasToReport(responsibility: Responsibility) {
  return typeof responsibility.reporting === 'object' && responsibility.reporting?.Case === 'Producer';
}

export function anyProducerHasToLicense(responsibility: Responsibility) {
  return typeof responsibility.licensing === 'object' && responsibility.licensing?.Case === 'Producer';
}

export function anyProducerHasToPay(responsibility: Responsibility) {
  return typeof responsibility.payment === 'object' && responsibility.payment?.Case === 'Producer';
}

export function anyProducerHasToDo(responsibility: Responsibility) {
  return (
    anyProducerHasToReport(responsibility) ||
    anyProducerHasToLicense(responsibility) ||
    anyProducerHasToPay(responsibility)
  );
}

export function supplierHasToReport(supplierId: string) {
  return (row: Responsibility) =>
    typeof row.reporting === 'object' && row.reporting?.Case === 'Supplier' && row.reporting?.Fields[0] === supplierId;
}

export function supplierHasToLicense(supplierId: string) {
  return (row: Responsibility) =>
    typeof row.licensing === 'object' && row.licensing?.Case === 'Supplier' && row.licensing.Fields[0] === supplierId;
}

export function supplierHasToPay(supplierId: string) {
  return (row: Responsibility) =>
    typeof row.payment === 'object' && row.payment?.Case === 'Supplier' && row.payment.Fields[0] === supplierId;
}

export type SystemForLicensing =
  | { type: 'SystemOfProducer'; producerId: string }
  | { type: 'SystemOfSupplier'; supplierId: string }
  | { type: 'SystemOfCustomer'; customerId: string }
  | { type: 'SystemsOfCustomers' }
  | { type: 'System'; systemId: number };

export function getSystemForLicensing(
  tryGetContract: (producerId: string) => ContractValue | null,
  getSystemForThirdPartyLicensing: (producerId: string, licensee: ThirdPartyLicensee) => ThirdPartyLicensing | null,
  reporting: Responsible,
  licensing: Responsible
): SystemForLicensing {
  if (licensing === 'Customers') {
    return { type: 'SystemsOfCustomers' };
  }

  if (licensing.Case === 'Producer') {
    const systemId = tryGetContract(licensing.Fields[0])?.systemId ?? null;
    return systemId === null
      ? { type: 'SystemOfProducer', producerId: licensing.Fields[0] }
      : { type: 'System', systemId };
  }

  if (licensing.Case === 'Supplier') {
    if (typeof reporting === 'object' && reporting.Case === 'Producer') {
      const thirdPartyLicensing = getSystemForThirdPartyLicensing(reporting.Fields[0], {
        Case: 'Supplier',
        Fields: [licensing.Fields[0]],
      });
      const systemId = thirdPartyLicensing ? thirdPartyLicensing.systemId : null;

      return systemId === null
        ? { type: 'SystemOfSupplier', supplierId: licensing.Fields[0] }
        : { type: 'System', systemId };
    }

    return { type: 'SystemOfSupplier', supplierId: licensing.Fields[0] };
  }

  if (licensing.Case === 'Customer') {
    if (typeof reporting === 'object' && reporting.Case === 'Producer') {
      const thirdPartyLicensing = getSystemForThirdPartyLicensing(reporting.Fields[0], {
        Case: 'Customer',
        Fields: [licensing.Fields[0]],
      });
      const systemId = thirdPartyLicensing ? thirdPartyLicensing.systemId : null;
      return systemId === null
        ? { type: 'SystemOfCustomer', customerId: licensing.Fields[0] }
        : { type: 'System', systemId };
    }

    return { type: 'SystemOfCustomer', customerId: licensing.Fields[0] };
  }

  expectNever(licensing);
}

export function getInitialYear() {
  const now = new Date();
  const year = now.getFullYear();
  return now.getMonth() <= 2 ? year - 1 : year;
}

const monthToPeriod = (month: number): Period | null => {
  switch (month) {
    case 0:
      return 'Jan';
    case 1:
      return 'Feb';
    case 2:
      return 'Mar';
    case 3:
      return 'Apr';
    case 4:
      return 'May';
    case 5:
      return 'Jun';
    case 6:
      return 'Jul';
    case 7:
      return 'Aug';
    case 8:
      return 'Sep';
    case 9:
      return 'Oct';
    case 10:
      return 'Nov';
    case 11:
      return 'Dec';
    default:
      return null;
  }
};

const monthToQuarter = (month: number): Period | null => {
  if (month >= 0 && month <= 2) {
    return 'Q1';
  }
  if (month >= 3 && month <= 5) {
    return 'Q2';
  }
  if (month >= 6 && month <= 8) {
    return 'Q3';
  }
  if (month >= 9 && month <= 11) {
    return 'Q4';
  }
  return null;
};

const monthToHalf = (month: number): Period | null => {
  if (month >= 0 && month <= 5) {
    return 'FirstHalfYear';
  }
  if (month >= 6 && month <= 11) {
    return 'SecondHalfYear';
  }
  return null;
};

export function getInitialPeriod(year: number, intervals: DeclarationInterval[]): Period {
  const now = new Date();

  if (intervals.includes('Monthly')) {
    if (year < now.getFullYear()) {
      return 'Dec';
    } else {
      return monthToPeriod(now.getMonth() - 1) ?? 'Year';
    }
  }

  if (intervals.includes('Quarterly')) {
    if (year < now.getFullYear()) {
      return 'Q4';
    } else {
      return monthToQuarter(now.getMonth() - 1) ?? 'Year';
    }
  }

  if (intervals.includes('BiYearly')) {
    if (year < now.getFullYear()) {
      return 'SecondHalfYear';
    } else {
      return monthToHalf(now.getMonth() - 1) ?? 'Year';
    }
  }

  return 'Year';
}

export function tryGetIdOfProducerThatHasToReport(responsibility: Responsibility) {
  if (responsibility.reporting === null) {
    return null;
  }

  if (responsibility.reporting === 'Customers') {
    return null;
  }

  if (responsibility.reporting.Case === 'Supplier') {
    return null;
  }

  if (responsibility.reporting.Case === 'Customer') {
    return null;
  }

  if (responsibility.reporting.Case === 'Producer') {
    return responsibility.reporting.Fields[0];
  }

  expectNever(responsibility.reporting);
}

export function tryGetIdOfProducerThatHasToLicense(responsibility: Responsibility) {
  if (responsibility.licensing === null) {
    return null;
  }

  if (responsibility.licensing === 'Customers') {
    return null;
  }

  if (responsibility.licensing.Case === 'Supplier') {
    return null;
  }

  if (responsibility.licensing.Case === 'Customer') {
    return null;
  }

  if (responsibility.licensing.Case === 'Producer') {
    return responsibility.licensing.Fields[0];
  }

  expectNever(responsibility.licensing);
}

export function producerReportingButNotLicensing(responsibility: Responsibility) {
  const producerId = tryGetIdOfProducerThatHasToReport(responsibility);
  if (producerId === null) {
    return null;
  }

  if (responsibility.licensing === null) {
    return null;
  }

  if (responsibility.licensing === 'Customers') {
    return null;
  }

  if (responsibility.licensing.Case === 'Supplier' || responsibility.licensing.Case === 'Customer') {
    return `${producerId}#${responsibility.licensing.Case}#${responsibility.licensing.Fields[0]}`;
  }

  if (responsibility.licensing.Case === 'Producer') {
    return null;
  }

  expectNever(responsibility.licensing);
}

export function isCustomers(responsible: Responsible): responsible is 'Customers' {
  return responsible === 'Customers';
}

export function isProducer(responsible: Responsible): responsible is { Case: 'Producer'; Fields: [string] } {
  return !isCustomers(responsible) && responsible.Case === 'Producer';
}

export function isSupplier(responsible: Responsible): responsible is { Case: 'Supplier'; Fields: [string] } {
  return !isCustomers(responsible) && responsible.Case === 'Supplier';
}

export function isCustomer(responsible: Responsible): responsible is { Case: 'Customer'; Fields: [string] } {
  return !isCustomers(responsible) && responsible.Case === 'Customer';
}
