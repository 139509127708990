﻿import {
  field,
  group,
  InputOf,
  numberField,
  optionalDateField,
  optionalNumberField,
  OutputOf,
  valid,
} from '../../../forms';

import { ContractPricesGermany, ContractValue, DeclarationInterval } from '../../../api';

const pricesGroup = group({
  glass: numberField,
  fiber: numberField,
  ferrousMetal: numberField,
  aluminum: numberField,
  plastic: numberField,
  beverageCarton: numberField,
  otherComposite: numberField,
  other: numberField,
});

export const contractForm = group({
  systemId: numberField,
  hasPrices: field<boolean>(valid),
  prices: pricesGroup,
  interval: field<DeclarationInterval>(valid),
  deadlines: group({
    annual: optionalDateField,
    periodical: optionalNumberField,
  }),
});

export type ContractForm = InputOf<typeof contractForm>;
export type ContractFormOutput = OutputOf<typeof contractForm>;

export const zeroPrices: ContractPricesGermany = {
  aluminum: 0,
  beverageCarton: 0,
  ferrousMetal: 0,
  fiber: 0,
  glass: 0,
  other: 0,
  otherComposite: 0,
  plastic: 0,
};

export const emptyForm: ContractForm = {
  systemId: null,
  hasPrices: false,
  prices: zeroPrices,
  interval: 'Yearly',
  deadlines: {
    annual: null,
    periodical: null,
  },
};

export function toForm(contractValue: ContractValue): ContractForm {
  if (!contractValue.prices) {
    return { ...contractValue, prices: zeroPrices, hasPrices: false };
  } else if (contractValue.prices.Case === 'Germany') {
    return { ...contractValue, prices: contractValue.prices.Fields[0], hasPrices: true };
  }

  throw new Error('Invalid case for prices, must be Germany');
}

export function toValue(
  contractForm: ContractFormOutput,
  year: number,
  contractId: number | null,
  producerId: string
): ContractValue {
  return {
    ...contractForm,
    year,
    contractId,
    producerId,
    country: 'Germany',
    prices: contractForm.hasPrices ? { Case: 'Germany', Fields: [contractForm.prices] } : null,
  };
}
