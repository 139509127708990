import { cache, Loader } from './useQuery';

/**
 * Revalidate all queries with subscribers of the specified loader.
 * @param loader The loader.
 */
export function useRevalidateAll<TRequest, TResponse>(loader: Loader<TRequest, TResponse>) {
  return () => {
    const resources = cache.getResourcesOfLoader(loader);
    return Promise.all(
      Array.from(resources.values())
        .filter(r => r.hasSubscribers)
        .map(r => r.revalidate(false))
    );
  };
}
