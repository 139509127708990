import * as React from 'react';
import { InfoScreen } from './InfoScreen';
import { useTranslation } from 'react-i18next';
import { RouteObject } from 'react-router';

export interface Props {
  children?: React.ReactNode;
}

export const notFoundRoute: RouteObject = {
  path: '*',
  element: <NotFoundInfo />,
};

export function NotFoundInfo(props: Props) {
  const { t } = useTranslation();

  return (
    <InfoScreen title={t('global:notFound.notFoundTitle')} icon="search">
      <p>{t('global:notFound.notFoundSubtitle')}</p>
      {props.children}
    </InfoScreen>
  );
}
