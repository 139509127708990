﻿import { DeclarationResultAustria, getPeriods, Period, release, tryGetDeclarationAustria } from '../../../api';
import { useTenantMutation } from '../../../remote';
import { Icon } from '../../../shared/components/Icon';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useTenantRevalidate } from '../../../shared/hooks/useTenantRevalidate';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

export interface Props {
  year: number;
  period: Period;
  declaration: DeclarationResultAustria;
}

export function ReleaseButton({ year, period, declaration }: Props) {
  const revalidatePeriods = useTenantRevalidate(getPeriods, { country: 'Austria', year });
  const revalidateDeclaration = useTenantRevalidate(tryGetDeclarationAustria, { year, period });
  const { t } = useTranslation('reports');

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  async function revalidate() {
    await revalidatePeriods();
    await revalidateDeclaration();
  }

  const navigate = useNavigate();

  const mutation = useTenantMutation(release);

  if (mutation.isPending) {
    throw mutation.promise;
  }

  function startRelease() {
    mutation.start(
      { declaration: { country: 'Austria', year, period }, sendNotifications: false },
      {
        async onSuccess() {
          await revalidate();
          navigate('../license');
        },
      }
    );
  }

  return (
    <>
      <button className="btn btn-success text-white me-2" onClick={() => handleShow()} disabled={mutation.isPending}>
        <Icon name="check-lg" /> {t('releaseButton.at.release')}
      </button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('releaseButton.at.release')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-4">
            {declaration.errors.length > 0 ? (
              <Trans t={t}>{t('releaseButton.at.issueHint')}</Trans>
            ) : (
              <Trans t={t}>{t('releaseButton.at.hint')}</Trans>
            )}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('releaseDialog.button.cancel')}
          </Button>
          <Button variant="primary" onClick={() => startRelease()}>
            {t('releaseDialog.button.confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
