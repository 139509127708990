import { LanguageSwitcher } from './LanguageSwitcher';
import { LogOutButton } from './public/auth/LogOutButton';
import { ReactNode } from 'react';

export interface Props {
  tenantNavigation?: ReactNode;
  children?: ReactNode;
}

export function Header(props: Props) {
  return (
    <div className="navbar py-0">
      <div className="container-fluid px-4 py-3 justify-content-between">
        {props.children ?? <div></div>}
        <div className="nav-item d-flex align-items-center">
          {props.tenantNavigation && <div className="nav-item me-3">{props.tenantNavigation}</div>}
          <div className="me-3">
            <LanguageSwitcher />
          </div>
          <div className="d-flex align-items-center">
            <LogOutButton />
          </div>
          <a className="navbar-brand text-black fs-4 fw-light ms-3 me-0" href="/">
            calculate
          </a>
        </div>
      </div>
    </div>
  );
}
