import { getPlacings, Period, upsertPlacing } from '../../../../../api';
import { useTranslation } from 'react-i18next';
import { useForm } from '../../../../../forms';
import { placingForm } from './form';
import { useTenantMutation } from '../../../../../remote';
import { v4 as uuid } from 'uuid';
import { Icon } from '../../../../../shared/components/Icon';
import { PlacingForm } from './PlacingForm';
import { Modal } from '../../../../../shared/components/Modal';
import { RouteObject, useNavigate, useParams } from 'react-router';
import { useTenantRevalidate } from '../../../../../shared/hooks/useTenantRevalidate';
import { useYearParam } from '../../../../../shared/hooks/useParam';

export const addModalRoute: RouteObject = {
  path: 'add',
  element: <AddModal />,
};

export function AddModal() {
  const params = useParams<{ period: Period; producerId: string }>();
  const year = useYearParam();
  const producerId = params.producerId || '';
  const period = params.period || 'Year';

  const initialData = {
    producerId,
    description: '',
    period: period,
    packagingType: null,
    weights: {
      glass: 0,
      fiber: 0,
      ferrousMetal: 0,
      aluminum: 0,
      plastic: 0,
      beverageCarton: 0,
      otherComposite: 0,
      other: 0,
      glassRecycled: 0,
      fiberRecycled: 0,
      ferrousMetalRecycled: 0,
      aluminumRecycled: 0,
      plasticRecycled: 0,
      beverageCartonRecycled: 0,
      otherCompositeRecycled: 0,
      otherRecycled: 0,
    },
  };

  const { t } = useTranslation('placings');

  const form = useForm(placingForm, initialData);

  const mutation = useTenantMutation(upsertPlacing);

  const revalidate = useTenantRevalidate(getPlacings, year);

  const navigate = useNavigate();

  function onClose() {
    navigate('..');
  }

  function onSave() {
    if (form.status.valid) {
      mutation.start(
        { year, placingId: uuid(), data: { ...form.status.value, producerId } },
        {
          async onSuccess() {
            await revalidate();
            navigate('..');
          },
        }
      );
    }
  }

  const footer = (
    <>
      <button
        disabled={mutation.isPending || !form.status.valid}
        className="btn btn-primary text-white"
        onClick={onSave}>
        <Icon name="plus-lg" /> {t('addButton.buttonText')}
      </button>
    </>
  );

  return (
    <Modal visible={true} title={t('addButton.title')} footer={footer} size="lg" onClose={onClose}>
      <PlacingForm disabled={mutation.isPending} field={form} />
    </Modal>
  );
}
