﻿import { numberField, StateOf } from '../../forms';
import { FormEvent } from 'react';
import { InputGroup } from './InputGroup';
import { useSystems } from '../../private/systems/Systems';

export interface Props {
  id?: string;
  field: StateOf<typeof numberField>;
  disabled?: boolean;
}

export function SystemInput(props: Props) {
  const { all: systems } = useSystems();

  function onChange(e: FormEvent<HTMLSelectElement>) {
    const valueAsNumber = parseInt(e.currentTarget.value);

    const value = isNaN(valueAsNumber) ? null : valueAsNumber;

    props.field.setValue(value);
  }

  let className = 'form-select';

  if (!props.field.status.valid) {
    className += ' is-invalid';
  }

  function compareAlphabetically(a: string, b: string) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }

  return (
    <InputGroup field={props.field}>
      <select
        id={props.id}
        disabled={props.disabled}
        className={className}
        value={props.field.value?.toString() ?? ''}
        onChange={onChange}>
        <option value=""></option>
        {Object.entries(systems)
          .sort((a, b) => compareAlphabetically(a[1].name, b[1].name))
          .map(([systemId, system]) => (
            <option key={systemId} value={systemId}>
              {system.name}
            </option>
          ))}
        {props.field.status.valid && systems[props.field.status.value] == undefined && (
          <option value={props.field.status.value}>{props.field.status.value}</option>
        )}
      </select>
    </InputGroup>
  );
}
