﻿import { ProducerData, Responsible } from '../../../api';

export function compareResponsible(producers: Record<string, ProducerData>, a: Responsible, b: Responsible): number {
  if (typeof a === 'string' && a === 'Customers') {
    if (typeof b === 'string' && b === 'Customers') {
      return 0;
    }

    return -1;
  }

  if (a.Case === 'Supplier') {
    if (typeof b === 'string' && b === 'Customers') {
      return -1;
    }

    if (b.Case === 'Supplier') {
      return a.Fields[0].localeCompare(b.Fields[0]);
    }

    if (b.Case === 'Producer') {
      return 1;
    }
  }

  if (a.Case === 'Producer') {
    if (typeof b === 'string' && b === 'Customers') {
      return -1;
    }

    if (b.Case === 'Supplier') {
      return -1;
    }

    if (b.Case === 'Producer') {
      return a.Fields[0].localeCompare(b.Fields[0]);
    }
  }

  return 0;
}
