﻿import { StateOf } from '../../../../../forms';
import { placingForm } from './form';
import { TextField } from '../../../../../shared/components/TextField';
import { NumberField } from '../../../../../shared/components/NumberField';
import { PeriodField } from '../../../../../shared/components/PeriodField';
import { Row } from '../../../../../shared/components/Row';
import { PackagingTypeField } from '../../../../../shared/components/PackagingTypeField';
import { useTranslation } from 'react-i18next';

export interface Props {
  disabled?: boolean;
  field: StateOf<typeof placingForm>;
}

export function PlacingForm({ disabled, field }: Props) {
  const { t } = useTranslation('placings');

  return (
    <>
      <Row>
        <PeriodField
          size={4}
          label={t('editablePlacingForm.period')}
          disabled={disabled}
          id="period"
          field={field.fields.period}
        />
        <PackagingTypeField
          size={8}
          label={t('editablePlacingForm.packagingType')}
          disabled={disabled}
          id="packagingType"
          field={field.fields.packagingType}
        />
      </Row>
      <Row>
        <TextField
          id="description"
          label={t('editablePlacingForm.description')}
          maxLength={128}
          disabled={disabled}
          field={field.fields.description}
        />
      </Row>
      <Row>
        <NumberField
          label={t('global:fractions.de.glass')}
          disabled={disabled}
          unit="kg"
          id="weights.glass"
          field={field.fields.weights.fields.glass}
        />
        <NumberField
          label={t('global:fractions.de.fiber')}
          disabled={disabled}
          unit="kg"
          id="weights.fiber"
          field={field.fields.weights.fields.fiber}
        />
      </Row>
      <Row>
        <NumberField
          label={t('global:fractions.de.ferrousMetal')}
          disabled={disabled}
          unit="kg"
          id="weights.ferrousMetal"
          field={field.fields.weights.fields.ferrousMetal}
        />
        <NumberField
          label={t('global:fractions.de.aluminum')}
          disabled={disabled}
          unit="kg"
          id="weights.aluminum"
          field={field.fields.weights.fields.aluminum}
        />
      </Row>
      <Row>
        <NumberField
          label={t('global:fractions.de.plastic')}
          disabled={disabled}
          unit="kg"
          id="weights.plastic"
          field={field.fields.weights.fields.plastic}
        />
        <NumberField
          label={t('global:fractions.de.beverageCarton')}
          disabled={disabled}
          unit="kg"
          id="weights.beverageCarton"
          field={field.fields.weights.fields.beverageCarton}
        />
      </Row>
      <Row>
        <NumberField
          label={t('global:fractions.de.otherComposite')}
          disabled={disabled}
          unit="kg"
          id="weights.otherComposite"
          field={field.fields.weights.fields.otherComposite}
        />
        <NumberField
          label={t('global:fractions.de.other')}
          disabled={disabled}
          unit="kg"
          id="weights.other"
          field={field.fields.weights.fields.other}
        />
      </Row>
    </>
  );
}
