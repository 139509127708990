﻿import { useTranslation } from 'react-i18next';

export function CustomersInfo() {
  const { t } = useTranslation();

  return (
    <div>
      <span className="badge bg-secondary">{t('global:customers')}</span>
    </div>
  );
}
