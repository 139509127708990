import { Period } from '../../../../api';
import { useProducersFromPlacings } from './useProducersFromPlacings';
import { useAccessibleProducers } from '../../../producers/useAccessibleProducers';
import { useActiveProducers } from '../../../producers/useActiveProducers';
import { useProducerIdsFromContracts } from '../../../contracts/useProducerIdsFromContracts';

export function useVisibleProducers(year: number, period: Period) {
  const producerIdsFromProducers = useActiveProducers();
  const producerIdsFromContracts = useProducerIdsFromContracts();
  const producerIdsFromPlacings = useProducersFromPlacings(year, period);
  const producerIds = Array.from(
    new Set([...producerIdsFromProducers, ...producerIdsFromContracts, ...producerIdsFromPlacings])
  );
  return useAccessibleProducers(producerIds);
}
