import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import validator from 'validator';
import { Country, Locale } from './api';
import isDate = validator.isDate;
import { useTranslation } from 'react-i18next';

// TODO the type Function needs to be defined, it is missing the shape
// eslint-disable-next-line @typescript-eslint/ban-types
type Resolve<T> = T extends Function ? T : { [P in keyof T]: T[P] };

/**
 * A validation error. Either a string or a react element that can be rendered.
 */
export type ValidationError = string | React.ReactNode;

/**
 * The status of a field with a valid output value of type O if the input value is valid.
 */
export type FieldStatus<O> =
  | { readonly valid: true; readonly value: O }
  | { readonly valid: false; readonly error: ValidationError | undefined };

/**
 * A validator with the input value I and valid output value O.
 */
export type Validator<I, O> = (value: I) => FieldStatus<O>;

/**
 * The state of a field and function to change the state.
 */
export interface FieldState<I, O> {
  readonly status: FieldStatus<O>;
  readonly value: I;
  readonly setValue: (value: I) => void;
  readonly touched: boolean;
  readonly setTouched: (touched: boolean) => void;
}

/**
 * The definition of a field.
 */
export interface FieldDefinition<I, O, S extends FieldState<I, O> = FieldState<I, O>> {
  init(initialValue: I, setState: Dispatch<SetStateAction<S>>): S;
  setStateValue(prevState: S, nextValue: I, setState: Dispatch<SetStateAction<S>>): S;
  setStateTouched(prevState: S, nextTouched: boolean, setState: Dispatch<SetStateAction<S>>): S;
}

/**
 * The field definitions of a group.
 */
export interface Definitions {
  readonly [name: string]: FieldDefinition<any, any, any>;
}

/**
 * The state of a group of fields.
 */
export interface GroupState<F extends Definitions> extends FieldState<InputsOf<F>, OutputsOf<F>> {
  readonly fields: StatesOf<F>;
}

/**
 * The definition of a group of fields.
 */
export type GroupDefinition<F extends Definitions> = FieldDefinition<InputsOf<F>, OutputsOf<F>, GroupState<F>>;

/**
 * The state of an optional group of fields.
 */
export interface OptionalGroupState<F extends Definitions> extends FieldState<InputsOf<F> | null, OutputsOf<F> | null> {
  readonly fields: StatesOf<F> | null;
}

/**
 * The definition of an optional group of fields.
 */
export type OptionalGroupDefinition<F extends Definitions> = FieldDefinition<
  InputsOf<F> | null,
  OutputsOf<F> | null,
  OptionalGroupState<F>
>;

/**
 * The state of a field definition.
 */
export type StateOf<D extends FieldDefinition<any, any, any>> = D extends FieldDefinition<any, any, infer S>
  ? S
  : never;

/**
 * The state of a group of field definitions.
 */
export type StatesOf<F extends Definitions> = { [P in keyof F]: StateOf<F[P]> };

/**
 * The output of a field definition.
 */
export type OutputOf<T extends FieldDefinition<any, any, any>> = T extends FieldDefinition<any, infer O, any>
  ? O
  : never;

/**
 * The outputs of a group of field definitions.
 */
export type OutputsOf<F extends Definitions> = Resolve<{ [P in keyof F]: OutputOf<F[P]> }>;

/**
 * The input of a field definition.
 */
export type InputOf<T extends FieldDefinition<any, any, any>> = T extends FieldDefinition<infer I, any, any>
  ? I
  : never;

/**
 * The inputs of a group of field definitions.
 */
export type InputsOf<F extends Definitions> = Resolve<{ [P in keyof F]: InputOf<F[P]> }>;

/**
 * Defines a field.
 * @param validator The validator the validates the value of the field.
 */
export function field<I, O = I>(validator: Validator<I, O>): FieldDefinition<I, O, FieldState<I, O>> {
  function init(initialValue: I, setState: Dispatch<SetStateAction<FieldState<I, O>>>) {
    function setValue(value: I) {
      setState(s => setStateValue(s, value));
    }

    function setTouched(touched: boolean) {
      setState(s => setStateTouched(s, touched));
    }

    const initialStatus = validator(initialValue);

    return {
      status: initialStatus,
      value: initialValue,
      touched: false,
      setValue,
      setTouched,
    };
  }

  function setStateValue(prevState: FieldState<I, O>, nextValue: I) {
    return {
      ...prevState,
      status: validator(nextValue),
      value: nextValue,
      touched: true,
    };
  }

  function setStateTouched(prevState: FieldState<I, O>, nextTouched: boolean) {
    return {
      ...prevState,
      touched: nextTouched,
    };
  }

  return { init, setStateValue, setStateTouched };
}

function getStatusFromFields<F extends Definitions>(fields: StatesOf<F>): FieldStatus<OutputsOf<F>> {
  let invalid = false;
  const nextValidValue: any = {};

  for (const name in fields) {
    if (fields[name].status.valid) {
      nextValidValue[name] = fields[name].status.value;
    } else {
      invalid = true;
      break;
    }
  }

  return invalid ? { valid: false, error: null } : { valid: true, value: nextValidValue };
}

function getInitialFields<F extends Definitions>(
  fields: F,
  initialValue: InputsOf<F>,
  setFieldState: (name: string, fieldState: SetStateAction<FieldState<unknown, unknown>>) => void
) {
  const initialFields: any = {};

  for (const name in fields) {
    initialFields[name] = fields[name].init(initialValue[name], fieldState => setFieldState(name, fieldState));
  }

  return initialFields;
}

function getNextFields<F extends Definitions>(
  fields: F,
  prevState: StatesOf<F>,
  nextValue: InputsOf<F>,
  setFieldState: (name: string, fieldState: SetStateAction<FieldState<unknown, unknown>>) => void
): StatesOf<F> {
  const nextState: any = {};

  for (const name in fields) {
    const fs = prevState[name];
    const fv = nextValue[name];
    nextState[name] = fields[name].setStateValue(fs, fv, fieldState => setFieldState(name, fieldState));
  }

  return nextState;
}

/**
 * Defines a group of fields.
 * @param fields The fields.
 */
export function group<F extends Definitions>(fields: F): GroupDefinition<F> {
  function init(initialValue: InputsOf<F>, setState: Dispatch<SetStateAction<GroupState<F>>>): GroupState<F> {
    function setValue(value: InputsOf<F>) {
      setState(s => setStateValue(s, value, setState));
    }

    function setTouched(touched: boolean) {
      setState(s => setStateTouched(s, touched, setState));
    }

    function setFieldState(name: string, fieldState: SetStateAction<FieldState<unknown, unknown>>) {
      setState(state => {
        const nextField = typeof fieldState === 'function' ? fieldState(state.fields[name]) : fieldState;

        const nextFields = {
          ...state.fields,
          [name]: nextField,
        };

        const nextValue = {
          ...state.value,
          [name]: nextField.value,
        };

        const nextStatus = getStatusFromFields<F>(nextFields);

        return {
          ...state,
          value: nextValue,
          status: nextStatus,
          fields: nextFields,
        };
      });
    }

    const initialFields = getInitialFields<F>(fields, initialValue, setFieldState);

    const initialStatus = getStatusFromFields<F>(initialFields);

    return {
      status: initialStatus,
      value: initialValue,
      setValue,
      touched: false,
      setTouched,
      fields: initialFields as StatesOf<F>,
    };
  }

  function setStateValue(
    state: GroupState<F>,
    nextValue: InputsOf<F>,
    setState: Dispatch<SetStateAction<GroupState<F>>>
  ): GroupState<F> {
    function setFieldState(name: string, fieldState: SetStateAction<FieldState<unknown, unknown>>) {
      setState(state => {
        const nextField = typeof fieldState === 'function' ? fieldState(state.fields[name]) : fieldState;

        const nextFields = {
          ...state.fields,
          [name]: nextField,
        };

        const nextValue = {
          ...state.value,
          [name]: nextField.value,
        };

        const nextStatus = getStatusFromFields<F>(nextFields);

        return {
          ...state,
          value: nextValue,
          status: nextStatus,
          fields: nextFields,
        };
      });
    }

    const nextFields = getNextFields(fields, state.fields, nextValue, setFieldState);
    const nextStatus = getStatusFromFields<F>(nextFields);

    return {
      ...state,
      value: nextValue,
      status: nextStatus,
      fields: nextFields,
    };
  }

  function setStateTouched(
    prevState: GroupState<F>,
    nextTouched: boolean,
    setState: Dispatch<SetStateAction<GroupState<F>>>
  ): GroupState<F> {
    function setFieldState(name: string, fieldState: SetStateAction<FieldState<unknown, unknown>>) {
      setState(state => {
        const nextField = typeof fieldState === 'function' ? fieldState(state.fields[name]) : fieldState;

        const nextFields = {
          ...state.fields,
          [name]: nextField,
        };

        return {
          ...state,
          touched: nextTouched,
          fields: nextFields,
        };
      });
    }

    const nextFields: any = {};

    for (const name in prevState.fields) {
      const fs = prevState.fields[name];
      nextFields[name] = fields[name].setStateTouched(fs, nextTouched, fieldState => setFieldState(name, fieldState));
    }

    return {
      ...prevState,
      fields: nextFields,
      touched: nextTouched,
    };
  }

  return { init, setStateValue, setStateTouched };
}

/**
 * Defines a group of fields.
 * @param fields The fields.
 */
export function optionalGroup<F extends Definitions>(fields: F): OptionalGroupDefinition<F> {
  function init(
    initialValue: InputsOf<F> | null,
    setState: Dispatch<SetStateAction<OptionalGroupState<F>>>
  ): OptionalGroupState<F> {
    if (initialValue === null) {
      return {
        status: { valid: true, value: null },
        value: null,
        setValue,
        touched: false,
        setTouched,
        fields: null,
      };
    }

    function setValue(value: InputsOf<F> | null) {
      setState(s => setStateValue(s, value, setState));
    }

    function setTouched(touched: boolean) {
      setState(s => setStateTouched(s, touched, setState));
    }

    function setFieldState(name: string, fieldState: SetStateAction<FieldState<unknown, unknown>>) {
      setState((state: OptionalGroupState<F>): OptionalGroupState<F> => {
        if (state.fields === null) {
          return state;
        }

        const nextField = typeof fieldState === 'function' ? fieldState(state.fields[name]) : fieldState;

        const nextFields = {
          ...state.fields,
          [name]: nextField,
        };

        const nextValue = {
          ...state.value,
          [name]: nextField.value,
        } as InputsOf<F>;

        const nextStatus = getStatusFromFields<F>(nextFields);

        return {
          ...state,
          value: nextValue,
          status: nextStatus,
          fields: nextFields,
        };
      });
    }

    const initialFields = getInitialFields<F>(fields, initialValue, setFieldState);
    const initialStatus = getStatusFromFields<F>(initialFields);

    return {
      status: initialStatus,
      value: initialValue,
      setValue,
      touched: false,
      setTouched,
      fields: initialFields,
    };
  }

  function setStateValue(
    state: OptionalGroupState<F>,
    nextValue: InputsOf<F> | null,
    setState: Dispatch<SetStateAction<OptionalGroupState<F>>>
  ): OptionalGroupState<F> {
    if (nextValue === null) {
      return {
        ...state,
        value: nextValue,
        status: { valid: true, value: null },
        touched: false,
        fields: null,
      };
    }

    function setFieldState(name: string, fieldState: SetStateAction<FieldState<unknown, unknown>>) {
      setState(state => {
        if (state.fields === null) {
          return state;
        }

        const nextField = typeof fieldState === 'function' ? fieldState(state.fields[name]) : fieldState;

        const nextFields = {
          ...state.fields,
          [name]: nextField,
        };

        const nextValue = {
          ...state.value,
          [name]: nextField.value,
        } as InputsOf<F> | null;

        const nextStatus = getStatusFromFields<F>(nextFields);

        return {
          ...state,
          value: nextValue,
          status: nextStatus,
          fields: nextFields,
        };
      });
    }

    const nextFields =
      state.fields === null
        ? getInitialFields(fields, nextValue, setFieldState)
        : getNextFields<F>(fields, state.fields, nextValue, setFieldState);

    const nextStatus = getStatusFromFields<F>(nextFields);

    return {
      ...state,
      value: nextValue,
      status: nextStatus,
      fields: nextFields,
    };
  }

  function setStateTouched(
    prevState: OptionalGroupState<F>,
    nextTouched: boolean,
    setState: Dispatch<SetStateAction<OptionalGroupState<F>>>
  ): OptionalGroupState<F> {
    function setFieldState(name: string, fieldState: SetStateAction<FieldState<unknown, unknown>>) {
      setState(state => {
        if (state.fields === null) {
          return state;
        }

        const nextField = typeof fieldState === 'function' ? fieldState(state.fields[name]) : fieldState;

        const nextFields = {
          ...state.fields,
          [name]: nextField,
        };

        return {
          ...state,
          touched: nextTouched,
          fields: nextFields,
        };
      });
    }

    const nextFields: any = {};

    for (const name in prevState.fields) {
      const fs = prevState.fields[name];
      nextFields[name] = fields[name].setStateTouched(fs, nextTouched, fieldState => setFieldState(name, fieldState));
    }

    return {
      ...prevState,
      fields: nextFields,
      touched: nextTouched,
    };
  }

  return { init, setStateValue, setStateTouched };
}

/**
 * A hook that takes a field definition and an initial value and returns the state
 * @param field The field definition.
 * @param initialValue The initial value.
 */
export function useForm<F extends FieldDefinition<unknown, unknown, any>>(
  field: F,
  initialValue: InputOf<F>
): StateOf<F> {
  const [state, setState] = React.useState<StateOf<F>>((): StateOf<F> => field.init(initialValue, setStateInternal));

  function setStateInternal(value: React.SetStateAction<StateOf<F>>) {
    setState(value);
  }

  return state;
}

/**
 * A validator that returns the input as a valid output.
 * @param value The input value.
 */
export function valid<O>(value: O): FieldStatus<O> {
  return { valid: true, value };
}

export function InputRequiredError() {
  const { t } = useTranslation('global');
  return <>{t('forms.errors.required')}</>;
}

export function InputInvalidError() {
  const { t } = useTranslation('global');
  return <>{t('forms.errors.invalid')}</>;
}

function InvalidDateError() {
  const { t } = useTranslation('global');
  return <>{t('forms.errors.invalid')}</>;
}

function AlreadyExistsError() {
  const { t } = useTranslation('global');
  return <>{t('forms.errors.alreadyExists')}</>;
}

export const optionalDateField = field<string | null>((value: string | null) =>
  value && !isDate(value) ? { valid: false, error: <InvalidDateError /> } : { valid: true, value }
);

// BooleanField
export type BooleanField = FieldDefinition<boolean, boolean>;
export const booleanField: BooleanField = field((value: boolean) => ({ valid: true, value }));

// TextField
export type TextField = FieldDefinition<string, string>;
export const textField: TextField = field((value: string) =>
  value.length === 0 ? { valid: false, error: <InputRequiredError /> } : { valid: true, value }
);

export function uniqueTextField(existingIds: string[]): TextField {
  function validate(value: string): FieldStatus<string> {
    if (value.length === 0) {
      return { valid: false, error: <InputRequiredError /> };
    }

    if (existingIds.includes(value)) {
      return { valid: false, error: <AlreadyExistsError /> };
    }

    return { valid: true, value };
  }

  return field(validate);
}

// OptionalTextField
export type OptionalTextField = FieldDefinition<string | null, string | null>;
export const optionalTextField: OptionalTextField = field<string | null>(valid);

// NumberField
export type NumberField = FieldDefinition<number | null, number>;
export const numberField: NumberField = field((value: number | null) =>
  value === null || isNaN(value) ? { valid: false, error: <InputRequiredError /> } : { valid: true, value }
);

// OptionalNumberField
export type OptionalNumberField = FieldDefinition<number | null, number | null>;
export const optionalNumberField: OptionalNumberField = field<number | null>(valid);

// CountryField
export type CountryField = FieldDefinition<Country | null, Country>;
export const countryField: CountryField = field<Country | null, Country>(value =>
  value === null ? { valid: false, error: <InputRequiredError /> } : { valid: true, value }
);

// CountryField
export type LocaleField = FieldDefinition<Locale | null, Locale>;
export const localeField: LocaleField = field<Locale | null, Locale>(value =>
  value === null ? { valid: false, error: <InputRequiredError /> } : { valid: true, value }
);
