import { StateOf, booleanField } from '../../forms';
import { ColSize } from './Col';
import { Field } from './Field';
import { BooleanInput } from './BooleanInput';

export interface Props {
  id: string;
  label: string;
  field: StateOf<typeof booleanField>;
  disabled?: boolean;
  size?: ColSize;
}

export function BooleanField(props: Props) {
  return (
    <Field size={props.size}>
      <div className="pt-3">&nbsp;</div>
      <BooleanInput id={props.id} disabled={props.disabled} field={props.field}>
        {props.label}
      </BooleanInput>
    </Field>
  );
}
