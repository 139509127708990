import { getAuthOptions, refreshToken, User } from '../../api';
import { singleCall } from '../../helpers';
import { useQuery } from '../../shared/hooks/useQuery';
import { field, InputInvalidError } from '../../forms';
import { useQueryState } from '../../shared/hooks/useQueryState';

export const AUTH_STATE = 'AUTH_STATE';
export const AUTH_RESPONSE = 'AUTH_RESPONSE';
export const AUTH_RETURN_PATH = 'AUTH_RETURN_PATH';

interface OAuthResult {
  idToken: string;
  expiresAt: string;
  user: User;
}

interface AuthResponse {
  state: string;
  result: OAuthResult;
}

export function tryGetAuthState() {
  return sessionStorage.getItem(AUTH_STATE);
}

export function readAuthResponse(): AuthResponse | null {
  const json = sessionStorage.getItem(AUTH_RESPONSE);

  if (json == null) {
    return null;
  }

  return JSON.parse(json);
}

function writeAuthResponse(auth: AuthResponse) {
  sessionStorage.setItem(AUTH_RESPONSE, JSON.stringify(auth));
}

/** Refreshes the ID token using the refresh token stored in a secure cookie. */
const refreshIdToken = singleCall(async function () {
  const auth = readAuthResponse();

  if (auth == null) {
    return null;
  }

  const r = await refreshToken();

  if (r.Case === 'Ok') {
    const [result] = r.Fields;
    const nextAuth: AuthResponse = { ...auth, result };
    writeAuthResponse(nextAuth);
    return nextAuth.result.idToken;
  } else {
    throw new Error(r.Fields[0]);
  }
});

export async function getToken() {
  const auth = readAuthResponse();
  if (auth === null) {
    return null;
  }

  const refreshAt = new Date(auth.result.expiresAt);
  refreshAt.setSeconds(refreshAt.getSeconds() - 30);

  if (new Date() > refreshAt) {
    return await refreshIdToken();
  }

  return auth.result.idToken;
}

export function useAuthOptions() {
  const query = useQuery(getAuthOptions, void 0, false);
  return useQueryState(query)[0];
}

/** Returns information and functions for authenticated users. Throws an error if the user is not authenticated. */
export function useUser() {
  const auth = readAuthResponse();

  if (auth == null) {
    throw new Error('Unauthenticated');
  }

  return auth.result.user;
}

/**
 * Field definition of a username input that trims the input automatically
 * and shows an input invalid error if there are any spaces in between.
 * Context: spaces are not allowed in AWS Cognito usernames and lead to an invalid parameter error.
 */
export const usernameField = field<string>(value => {
  value = value.trim();
  return value.includes(' ') ? { valid: false, error: <InputInvalidError /> } : { valid: true, value };
});
