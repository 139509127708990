﻿import { Outlet, RouteObject, useOutletContext } from 'react-router';
import { DeclarationResultAustria } from '../../../../api';
import { NotFoundInfo } from '../../../../shared/components/NotFoundInfo';
import { licenseIndexRoute } from './LicenseIndex';
import { licenseDetailRoute } from './LicenseDetail';

export const licenseRoute: RouteObject = {
  path: 'license',
  element: <LicenseStep />,
  children: [licenseIndexRoute, licenseDetailRoute],
};

export function LicenseStep() {
  const declaration = useOutletContext<DeclarationResultAustria | null>();

  if (declaration === null) {
    return <NotFoundInfo />;
  }

  return <Outlet context={declaration} />;
}
