import { useMemo } from 'react';
import { To, useSearchParams as useRouterSearchParams } from 'react-router-dom';

export interface SearchParamConverter<V> {
  write(name: string, params: URLSearchParams, value: V): void;
  read(name: string, params: URLSearchParams): V;
}

export function stringParam<T extends string = string>(defaultValue: T): SearchParamConverter<T> {
  return {
    write(name, params, value) {
      if (value === defaultValue) {
        params.delete(name);
      } else {
        params.set(name, value);
      }
    },
    read(name, params) {
      return (params.get(name) as T) ?? defaultValue;
    },
  };
}

export function optionalStringParam(defaultValue: string | null): SearchParamConverter<string | null> {
  return {
    write(name, params, value) {
      if (value === defaultValue || value == null) {
        params.delete(name);
      } else {
        params.set(name, value);
      }
    },
    read(name, params) {
      return params.get(name) ?? defaultValue;
    },
  };
}

export function numberParam(defaultValue: number): SearchParamConverter<number> {
  return {
    write(name, params, value) {
      if (value === defaultValue || value == null || isNaN(value)) {
        params.delete(name);
      } else {
        params.set(name, value.toString());
      }
    },
    read(name, params) {
      const str = params.get(name);
      const value = Number(str);
      return isNaN(value) ? defaultValue : value;
    },
  };
}

export function enumParam<T extends number>(defaultValue: T): SearchParamConverter<T> {
  return {
    write(name, params, value) {
      if (value === defaultValue || value == null || isNaN(value)) {
        params.delete(name);
      } else {
        params.set(name, value.toString());
      }
    },
    read(name, params) {
      const str = params.get(name);
      const value = Number(str);
      return isNaN(value) ? defaultValue : (value as T);
    },
  };
}

export function booleanParam(defaultValue: boolean): SearchParamConverter<boolean> {
  return {
    write(name, params, value) {
      if (value === defaultValue) {
        params.delete(name);
      } else {
        params.set(name, value ? 'true' : 'false');
      }
    },
    read(name, params) {
      const str = params.get(name);
      if (str === 'true') {
        return true;
      } else if (str === 'false') {
        return false;
      }
      return defaultValue;
    },
  };
}

export function optionalNumberParam(defaultValue: number | null): SearchParamConverter<number | null> {
  return {
    write(name, params, value) {
      if (value === defaultValue || value == null || isNaN(value)) {
        params.delete(name);
      } else {
        params.set(name, value.toString());
      }
    },
    read(name, params) {
      const str = params.get(name);
      if (str == null) {
        return defaultValue;
      }
      const value = parseFloat(str);
      return isNaN(value) ? defaultValue : value;
    },
  };
}

export function objectParam<V extends object>(children: {
  [P in keyof V]: SearchParamConverter<V[P]>;
}): SearchParamConverter<V> {
  return {
    write(name, params, value) {
      for (const key in children) {
        const child = children[key];
        child.write(name + '.' + key, params, value[key]);
      }
    },
    read(name, params) {
      const value: any = {};
      for (const key in children) {
        const child = children[key];
        value[key] = child.read(name + '.' + key, params);
      }
      return value;
    },
  };
}

/**
 * Allows to use the search params as state.
 * @param name The name of the search param.
 * @param converter The converter that converts a value to search params and search params to a value.
 */
export function useSearchParams<V>(
  name: string,
  converter: SearchParamConverter<V>
): [V, (value: V, mode?: 'replace' | 'push') => void, (value: V) => To] {
  const [params, setParams] = useRouterSearchParams();

  // We don't need to convert the search params again, if location.search didn't change.
  const value = useMemo(() => {
    return converter.read(name, params);
  }, [name, converter, params]); // TODO: Is it safe to use useMemo? There is no guarantee that memo always returns the same value if location.search doesn't change.

  function getValueTo(value: V) {
    const nextParams = new URLSearchParams(params);
    converter.write(name, nextParams, value);
    return { pathname: './', search: nextParams.toString() };
  }

  // Sets the new value using react-router.
  function setValue(value: V, mode: 'replace' | 'push' = 'replace') {
    const nextParams = new URLSearchParams(params);
    converter.write(name, nextParams, value);
    if (mode === 'replace') {
      setParams(nextParams, { replace: true });
    } else {
      setParams(nextParams, { replace: false });
    }
  }

  return [value, setValue, getValueTo];
}
