﻿import { StateOf } from '../../../forms';
import { contractForm } from './form';
import { Row } from '../../../shared/components/Row';
import { SystemField } from '../../../shared/components/SystemField';
import { NumberInput } from '../../../shared/components/NumberInput';
import { DateInput } from '../../../shared/components/DateInput';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { PriceField } from '../PriceField';
import { IntervalInput } from '../../../shared/components/IntervalInput';
import { Form } from 'react-bootstrap';

export interface Props {
  disabled?: boolean;
  form: StateOf<typeof contractForm>;
  year: number;
}

export function ContractForm({ disabled, form, year }: Props) {
  const { t } = useTranslation('contracts');

  const minDate = dayjs().year(year).add(1, 'year').date(1).month(0).format('YYYY-MM-DD');
  const maxDate = dayjs().year(year).add(1, 'year').date(31).month(11).format('YYYY-MM-DD');

  const priceFields = (
    <>
      <Row>
        <PriceField
          label={t('global:fractions.at.glassHouseHold')}
          disabled={disabled}
          id="prices.glassHousehold"
          field={form.fields.prices.fields.glassHousehold}
        />
        <PriceField
          label={t('global:fractions.at.fiberHouseHold')}
          disabled={disabled}
          id="prices.fiberHousehold"
          field={form.fields.prices.fields.fiberHousehold}
        />
      </Row>
      <Row>
        <PriceField
          label={t('global:fractions.at.ferrousMetalHouseHold')}
          disabled={disabled}
          id="prices.ferrousMetalHousehold"
          field={form.fields.prices.fields.ferrousMetalHousehold}
        />
        <PriceField
          label={t('global:fractions.at.aluminumHouseHold')}
          disabled={disabled}
          id="prices.aluminumHousehold"
          field={form.fields.prices.fields.aluminumHousehold}
        />
      </Row>
      <Row>
        <PriceField
          label={t('global:fractions.at.plasticHouseHold')}
          disabled={disabled}
          id="prices.plasticHousehold"
          field={form.fields.prices.fields.plasticHousehold}
        />
        <PriceField
          label={t('global:fractions.at.beverageCartonHouseHold')}
          disabled={disabled}
          id="prices.beverageCartonHousehold"
          field={form.fields.prices.fields.beverageCartonHousehold}
        />
      </Row>
      <Row>
        <PriceField
          label={t('global:fractions.at.organicHouseHold')}
          disabled={disabled}
          id="prices.organicHousehold"
          field={form.fields.prices.fields.organicHousehold}
        />
        <PriceField
          label={t('global:fractions.at.ceramicHouseHold')}
          disabled={disabled}
          id="prices.ceramicHousehold"
          field={form.fields.prices.fields.ceramicHousehold}
        />
      </Row>
      <Row>
        <PriceField
          label={t('global:fractions.at.woodHouseHold')}
          disabled={disabled}
          id="prices.woodHousehold"
          field={form.fields.prices.fields.woodHousehold}
        />
        <PriceField
          label={t('global:fractions.at.textileHouseHold')}
          disabled={disabled}
          id="prices.textileHousehold"
          field={form.fields.prices.fields.textileHousehold}
        />
      </Row>
      <Row>
        <PriceField
          label={t('global:fractions.at.compositeHouseHold')}
          disabled={disabled}
          id="prices.compositeHousehold"
          field={form.fields.prices.fields.compositeHousehold}
        />
      </Row>
      <Row>
        <PriceField
          label={t('global:fractions.at.fiberCommercial')}
          disabled={disabled}
          id="prices.fiberCommercial"
          field={form.fields.prices.fields.fiberCommercial}
        />
        <PriceField
          label={t('global:fractions.at.ferrousMetalCommercial')}
          disabled={disabled}
          id="prices.ferrousMetalCommercial"
          field={form.fields.prices.fields.ferrousMetalCommercial}
        />
      </Row>
      <Row>
        <PriceField
          label={t('global:fractions.at.aluminumCommercial')}
          disabled={disabled}
          id="prices.aluminumCommercial"
          field={form.fields.prices.fields.aluminumCommercial}
        />
        <PriceField
          label={t('global:fractions.at.organicCommercial')}
          disabled={disabled}
          id="prices.organicCommercial"
          field={form.fields.prices.fields.organicCommercial}
        />
      </Row>
      <Row>
        <PriceField
          label={t('global:fractions.at.hollowBodiesCommercial')}
          disabled={disabled}
          id="prices.hollowBodiesCommercial"
          field={form.fields.prices.fields.hollowBodiesCommercial}
        />
        <PriceField
          label={t('global:fractions.at.epsCommercial')}
          disabled={disabled}
          id="prices.epsCommercial"
          field={form.fields.prices.fields.epsCommercial}
        />
      </Row>
      <Row>
        <PriceField
          label={t('global:fractions.at.compositeCommercial')}
          disabled={disabled}
          id="prices.compositeCommercial"
          field={form.fields.prices.fields.compositeCommercial}
        />
        <PriceField
          label={t('global:fractions.at.ceramicCommercial')}
          disabled={disabled}
          id="prices.ceramicCommercial"
          field={form.fields.prices.fields.ceramicCommercial}
        />
      </Row>
      <Row>
        <PriceField
          label={t('global:fractions.at.woodCommercial')}
          disabled={disabled}
          id="prices.woodCommercial"
          field={form.fields.prices.fields.woodCommercial}
        />
        <PriceField
          label={t('global:fractions.at.textileCommercial')}
          disabled={disabled}
          id="prices.textileCommercial"
          field={form.fields.prices.fields.textileCommercial}
        />
      </Row>
      <Row>
        <PriceField
          label={t('global:fractions.at.foilsCommercial')}
          disabled={disabled}
          id="prices.foilsCommercial"
          field={form.fields.prices.fields.foilsCommercial}
        />
      </Row>
    </>
  );

  return (
    <>
      <Row>
        <SystemField id="systemId" label={t('contractForm.systemProvider')} field={form.fields.systemId} />
      </Row>
      <hr />
      <Row>
        <Form.Group className="mb-3 d-flex justify-content-between gap-2 align-items-center">
          <div className="h4 mb-0">{t('contractForm.fees')}</div>
          <Form.Check
            type="switch"
            checked={form.fields.hasPrices.value}
            onChange={value => form.fields.hasPrices.setValue(value.target.checked)}
          />
        </Form.Group>
      </Row>
      {form.fields.hasPrices.value ? priceFields : <></>}
      <hr />
      <Row>
        <div className="col-12">
          <p className="h4">{t('contractForm.declarationPeriod')}</p>
          <Row>
            <div className="col-12 pt-2 pb-5">
              <div className="d-flex justify-content-start gap-3 align-items-center">
                <div>{t('contractForm.declarationPeriodDescription')}</div>
                <div className="w-50">
                  <IntervalInput id={'reminder.interval'} field={form.fields.interval}></IntervalInput>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </Row>
      <Row>
        <div className="col-12">
          <p className="h4">{t('contractForm.deadlines')}</p>
          <Row>
            <div className="col-12 pt-2">
              <div className="d-flex justify-content-start align-items-baseline">
                <span className="pe-3">{t('contractForm.annualReportingDueBy')}</span>
                <div className="col-4">
                  <DateInput
                    id="reminder.annual"
                    field={form.fields.deadlines.fields.annual}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-12 pt-4">
              <div className="d-flex justify-content-start align-items-baseline">
                <span className="text-nowrap pe-3">{t('contractForm.periodicalReportingDueBy')}</span>
                <div className="col-1">
                  <NumberInput
                    id="reminder.periodical.daysAfter"
                    field={form.fields.deadlines.fields.periodical}
                    min={1}
                    decimalPlaces={0}
                  />
                </div>
                <span className="text-nowrap ps-3">{t('contractForm.daysAfterEndOfPeriod')}</span>
              </div>
            </div>
          </Row>
        </div>
      </Row>
    </>
  );
}
