import { DeclarationResultGermany } from '../../../../api';
import { Outlet, RouteObject, useOutletContext } from 'react-router';
import { NotFoundInfo } from '../../../../shared/components/NotFoundInfo';
import { thirdPartyLicensingIndexRoute } from './ThirdPartyLicensingIndex';
import { usePeriodParam, useYearParam } from '../../../../shared/hooks/useParam';
import { Actions } from '../Actions';
import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { useTranslation } from 'react-i18next';

export const thirdPartyLicensingRoute: RouteObject = {
  path: 'thirdPartyLicensing',
  element: <ThirdPartyLicensingStep />,
  children: [thirdPartyLicensingIndexRoute],
};

export function ThirdPartyLicensingStep() {
  const year = useYearParam();
  const period = usePeriodParam();
  const declaration = useOutletContext<DeclarationResultGermany | null>();
  const { t } = useTranslation('reports');

  if (declaration === null) {
    return <NotFoundInfo />;
  }

  return (
    <div>
      <Actions
        year={year}
        period={period}
        declaration={declaration}
        releaseButtonDisabled={true}
        withdrawButtonDisabled={false}
      />

      <div className="bg-light rounded mb-4">
        <InfoScreen icon="eye" color="primary" title={t('thirdPartyLicensingStep.title')}>
          <p>{t('thirdPartyLicensingStep.subtitle')}</p>
          {period !== 'Year' && <p>{t('thirdPartyLicensingStep.thirdPartyLicensingReportingIntervalHint')}</p>}
        </InfoScreen>
      </div>
      <Outlet context={declaration}></Outlet>
    </div>
  );
}
