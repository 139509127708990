﻿import { PackagingWeights, Responsibility } from '../../../../api';
import { useProducers } from '../../../producers/Producers';
import { useContracts } from '../../../contracts/Contracts';
import { useThirdPartyLicensing } from '../thirdPartyLicensing/ThirdPartyLicensing';
import {
  filterAndGroupResponsibilities,
  getEmptyWeights,
  getSystemForLicensing,
  groupResponsibilities,
  sumWeights,
  SystemForLicensing,
} from '../helpers';
import { ReportDetailPerSystemRow } from './ReportDetailPerSystemRow';
import { useSorting } from '../../../../shared/hooks/useSorting';
import { selectAndLocaleCompare } from '../selectAndLocaleCompare';
import { selectAndNumericCompare } from '../selectAndNumericCompare';
import { SortableHeaderCell } from '../../../../shared/components/SortableHeaderCell';
import { SortableWeightsHeaderCells } from '../../../../shared/components/SortableWeightsHeaderCells';
import { PackagingWeightsKeys } from '../PackagingWeightsKeys';
import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { useReports } from '../../Reports';
import { Trans, useTranslation } from 'react-i18next';
import { useSystems } from '../../../systems/Systems';
import { usePeriodParam } from '../../../../shared/hooks/useParam';
import { ReportHistory } from '../ReportHistory';
import { AuditorExportButton } from '../export/AuditorExport';

export interface Props {
  declarationId: number;
  producerId: string;
  responsibilities: Responsibility[];
}

interface Row {
  keyAsString: string;
  system: SystemForLicensing;
  count: number;
  weights: PackagingWeights;
}

export function ReportDetailPerSystem({ declarationId, responsibilities, producerId }: Props) {
  const { t } = useTranslation('reports');
  const producers = useProducers();
  const producer = producers.get(producerId);

  const period = usePeriodParam();

  const contracts = useContracts();
  const thirdPartyLicensing = useThirdPartyLicensing();

  function tryGetSystemForLicensing(responsibility: Responsibility) {
    if (responsibility.reporting === null || responsibility.licensing === null) {
      return null;
    }

    return getSystemForLicensing(
      contracts.get,
      thirdPartyLicensing.get,
      responsibility.reporting,
      responsibility.licensing
    );
  }

  const groups: Row[] = filterAndGroupResponsibilities(tryGetSystemForLicensing, responsibilities).map(group => ({
    keyAsString: group.keyAsString,
    system: group.key,
    // We need to group the responsibilities again because we want the number of different licensers, but a licenser can have multiple licensing responsibilities
    count: groupResponsibilities(r => r.licensing, group.responsibilities).length,
    weights: sumWeights(group.responsibilities.map(r => r.weights)),
  }));

  const systems = useSystems();

  function tryGetSystemName(systemForLicensing: SystemForLicensing) {
    if (systemForLicensing.type === 'System') {
      const system = systems.get(systemForLicensing.systemId);
      return system?.name ?? null;
    }

    return null;
  }

  const emptySystem: SystemForLicensing = {
    type: 'SystemOfProducer',
    producerId: producerId,
  };

  const emptyWeights: PackagingWeights = getEmptyWeights();

  const sorting = useSorting<Row, 'system' | PackagingWeightsKeys>(
    groups,
    {
      system: selectAndLocaleCompare<Row>(r => tryGetSystemName(r.system)),
      glass: selectAndNumericCompare<Row>(r => r.weights.glass),
      fiber: selectAndNumericCompare<Row>(r => r.weights.fiber),
      ferrousMetal: selectAndNumericCompare<Row>(r => r.weights.ferrousMetal),
      aluminum: selectAndNumericCompare<Row>(r => r.weights.aluminum),
      plastic: selectAndNumericCompare<Row>(r => r.weights.plastic),
      beverageCarton: selectAndNumericCompare<Row>(r => r.weights.beverageCarton),
      otherComposite: selectAndNumericCompare<Row>(r => r.weights.otherComposite),
      other: selectAndNumericCompare<Row>(r => r.weights.other),
    },
    { key: 'system', direction: 'asc' }
  );

  const reports = useReports();
  const report = reports.get(declarationId, producerId);

  const includesThirdPartyLicensing = responsibilities.some(
    responsibility =>
      responsibility.licensing &&
      typeof responsibility.licensing === 'object' &&
      responsibility.licensing.Case !== 'Producer'
  );

  return (
    <>
      <div className="bg-light rounded mb-4">
        <InfoScreen icon="send" color="primary" title={t('reportDetailPerSystem.title')}>
          <p>
            <Trans t={t}>{t('reportDetailPerSystem.subtitle', { producer: producer?.name ?? producerId })}</Trans>
          </p>

          {includesThirdPartyLicensing && period !== 'Year' ? (
            <p>{t('reportDetailPerSystem.thirdPartyLicensingReportingIntervalHint')}</p>
          ) : (
            <></>
          )}
          <div className="form-check form-switch d-inline-block">
            <input
              className="form-check-input"
              id="cb-report"
              type="checkbox"
              checked={!!report?.lucidReportedAt}
              onChange={e => reports.report(declarationId, producerId, 'Lucid', e.target.checked)}
            />
            <label className="form-check-label" htmlFor="cb-report">
              {t('reportDetailPerSystem.markAsReported')}
            </label>
          </div>
          <div className="mt-2">
            <ReportHistory
              producerId={producerId}
              type="Lucid"
              renderDownloadButton={declarationId => <AuditorExportButton declarationId={declarationId} />}
            />
          </div>
        </InfoScreen>
      </div>
      <table className="table table-hover  table-striped">
        <thead className="sticky-top bg-white">
          <tr>
            <SortableHeaderCell sorting={sorting} sortKey="system">
              {t('reportDetailPerSystem.systemProvider')}
            </SortableHeaderCell>
            <SortableWeightsHeaderCells sorting={sorting} />
          </tr>
        </thead>
        <tbody>
          {sorting.sorted.length > 0 ? (
            sorting.sorted.map(row => (
              <ReportDetailPerSystemRow
                producerId={producerId}
                key={row.keyAsString}
                system={row.system}
                count={row.count}
                weights={row.weights}
              />
            ))
          ) : (
            <ReportDetailPerSystemRow producerId={producerId} system={emptySystem} count={0} weights={emptyWeights} />
          )}
        </tbody>
      </table>
    </>
  );
}
