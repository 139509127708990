{
  "contractRow": {
    "noContracts": "No contract with any system providers"
  },
  "contractsTable": {
    "producer": "Producer",
    "systemProvider": "System provider",
    "interval": "Declaration Period"
  },
  "contractForm": {
    "producer": "Producer",
    "systemProvider": "System provider",
    "fees": "Fees",
    "deadlines": "Deadlines",
    "annualReportingDueBy": "Annual reporting due by",
    "periodicalReportingDueBy": "Periodical reporting due by",
    "daysAfterEndOfPeriod": "days after end of period",
    "declarationPeriod": "Declaration Period",
    "declarationPeriodDescription": "Time period for regular declarations"
  },
  "editButton": {
    "save": "Save",
    "delete": "Delete",
    "contractWithSystemProvider": "Contract with system provider",
    "confirmDeletion": "Are you sure to delete this contract?"
  }
}
