import { SetStateAction } from 'react';
import { QueryResponse } from './useQuery';

export function useQueryState<D>(query: QueryResponse<D>): [D, (data: SetStateAction<D>) => void] {
  if (query.status === 'pending') {
    throw query.promise;
  }

  if (query.status === 'rejected') {
    throw query.error;
  }

  return [query.data, query.setValue];
}
