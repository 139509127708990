import { Navigate, Outlet, RouteObject } from 'react-router';
import { declarationIndexRoute as declarationIndexRouteAt } from '../../reports/at/DeclarationIndex';
import { Suspense } from 'react';
import { TenantSwitcher } from '../TenantSwitcher';
import { TenantImport } from '../TenantImport';
import { Header } from '../../../Header';
import { TenantNavigation } from '../TenantNavigation';
import { Fallback } from '../../../Fallback';
import { Producers } from '../../producers/Producers';
import { useTenant } from '../TenantDetail';
import { contractIndexRoute } from '../../contracts/at/ContractIndex';
import { branchIndexRoute } from '../../branches/BranchIndex';
import { producerIndexRoute } from '../../producers/ProducerIndex';
import { Contracts } from '../../contracts/Contracts';
import { CountrySwitcher } from '../CountrySwitcher';
import { useYearParam } from '../../../shared/hooks/useParam';
import { Systems } from '../../systems/Systems';

export const yearRoute: RouteObject = {
  path: ':year',
  element: <YearDetail />,
  children: [
    { index: true, element: <Navigate to="./declarations" replace /> },
    declarationIndexRouteAt,
    contractIndexRoute,
    branchIndexRoute,
    producerIndexRoute,
  ],
};

function YearDetail() {
  const year = useYearParam();
  const tenant = useTenant();

  if (!tenant.countries.includes('at')) {
    return <Navigate to={'../../'} />;
  }

  const tenantNavigation = (
    <div className="d-flex flex-row">
      <CountrySwitcher />
      <TenantSwitcher />
      {tenant.permissions.central && <TenantImport />}
    </div>
  );

  return (
    <>
      <Header tenantNavigation={tenantNavigation}>
        <TenantNavigation year={year} hideNavigation={false} minYear={2020} maxYear={new Date().getFullYear()} />
      </Header>
      <Suspense fallback={<Fallback />}>
        <Systems country="Austria">
          <Producers>
            <Contracts year={year} country="Austria">
              <Outlet />
            </Contracts>
          </Producers>
        </Systems>
      </Suspense>
    </>
  );
}
