import { PackagingWeights, Responsible } from '../../../../api';
import { Aggregation } from '../helpers';
import { useTranslation } from 'react-i18next';
import { useSorting } from '../../../../shared/hooks/useSorting';
import { PackagingWeightsKeys } from '../PackagingWeightsKeys';
import { SortableHeaderCell } from '../../../../shared/components/SortableHeaderCell';
import { SortableWeightsHeaderCells } from '../../../../shared/components/SortableWeightsHeaderCells';
import { ResponsibleView } from '../ResponsibleView';
import { ReviewWeightsRowCells } from './ReviewWeightsRowCells';
import { useCompareResponsible } from '../CompareResponsible';

function compareWeight(key: keyof PackagingWeights) {
  return (a: Aggregation<Responsible>, b: Aggregation<Responsible>) => {
    const wa = a.weights[key];
    const wb = b.weights[key];
    return wa - wb;
  };
}

export function ReviewList({ data }: { data: Aggregation<Responsible>[] }) {
  const { t } = useTranslation('reports');

  const compareResponsible = useCompareResponsible();

  function compareKey(a: Aggregation<Responsible>, b: Aggregation<Responsible>) {
    return compareResponsible(a.key, b.key);
  }

  const sorting = useSorting<Aggregation<Responsible>, 'key' | PackagingWeightsKeys>(
    data,
    {
      key: compareKey,
      glass: compareWeight('glass'),
      fiber: compareWeight('fiber'),
      ferrousMetal: compareWeight('ferrousMetal'),
      aluminum: compareWeight('aluminum'),
      plastic: compareWeight('plastic'),
      beverageCarton: compareWeight('beverageCarton'),
      otherComposite: compareWeight('otherComposite'),
      other: compareWeight('other'),
    },
    { key: 'key', direction: 'asc' }
  );

  return (
    <table className="table table-striped table-hover">
      <thead className="sticky-top bg-white">
        <tr>
          <SortableHeaderCell sorting={sorting} sortKey="key">
            {t('reviewStep.company')}
          </SortableHeaderCell>
          <SortableWeightsHeaderCells sorting={sorting} />
        </tr>
      </thead>
      <tbody>
        {sorting.sorted.map(a => (
          <tr key={a.keyAsString}>
            <td>
              <ResponsibleView responsible={a.key} />
            </td>
            <ReviewWeightsRowCells weights={a.weights} comparison={a.comparisonWeights} />
          </tr>
        ))}
      </tbody>
    </table>
  );
}
