{
  "producersTable": {
    "name": "Name",
    "id": "ID",
    "lucidNumber": "LUCID",
    "contact": "Contact",
    "address": "Address (Country)",
    "active": "Active"
  },
  "producerForm": {
    "name": "Name",
    "id": "ID",
    "lucidNumber": "LUCID",
    "active": "Active",
    "contact": {
      "name": "Contact's name",
      "email": "Email",
      "phone": "Phone",
      "language": "Language"
    },
    "address": {
      "street": "Street",
      "postCode": "Postcode",
      "city": "City",
      "country": "Country"
    }
  },
  "producerModal": {
    "title": "New Producer",
    "delete": "Delete",
    "save": "Save",
    "confirmDeletion": "Are you sure to delete this producer?"
  },
  "newProducer": "New Producer"
}