{
  "addButton": {
    "buttonText": "Add",
    "title": "New placings"
  },
  "editablePlacingForm": {
    "producer": "Producer",
    "period": "Period",
    "packagingType": "Packaging type",
    "description": "Description"
  },
  "editButton": {
    "save": "Save"
  },
  "placingRow": {
    "confirmDeletion": "Are you sure to delete these placings?"
  },
  "placingsOfYear": {
    "title": "Manage additional placings.",
    "subtitle": "Here you can manage placings that could not be determined from movement data.<br />This can for instance be weights of shipment packaging that vary between movements.",
    "producer": "Producer",
    "description": "Description",
    "period": "Period",
    "packagingType": "Packaging type",
    "notIncludedInPeriod": "Placings that are not included in the selected period"
  }
}
