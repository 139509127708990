import * as React from 'react';
import { ColSize } from './Col';

export interface Props {
  size?: ColSize;
  children?: React.ReactNode;
}

export function Field(props: Props) {
  const className = props.size == null ? `mb-3 col` : `mb-3 col-${props.size}`;
  return <div className={className}>{props.children}</div>;
}
