import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { AUTH_RESPONSE, AUTH_RETURN_PATH, AUTH_STATE, useAuthOptions } from './shared';

/** A button to log out. */
export function LogOutButton() {
  const { t } = useTranslation('auth');
  const authOptions = useAuthOptions();

  const state = nanoid();
  const params = new URLSearchParams();
  params.set('response_type', 'code');
  params.set('client_id', authOptions.clientId);
  params.set('logout_uri', window.location.protocol + '//' + window.location.host + '/api/account/logout');
  params.set('state', state);

  const logoutUrl = `${authOptions.logoutUrl}?${params.toString()}`;

  function onClick() {
    sessionStorage.removeItem(AUTH_RESPONSE);
    sessionStorage.setItem(AUTH_STATE, state);
    sessionStorage.setItem(AUTH_RETURN_PATH, window.location.pathname + window.location.search + window.location.hash);
  }

  return (
    <a id="logOutButton" className="btn btn-secondary" role="button" onClick={onClick} href={logoutUrl}>
      {t('LogOutButton.label')}
    </a>
  );
}
