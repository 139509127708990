﻿import { Step } from './Step';
import { useTranslation } from 'react-i18next';
import { DeclarationResultFrance, LongRunningTask } from '../../../api';
import { expectNever } from '../../../helpers';
import { useYearParam } from '../../../shared/hooks/useParam';

export interface Props {
  task: LongRunningTask<DeclarationResultFrance, [number, string]> | null;
}

function declarationStatus(task: LongRunningTask<DeclarationResultFrance, [number, string]> | null) {
  if (task === null) {
    return 'none';
  }

  if (task === 'Running') {
    return 'running';
  }

  if (task.Case === 'Failed') {
    return 'failed';
  }

  if (task.Case === 'Finished') {
    const kind = task.Fields[0].kind;

    if (kind === 'Preview') {
      return 'preview';
    }

    if (kind === 'Release') {
      return 'released';
    }

    expectNever(kind);
  }

  expectNever(task);
}

export function Steps({ task }: Props) {
  const { t } = useTranslation('reports');

  const year = useYearParam();
  const status = declarationStatus(task);

  return (
    <div className="list-group list-group-flush">
      <Step
        disabled={year === 2022 && (status === 'running' || status === 'preview' || status === 'released')}
        num={1}
        name="import"
        title={t('steps.fr.importAndCalculate')}
      />
      <Step
        disabled={status === 'none' || status === 'running' || status === 'failed'}
        num={2}
        name="validate"
        title={t('steps.fr.review')}
      />
      <Step
        disabled={status === 'none' || status === 'running' || status === 'failed' || status === 'preview'}
        num={3}
        name="finish"
        title={t('steps.fr.finish')}
      />
    </div>
  );
}
