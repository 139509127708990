import { StateOf, numberField, optionalNumberField } from '../../forms';
import { NumberInput } from './NumberInput';
import { ColSize } from './Col';
import { Field } from './Field';

export interface Props {
  id: string;
  label: string;
  field: StateOf<typeof numberField> | StateOf<typeof optionalNumberField>;
  focusOnMount?: boolean;
  min?: number;
  max?: number;
  unit?: string;
  disabled?: boolean;
  size?: ColSize;
  decimalPlaces?: number;
}

export function NumberField(props: Props) {
  return (
    <Field size={props.size}>
      <label className="form-label" htmlFor={props.id}>
        {props.label}
      </label>
      <NumberInput
        id={props.id}
        focusOnMount={props.focusOnMount}
        disabled={props.disabled}
        field={props.field}
        unit={props.unit}
        min={props.min}
        max={props.max}
        decimalPlaces={props.decimalPlaces}
      />
    </Field>
  );
}
