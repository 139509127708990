import { Navigate, Outlet, RouteObject, useOutletContext } from 'react-router';
import { DeclarationResultGermany } from '../../../../api';
import { Actions } from '../Actions';
import { ReportList } from './ReportList';
import { ContractEdit } from '../../../contracts/de/ContractEdit';
import { useProducersThatHaveToReport } from './useProducersThatHaveToReport';
import { usePeriodParam, useYearParam } from '../../../../shared/hooks/useParam';

export const reportIndexRoute: RouteObject = {
  element: <ReportIndex />,
  children: [{ index: true }, { path: 'contracts/edit/:contractProducerId', element: <ContractEdit /> }],
};

function ReportIndex() {
  const [period, year] = [usePeriodParam(), useYearParam()];
  const declaration = useOutletContext<DeclarationResultGermany>();

  const producerIds = useProducersThatHaveToReport(period, declaration, true);

  if (producerIds.length === 1) {
    return <Navigate to={`./${producerIds[0]}`} replace />;
  }

  return (
    <>
      <Actions year={year} period={period} declaration={declaration} />
      <ReportList declarationId={declaration.declarationId} producerIds={producerIds} />
      <Outlet />
    </>
  );
}
