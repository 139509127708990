import { useMutation } from '../../remote';
import { initiateAuth } from '../../api';
import { stringParam, useSearchParams } from '../../shared/hooks/useSearchParams';
import { group, textField, useForm } from '../../forms';
import { TextField } from '../../shared/components/TextField';
import { AuthErrorView } from './AuthErrorView';
import { AuthStateView } from './AuthStateView';
import { Link } from 'react-router-dom';
import { GoogleSignInButton } from './GoogleSignInButton';
import { useTranslation } from 'react-i18next';
import { usernameField } from './shared';
import { FormEvent } from 'react';

const definition = group({
  username: usernameField,
  password: textField,
});

export function InitiateAuth() {
  const { t } = useTranslation('auth');

  const [returnPath] = useSearchParams<string>('returnPath', stringParam('/'));

  const mutation = useMutation(initiateAuth);

  const form = useForm(definition, { username: '', password: '' });

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (form.status.valid) {
      mutation.start(form.status.value);
    } else {
      form.setTouched(true);
    }
  }

  if (mutation.isResolved) {
    const result = mutation.data;
    if (result.Case === 'Ok') {
      const state = result.Fields[0];
      return <AuthStateView state={state} />;
    }
  }

  const params = new URLSearchParams({
    returnPath: returnPath,
  });

  return (
    <>
      <h1 className="h4 text-center text-secondary">{t('InitiateAuth.title')}</h1>
      <p className="my-4 fs-4 fw-light text-muted">{t('InitiateAuth.description')}</p>
      <div className="mb-3">
        <form onSubmit={onSubmit} className="mb-3">
          <TextField
            hideErrorUntilTouched
            id="username"
            label={t('InitiateAuth.username')}
            field={form.fields.username}
            focusOnMount
            autoComplete="username"
          />
          <TextField
            hideErrorUntilTouched
            id="password"
            label={t('InitiateAuth.password')}
            field={form.fields.password}
            type="password"
            autoComplete="current-password"
          />
          <div className="mt-3 d-flex flex-row align-items-center justify-content-between">
            <button className="btn btn-primary" type="submit">
              {t('InitiateAuth.signIn')}
            </button>
            <Link
              className="text-decoration-none"
              to={{
                pathname: '/auth/forgotpassword',
                search: params.toString(),
              }}>
              {t('InitiateAuth.forgotPassword')}
            </Link>
          </div>
        </form>
        {mutation.isResolved && mutation.data.Case === 'Error' && <AuthErrorView error={mutation.data.Fields[0]} />}
      </div>
      <hr />
      <div className="text-center mt-3 pt-1">
        <GoogleSignInButton label={t('InitiateAuth.signInWithGoogle')} returnPath={returnPath} />
      </div>
    </>
  );
}
