﻿import { Icon } from '../../../../../shared/components/Icon';
import { deletePlacing, getPlacings, Period, PlacingData } from '../../../../../api';
import { getNameOfPackagingType, getNameOfPeriod } from '../../../../../helpers';
import { useTenantMutation } from '../../../../../remote';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useTenantRevalidate } from '../../../../../shared/hooks/useTenantRevalidate';
import { PlacingWeightsRowCells } from './PlacingWeightsRowCells';

export interface Props {
  year: number;
  placingId: string;
  period: Period;
  data: PlacingData;
  cellClassName?: string;
}

export function PlacingRow({ year, placingId, data, cellClassName }: Props) {
  const { t } = useTranslation('placings');
  const mutation = useTenantMutation(deletePlacing);

  const revalidate = useTenantRevalidate(getPlacings, year);

  function onDelete() {
    if (confirm(t('placingRow.confirmDeletion'))) {
      mutation.start(placingId, {
        async onSuccess() {
          await revalidate();
        },
      });
    }
  }

  return (
    <tr>
      <td className={`align-middle ${cellClassName}`}>{data.description}</td>
      <td className={`align-middle text-nowrap ${cellClassName}`}>{getNameOfPeriod(data.period)}</td>
      <td className={`align-middle text-nowrap ${cellClassName}`}>{getNameOfPackagingType(data.packagingType)}</td>
      <PlacingWeightsRowCells weights={data.weights} cellClassName={cellClassName} />
      <td className="text-nowrap align-middle">
        <NavLink to={`./edit/${placingId}`} className="btn btn-outline-secondary me-2">
          <Icon name="pencil" />
        </NavLink>
        <button className="btn btn-outline-secondary" onClick={onDelete}>
          <Icon name="trash" />
        </button>
      </td>
    </tr>
  );
}
