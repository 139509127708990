export interface Props {
  active: boolean;
  children?: React.ReactNode;
}

export function FallbackAnimation() {
  return (
    <div className="fallback-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export function FallbackWrapper(props: Props) {
  return (
    <div style={{ minHeight: '20rem' }}>
      {props.active && (
        <div className="text-center" style={{ zIndex: 1000, height: 0, transform: 'translateY(16rem)' }}>
          <FallbackAnimation />
        </div>
      )}
      <div style={props.active ? { opacity: 0.2, pointerEvents: 'none' } : {}}>{props.children}</div>
    </div>
  );
}

export function Fallback() {
  return (
    <div
      className="d-flex flex-row align-items-center justify-content-center"
      style={{ minHeight: '20rem', width: '100%' }}>
      <FallbackAnimation />
    </div>
  );
}
