import { createBrowserRouter, RouterProvider, useRouteError } from 'react-router-dom';
import './i18n';
import { Fallback } from './Fallback';
import { authRootRoute } from './public/auth/AuthRoot';
import { privateRoute } from './private/Private';
import { ErrorFallback } from './shared/components/ErrorFallback';

function RouteErrorFallback() {
  const error = useRouteError();
  return <ErrorFallback error={error} />;
}

const router = createBrowserRouter([{ errorElement: <RouteErrorFallback />, children: [authRootRoute, privateRoute] }]);

function App() {
  return <RouterProvider router={router} fallbackElement={<Fallback />} />;
}

export default App;
