import * as React from 'react';
import { field, StateOf, valid } from '../../forms';
import { DeclarationInterval, DeclarationIntervalEnumerable } from '../../api';
import { getNameOfInterval } from '../../helpers';
import { InputGroup } from './InputGroup';

export const intervalField = field<DeclarationInterval>(valid);

export interface Props {
  id: string;
  field: StateOf<typeof intervalField>;
  focusOnMount?: boolean;
  disabled?: boolean;
}

export function IntervalInput(props: Props) {
  const inputRef = React.useRef<HTMLSelectElement>();

  React.useEffect(() => {
    if (props.focusOnMount) {
      inputRef.current?.focus();
    }
  }, [props.focusOnMount]);

  let selectClassName = 'form-select';

  if (!props.field.status.valid) {
    selectClassName += ' is-invalid';
  }

  return (
    <InputGroup field={props.field}>
      <select
        disabled={props.disabled}
        className={selectClassName}
        id={props.id}
        ref={(e: HTMLSelectElement) => (inputRef.current = e)}
        value={props.field.value}
        onChange={e => props.field.setValue(e.currentTarget.value as DeclarationInterval)}
        onFocus={() => props.field.setTouched(true)}>
        {DeclarationIntervalEnumerable.map(interval => {
          return (
            <option key={interval} value={interval}>
              {getNameOfInterval(interval)}
            </option>
          );
        })}
      </select>
    </InputGroup>
  );
}
