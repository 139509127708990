import { useTranslation } from 'react-i18next';
import { useTenant } from './TenantDetail';
import { useCountryParam } from '../../shared/hooks/useParam';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router';

export function CountrySwitcher() {
  const { t } = useTranslation('tenants');
  const navigate = useNavigate();
  const tenant = useTenant();
  const selected = useCountryParam();

  if (selected === undefined || tenant.countries.length <= 1) {
    return <></>;
  }

  return (
    <Dropdown className="me-2">
      <Dropdown.Toggle>{t(`countrySwitch.countries.${selected}`)}</Dropdown.Toggle>
      <Dropdown.Menu>
        {tenant.countries.map(country => (
          <Dropdown.Item key={country} active={country === selected} onClick={() => navigate(`../../${country}`)}>
            {t(`countrySwitch.countries.${country}`)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
