{
  "addButton": {
    "buttonText": "Hinzufügen",
    "title": "Neue Mengen"
  },
  "editablePlacingForm": {
    "producer": "Inverkehrbringer",
    "period": "Zeitraum",
    "packagingType": "Art der Verpackung",
    "description": "Beschreibung"
  },
  "editButton": {
    "save": "Speichern"
  },
  "placingRow": {
    "confirmDeletion": "Wollen Sie diese Mengen wirklich löschen?"
  },
  "placingsOfYear": {
    "title": "Sonstige in Verkehr gesetzte Mengen verwalten",
    "subtitle": "Hier können Sie in Verkehr gesetzte Mengen verwalten die nicht aus Warenbewegungen ermittelt werden können.<br />Dabei kann es sich z.B. um Mengen von Versandkartons handeln, die bei jeder Bestellung variieren.",
    "producer": "Inverkehrsetzer",
    "description": "Beschreibung",
    "period": "Zeitraum",
    "packagingType": "Art der Verpackung",
    "notIncludedInPeriod": "Mengen die nicht im ausgewählten Zeitraum enthalten sind"
  }
}