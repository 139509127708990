import { DeclarationResultGermany, Period } from '../../../../api';
import { useTenant } from '../../../tenants/TenantDetail';
import { hasAccessToProducer } from '../../../hasAccessToProducer';
import { useContractFilterFunctions } from '../useContractFilterFunctions';
import { isProducer } from '../helpers';
import { useProducerIdsFromContracts } from '../../../contracts/useProducerIdsFromContracts';
import { useActiveProducers } from '../../../producers/useActiveProducers';

/**
 * Returns the list of IDs of the producers that have to report in the specified period.
 * @param period The period.
 * @param declaration The declaration.
 * @param includeUnclearCases Should we include the cases where we don't have enough information if reporting is required.
 */
export function useProducersThatHaveToLicense(
  period: Period,
  declaration: DeclarationResultGermany,
  includeUnclearCases: boolean
) {
  const tenant = useTenant();
  const { hasContractForPeriod, hasNoContract } = useContractFilterFunctions(period);

  const producerIds = new Set<string>();

  // Add all producers that have to license from the declaration
  for (const responsibility of declaration.responsibilities) {
    if (responsibility.licensing != null && isProducer(responsibility.licensing)) {
      producerIds.add(responsibility.licensing.Fields[0]);
    }
  }

  // Add all producers from the producers table
  for (const producerId of useActiveProducers()) {
    producerIds.add(producerId);
  }

  // Add all producers from the contracts table
  for (const producerId of useProducerIdsFromContracts()) {
    producerIds.add(producerId);
  }

  function hasProducerToLicenseInPeriod(producerId: string) {
    return hasContractForPeriod(producerId) || (includeUnclearCases && hasNoContract(producerId));
  }

  return Array.from(producerIds.values()).filter(
    producerId => hasAccessToProducer(tenant.permissions, producerId) && hasProducerToLicenseInPeriod(producerId)
  );
}
