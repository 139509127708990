import { useTenant } from '../tenants/TenantDetail';
import { hasAccessToProducer } from '../hasAccessToProducer';

/**
 * Filters the producer IDs by returning only the IDs of the producers, that are accessible by the user.
 */
export function useAccessibleProducers(producerIds: string[]) {
  const tenant = useTenant();
  return producerIds.filter(producerId => hasAccessToProducer(tenant.permissions, producerId));
}
