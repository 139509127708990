﻿import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export type ModalSize = 'xl' | 'lg' | 'sm';

export interface Props {
  children?: React.ReactNode;
  footer?: React.ReactNode;
  visible?: boolean;
  onClose?: () => void;
  title?: string;
  size?: ModalSize;
}

export function Modal(props: Props) {
  const containerRef = useRef(document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(containerRef.current);
    return () => {
      document.body.removeChild(containerRef.current);
    };
  }, []);

  // First render with with fadeVisible = false, so that the animation can take in
  const [fadeVisible, setFadeVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => setFadeVisible(props.visible || false), 0);
  }, [props.visible]);

  const modalSizeClassName = props.size == null ? '' : ` modal-${props.size}`;

  return createPortal(
    <>
      <div
        className={`fade modal-backdrop ${fadeVisible ? 'show' : 'hide'}`}
        style={{ display: props.visible ? 'block' : 'none' }}></div>
      <div
        className={`modal fade ${fadeVisible ? 'show' : 'hide'}`}
        style={{ display: props.visible ? 'block' : 'none' }}
        aria-hidden={!props.visible}>
        <div className={`modal-dialog modal-dialog-centered modal-dialog-scrollable${modalSizeClassName}`}>
          <div className="modal-content">
            <div className="modal-header">
              {props.title && <h5 className="modal-title">{props.title}</h5>}
              {props.onClose && (
                <button type="button" className="btn-close" aria-label="Close" onClick={props.onClose}></button>
              )}
            </div>
            <div className="modal-body">{props.children}</div>
            {props.footer && <div className="modal-footer">{props.footer}</div>}
          </div>
        </div>
      </div>
    </>,
    containerRef.current
  );
}
