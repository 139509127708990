﻿import { objectParam, stringParam, useSearchParams } from './useSearchParams';
import { To } from 'react-router-dom';

type SortingDirection = 'asc' | 'desc';

interface SortingOption<K extends string> {
  readonly key: K;
  readonly direction: SortingDirection;
}

export interface Sorting<T, K extends string> {
  readonly sorted: T[];
  readonly option: SortingOption<K>;
  readonly setOption: (option: SortingOption<K>, mode?: 'replace' | 'push') => void;
  readonly getOptionTo: (option: SortingOption<K>) => To;
}

export function useSorting<T, K extends string>(
  source: T[],
  compareFns: Record<K, (a: T, b: T) => number>,
  defaultOption: SortingOption<K>
): Sorting<T, K> {
  const [option, setOption, getOptionTo] = useSearchParams(
    'sort',
    objectParam({
      key: stringParam<K>(defaultOption.key),
      direction: stringParam<SortingDirection>(defaultOption.direction),
    })
  );

  const compareFn = option.direction === 'asc' ? compareFns[option.key] : (a: T, b: T) => compareFns[option.key](b, a);

  const sorted = [...source];
  sorted.sort(compareFn);

  return {
    sorted,
    option,
    setOption,
    getOptionTo,
  };
}
