import { Outlet, RouteObject } from 'react-router';
import { InitiateAuth } from './InitiateAuth';
import { ForgotPassword } from './ForgotPassword';
import { Footer } from '../../shared/components/Footer';

export const authRootRoute: RouteObject = {
  path: '/auth',
  element: <AuthRoot />,
  children: [
    { path: 'initiateauth', element: <InitiateAuth /> },
    { path: 'forgotpassword', element: <ForgotPassword /> },
  ],
};

const faviconUrl = new URL('../../../assets/favicon.svg', import.meta.url);

export function AuthRoot() {
  return (
    <div className="bg-custom-gradient d-flex flex-column pt-2">
      <div className="container flex-grow-1 col-sm-8 col-md-7 col-lg-6 col-xl-5 col-xxl-4">
        <div className="text-center my-4 py-4">
          <img height={130} style={{ opacity: 0.2 }} src={faviconUrl.toString()} alt="logo" />
        </div>
        <main className="bg-white p-4 rounded-3 mt-4 shadow auth-main-content">
          <Outlet />
        </main>
      </div>
      <Footer />
    </div>
  );
}
