﻿import { useTenant } from '../../../tenants/TenantDetail';
import {
  getAllThirdPartyLicensings,
  upsertThirdPartyLicensing,
  deleteThirdPartyLicensing,
  ThirdPartyLicensee,
  PackagingWeights,
  ThirdPartyLicensing,
  Period,
} from '../../../../api';
import { createContext, ReactNode, useContext } from 'react';
import { useTenantQuery } from '../../../../shared/hooks/useTenantQuery';
import { useQueryState } from '../../../../shared/hooks/useQueryState';

export interface ThirdPartyLicensingService {
  get(producerId: string, licensee: ThirdPartyLicensee): ThirdPartyLicensing | null;

  set(
    producerId: string,
    licensee: ThirdPartyLicensee,
    systemId: number,
    weights: PackagingWeights | null
  ): Promise<void>;

  remove(producerId: string, licensee: ThirdPartyLicensee): Promise<void>;

  all: Record<string, ThirdPartyLicensing>;
}

const ThirdPartyLicensingContext = createContext<ThirdPartyLicensingService | null>(null);

function getKey(producerId: string, licensee: ThirdPartyLicensee) {
  return `${producerId}#${licensee.Case}#${licensee.Fields[0]}`;
}

export function ThirdPartyLicensing(props: { year: number; period: Period; children?: ReactNode }) {
  const tenant = useTenant();

  const query = useTenantQuery(getAllThirdPartyLicensings, { year: props.year, period: props.period }, false);
  const [data, setData] = useQueryState(query);

  function get(producerId: string, licensee: ThirdPartyLicensee) {
    return data[getKey(producerId, licensee)] ?? null;
  }

  async function set(
    producerId: string,
    licensee: ThirdPartyLicensee,
    systemId: number,
    weights: PackagingWeights | null
  ) {
    const value: ThirdPartyLicensing = {
      producerId,
      licensee,
      systemId,
      period: props.period,
      weights,
      year: props.year,
    };

    // Change local
    setData(prev => {
      const key = getKey(producerId, licensee);
      return { ...prev, [key]: value };
    });

    try {
      // Change remote
      await upsertThirdPartyLicensing({
        tenantId: tenant.id,
        data: value,
      });
    } finally {
      // Revalidate
      await query.revalidate();
    }
  }

  async function remove(producerId: string, licensee: ThirdPartyLicensee) {
    // Change local
    setData(prev => {
      const key = getKey(producerId, licensee);
      const next = { ...prev };
      delete next[key];
      return next;
    });

    try {
      // Change remote
      await deleteThirdPartyLicensing({
        tenantId: tenant.id,
        data: { producerId, licensee, period: props.period, year: props.year },
      });
    } finally {
      // Revalidate
      await query.revalidate();
    }
  }

  return (
    <ThirdPartyLicensingContext.Provider value={{ get, set, remove, all: data }}>
      {props.children}
    </ThirdPartyLicensingContext.Provider>
  );
}

export function useThirdPartyLicensing(): ThirdPartyLicensingService {
  const service = useContext(ThirdPartyLicensingContext);

  if (service == null) {
    throw new Error('ThirdPartyLicensing must be defined higher in tree.');
  }

  return service;
}
