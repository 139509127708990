﻿import { CompanyInfo } from './CompanyInfo';
import { useSuppliers } from '../suppliers/suppliers';
import { useTranslation } from 'react-i18next';

export interface Props {
  supplierId: string;
}

export function SupplierInfo({ supplierId }: Props) {
  const suppliers = useSuppliers();
  const supplierName = suppliers[supplierId];
  const { t } = useTranslation();

  return <CompanyInfo companyId={supplierId} name={supplierName} badge={t('global:supplier')} />;
}
