import { ClientAuthState, OAuthResult } from '../../api';
import { ConfirmForgotPassword } from './ConfirmForgotPassword';
import { expectNever } from '../../helpers';
import { AUTH_RESPONSE, AUTH_STATE } from './shared';
import { Navigate } from 'react-router';
import { SmsMfaChallenge } from './SmsMfaChallenge';
import { NewPasswordRequiredChallenge } from './NewPasswordRequiredChallenge';
import { stringParam, useSearchParams } from '../../shared/hooks/useSearchParams';

function Authenticated({ result }: { result: OAuthResult }) {
  const [returnPath] = useSearchParams<string>('returnPath', stringParam('/'));

  sessionStorage.removeItem(AUTH_STATE);
  sessionStorage.setItem(
    AUTH_RESPONSE,
    JSON.stringify({
      state: null,
      result,
    })
  );

  return <Navigate to={returnPath} replace />;
}

export interface Props {
  state: ClientAuthState;
}

export function AuthStateView({ state }: Props) {
  if (state.Case === 'Authenticated') {
    return <Authenticated result={state.Fields[0]} />;
  }

  if (state.Case === 'Challenge') {
    const challenge = state.Fields[0];

    if (challenge.Case === 'NewPasswordRequired') {
      return <NewPasswordRequiredChallenge context={challenge.Fields[0]} requiredAttributes={challenge.Fields[1]} />;
    }

    if (challenge.Case === 'SmsMfa') {
      return <SmsMfaChallenge context={challenge.Fields[0]} codeDeliveryDestination={challenge.Fields[1]} />;
    }

    expectNever(challenge);
  }

  if (state.Case === 'PasswordResetRequired') {
    return <ConfirmForgotPassword username={state.Fields[0]} />;
  }

  expectNever(state);
}
