import { Modal } from '../../shared/components/Modal';
import { useNavigate, RouteObject, useLoaderData } from 'react-router';
import { deleteTenant, getTenantsByUser, Tenant, tryGetTenantById, updateTenant } from '../../api';
import { definition, TenantForm } from './TenantForm';
import { useForm } from '../../forms';
import { useModifiedPathname } from '../../shared/hooks/useModifiedPathname';
import { FormEvent } from 'react';
import { Icon } from '../../shared/components/Icon';
import { useTranslation } from 'react-i18next';
import { useRevalidateAll } from '../../shared/hooks/useRevalidateAll';

export const editRoute: RouteObject = {
  path: 'edit/:tenantId',
  element: <EditDialog />,
  loader: ({ params, request }) => {
    if (params.tenantId === undefined) {
      throw Error('missing tenantId');
    }
    const tenantId = parseInt(params.tenantId);
    return tryGetTenantById({ tenantId, data: void 0 }, request.signal);
  },
};

export function EditDialog() {
  const { t } = useTranslation('tenants');

  const tenant = useLoaderData() as Tenant;

  const form = useForm(definition, { name: tenant.name });

  const revalidate = useRevalidateAll(getTenantsByUser);

  const navigate = useNavigate();
  const toParent = useModifiedPathname('..');

  function onClose() {
    navigate(toParent);
  }

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (form.status.valid) {
      try {
        await updateTenant({ tenantId: tenant.id, data: form.status.value.name });
        onClose();
      } finally {
        await revalidate();
      }
    }
  }

  const onDeleteClick = async () => {
    if (confirm(`Do you really want to delete the group "${tenant.name}"?`)) {
      try {
        await deleteTenant({ tenantId: tenant.id, data: void 0 });
      } finally {
        await revalidate();
      }
      onClose();
    }
  };

  // Align with other UI
  const footer = (
    <>
      <button className="btn btn-outline-danger" onClick={onDeleteClick}>
        <Icon name="trash" className="me-2" />
        {t('tenantModal.delete')}
      </button>
      <button className={'btn btn-primary text-white'} type="submit" disabled={!form.status.valid} form="form">
        {t('tenantModal.save')}
      </button>
    </>
  );

  return (
    <Modal visible title={tenant?.name} footer={footer} onClose={onClose}>
      <form id="form" onSubmit={onSubmit}>
        <TenantForm form={form} />
      </form>
    </Modal>
  );
}
