﻿import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { useTranslation } from 'react-i18next';
import { Navigate, RouteObject, useOutletContext } from 'react-router';
import { WithdrawButton } from '../WithdrawButton';
import { ReleaseButton } from '../ReleaseButton';
import { DeclarationResultFrance, LongRunningTask } from '../../../../api';
import { formatCurrency } from '../../../../helpers';
import { useYearParam } from '../../../../shared/hooks/useParam';
import { ValidationTable } from './ValidationTable';

export const validateRoute: RouteObject = {
  path: 'validate',
  element: <ValidateStep />,
};

export function ValidateStep() {
  const { t, i18n } = useTranslation('reports');

  const year = useYearParam();

  const task = useOutletContext<LongRunningTask<DeclarationResultFrance, string> | null>();

  if (task === null || task === 'Running' || task.Case === 'Failed') {
    return <Navigate to="../import" replace />;
  }

  const declaration = task.Fields[0];

  return (
    <>
      <InfoScreen color="success" bgColor="success" icon="check-circle" title={t('csuValidateStep.noIssuesTitle')}>
        {t('csuValidateStep.noIssuesSubtitle')}
        <div className="mt-3 text-center fs-5 text-muted">
          {t('csuValidateStep.totalCosts')}
          <br />
          <span className="fw-bold text-black">{formatCurrency(declaration.totalCosts, i18n.language)}</span>
        </div>
      </InfoScreen>
      <div className={'mx-4'}>
        <ValidationTable data={declaration.membershipCosts} />
        <div className="d-flex justify-content-center">
          <WithdrawButton declarationId={declaration.declarationId} year={year} period="Year" />
          <ReleaseButton year={year} period="Year" kind={declaration.kind} />
        </div>
      </div>
    </>
  );
}
