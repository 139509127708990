import { Pagination as ReactPagination } from 'react-bootstrap';

type Props = {
  totalItems: number;
  itemsPerPage: number;
  pagesToShow: number;
  currentPageIndex: number;
  setCurrentPage: (page: number) => void;
};

export function Pagination(props: Props): JSX.Element {
  const { pagesToShow, currentPageIndex, setCurrentPage } = props;
  const numberOfPages = Math.ceil(props.totalItems / props.itemsPerPage);
  const indexOfLastPage = numberOfPages - 1;
  const pagesLookAhead = Math.ceil(pagesToShow / 2);

  const maxPagesBefore = Math.min(currentPageIndex - 1, pagesLookAhead);
  const maxPagesAhead = Math.min(pagesLookAhead, indexOfLastPage - currentPageIndex - 1);
  const pagesBefore = Math.min(currentPageIndex - 1, maxPagesBefore + (pagesLookAhead - maxPagesAhead));
  const pagesAhead = Math.min(numberOfPages - currentPageIndex - 2, maxPagesAhead + (pagesLookAhead - maxPagesBefore));

  const paginationItems: JSX.Element[] = [];

  for (let i = 1; i < numberOfPages - 1; i++) {
    paginationItems.push(
      <ReactPagination.Item key={i} active={i === currentPageIndex} onClick={() => setCurrentPage(i)}>
        {i + 1}
      </ReactPagination.Item>
    );
  }

  if (numberOfPages === 1) {
    return (
      <ReactPagination>
        <ReactPagination.Prev disabled />
        <ReactPagination.Item active={true}>1</ReactPagination.Item>
        <ReactPagination.Next disabled />
      </ReactPagination>
    );
  }

  return (
    <ReactPagination>
      <ReactPagination.Prev disabled={currentPageIndex <= 0} onClick={() => setCurrentPage(currentPageIndex - 1)} />
      <ReactPagination.Item active={0 === currentPageIndex} onClick={() => setCurrentPage(0)}>
        1
      </ReactPagination.Item>
      {paginationItems.length > pagesToShow * 2 && currentPageIndex - 1 > pagesLookAhead && (
        <ReactPagination.Ellipsis />
      )}
      {paginationItems.slice(currentPageIndex - pagesBefore - 1, currentPageIndex + pagesAhead)}
      {paginationItems.length > pagesToShow * 2 && currentPageIndex < indexOfLastPage - pagesAhead - 1 && (
        <ReactPagination.Ellipsis />
      )}
      <ReactPagination.Item
        active={numberOfPages - 1 === currentPageIndex}
        onClick={() => setCurrentPage(numberOfPages - 1)}>
        {numberOfPages}
      </ReactPagination.Item>
      <ReactPagination.Next
        disabled={currentPageIndex >= numberOfPages - 1}
        onClick={() => setCurrentPage(currentPageIndex + 1)}
      />
    </ReactPagination>
  );
}
