﻿import { NavLink } from 'react-router-dom';
import { Icon } from '../../shared/components/Icon';
import { useTenant } from './TenantDetail';
import { useTranslation } from 'react-i18next';
import { useCountryParam } from '../../shared/hooks/useParam';

type Props = {
  year: number;
  hideNavigation: boolean;
  minYear: number;
  maxYear: number;
};

export function TenantNavigation({ year, hideNavigation, minYear, maxYear }: Props) {
  const { t } = useTranslation('tenants');

  const tenant = useTenant();

  const country = useCountryParam();

  const prevYear = year - 1;
  const nextYear = year + 1;

  const prevIsDisabled = year <= minYear;
  const nextIsDisabled = year >= maxYear;

  return (
    <>
      <div className="nav-item">
        <div className="d-flex fw-light fs-2 align-items-center">
          <NavLink className={prevIsDisabled ? 'disabled' : undefined} to={`../${prevYear}`}>
            <Icon name="chevron-left" className="me-2" />
          </NavLink>
          {year}
          <NavLink className={nextIsDisabled ? 'disabled' : undefined} to={`../${nextYear}`}>
            <Icon name="chevron-right" className="ms-2" />
          </NavLink>
        </div>
      </div>
      {!hideNavigation && (
        <ul className="nav nav-pills flex-grow-1 ms-4">
          <li className="nav-item">
            <NavLink className="nav-link" to="./declarations">
              {t('navigation.declarations')}
            </NavLink>
          </li>
          {(country === 'de' || country === 'at') && (
            <li className="nav-item">
              <NavLink className="nav-link" to="./contracts">
                {t('navigation.manageContracts')}
              </NavLink>
            </li>
          )}
          {tenant.permissions.central && (
            <>
              <li className="nav-item">
                <NavLink className="nav-link" to="./branches">
                  {t('navigation.manageBranches')}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="./producers">
                  {t('navigation.manageProducers')}
                </NavLink>
              </li>
            </>
          )}
        </ul>
      )}
    </>
  );
}
