import { group, StateOf, textField } from '../../forms';
import { Row } from '../../shared/components/Row';
import { TextField } from '../../shared/components/TextField';
import { useTranslation } from 'react-i18next';

export const definition = group({
  name: textField,
});

export function TenantForm({ form }: { form: StateOf<typeof definition> }) {
  const { t } = useTranslation('tenants');
  return (
    <>
      <Row>
        <TextField focusOnMount id="name" label={t('form.name')} field={form.fields.name} />
      </Row>
    </>
  );
}
