﻿import { LongRunningTask } from '../../api';
import { useEffect } from 'react';

export function usePolling<T, E>(task: LongRunningTask<T, E> | null, revalidate: () => void, intervalMs = 5000) {
  useEffect(() => {
    if (task != null && task === 'Running') {
      const handle = setInterval(() => {
        revalidate();
      }, intervalMs);

      return () => {
        clearInterval(handle);
      };
    }
  }, [task]);
}
