import { AUTH_RESPONSE, readAuthResponse, tryGetAuthState } from './shared';
import { Navigate } from 'react-router';
import { ReactNode } from 'react';

/**
 * Redirects the user to the sign in screen if she is not authenticated.
 */
export function Authenticated({ children }: { children?: ReactNode }) {
  const storedState = tryGetAuthState();
  const auth = readAuthResponse();

  if (auth == null || (storedState != null && auth.state !== storedState)) {
    if (auth != null) {
      sessionStorage.removeItem(AUTH_RESPONSE);
    }

    const returnPath = window.location.pathname + window.location.search + window.location.hash;
    const params = new URLSearchParams();
    params.set('returnPath', returnPath);

    return <Navigate to={{ pathname: '/auth/initiateauth', search: params.toString() }} replace />;
  }

  return <>{children}</>;
}
