import { useTranslation } from 'react-i18next';
import { useForm } from '../../../forms';
import { contractForm, emptyForm, toForm, toValue } from './form';
import { useContracts } from '../Contracts';
import { Icon } from '../../../shared/components/Icon';
import { Modal } from '../../../shared/components/Modal';
import { ContractForm } from './ContractForm';
import { RouteObject, useNavigate } from 'react-router';
import { useProducers } from '../../producers/Producers';
import { useSearchParams } from 'react-router-dom';
import { useStringParams, useYearParam } from '../../../shared/hooks/useParam';

export const contractEditRoute: RouteObject = {
  path: 'edit/:contractProducerId',
  element: <ContractEdit />,
};

export function ContractEdit() {
  const year = useYearParam();
  const { contractProducerId: producerId } = useStringParams<'contractProducerId'>();
  const contracts = useContracts();
  const producers = useProducers();
  const producer = producers.get(producerId);
  const contract = contracts.get(producerId);

  const { t } = useTranslation('contracts');
  const form = useForm(contractForm, contract ? toForm(contract) : emptyForm);

  const navigate = useNavigate();
  const [search] = useSearchParams();

  function onClose() {
    navigate({ pathname: '..', search: search.toString() });
  }

  async function onSave() {
    if (form.status.valid) {
      await contracts.set(producerId, toValue(form.status.value, year, contract?.contractId || null, producerId));
      onClose();
    }
  }

  async function onDelete() {
    if (confirm(t('editButton.confirmDeletion'))) {
      onClose();
      await contracts.remove(producerId);
    }
  }

  const footer = (
    <>
      {contract && (
        <button className="ms-2 btn btn-outline-danger" onClick={onDelete}>
          <Icon name="trash" /> {t('editButton.delete')}
        </button>
      )}
      <button disabled={!form.status.valid} className="btn btn-primary text-white" onClick={onSave}>
        <Icon name="check-lg" /> {t('editButton.save')}
      </button>
    </>
  );

  return (
    <Modal
      onClose={onClose}
      visible={true}
      title={`${producer?.name ?? producerId} - ${t('editButton.contractWithSystemProvider')}`}
      footer={footer}
      size="lg">
      <ContractForm form={form} year={year} />
    </Modal>
  );
}
