﻿import { Sorting } from '../hooks/useSorting';
import { SortableHeaderCell } from './SortableHeaderCell';
import { useTranslation } from 'react-i18next';

export function SortableWeightsHeaderCells({ sorting }: { sorting: Sorting<any, any> }) {
  const { t } = useTranslation();
  return (
    <>
      <SortableHeaderCell sorting={sorting} sortKey="glass" className="col-1 text-end">
        {t('global:fractions.de.glass')}
      </SortableHeaderCell>
      <SortableHeaderCell sorting={sorting} sortKey="fiber" className="col-1 text-end">
        {t('global:fractions.de.fiber')}
      </SortableHeaderCell>
      <SortableHeaderCell sorting={sorting} sortKey="ferrousMetal" className="col-1 text-end">
        {t('global:fractions.de.ferrousMetal')}
      </SortableHeaderCell>
      <SortableHeaderCell sorting={sorting} sortKey="aluminum" className="col-1 text-end">
        {t('global:fractions.de.aluminum')}
      </SortableHeaderCell>
      <SortableHeaderCell sorting={sorting} sortKey="plastic" className="col-1 text-end">
        {t('global:fractions.de.plastic')}
      </SortableHeaderCell>
      <SortableHeaderCell sorting={sorting} sortKey="beverageCarton" className="col-1 text-end">
        {t('global:fractions.de.beverageCarton')}
      </SortableHeaderCell>
      <SortableHeaderCell sorting={sorting} sortKey="otherComposite" className="col-1 text-end">
        {t('global:fractions.de.otherComposite')}
      </SortableHeaderCell>
      <SortableHeaderCell sorting={sorting} sortKey="other" className="col-1 text-end">
        {t('global:fractions.de.other')}
      </SortableHeaderCell>
    </>
  );
}
