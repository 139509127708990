﻿import { group, textField, localeField, optionalTextField, InputOf, TextField, booleanField } from '../../forms';
import { ProducerData } from '../../api';

export const contactForm = group({
  name: optionalTextField,
  eMail: optionalTextField,
  phone: optionalTextField,
  language: localeField,
});

export const addressForm = group({
  country: optionalTextField,
  city: optionalTextField,
  postCode: optionalTextField,
  street: optionalTextField,
});

export function producerForm(idField: TextField) {
  return group({
    id: idField,
    name: textField,
    lucidNumber: optionalTextField,
    active: booleanField,
    contact: contactForm,
    address: addressForm,
  });
}

export type FormDefinition = ReturnType<typeof producerForm>;

export type FormInput = InputOf<FormDefinition>;

export function getInitialInput(data: ProducerData | null): FormInput {
  if (data) {
    return {
      id: data.id,
      name: data.name,
      lucidNumber: data.lucidNumber,
      active: data.active,
      contact: {
        name: data.contact.name,
        eMail: data.contact.eMail,
        phone: data.contact.phone,
        language: data.contact.language,
      },
      address: {
        country: data.address.country,
        city: data.address.city,
        postCode: data.address.postCode,
        street: data.address.street,
      },
    };
  }

  return {
    id: '',
    name: '',
    lucidNumber: null,
    active: true,
    contact: {
      name: null,
      eMail: null,
      phone: null,
      language: 'DE_de',
    },
    address: {
      country: '',
      city: null,
      postCode: null,
      street: null,
    },
  };
}
