import { getPlacings, Period } from '../../../../api';
import { useQueryValue } from '../../../../shared/hooks/useQueryValue';
import { useTenantQuery } from '../../../../shared/hooks/useTenantQuery';
import { getPeriodContains } from '../../../../helpers';

export function useProducersFromPlacings(year: number, period: Period) {
  const placings = useQueryValue(useTenantQuery(getPlacings, year, false));
  return Object.values(placings)
    .filter(p => getPeriodContains(period, p.period)) // Only producer IDs from relevant placings
    .map(p => p.producerId);
}
