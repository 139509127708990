import { useEffect, useRef } from 'react';
import { QueryResponse } from './useQuery';

/**
 * Returns the data of a query if possible.
 * - In case of the existence of a previous value, the previous value is returned.
 * - In case of initial loading, reacts suspense feature is triggered
 * - In case of an error, the error is thrown
 */
export function useQueryValueOrLatest<D>(query: QueryResponse<D>): D {
  const ref = useRef<D>();

  useEffect(() => {
    if (query.status !== 'pending' && query.status !== 'rejected') {
      ref.current = query.data;
    }
  }, [query]);

  if (query.status === 'pending') {
    if (ref.current != null) {
      return ref.current;
    }

    throw query.promise;
  }

  if (query.status === 'rejected') {
    throw query.error;
  }

  return query.data;
}
