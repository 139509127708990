import { Outlet, RouteObject, useOutletContext } from 'react-router';
import { DeclarationResultGermany } from '../../../../api';
import {
  AggregationWithThirdPartyLicensing,
  filterAndAggregateWeightsForThirdPartyLicensing,
  producerReportingButNotLicensing,
} from '../helpers';
import { useThirdPartyLicensing } from './ThirdPartyLicensing';
import { thirdPartyLicensingEditRoute } from './ThirdPartyLicensingEdit';
import { ThirdPartyLicensingList } from './ThirdPartyLicensingList';

export const thirdPartyLicensingIndexRoute: RouteObject = {
  element: <ThirdPartyLicensingIndex />,
  children: [{ index: true }, thirdPartyLicensingEditRoute],
};

function ThirdPartyLicensingIndex() {
  const declaration = useOutletContext<DeclarationResultGermany>();

  const thirdPartyLicensings = useThirdPartyLicensing().all;

  const reportingButNotLicensingProducers: AggregationWithThirdPartyLicensing<string | null>[] =
    filterAndAggregateWeightsForThirdPartyLicensing(producerReportingButNotLicensing, declaration.responsibilities).map(
      (aggregation: AggregationWithThirdPartyLicensing<string | null>) => {
        const thirdPartyLicensing = Object.values(thirdPartyLicensings).find(
          thirdPartyLicensing =>
            thirdPartyLicensing.producerId === aggregation.producer &&
            thirdPartyLicensing.licensee.Case === aggregation.licenseeType &&
            thirdPartyLicensing.licensee.Fields[0] === aggregation.licensee
        );
        return { ...aggregation, thirdPartyLicensing: thirdPartyLicensing ?? null };
      }
    );

  return (
    <>
      <ThirdPartyLicensingList data={reportingButNotLicensingProducers} />
      <Outlet context={reportingButNotLicensingProducers}></Outlet>
    </>
  );
}
