import { useMutation } from '../../remote';
import { forgotPassword } from '../../api';
import { group, useForm } from '../../forms';
import { TextField } from '../../shared/components/TextField';
import { AuthErrorView } from './AuthErrorView';
import { AuthStateView } from './AuthStateView';
import { useTranslation } from 'react-i18next';
import { usernameField } from './shared';
import { FormEvent } from 'react';

const definition = group({
  username: usernameField,
});

export function ForgotPassword() {
  const { t } = useTranslation('auth');

  const mutation = useMutation(forgotPassword);

  const form = useForm(definition, { username: '' });

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (form.status.valid) {
      mutation.start(form.status.value.username);
    }
  }

  if (mutation.isResolved) {
    const result = mutation.data;
    if (result.Case === 'Ok') {
      const state = result.Fields[0];
      return <AuthStateView state={state} />;
    }
  }

  return (
    <>
      <h1 className="h5 text-center text-secondary">{t('ForgotPassword.title')}</h1>
      <p className="my-4 alert alert-primary">{t('ForgotPassword.description')}</p>
      <form onSubmit={onSubmit} className="mb-3">
        <TextField
          focusOnMount
          id="username"
          label={t('ForgotPassword.username')}
          field={form.fields.username}
          autoComplete="username"
        />
        <button disabled={!form.status.valid} className="btn btn-primary" type="submit">
          {t('ForgotPassword.getCode')}
        </button>
      </form>
      {mutation.isResolved && mutation.data.Case === 'Error' && <AuthErrorView error={mutation.data.Fields[0]} />}
    </>
  );
}
