﻿import { useProducers } from './Producers';
import { CompanyInfo } from '../reports/CompanyInfo';

export interface Props {
  producerId: string;
  country?: string;
}

export function ProducerInfo({ producerId, country }: Props) {
  const producers = useProducers();
  const producer = producers.get(producerId);

  if (country === 'Austria') {
    return <CompanyInfo companyId={producerId} name={producer?.name} />;
  }

  return <CompanyInfo companyId={producerId} name={producer?.name} lucidNumber={producer?.lucidNumber || undefined} />;
}
