﻿import { getHash } from '../../helpers';
import { SetStateAction, useEffect, useState } from 'react';

// The milliseconds that have to pass until we remove a resource if there is no subscription
const pastMillisecondsForRemove = 1000 * 60 * 3;

// The milliseconds that have to pass until we revalidate after a fresh result
const pastMillisecondsForRevalidate = 10000;

// The time in milliseconds we wait before doing revalidation after an event like "focus" or "visibilitychange"
const revalidateDelayInMilliseconds = 500;

export type Loader<TRequest, TResponse> = (request: TRequest, signal: AbortSignal) => Promise<TResponse>;

export type QueryState<D> =
  | { readonly status: 'pending'; promise: Promise<void>; controller: AbortController }
  | { readonly status: 'resolved'; data: D; resolvedAt: number | null }
  | { readonly status: 'rejected'; error: unknown }
  | { readonly status: 'revalidating'; promise: Promise<void>; controller: AbortController; data: D };

export type QueryResponse<D> = QueryState<D> & {
  revalidate: () => Promise<void>;
  setValue: (data: SetStateAction<D>) => void;
};

export class Query<TRequest, TResponse> {
  private _state: QueryState<TResponse>;
  private _subscribers = new Set<(state: QueryState<TResponse>) => void>();
  private _handle: number | null;

  constructor(private loader: Loader<TRequest, TResponse>, private request: TRequest, private onRemove: () => void) {
    this._state = this.load(true);
    this._handle = setTimeout(onRemove, pastMillisecondsForRemove) as any;
  }

  get state() {
    return this._state;
  }

  get hasSubscribers() {
    return this._subscribers.size > 0;
  }

  subscribe(subscriber: (state: QueryState<TResponse>) => void) {
    // Stop timeout if the first one subscribes
    if (this._subscribers.size === 0 && this._handle !== null) {
      clearTimeout(this._handle);
      this._handle = null;
    }

    this._subscribers.add(subscriber);

    return () => {
      this._subscribers.delete(subscriber);
      // Start timeout if the last one unsubscribed
      if (this._subscribers.size === 0 && this._handle === null) {
        this._handle = setTimeout(this.onRemove, pastMillisecondsForRemove) as any;
      }
    };
  }

  /**
   * Revalidates the query.
   * @param onlyIfStale Revalidates if the value is stale only.
   */
  revalidate(onlyIfStale: boolean) {
    const state = this.load(onlyIfStale);
    this.setState(state);
    return state.status === 'pending' || state.status === 'revalidating' ? state.promise : Promise.resolve();
  }

  private setState(state: QueryState<TResponse>) {
    this._state = state;
    this._subscribers.forEach(subscriber => subscriber(state));
  }

  setValue(data: SetStateAction<TResponse>, resolvedAt: number | null = null) {
    const current = this._state;
    if (current !== undefined) {
      if (typeof data === 'function') {
        // Set value only if we have resolved data
        if (current.status === 'resolved' || current.status === 'revalidating') {
          const nextData = (data as (nextState: TResponse) => TResponse)(current.data);
          this.setState({ status: 'resolved', data: nextData, resolvedAt });
        }
      } else {
        // Abort pending fetches
        if (current.status === 'pending' || current.status === 'revalidating') {
          current.controller.abort();
        }

        // Set value
        this.setState({ status: 'resolved', data, resolvedAt });
      }
    }
  }

  private load(onlyIfStale: boolean): QueryState<TResponse> {
    const current = this._state;

    if (current !== undefined && (current.status === 'pending' || current.status === 'revalidating')) {
      if (onlyIfStale) {
        return current;
      }

      // If we are in pending or revalidating status we abort the previous fetch.
      current.controller.abort();
    }

    const controller = new AbortController();
    const start = () =>
      this.loader(this.request, controller.signal).then(
        data => {
          if (!controller.signal.aborted) {
            this.setState({ status: 'resolved', data, resolvedAt: Date.now() });
          }
        },
        error => {
          if (!controller.signal.aborted) {
            this.setState({ status: 'rejected', error });
          }
        }
      );

    // If we have already a result and the result is younger than a specific time, we don't revalidate.
    if (current != null && current.status === 'resolved') {
      if (
        !onlyIfStale ||
        current.resolvedAt === null ||
        Date.now() - current.resolvedAt > pastMillisecondsForRevalidate
      ) {
        const promise = start();
        return { status: 'revalidating', data: current.data, promise, controller };
      }

      return current;
    }

    const promise = start();
    return { status: 'pending', promise, controller };
  }
}

class QueryCache {
  private _caches = new WeakMap<Loader<any, any>, Map<string, Query<any, any>>>();

  getResourcesOfLoader<TRequest, TResponse>(loader: Loader<TRequest, TResponse>) {
    let cache = this._caches.get(loader) as Map<string, Query<TRequest, TResponse>> | undefined;

    if (cache === undefined) {
      cache = new Map<string, Query<TRequest, TResponse>>();
      this._caches.set(loader, cache);
    }

    return cache;
  }

  getResource<TRequest, TResponse>(loader: Loader<TRequest, TResponse>, request: TRequest) {
    const resources = this.getResourcesOfLoader(loader);

    const hash = getHash(request);

    let query = resources.get(hash);
    if (query === undefined) {
      query = new Query<TRequest, TResponse>(loader, request, () => resources.delete(hash));
      resources.set(hash, query);
    }

    return query;
  }
}

export const cache = new QueryCache();

export function useQuery<TRequest, TResponse>(
  loader: Loader<TRequest, TResponse>,
  request: TRequest,
  automaticRevalidation = true
) {
  const resource = cache.getResource(loader, request);

  const [state, setState] = useState(resource.state);

  // Ensure that local state always matches resource state
  if (state !== resource.state) {
    setState(resource.state);
  }

  // Subscribe to state changes
  useEffect(() => {
    return resource.subscribe(d => {
      setState(d);
    });
  }, [resource]);

  // Revalidate when visibility, focus or internet connection changes
  useEffect(() => {
    if (automaticRevalidation) {
      const revalidate = () => {
        if (resource.hasSubscribers && document.visibilityState === 'visible') {
          setTimeout(() => resource.revalidate(true), revalidateDelayInMilliseconds);
        }
      };

      document.addEventListener('visibilitychange', revalidate);
      window.addEventListener('focus', revalidate);
      window.addEventListener('online', revalidate);

      return () => {
        document.removeEventListener('visibilitychange', revalidate);
        window.removeEventListener('focus', revalidate);
        window.removeEventListener('online', revalidate);
      };
    }
  }, [resource, resource.hasSubscribers, automaticRevalidation]);

  return {
    ...state,
    revalidate() {
      return resource.revalidate(false);
    },
    setValue(data: SetStateAction<TResponse>) {
      resource.setValue(data);
    },
  };
}
