﻿import { useThirdPartyLicensing } from './thirdPartyLicensing/ThirdPartyLicensing';
import { useProducers } from '../../producers/Producers';
import { Trans, useTranslation } from 'react-i18next';
import { ThirdPartyLicensee, ThirdPartyLicensing } from '../../../api';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../../shared/components/Icon';
import { useSystems } from '../../systems/Systems';
import { ReactNode } from 'react';

export interface Props {
  licensee: ThirdPartyLicensee;

  value: ThirdPartyLicensing | null;
  children?: ReactNode;
  pathPrefix?: string;
}

export function EditButton({ licensee, value, children, pathPrefix }: Props) {
  pathPrefix = pathPrefix !== undefined ? `./${pathPrefix}` : '.';

  return (
    <>
      <NavLink
        to={{
          pathname: `${pathPrefix}/edit/${licensee.Case}/${licensee.Fields[0]}`,
          search: location.search,
        }}
        className="btn btn-outline-secondary">
        <Icon name={value ? 'pencil' : 'plus-lg'} /> {children}
      </NavLink>
    </>
  );
}

export function SystemOfCustomerInfo(props: { producerId: string; customerId: string; allowUpdate: boolean }) {
  const { t } = useTranslation('reports');
  const systems = useSystems();
  const producers = useProducers();
  const producer = producers.get(props.producerId);
  const producerName = producer?.name ?? props.producerId;

  const thirdPartyLicensing = useThirdPartyLicensing();

  const licensee: ThirdPartyLicensee = { Case: 'Customer', Fields: [props.customerId] };

  const value = thirdPartyLicensing.get(props.producerId, licensee);

  if (value === null) {
    return (
      <>
        <div className="text-danger">
          <Trans t={t}>
            {t('systemOfCustomerInfo.noThirdPartyLicensing', { customer: props.customerId, producer: producerName })}
          </Trans>
        </div>
        <div className="py-2">
          <EditButton pathPrefix="thirdPartyLicensing" licensee={licensee} value={value}>
            {t('systemOfCustomerInfo.addThirdPartyLicensing')}
          </EditButton>
        </div>
      </>
    );
  }

  const systemInfo = <span>{systems.get(value.systemId)?.name}</span>;

  if (props.allowUpdate) {
    return (
      <>
        {systemInfo}
        <div className="py-2">
          <EditButton pathPrefix="thirdPartyLicensing" licensee={licensee} value={value}>
            {t('systemOfCustomerInfo.editThirdPartyLicensing')}
          </EditButton>
        </div>
      </>
    );
  }

  return systemInfo;
}
