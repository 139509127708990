import { StateOf } from '../../forms';
import { ColSize } from './Col';
import { Field } from './Field';
import { packagingTypeField, PackagingTypeInput } from './PackagingTypeInput';

export interface Props {
  id: string;
  label: string;
  field: StateOf<typeof packagingTypeField>;
  focusOnMount?: boolean;
  disabled?: boolean;
  size?: ColSize;
}

export function PackagingTypeField(props: Props) {
  return (
    <Field size={props.size}>
      <label className="form-label" htmlFor={props.id}>
        {props.label}
      </label>
      <PackagingTypeInput
        id={props.id}
        focusOnMount={props.focusOnMount}
        disabled={props.disabled}
        field={props.field}
      />
    </Field>
  );
}
