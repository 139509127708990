import { StateOf } from '../../forms';
import { periodField, PeriodInput } from './PeriodInput';
import { ColSize } from './Col';
import { Field } from './Field';

export interface Props {
  id: string;
  label: string;
  field: StateOf<typeof periodField>;
  focusOnMount?: boolean;
  disabled?: boolean;
  size?: ColSize;
}

export function PeriodField(props: Props) {
  return (
    <Field size={props.size}>
      <label className="form-label" htmlFor={props.id}>
        {props.label}
      </label>
      <PeriodInput id={props.id} focusOnMount={props.focusOnMount} disabled={props.disabled} field={props.field} />
    </Field>
  );
}
