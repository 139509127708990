﻿import { useProducers } from './Producers';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../shared/components/Icon';
import { stringParam, useSearchParams } from '../../shared/hooks/useSearchParams';
import { useSorting } from '../../shared/hooks/useSorting';
import { SortableHeaderCell } from '../../shared/components/SortableHeaderCell';
import { ProducersTableRow, Row } from './ProducersTableRow';
import { ProducerData } from '../../api';

export function ProducersTable() {
  const producers = useProducers();
  const { t } = useTranslation('producers');

  const rows: [string, Row][] = Object.entries(producers.all).map(([id, producer]: [string, ProducerData]) => [
    id,
    {
      ...producer,
      fullAddress: [producer.address.country].join(' '),
      fullContact: [producer.contact.name, producer.contact.eMail, producer.contact.phone].join(' '),
    },
  ]);

  const [search, setSearch] = useSearchParams<string>('search', stringParam(''));

  const compareString =
    (field: Exclude<keyof Row, 'address' | 'contact' | 'active'>) => (a: [string, Row], b: [string, Row]) =>
      (a[1][field] || '').localeCompare(b[1][field] || '');

  const compareBoolean = (field: 'active') => (a: [string, Row], b: [string, Row]) =>
    Number(a[1][field]) - Number(b[1][field]);

  const producerIncludes = ([, row]: [string, Row]) => {
    const lowerCaseSearch = search.toLocaleLowerCase();
    return (
      row.id.toLowerCase().includes(lowerCaseSearch) ||
      row.name.toLocaleLowerCase().includes(lowerCaseSearch) ||
      (row.lucidNumber ?? '').toLocaleLowerCase().includes(lowerCaseSearch) ||
      row.fullAddress.toLocaleLowerCase().includes(lowerCaseSearch) ||
      row.fullContact.toLocaleLowerCase().includes(lowerCaseSearch)
    );
  };

  const sorting = useSorting<[string, Row], 'name' | 'id' | 'lucidNumber' | 'active' | 'fullAddress' | 'fullContact'>(
    rows,
    {
      name: compareString('name'),
      id: compareString('id'),
      lucidNumber: compareString('lucidNumber'),
      fullContact: compareString('fullContact'),
      fullAddress: compareString('fullAddress'),
      active: compareBoolean('active'),
    },
    { key: 'name', direction: 'asc' }
  );

  return (
    <>
      <div className="row my-4">
        <div className="col-4">
          <input
            placeholder={t('global:search')}
            type="search"
            className="form-control"
            value={search}
            onChange={e => setSearch(e.currentTarget.value)}
          />
        </div>
      </div>
      <table className="table table-striped table-hover">
        <thead className="sticky-top bg-white">
          <tr>
            <SortableHeaderCell className="col-2" sorting={sorting} sortKey="name">
              {t('producersTable.name')}
            </SortableHeaderCell>
            <SortableHeaderCell className="col-2" sorting={sorting} sortKey="id">
              {t('producersTable.id')}
            </SortableHeaderCell>
            <SortableHeaderCell className="col-2" sorting={sorting} sortKey="lucidNumber">
              {t('producersTable.lucidNumber')}
            </SortableHeaderCell>
            <SortableHeaderCell className="col-3" sorting={sorting} sortKey="fullContact">
              {t('producersTable.contact')}
            </SortableHeaderCell>
            <SortableHeaderCell className="col-3" sorting={sorting} sortKey="fullAddress">
              {t('producersTable.address')}
            </SortableHeaderCell>
            <SortableHeaderCell className="col-3" sorting={sorting} sortKey="active">
              {t('producersTable.active')}
            </SortableHeaderCell>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sorting.sorted.filter(producerIncludes).map(([, row]) => (
            <ProducersTableRow key={row.id} row={row} />
          ))}
        </tbody>
      </table>

      <NavLink to={{ pathname: `./add`, search: location.search }} className="btn btn-outline-secondary">
        <>
          <Icon name={'plus-lg'} /> {t('newProducer')}
        </>
      </NavLink>
    </>
  );
}
