import { useParams } from 'react-router';
import { Period } from '../../api';

export function useStringParams<K extends string = string>(): Required<{
  [key in K]: string;
}> {
  return useParams<K>() as Required<{
    [key in K]: string;
  }>;
}

export function usePeriodParam(): Period {
  const { period } = useStringParams<'period'>();
  return period as Period;
}

export function useYearParam(): number {
  const { year } = useStringParams<'year'>();
  return parseInt(year);
}

export function useCountryParam(): string {
  const { country } = useStringParams<'country'>();
  return country;
}
