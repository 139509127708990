﻿import { getAllProducers, upsertProducer, deleteProducer, ProducerData } from '../../api';
import { createContext, ReactNode, useContext } from 'react';
import { useTenant } from '../tenants/TenantDetail';
import { useTenantQuery } from '../../shared/hooks/useTenantQuery';
import { useQueryState } from '../../shared/hooks/useQueryState';

export interface ProducersService {
  get(id: string): ProducerData | null;

  upsert(value: ProducerData): Promise<void>;

  delete(id: string): Promise<void>;

  accessible(): Record<string, ProducerData>;

  all: Record<string, ProducerData>;
}

const ProducersContext = createContext<ProducersService | null>(null);

export function Producers(props: { children?: ReactNode }) {
  const producersQuery = useTenantQuery(getAllProducers, void 0, false);
  const [producers] = useQueryState(producersQuery);
  const tenant = useTenant();

  const service: ProducersService = {
    get(producerId: string): ProducerData | null {
      return producers[producerId];
    },
    async upsert(data: ProducerData) {
      await upsertProducer({ tenantId: tenant.id, data });
      await producersQuery.revalidate();
    },
    async delete(id: string) {
      await deleteProducer({ tenantId: tenant.id, data: id });
      await producersQuery.revalidate();
    },
    accessible: () => {
      const tenant = useTenant();
      return tenant.permissions.central
        ? producers
        : tenant.permissions.local.reduce((r, id) => ({ ...r, [id]: producers[id] }), {});
    },
    all: producers,
  };

  return <ProducersContext.Provider value={service}>{props.children}</ProducersContext.Provider>;
}

export function useProducers(): ProducersService {
  const service = useContext(ProducersContext);

  if (service == null) {
    throw new Error('Producers must be defined higher in tree.');
  }

  return service;
}
