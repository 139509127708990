import { NavLink } from 'react-router-dom';
import { Icon } from '../../shared/components/Icon';
import { Branch } from '../../api';

export type Row = Branch & {
  producerName: string;
  countryName: string;
};

export function BranchesTableRow({ row }: { row: Row }) {
  return (
    <tr>
      <td>{row.name}</td>
      <td>{row.branchId}</td>
      <td>{row.producerName}</td>
      <td>{row.countryName}</td>
      <td>
        <NavLink
          to={{ pathname: `./edit/${row.branchId}`, search: location.search }}
          className="btn btn-outline-secondary">
          <Icon name={'pencil'} />
        </NavLink>
      </td>
    </tr>
  );
}
