import { languages } from './i18n';
import { useTranslation } from 'react-i18next';

export function LanguageSwitcher() {
  const { t, i18n } = useTranslation();

  return (
    <select className="form-select" value={i18n.language} onChange={e => i18n.changeLanguage(e.currentTarget.value)}>
      {languages.map(language => (
        <option key={language} value={language}>
          {t(`global:languages.${language}`)}
        </option>
      ))}
    </select>
  );
}
