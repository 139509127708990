﻿import { DeclarationResultGermany, Period } from '../../../api';
import { WithdrawButton } from './WithdrawButton';
import { ReleaseButton } from './ReleaseButton';
import { useTenant } from '../../tenants/TenantDetail';
import { ExportDialog } from './export/ExportDialog';

export interface Props {
  year: number;
  period: Period;
  declaration: DeclarationResultGermany;
  withdrawButtonDisabled?: boolean;
  releaseButtonDisabled?: boolean;
}

export function Actions({ year, period, declaration, withdrawButtonDisabled, releaseButtonDisabled }: Props) {
  const tenant = useTenant();

  return (
    <div className="my-4 d-flex">
      {tenant.permissions.central && (
        <>
          {declaration.kind === 'Preview' && !releaseButtonDisabled && (
            <ReleaseButton year={year} period={period} declaration={declaration} />
          )}
          {!withdrawButtonDisabled && (
            <WithdrawButton declarationId={declaration.declarationId} year={year} period={period} />
          )}
        </>
      )}
      <ExportDialog
        declarationId={declaration.declarationId}
        year={year}
        period={period}
        kind={declaration.kind}
        responsibilities={declaration.responsibilities}
      />
    </div>
  );
}
