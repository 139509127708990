﻿import { useContracts } from '../../contracts/Contracts';
import { useProducers } from '../../producers/Producers';
import { EditButton } from '../../contracts/EditButton';
import { Trans, useTranslation } from 'react-i18next';
import { useSystems } from '../../systems/Systems';

export function SystemOfProducerInfo(props: { producerId: string; allowUpdate: boolean }) {
  const { t } = useTranslation('reports');
  const systems = useSystems();
  const contracts = useContracts();
  const producers = useProducers();

  const producer = producers.get(props.producerId);
  const producerName = producer?.name ?? `${t('systemOfProducerInfo.company')} ${props.producerId}`;

  const value = contracts.get(props.producerId);

  if (value === null) {
    return (
      <>
        <span className="text-danger">
          <Trans t={t}>{t('systemOfProducerInfo.noContract', { producer: producerName })}</Trans>
        </span>
        <div className="py-2">
          <EditButton pathPrefix="contracts" producerId={props.producerId} value={value}>
            {t('systemOfProducerInfo.addContract')}
          </EditButton>
        </div>
      </>
    );
  }

  const systemInfo = <span>{systems.get(value.systemId)?.name}</span>;

  if (props.allowUpdate) {
    return (
      <>
        {systemInfo}
        <div className="py-2">
          <EditButton pathPrefix="contracts" producerId={props.producerId} value={value}>
            {t('systemOfProducerInfo.editContract')}
          </EditButton>
        </div>
      </>
    );
  }

  return systemInfo;
}
