﻿import {
  AggregatedDecision,
  DeclarationKind,
  Period,
  ProducerData,
  Responsibility,
  Responsible,
} from '../../../../api';
import { useProducers } from '../../../producers/Producers';
import { useContracts } from '../../../contracts/Contracts';
import { useThirdPartyLicensing } from '../thirdPartyLicensing/ThirdPartyLicensing';
import { Icon } from '../../../../shared/components/Icon';
import {
  getPeriodForFileName,
  getResponsibleIdForCsv,
  getResponsibleLUCIDForCSV,
  getResponsibleNameForCsv,
  getResponsibleTypeForCsv,
  getSystemNameForCsv,
  getWeightForCsv,
} from '../csv';
import { exportAsCsvUtf8 } from '../../../../helpers';
import { useTranslation } from 'react-i18next';
import { useSuppliers } from '../../../suppliers/suppliers';
import { TFunction } from 'i18next';
import { useSystems } from '../../../systems/Systems';

function getSystemParticipation(t: TFunction, decision: AggregatedDecision) {
  return typeof decision === 'string'
    ? t(`declarationExport.file.systemParticipation.values.${decision}`)
    : t(`declarationExport.file.systemParticipation.values.${decision.Case}`);
}

function getReason(t: TFunction, decision: AggregatedDecision) {
  return decision === 'Failed' || decision.Case === 'No'
    ? ''
    : t(`declarationExport.file.reason.values.${decision.Fields[0]}`);
}

function exportAsCsv(
  t: TFunction,
  year: number,
  period: Period,
  kind: DeclarationKind,
  producers: Record<string, ProducerData>,
  suppliers: Record<string, string>,
  tryGetSystemNameForCSV: (responsibility: { reporting: Responsible | null; licensing: Responsible | null }) => string,
  responsibilities: Responsibility[]
) {
  const header = [
    t('declarationExport.file.systemParticipation.header'),
    t('declarationExport.file.reason.header'),
    t('declarationExport.file.reportingType'),
    t('declarationExport.file.reportingName'),
    t('declarationExport.file.reportingId'),
    t('declarationExport.file.reportingLUCID'),
    t('declarationExport.file.licensingType'),
    t('declarationExport.file.licensingName'),
    t('declarationExport.file.licensingId'),
    t('declarationExport.file.licensingLUCID'),
    t('declarationExport.file.paymentType'),
    t('declarationExport.file.paymentName'),
    t('declarationExport.file.paymentId'),
    t('declarationExport.file.paymentLUCID'),
    t('declarationExport.file.systemProvider'),
    t('declarationExport.file.glass'),
    t('declarationExport.file.fiber'),
    t('declarationExport.file.ferrousMetal'),
    t('declarationExport.file.aluminum'),
    t('declarationExport.file.plastic'),
    t('declarationExport.file.beverageCarton'),
    t('declarationExport.file.otherComposite'),
    t('declarationExport.file.other'),
  ];

  const rows = responsibilities.map(a => [
    getSystemParticipation(t, a.decision),
    getReason(t, a.decision),
    getResponsibleTypeForCsv(t, producers, a.reporting),
    getResponsibleNameForCsv(producers, suppliers, a.reporting),
    getResponsibleIdForCsv(producers, a.reporting),
    getResponsibleLUCIDForCSV(producers, a.reporting),

    getResponsibleTypeForCsv(t, producers, a.licensing),
    getResponsibleNameForCsv(producers, suppliers, a.licensing),
    getResponsibleIdForCsv(producers, a.licensing),
    getResponsibleLUCIDForCSV(producers, a.licensing),

    getResponsibleTypeForCsv(t, producers, a.payment),
    getResponsibleNameForCsv(producers, suppliers, a.payment),
    getResponsibleIdForCsv(producers, a.payment),
    getResponsibleLUCIDForCSV(producers, a.payment),

    tryGetSystemNameForCSV(a),

    getWeightForCsv(a.weights.glass),
    getWeightForCsv(a.weights.fiber),
    getWeightForCsv(a.weights.ferrousMetal),
    getWeightForCsv(a.weights.aluminum),
    getWeightForCsv(a.weights.plastic),
    getWeightForCsv(a.weights.beverageCarton),
    getWeightForCsv(a.weights.otherComposite),
    getWeightForCsv(a.weights.other),
  ]);

  exportAsCsvUtf8(`DE_${getPeriodForFileName(year, period)}_${t('declarationExport.volumes')}.csv`, header, rows);
}

export interface Props {
  year: number;
  period: Period;
  kind: DeclarationKind;
  responsibilities: Responsibility[];
}

export function DeclarationExport({ year, period, kind, responsibilities }: Props) {
  const systems = useSystems();
  const producers = useProducers();
  const contracts = useContracts();
  const suppliers = useSuppliers();
  const thirdPartyLicensing = useThirdPartyLicensing();

  const { t } = useTranslation('reports');

  function tryGetSystemNameForCSV(responsibility: { reporting: Responsible | null; licensing: Responsible | null }) {
    if (responsibility.reporting === null || responsibility.licensing === null) {
      return '';
    }

    return getSystemNameForCsv(
      systems,
      contracts,
      thirdPartyLicensing,
      responsibility.reporting,
      responsibility.licensing
    );
  }

  return (
    <section>
      <h1 className="h5">{t('declarationExport.title')}</h1>
      <p className="fw-light fs-5 mt-2">{t('declarationExport.subtitle')}</p>
      <ul>
        <li>{t('declarationExport.producer')}</li>
        <li>{t('declarationExport.licenser')}</li>
        <li>{t('declarationExport.payment')}</li>
        <li>{t('declarationExport.reason')}</li>
      </ul>
      <div className="mt-4 mb-2  text-end">
        <button
          className="btn btn-secondary"
          onClick={() =>
            exportAsCsv(t, year, period, kind, producers.all, suppliers, tryGetSystemNameForCSV, responsibilities)
          }>
          <Icon name="download" /> {t('declarationExport.download')}
        </button>
      </div>
    </section>
  );
}
