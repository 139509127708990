﻿import {
  AggregatedPackagingWeightsAustria,
  ContractValue,
  DeclarationInterval,
  Period,
  Responsibility,
  ResponsibilityAustria,
  Responsible,
} from '../../../api';
import { expectNever } from '../../../helpers';

export function getEmptyWeights(): AggregatedPackagingWeightsAustria {
  return {
    aluminumCommercial: 0,
    aluminumHouseHold: 0,
    beverageCartonHouseHold: 0,
    ceramicCommercial: 0,
    ceramicHouseHold: 0,
    compositeCommercial: 0,
    compositeHouseHold: 0,
    epsCommercial: 0,
    ferrousMetalCommercial: 0,
    ferrousMetalHouseHold: 0,
    fiberCommercial: 0,
    fiberHouseHold: 0,
    foilsCommercial: 0,
    glassHouseHold: 0,
    hollowBodiesCommercial: 0,
    organicCommercial: 0,
    organicHouseHold: 0,
    plasticHouseHold: 0,
    textileCommercial: 0,
    textileHouseHold: 0,
    woodCommercial: 0,
    woodHouseHold: 0,
  };
}

export function addWeights(
  a: AggregatedPackagingWeightsAustria,
  b: AggregatedPackagingWeightsAustria
): AggregatedPackagingWeightsAustria {
  return {
    aluminumCommercial: a.aluminumCommercial + b.aluminumCommercial,
    aluminumHouseHold: a.aluminumHouseHold + b.aluminumHouseHold,
    beverageCartonHouseHold: a.beverageCartonHouseHold + b.beverageCartonHouseHold,
    ceramicCommercial: a.ceramicCommercial + b.ceramicCommercial,
    ceramicHouseHold: a.ceramicHouseHold + b.ceramicHouseHold,
    compositeCommercial: a.compositeCommercial + b.compositeCommercial,
    compositeHouseHold: a.compositeHouseHold + b.compositeHouseHold,
    epsCommercial: a.epsCommercial + b.epsCommercial,
    ferrousMetalCommercial: a.ferrousMetalCommercial + b.ferrousMetalCommercial,
    ferrousMetalHouseHold: a.ferrousMetalHouseHold + b.ferrousMetalHouseHold,
    fiberCommercial: a.fiberCommercial + b.fiberCommercial,
    fiberHouseHold: a.fiberHouseHold + b.fiberHouseHold,
    foilsCommercial: a.foilsCommercial + b.foilsCommercial,
    glassHouseHold: a.glassHouseHold + b.glassHouseHold,
    hollowBodiesCommercial: a.hollowBodiesCommercial + b.hollowBodiesCommercial,
    organicCommercial: a.organicCommercial + b.organicCommercial,
    organicHouseHold: a.organicHouseHold + b.organicHouseHold,
    plasticHouseHold: a.plasticHouseHold + b.plasticHouseHold,
    textileCommercial: a.textileCommercial + b.textileCommercial,
    textileHouseHold: a.textileHouseHold + b.textileHouseHold,
    woodCommercial: a.woodCommercial + b.woodCommercial,
    woodHouseHold: a.woodHouseHold + b.woodHouseHold,
  };
}

export function sumWeights(w: AggregatedPackagingWeightsAustria[]): AggregatedPackagingWeightsAustria {
  return w.reduce(addWeights, getEmptyWeights());
}

function serializeToKeyReplacer(key: string, value: any) {
  if (value === null) {
    return null;
  }

  if (typeof value === 'object') {
    const keys = Object.keys(value);
    keys.sort();
    const data: any = {};
    for (const key of keys) {
      data[key] = value[key];
    }
    return data;
  }

  return value;
}

export function serializeToKey(value: any) {
  return JSON.stringify(value, serializeToKeyReplacer);
}

export function addOrUpdateMap<K, V>(map: Map<K, V>, key: K, initialValue: V, update: (prev: V) => V) {
  const prev = map.get(key) ?? initialValue;
  const next = update(prev);
  map.set(key, next);
}

export function addOrUpdateRecord<V>(record: Record<string, V>, key: string, initialValue: V, update: (prev: V) => V) {
  const prev = key in record ? record[key] : initialValue;
  const next = update(prev);
  return {
    ...record,
    [key]: next,
  };
}

export function getKeyOfRow({ decision, reporting, licensing, payment }: Responsibility) {
  return serializeToKey({ decision, reporting, licensing, payment });
}

export interface Group<K> {
  key: K;
  keyAsString: string;
  responsibilities: ResponsibilityAustria[];
}

export function groupResponsibilities<K>(
  getKey: (responsibility: ResponsibilityAustria) => K,
  rows: ResponsibilityAustria[]
): Group<K>[] {
  const result: Record<string, Group<K>> = {};

  for (const row of rows) {
    const key = getKey(row);
    const keyAsString = serializeToKey(key);

    if (keyAsString in result) {
      result[keyAsString].responsibilities.push(row);
    } else {
      result[keyAsString] = {
        key,
        keyAsString,
        responsibilities: [row],
      };
    }
  }

  return Object.values(result);
}

export function filterAndGroupResponsibilities<K>(
  getKey: (responsibility: ResponsibilityAustria) => K | null,
  rows: ResponsibilityAustria[]
): Group<K>[] {
  const result: Record<string, Group<K>> = {};

  for (const row of rows) {
    const key = getKey(row);

    if (key === null) {
      continue;
    }

    const keyAsString = serializeToKey(key);

    if (keyAsString in result) {
      result[keyAsString].responsibilities.push(row);
    } else {
      result[keyAsString] = {
        key,
        keyAsString,
        responsibilities: [row],
      };
    }
  }

  return Object.values(result);
}

export interface Aggregation<K> {
  key: K;
  keyAsString: string;
  weights: AggregatedPackagingWeightsAustria;
  comparisonWeights: AggregatedPackagingWeightsAustria | null;
}

export function aggregateWeights<K>(
  getKey: (row: ResponsibilityAustria) => K,
  responsibilities: ResponsibilityAustria[]
): Aggregation<K>[] {
  const result: Record<string, Aggregation<K>> = {};

  for (const responsibility of responsibilities) {
    const key = getKey(responsibility);
    const keyAsString = serializeToKey(key);

    if (keyAsString in result) {
      result[keyAsString].weights = addWeights(result[keyAsString].weights, responsibility.weights);
    } else {
      result[keyAsString] = {
        key,
        keyAsString,
        weights: responsibility.weights,
        comparisonWeights: null,
      };
    }
  }

  return Object.values(result);
}

export function filterAndAggregateWeights<K>(
  getKey: (row: ResponsibilityAustria) => K | null,
  responsibilities: ResponsibilityAustria[],
  comparisonResponsibilities: ResponsibilityAustria[] = []
): Aggregation<K>[] {
  const responsibilityMapper = (values: ResponsibilityAustria[]) => {
    return values.reduce<{ [key: string]: Pick<Aggregation<K>, 'key' | 'keyAsString' | 'weights'> }>(
      (prev, current: ResponsibilityAustria) => {
        const key = getKey(current);

        if (key === null) {
          return prev;
        }

        const keyAsString = serializeToKey(key);

        prev[keyAsString] = prev[keyAsString]
          ? { weights: addWeights(prev[keyAsString].weights, current.weights), key, keyAsString }
          : { weights: current.weights, key, keyAsString };

        return prev;
      },
      {}
    );
  };

  const mappedResponsibilities = responsibilityMapper(responsibilities);
  const mappedComparisonResponsibilities = responsibilityMapper(comparisonResponsibilities);

  return Object.entries(mappedResponsibilities).map(([key, items]) => {
    return {
      key: items.key,
      keyAsString: items.keyAsString,
      weights: items.weights,
      comparisonWeights: mappedComparisonResponsibilities[key] ? mappedComparisonResponsibilities[key].weights : null,
    };
  });
}

export interface AggregationWithSuppliers<K> {
  key: K;
  keyAsString: string;
  suppliers: string[];
  weights: AggregatedPackagingWeightsAustria;
}

export function aggregateWeightsWithSuppliers<K>(
  getKey: (row: ResponsibilityAustria) => K,
  rows: ResponsibilityAustria[]
): AggregationWithSuppliers<K>[] {
  const result: Record<string, AggregationWithSuppliers<K>> = {};

  for (const row of rows) {
    const key = getKey(row);
    const keyAsString = serializeToKey(key);

    if (keyAsString in result) {
      const aggregation = result[keyAsString];

      if (typeof row.licensing === 'object' && row.licensing?.Case === 'Supplier') {
        const suppliers = new Set(aggregation.suppliers);
        suppliers.add(row.licensing.Fields[0]);
        aggregation.suppliers = Array.from(suppliers);
      }

      aggregation.weights = addWeights(aggregation.weights, row.weights);
    } else {
      result[keyAsString] = {
        key,
        keyAsString,
        suppliers:
          typeof row.licensing === 'object' && row.licensing?.Case === 'Supplier' ? [row.licensing.Fields[0]] : [],
        weights: row.weights,
      };
    }
  }

  return Object.values(result);
}

export function producerHasToReport(producerId: string) {
  return (row: Responsibility) =>
    typeof row.reporting === 'object' && row.reporting?.Case === 'Producer' && row.reporting.Fields[0] === producerId;
}

export function producerHasToLicense(producerId: string) {
  return (row: Responsibility) =>
    typeof row.licensing === 'object' && row.licensing?.Case === 'Producer' && row.licensing.Fields[0] === producerId;
}

export function producerHasToPay(producerId: string) {
  return (row: Responsibility) =>
    typeof row.payment === 'object' && row.payment?.Case === 'Producer' && row.payment.Fields[0] === producerId;
}

export function producerHasToDo(producerId: string) {
  return (responsibility: Responsibility) =>
    producerHasToReport(producerId)(responsibility) ||
    producerHasToLicense(producerId)(responsibility) ||
    producerHasToPay(producerId)(responsibility);
}

export function anyProducerHasToReport(responsibility: Responsibility) {
  return typeof responsibility.reporting === 'object' && responsibility.reporting?.Case === 'Producer';
}

export function anyProducerHasToLicense(responsibility: Responsibility) {
  return typeof responsibility.licensing === 'object' && responsibility.licensing?.Case === 'Producer';
}

export function anyProducerHasToPay(responsibility: Responsibility) {
  return typeof responsibility.payment === 'object' && responsibility.payment?.Case === 'Producer';
}

export function anyProducerHasToDo(responsibility: Responsibility) {
  return (
    anyProducerHasToReport(responsibility) ||
    anyProducerHasToLicense(responsibility) ||
    anyProducerHasToPay(responsibility)
  );
}

export function supplierHasToReport(supplierId: string) {
  return (row: Responsibility) =>
    typeof row.reporting === 'object' && row.reporting?.Case === 'Supplier' && row.reporting?.Fields[0] === supplierId;
}

export function supplierHasToLicense(supplierId: string) {
  return (row: Responsibility) =>
    typeof row.licensing === 'object' && row.licensing?.Case === 'Supplier' && row.licensing.Fields[0] === supplierId;
}

export function supplierHasToPay(supplierId: string) {
  return (row: Responsibility) =>
    typeof row.payment === 'object' && row.payment?.Case === 'Supplier' && row.payment.Fields[0] === supplierId;
}

export type SystemForLicensing =
  | { type: 'SystemOfProducer'; producerId: string }
  | { type: 'SystemOfSupplier'; supplierId: string }
  | { type: 'SystemsOfCustomers' }
  | { type: 'System'; systemId: number };

export function getSystemForLicensing(
  tryGetContract: (producerId: string) => ContractValue | null,
  getSystemForThirdPartyLicensing: (producerId: string, supplierId: string) => number | null,
  reporting: Responsible,
  licensing: Responsible
): SystemForLicensing {
  if (licensing === 'Customers') {
    return { type: 'SystemsOfCustomers' };
  }

  if (licensing.Case === 'Producer') {
    const systemId = tryGetContract(licensing.Fields[0])?.systemId ?? null;
    return systemId === null
      ? { type: 'SystemOfProducer', producerId: licensing.Fields[0] }
      : { type: 'System', systemId };
  }

  if (licensing.Case === 'Supplier') {
    const systemId =
      typeof reporting === 'object' && reporting.Case === 'Producer'
        ? getSystemForThirdPartyLicensing(reporting.Fields[0], licensing.Fields[0])
        : null;

    return systemId === null
      ? { type: 'SystemOfSupplier', supplierId: licensing.Fields[0] }
      : { type: 'System', systemId };
  }

  if (licensing.Case === 'Customer') {
    // A customer doing the licensing is currently not supported.
    throw new Error('Unsupported');
  }

  expectNever(licensing);
}

export function getInitialYear() {
  const now = new Date();
  const year = now.getFullYear();
  return now.getMonth() <= 2 ? year - 1 : year;
}

const monthToPeriod = (month: number): Period | null => {
  switch (month) {
    case 0:
      return 'Jan';
    case 1:
      return 'Feb';
    case 2:
      return 'Mar';
    case 3:
      return 'Apr';
    case 4:
      return 'May';
    case 5:
      return 'Jun';
    case 6:
      return 'Jul';
    case 7:
      return 'Aug';
    case 8:
      return 'Sep';
    case 9:
      return 'Oct';
    case 10:
      return 'Nov';
    case 11:
      return 'Dec';
    default:
      return null;
  }
};

const monthToQuarter = (month: number): Period | null => {
  if (month >= 0 && month <= 2) {
    return 'Q1';
  }
  if (month >= 3 && month <= 5) {
    return 'Q2';
  }
  if (month >= 6 && month <= 8) {
    return 'Q3';
  }
  if (month >= 9 && month <= 11) {
    return 'Q4';
  }
  return null;
};

const monthToHalf = (month: number): Period | null => {
  if (month >= 0 && month <= 5) {
    return 'FirstHalfYear';
  }
  if (month >= 6 && month <= 11) {
    return 'SecondHalfYear';
  }
  return null;
};

export function getInitialPeriod(year: number, intervals: DeclarationInterval[]): Period {
  const now = new Date();

  if (intervals.includes('Monthly')) {
    if (year < now.getFullYear()) {
      return 'Dec';
    } else {
      return monthToPeriod(now.getMonth()) ?? 'Year';
    }
  }

  if (intervals.includes('Quarterly')) {
    if (year < now.getFullYear()) {
      return 'Q4';
    } else {
      return monthToQuarter(now.getMonth()) ?? 'Year';
    }
  }

  if (intervals.includes('BiYearly')) {
    if (year < now.getFullYear()) {
      return 'SecondHalfYear';
    } else {
      return monthToHalf(now.getMonth()) ?? 'Year';
    }
  }

  return 'Year';
}

export function tryGetIdOfProducerThatHasToReport(responsibility: Responsibility) {
  if (responsibility.reporting === null) {
    return null;
  }

  if (responsibility.reporting === 'Customers') {
    return null;
  }

  if (responsibility.reporting.Case === 'Supplier') {
    return null;
  }

  if (responsibility.reporting.Case === 'Customer') {
    return null;
  }

  if (responsibility.reporting.Case === 'Producer') {
    return responsibility.reporting.Fields[0];
  }

  expectNever(responsibility.reporting);
}

export function tryGetIdOfProducerThatHasToLicense(responsibility: ResponsibilityAustria) {
  if (responsibility.licensing === null) {
    return null;
  }

  if (responsibility.licensing === 'Customers') {
    return null;
  }

  if (responsibility.licensing.Case === 'Supplier') {
    return null;
  }

  if (responsibility.licensing.Case === 'Customer') {
    return null;
  }

  if (responsibility.licensing.Case === 'Producer') {
    return responsibility.licensing.Fields[0];
  }

  expectNever(responsibility.licensing);
}
