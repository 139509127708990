﻿import { DeclarationResultGermany } from '../../../../api';
import { Actions } from '../Actions';
import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { Trans, useTranslation } from 'react-i18next';
import { RouteObject, useOutletContext } from 'react-router';
import { NotFoundInfo } from '../../../../shared/components/NotFoundInfo';
import { usePeriodParam, useYearParam } from '../../../../shared/hooks/useParam';

export const finishRoute: RouteObject = {
  path: 'finish',
  element: <FinishStep />,
};

export function FinishStep() {
  const { t } = useTranslation('reports');

  const year = useYearParam();
  const period = usePeriodParam();
  const declaration = useOutletContext<DeclarationResultGermany | null>();

  if (declaration === null) {
    return <NotFoundInfo />;
  }

  return (
    <>
      <Actions year={year} period={period} declaration={declaration} />
      <InfoScreen title={t('finishStep.title')} icon="check-circle" color="success">
        <p>
          <Trans t={t}>{t('finishStep.successHint')}</Trans>
        </p>
        <p>{t('finishStep.thankYou')}</p>
      </InfoScreen>
    </>
  );
}
