﻿import { ContractValue } from '../../api';
import { EditButton } from './EditButton';
import { ProducerInfo } from '../producers/ProducerInfo';
import { useTranslation } from 'react-i18next';
import { getNameOfInterval } from '../../helpers';

export interface Props {
  year: number;
  producerId: string;
  contract: ContractValue | null;
  systemName: string | null;
}

export function ContractRow({ systemName, producerId, contract }: Props) {
  const { t } = useTranslation('contracts');

  return (
    <tr>
      <td className="align-middle">
        <ProducerInfo producerId={producerId} />
      </td>
      {contract === null ? (
        <td colSpan={2} className="align-middle text-danger">
          {t('contractRow.noContracts')}
        </td>
      ) : (
        <>
          <td className="align-middle">{systemName ?? contract.systemId}</td>
          <td className="align-middle">{getNameOfInterval(contract.interval)}</td>
        </>
      )}
      <td className="text-nowrap align-middle text-end">
        <EditButton producerId={producerId} value={contract} />
      </td>
    </tr>
  );
}
