﻿import { DeclarationResultAustria } from '../../../../api';
import { DeclarationErrors } from './DeclarationErrors';
import { Actions } from '../Actions';
import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { Trans, useTranslation } from 'react-i18next';
import { RouteObject, useOutletContext } from 'react-router';
import { NotFoundInfo } from '../../../../shared/components/NotFoundInfo';
import { usePeriodParam, useYearParam } from '../../../../shared/hooks/useParam';

export const issuesRoute: RouteObject = {
  path: 'issues',
  element: <IssuesStep />,
};

export function IssuesStep() {
  const { t } = useTranslation('reports');

  const [year, period] = [useYearParam(), usePeriodParam()];
  const declaration = useOutletContext<DeclarationResultAustria | null>();

  if (declaration === null) {
    return <NotFoundInfo />;
  }

  return (
    <>
      <Actions year={year} period={period} declaration={declaration} releaseButtonDisabled />
      {declaration.errors.length > 0 ? (
        <>
          <div className="bg-light rounded mb-4">
            <InfoScreen title={t('issuesStep.issuesTitle')} icon="exclamation-circle" color="danger">
              <p>
                <Trans t={t}>{t('issuesStep.issuesSubtitle')}</Trans>
              </p>
            </InfoScreen>
          </div>
          <DeclarationErrors year={year} period={period} errors={declaration.errors} />
        </>
      ) : (
        <InfoScreen icon="circle-check" color="success" title={t('issuesStep.noIssuesTitle')}>
          {t('issuesStep.noIssuesSubtitle')}
        </InfoScreen>
      )}
    </>
  );
}
