﻿import { useBranches } from './Branches';
import { BranchForm } from './BranchForm';
import { branchForm as FormDecl, emptyForm } from './form';
import { uniqueTextField, useForm } from '../../forms';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../shared/components/Modal';
import { RouteObject, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export const branchAddRoute: RouteObject = {
  path: 'add',
  element: <BranchAdd />,
};

export function BranchAdd() {
  const branches = useBranches();

  const { t } = useTranslation('branches');

  const branchForm = useForm(FormDecl(uniqueTextField(Object.keys(branches.all))), emptyForm);

  const navigate = useNavigate();
  const [search] = useSearchParams();

  function onClose() {
    navigate({ pathname: '..', search: search.toString() });
  }

  const onSubmit = async () => {
    if (branchForm.status.valid) {
      onClose();
      await branches.upsert(branchForm.status.value);
    }
  };

  const footer = (
    <button
      className={'btn btn-primary text-white'}
      type="submit"
      disabled={!branchForm.status.valid}
      onClick={onSubmit}>
      {t('branchModal.save')}
    </button>
  );

  return (
    <Modal visible={true} title={t('branchModal.title')} onClose={onClose} footer={footer} size="lg">
      <BranchForm form={branchForm} newRecord />
    </Modal>
  );
}
