﻿import { DeclarationErrorInfo, Period } from '../../../../api';
import { exportAsCsvUtf8 } from '../../../../helpers';
import { Icon } from '../../../../shared/components/Icon';
import { getPeriodForFileName } from '../csv';
import {
  DeclarationErrorsTable,
  getEvaluationErrorBranchId,
  getEvaluationErrorArticleId,
  getEvaluationErrorText,
  getEvaluationErrorSupplierId,
} from './DeclarationErrorsTable';
import { useTranslation } from 'react-i18next';

/* The numbers of errors that should be visible in the UI. */
export const maxDisplayedErrors = 10;

export function DeclarationErrors(props: { year: number; period: Period; errors: DeclarationErrorInfo[] }) {
  const { t } = useTranslation('reports');

  function download() {
    const header = [
      t('declarationErrors.download.description'),
      t('declarationErrors.download.branchId'),
      t('declarationErrors.download.articleId'),
      t('declarationErrors.download.supplierId'),
      t('declarationErrors.download.quantity'),
    ];
    const rows = props.errors.map(e => {
      return [
        getEvaluationErrorText(e.error),
        getEvaluationErrorBranchId(e.error),
        getEvaluationErrorArticleId(e.error),
        getEvaluationErrorSupplierId(e.error),
        e.quantity.toString(),
      ];
    });
    exportAsCsvUtf8(
      `${getPeriodForFileName(props.year, props.period)}_${t('declarationErrors.download.issues')}.csv`,
      header,
      rows
    );
  }

  const errors = props.errors.slice(0, Math.min(props.errors.length, maxDisplayedErrors));

  return (
    <div className="row mt-4">
      <div className="col-9">
        <h2 className="h5 text-alternative">{t('declarationErrors.highPriorityTitle')}</h2>
        <p className="text-muted fs-6">{t('declarationErrors.highPrioritySubtitle', { number: errors.length })}</p>
        <DeclarationErrorsTable errors={props.errors.slice(0, Math.min(props.errors.length, maxDisplayedErrors))} />
      </div>
      <div className="col-3">
        <h2 className="h5 text-alternative">{t('declarationErrors.downloadTitle')}</h2>
        <p className="text-muted fs-6">{t('declarationErrors.downloadSubtitle')}</p>
        <button className="btn btn-secondary" onClick={download}>
          <Icon name="download" className="me-2" />
          {t('declarationErrors.downloadButtonText')}
        </button>
      </div>
    </div>
  );
}
