﻿import { DeclarationResultGermany, getPeriods, Period, release, tryGetDeclarationGermany } from '../../../api';
import { useTenantMutation } from '../../../remote';
import { Icon } from '../../../shared/components/Icon';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useTenantRevalidate } from '../../../shared/hooks/useTenantRevalidate';
import { useTenant } from '../../tenants/TenantDetail';
import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

export interface Props {
  year: number;
  period: Period;
  declaration: DeclarationResultGermany;
}

export function ReleaseButton({ year, period, declaration }: Props) {
  const revalidatePeriods = useTenantRevalidate(getPeriods, { country: 'Germany', year });
  const revalidateDeclaration = useTenantRevalidate(tryGetDeclarationGermany, { year, period });
  const { t } = useTranslation('reports');
  const tenant = useTenant();

  const [show, setShow] = useState(false);
  const [notify, setNotify] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  async function revalidate() {
    await revalidatePeriods();
    await revalidateDeclaration();
  }

  const navigate = useNavigate();

  const mutation = useTenantMutation(release);

  if (mutation.isPending) {
    throw mutation.promise;
  }

  function startRelease(sendNotifications: boolean) {
    mutation.start(
      { declaration: { country: 'Germany', year, period }, sendNotifications },
      {
        async onSuccess() {
          handleClose();
          await revalidate();
          if (tenant.permissions.central) {
            navigate('../license');
          } else {
            navigate('../adjust');
          }
        },
      }
    );
  }

  return (
    <>
      <button className="btn btn-success text-white me-2" onClick={() => handleShow()} disabled={mutation.isPending}>
        <Icon name="check-lg" /> {t('releaseButton.de.release')}
      </button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('releaseButton.de.release')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-4">
            {declaration.errors.length > 0 ? (
              <Trans t={t}>{t('releaseButton.de.issueHint')}</Trans>
            ) : (
              <Trans t={t}>{t('releaseButton.de.hint')}</Trans>
            )}
          </p>
          <Form>
            <Form.Group className="mb-3" controlId="notifyAboutReleaseCheck">
              <Form.Check
                type="switch"
                checked={notify}
                onChange={value => setNotify(value.target.checked)}
                label={t('releaseDialog.notifyAboutRelease')}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('releaseDialog.button.cancel')}
          </Button>
          <Button variant="primary" onClick={() => startRelease(notify)}>
            {t('releaseDialog.button.confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
