﻿import { NavLink } from 'react-router-dom';
import { Icon } from '../../../shared/components/Icon';

export interface Props {
  disabled?: boolean;
  name: string;
  title: string;
  done?: boolean;
}

export function Step(props: Props) {
  return (
    <NavLink
      to={`./${props.name}`}
      className={({ isActive }) =>
        `rounded py-3 d-flex align-items-center list-group-item ${isActive ? 'active' : ''}${
          props.disabled ? ' disabled opacity-50' : ''
        }`
      }>
      {({ isActive }) => {
        return (
          <>
            <div className="flex-grow-1">
              <span className="fs-5">{props.title}</span>
            </div>
            <div
              style={{ width: '3rem', height: '3rem' }}
              className={isActive ? 'text-center fs-4 lh-lg' : 'text-center fs-4 lh-lg'}>
              {props.done && <Icon name="check-lg" />}
            </div>
          </>
        );
      }}
    </NavLink>
  );
}
