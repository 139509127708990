import { AggregatedPackagingWeightsAustria, Responsible } from '../../../../api';
import { Aggregation } from '../helpers';
import { useTranslation } from 'react-i18next';
import { useProducers } from '../../../producers/Producers';
import { useSorting } from '../../../../shared/hooks/useSorting';
import { PackagingWeightsKeys } from '../PackagingWeightsKeys';
import { SortableHeaderCell } from '../SortableHeaderCell';
import { SortableWeightsHeaderCells } from '../SortableWeightsHeaderCells';
import { ResponsibleView } from '../ResponsibleView';
import { ReviewWeightsRowCells } from './ReviewWeightsRowCells';

function compareWeight(key: keyof AggregatedPackagingWeightsAustria) {
  return (a: Aggregation<Responsible>, b: Aggregation<Responsible>) => {
    const wa = a.weights[key];
    const wb = b.weights[key];
    return wa - wb;
  };
}

export function ReviewList({ data }: { data: Aggregation<Responsible>[] }) {
  const { t } = useTranslation('reports');

  const producers = useProducers();

  function compareKey(a: Aggregation<Responsible>, b: Aggregation<Responsible>) {
    if (typeof a.key === 'object' && a.key.Case === 'Producer') {
      if (typeof b.key === 'object' && b.key.Case === 'Producer') {
        const namea = producers.all[a.key.Fields[0]]?.name ?? 'zzz';
        const nameb = producers.all[b.key.Fields[0]]?.name ?? 'zzz';
        return namea.localeCompare(nameb);
      }

      return -1;
    }

    if (!(typeof b.key === 'object' && b.key.Case === 'Producer')) {
      if (typeof a.key === 'object' && (a.key.Case === 'Supplier' || a.key.Case === 'Customer')) {
        if (typeof b.key === 'object' && (b.key.Case === 'Supplier' || b.key.Case === 'Customer')) {
          const namea = a.key.Fields[0];
          const nameb = b.key.Fields[0];
          return namea.localeCompare(nameb);
        }

        return -1;
      }
      return -1;
    } else {
      return 1;
    }
  }

  const sorting = useSorting<Aggregation<Responsible>, 'key' | PackagingWeightsKeys>(
    data,
    {
      key: compareKey,
      glassHouseHold: compareWeight('glassHouseHold'),
      fiberHouseHold: compareWeight('fiberHouseHold'),
      ferrousMetalHouseHold: compareWeight('ferrousMetalHouseHold'),
      aluminumHouseHold: compareWeight('aluminumHouseHold'),
      plasticHouseHold: compareWeight('plasticHouseHold'),
      beverageCartonHouseHold: compareWeight('beverageCartonHouseHold'),
      compositeHouseHold: compareWeight('compositeHouseHold'),
      ceramicHouseHold: compareWeight('ceramicHouseHold'),
      woodHouseHold: compareWeight('woodHouseHold'),
      textileHouseHold: compareWeight('textileHouseHold'),
      organicHouseHold: compareWeight('organicHouseHold'),
      fiberCommercial: compareWeight('fiberCommercial'),
      ferrousMetalCommercial: compareWeight('ferrousMetalCommercial'),
      aluminumCommercial: compareWeight('aluminumCommercial'),
      foilsCommercial: compareWeight('foilsCommercial'),
      hollowBodiesCommercial: compareWeight('hollowBodiesCommercial'),
      epsCommercial: compareWeight('epsCommercial'),
      compositeCommercial: compareWeight('compositeCommercial'),
      ceramicCommercial: compareWeight('ceramicCommercial'),
      woodCommercial: compareWeight('woodCommercial'),
      textileCommercial: compareWeight('textileCommercial'),
      organicCommercial: compareWeight('organicCommercial'),
    },
    { key: 'key', direction: 'asc' }
  );

  return (
    <div className="table-responsive shadow-inner-end">
      <table className="table table-striped table-hover">
        <thead className="">
          <tr>
            <SortableHeaderCell sorting={sorting} sortKey="key">
              {t('reviewStep.company')}
            </SortableHeaderCell>
            <SortableWeightsHeaderCells sorting={sorting} />
          </tr>
        </thead>
        <tbody>
          {sorting.sorted.map(a => (
            <tr key={a.keyAsString}>
              <td>
                <ResponsibleView responsible={a.key} />
              </td>
              <ReviewWeightsRowCells weights={a.weights} comparison={a.comparisonWeights} />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
