﻿import { PackagingWeights } from './api';
import { weightFormat } from './helpers';

export interface Props {
  weights: PackagingWeights;
  muted?: boolean;
}

export function WeightsRowCells(props: Props) {
  const className = `align-middle text-end text-nowrap${props.muted ? ' text-muted opacity-50' : ''}`;

  return (
    <>
      <td className={className}>{weightFormat.format(props.weights.glass)} kg</td>
      <td className={className}>{weightFormat.format(props.weights.fiber)} kg</td>
      <td className={className}>{weightFormat.format(props.weights.ferrousMetal)} kg</td>
      <td className={className}>{weightFormat.format(props.weights.aluminum)} kg</td>
      <td className={className}>{weightFormat.format(props.weights.plastic)} kg</td>
      <td className={className}>{weightFormat.format(props.weights.beverageCarton)} kg</td>
      <td className={className}>{weightFormat.format(props.weights.otherComposite)} kg</td>
      <td className={className}>{weightFormat.format(props.weights.other)} kg</td>
    </>
  );
}
