﻿import { Branches } from './Branches';
import { BranchesTable } from './BranchesTable';
import { Outlet, RouteObject } from 'react-router';
import { branchEditRoute } from './BranchEdit';
import { branchAddRoute } from './BranchAdd';

export const branchIndexRoute: RouteObject = {
  path: 'branches',
  element: <BranchIndex />,
  children: [branchAddRoute, branchEditRoute],
};

export function BranchIndex() {
  return (
    <div className="mx-4">
      <Branches>
        <BranchesTable />
        <Outlet />
      </Branches>
    </div>
  );
}
