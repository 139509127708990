﻿import { DeclarationErrorInfo, EvaluationError } from '../../../../api';
import { expectNever, getHash } from '../../../../helpers';
import { DeclarationErrorRow } from './DeclarationErrorRow';
import i18n from '../../../../i18n';
import { useTranslation } from 'react-i18next';

export function getEvaluationErrorText(error: EvaluationError) {
  const t = i18n.getFixedT(null, 'reports', 'declarationErrorsTable.issues');

  if (error === 'Deprecated') {
    return t('deprecated');
  }

  switch (error.Case) {
    case 'BranchRequired':
      return t('branchRequired');
    case 'ProducerIdRequired':
      return t('producerIdRequired');
    case 'ArticleRequired':
      return t('articleRequired');
    case 'ServicePackagingRequired':
      return t('servicePackagingRequired');
    case 'SupplierCountryRequired':
      return t('supplierCountryRequired');
    case 'SupplierIdRequired':
      return t('supplierIdRequired');
    case 'CustomerIdRequired':
      return t('customerIdRequired');
    case 'SupplierIncoTermRequired':
      return t('supplierIncoTermRequired');
    case 'DelegationOfLicensingRequired':
      return t('delegationOfLicensingRequired');
    case 'DelegationOfPaymentRequired':
      return t('delegationOfPaymentRequired');
    case 'SalePackagingRequired':
      return t('salePackagingRequired');
    case 'SalePackagingWeightsRequired':
      return t('salePackagingWeightsRequired');
    case 'ServicePackagingWeightsRequired':
      return t('servicePackagingWeightsRequired');
    case 'ArticleQuantityRequired':
      return t('articleQuantityRequired');
    case 'ShipToCountryRequired':
      return t('shipToCountryRequired');
    case 'CustomerDelegatesObligationRequired':
      return t('customerDelegatesObligationRequired');
    case 'CompanyRequired':
      return t('companyRequired');
    case 'OriginCountryRequired':
      return t('originCountryRequired');
    case 'ProducerOnPackagingRequired':
      return t('producerOnPackagingRequired');
    case 'SupplierRequired':
      return t('supplierRequired');
    case 'ManufacturersRequired':
      return t('manufacturersRequired');
    case 'HouseholdPackagingRequired':
      return t('householdPackagingRequired');
    case 'HouseholdPackagingWeightsRequired':
      return t('householdPackagingWeightsRequired');
    case 'CommercialPackagingRequired':
      return t('commercialPackagingRequired');
    case 'CommercialPackagingWeightsRequired':
      return t('commercialPackagingWeightsRequired');
  }

  expectNever(error);
}

export function getEvaluationErrorBranchId(error: EvaluationError) {
  const t = i18n.getFixedT(null, 'reports', 'declarationErrorsTable.issues');

  if (error === 'Deprecated') {
    return t('deprecated');
  }

  switch (error.Case) {
    case 'BranchRequired':
      return error.Fields[0];
    case 'ProducerIdRequired':
      return error.Fields[0];
    case 'ArticleRequired':
      return null;
    case 'ServicePackagingRequired':
      return null;
    case 'SupplierCountryRequired':
      return null;
    case 'SupplierIdRequired':
      return error.Fields[0];
    case 'CustomerIdRequired':
      return error.Fields[0];
    case 'SupplierIncoTermRequired':
      return null;
    case 'DelegationOfLicensingRequired':
      return null;
    case 'DelegationOfPaymentRequired':
      return null;
    case 'SalePackagingRequired':
      return null;
    case 'SalePackagingWeightsRequired':
      return null;
    case 'ServicePackagingWeightsRequired':
      return null;
    case 'ArticleQuantityRequired':
      return null;
    case 'ProducerOnPackagingRequired':
      return null;
    case 'ShipToCountryRequired':
      return error.Fields[0];
    case 'CustomerDelegatesObligationRequired':
      return error.Fields[0];
    case 'CompanyRequired':
      return error.Fields[0];
    case 'OriginCountryRequired':
      return error.Fields[0];
    case 'SupplierRequired':
      return null;
    case 'ManufacturersRequired':
      return null;
    case 'HouseholdPackagingRequired':
      return null;
    case 'HouseholdPackagingWeightsRequired':
      return null;
    case 'CommercialPackagingRequired':
      return null;
    case 'CommercialPackagingWeightsRequired':
      return null;
  }

  expectNever(error);
}

export function getEvaluationErrorArticleId(error: EvaluationError) {
  const t = i18n.getFixedT(null, 'reports', 'declarationErrorsTable.issues');

  if (error === 'Deprecated') {
    return t('deprecated');
  }

  switch (error.Case) {
    case 'BranchRequired':
      return null;
    case 'ProducerIdRequired':
      return null;
    case 'ArticleRequired':
      return error.Fields[0];
    case 'ServicePackagingRequired':
      return error.Fields[0];
    case 'SupplierCountryRequired':
      return error.Fields[0];
    case 'SupplierIdRequired':
      return error.Fields[1];
    case 'CustomerIdRequired':
      return error.Fields[1];
    case 'SupplierIncoTermRequired':
      return null;
    case 'DelegationOfLicensingRequired':
      return error.Fields[0];
    case 'DelegationOfPaymentRequired':
      return error.Fields[0];
    case 'SalePackagingRequired':
      return error.Fields[0];
    case 'SalePackagingWeightsRequired':
      return error.Fields[0];
    case 'ServicePackagingWeightsRequired':
      return error.Fields[0];
    case 'ArticleQuantityRequired':
      return error.Fields[0];
    case 'ProducerOnPackagingRequired':
      return error.Fields[0];
    case 'ShipToCountryRequired':
      return error.Fields[1];
    case 'CustomerDelegatesObligationRequired':
      return error.Fields[1];
    case 'CompanyRequired':
      return error.Fields[1];
    case 'OriginCountryRequired':
      return error.Fields[1];
    case 'SupplierRequired':
      return null;
    case 'ManufacturersRequired':
      return error.Fields[0];
    case 'HouseholdPackagingRequired':
      return error.Fields[0];
    case 'HouseholdPackagingWeightsRequired':
      return error.Fields[0];
    case 'CommercialPackagingRequired':
      return error.Fields[0];
    case 'CommercialPackagingWeightsRequired':
      return error.Fields[0];
  }

  expectNever(error);
}

export function getEvaluationErrorSupplierId(error: EvaluationError) {
  const t = i18n.getFixedT(null, 'reports', 'declarationErrorsTable.issues');

  if (error === 'Deprecated') {
    return t('deprecated');
  }

  switch (error.Case) {
    case 'BranchRequired':
      return null;
    case 'ProducerIdRequired':
      return null;
    case 'ArticleRequired':
      return null;
    case 'ServicePackagingRequired':
      return null;
    case 'SupplierCountryRequired':
      return null;
    case 'SupplierIdRequired':
      return null;
    case 'CustomerIdRequired':
      return null;
    case 'SupplierIncoTermRequired':
      return error.Fields[0];
    case 'DelegationOfLicensingRequired':
      return null;
    case 'DelegationOfPaymentRequired':
      return null;
    case 'SalePackagingRequired':
      return null;
    case 'SalePackagingWeightsRequired':
      return null;
    case 'ServicePackagingWeightsRequired':
      return null;
    case 'ArticleQuantityRequired':
      return null;
    case 'ProducerOnPackagingRequired':
      return null;
    case 'ShipToCountryRequired':
      return null;
    case 'CustomerDelegatesObligationRequired':
      return error.Fields[1];
    case 'CompanyRequired':
      return null;
    case 'OriginCountryRequired':
      return null;
    case 'SupplierRequired':
      return error.Fields[0];
    case 'ManufacturersRequired':
      return null;
    case 'HouseholdPackagingRequired':
      return null;
    case 'HouseholdPackagingWeightsRequired':
      return null;
    case 'CommercialPackagingRequired':
      return null;
    case 'CommercialPackagingWeightsRequired':
      return null;
  }

  expectNever(error);
}

export interface Props {
  errors: DeclarationErrorInfo[];
}

export function DeclarationErrorsTable(props: Props) {
  const { t } = useTranslation('reports');

  return (
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th style={{ width: '1%' }} className="text-end">
            #
          </th>
          <th>{t('declarationErrorsTable.description')}</th>
          <th className="text-nowrap">{t('declarationErrorsTable.branchId')}</th>
          <th className="text-nowrap">{t('declarationErrorsTable.articleId')}</th>
          <th className="text-nowrap">{t('declarationErrorsTable.supplierId')}</th>
          <th style={{ width: '1%' }}>{t('declarationErrorsTable.quantity')}</th>
        </tr>
      </thead>
      <tbody>
        {props.errors.map((info, i) => {
          const text = getEvaluationErrorText(info.error);
          const branchId = getEvaluationErrorBranchId(info.error);
          const articleId = getEvaluationErrorArticleId(info.error);
          const supplierId = getEvaluationErrorSupplierId(info.error);
          return (
            <DeclarationErrorRow
              key={getHash(info)}
              index={i + 1}
              quantity={info.quantity}
              error={text}
              branchId={branchId}
              articleId={articleId}
              supplierId={supplierId}
            />
          );
        })}
      </tbody>
    </table>
  );
}
