import { getAllSuppliers } from '../../api';
import { createContext, ReactNode, useContext } from 'react';
import { useTenantQuery } from '../../shared/hooks/useTenantQuery';
import { useQueryState } from '../../shared/hooks/useQueryState';

const SuppliersContext = createContext<Record<string, string>>({});

/**
 * Provides the suppliers of the specified year to all child components.
 */
export function Suppliers(props: { year: number; children?: ReactNode }) {
  const query = useTenantQuery(getAllSuppliers, props.year);
  const [suppliers] = useQueryState(query);

  return <SuppliersContext.Provider value={suppliers}>{props.children}</SuppliersContext.Provider>;
}

/**
 * Returns all suppliers that are provided.
 */
export function useSuppliers() {
  return useContext(SuppliersContext);
}
