import * as React from 'react';

export function Toolbar(props: { children?: React.ReactNode }) {
  return (
    <div
      style={{
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#fff',
        background: 'linear-gradient(to top, #fff, rgba(255, 255, 255, 0.25))',
      }}>
      <div className="py-3 text-center">{props.children}</div>
    </div>
  );
}
