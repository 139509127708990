﻿import { DeclarationResultGermany } from '../../../../api';
import { Outlet, RouteObject, useOutletContext } from 'react-router';
import { adjustDetailRoute } from './AdjustDetail';
import { adjustIndexRoute } from './AdjustIndex';

export const adjustStepRoute: RouteObject = {
  path: 'adjust',
  element: <AdjustStep />,
  children: [adjustIndexRoute, adjustDetailRoute],
};

export function AdjustStep() {
  const declaration = useOutletContext<DeclarationResultGermany | null>();

  if (declaration === null) {
    return <Outlet />;
  }

  return <Outlet context={declaration} />;
}
