﻿import { Icon } from '../../shared/components/Icon';
import { getLatestImports, getPresignedUploadUrl, ImportInfo, ImportStatus } from '../../api';
import { useTenant } from './TenantDetail';
import * as React from 'react';
import { Suspense, useState } from 'react';
import { expectNever } from '../../helpers';
import { Modal } from '../../shared/components/Modal';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { useTenantQuery } from '../../shared/hooks/useTenantQuery';
import { useQueryState } from '../../shared/hooks/useQueryState';
import { UTMPrefixModal } from './UTMPrefixModal';

function getClassNameAndText(status: ImportStatus) {
  const t = i18n.getFixedT(null, 'tenants');

  if (status === 'Pending') {
    return ['secondary', t('import.inProgress')];
  }

  if (status.Case === 'Succeeded') {
    return ['success', t('import.success')];
  }

  if (status.Case === 'Failed') {
    return ['danger', t('import.failure')];
  }

  expectNever(status);
}

const dateFormat = Intl.DateTimeFormat('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });

function formatDate(date: Date) {
  return dateFormat.format(date);
}

const timeFormat = Intl.DateTimeFormat('de-DE', { hour: '2-digit', minute: '2-digit', second: '2-digit' });

function formatTime(date: Date) {
  return timeFormat.format(date);
}

function TenantNotification({ info }: { info: ImportInfo }) {
  const { t } = useTranslation('tenants');
  const startedAt = new Date(info.startedAt);

  const finishedAt = typeof info.status === 'object' ? new Date(info.status.Fields[0]) : null;

  const [color, text] = getClassNameAndText(info.status);

  return (
    <li key={info.importId} className="list-group-item">
      <div className="d-flex">
        <strong className={`fw-bold flex-grow-1 text-${color}`}>{info.filename}</strong>
        <div>
          <span className={`badge bg-${color}`}>{text}</span>
        </div>
      </div>

      {typeof info.status === 'object' && info.status.Case === 'Failed' && (
        <pre className="text-danger">{info.status.Fields[1]}</pre>
      )}

      <div className="d-flex">
        <div className="text-muted flex-grow-1">
          {t('import.startedOn')} <strong>{formatDate(startedAt)}</strong> {t('import.statedAt')}{' '}
          <strong>{formatTime(startedAt)}</strong>
        </div>

        {finishedAt && (
          <div className="text-muted">
            {t('import.finishedAt')} <strong>{formatTime(finishedAt)}</strong>
          </div>
        )}
      </div>
    </li>
  );
}

/** Modal that shows all notifications related to the current tenant. */
function ImportsModal(props: { onClose: () => void }) {
  const { t } = useTranslation('tenants');

  const importsQuery = useTenantQuery(getLatestImports, void 0, true);
  const [imports] = useQueryState(importsQuery);

  const footer = (
    <>
      <button className="btn btn-primary text-white" onClick={() => importsQuery.revalidate()}>
        {t('import.refresh')}
      </button>
    </>
  );

  return (
    <Modal visible={true} title={t('import.imports')} size="xl" onClose={props.onClose} footer={footer}>
      <ul className="list-group">
        {imports.map(i => (
          <TenantNotification key={i.importId} info={i} />
        ))}
      </ul>
    </Modal>
  );
}

export function TenantImport() {
  const tenant = useTenant();

  const { t } = useTranslation('tenants');

  const [visible, setVisible] = React.useState(false);

  const [inProgress, setInProgress] = useState(false);

  const [utmFiles, setUtmFiles] = useState<File[] | null>(null);

  async function uploadFiles(files: File[], fileNamePrefix?: string) {
    const failed: string[] = [];

    for (const file of files) {
      try {
        const url = await getPresignedUploadUrl({ tenantId: tenant.id, data: (fileNamePrefix ?? '') + file.name });
        const headers = {
          'Content-Type': 'application/octet-stream',
        };
        const response = await fetch(url, { method: 'PUT', body: file, headers });
        if (!response.ok) {
          failed.push(file.name);
        }
      } catch (e) {
        failed.push(file.name);
        console.error(e);
      }
    }

    if (failed.length === files.length) {
      if (files.length === 1) {
        alert(t('import.uploadFailedForFile'));
      } else {
        alert(t('import.uploadFailedForFiles'));
      }
    } else if (failed.length === 0) {
      alert(t('import.importStarts'));
    } else {
      alert(t('import.uploadFailedForTheseFiles', { files: `${failed.join('\n')}` }));
    }
  }

  function upload(files: File[], fileNamePrefix?: string) {
    setInProgress(true);
    uploadFiles(files, fileNamePrefix).then(
      () => setInProgress(false),
      () => setInProgress(false)
    );
  }

  function onUTMImport(prefix: string, utmFiles: File[]) {
    upload(utmFiles, prefix);
    setUtmFiles(null);
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.currentTarget.files ?? [];
    if (files.length > 0) {
      const a = Array.from(files);
      if (tenant.hint === 'UTM') {
        setUtmFiles(a);
      } else {
        upload(a);
      }
    }

    e.currentTarget.value = '';
  }

  const labelClassName = inProgress
    ? 'btn btn-secondary ms-3 text-nowrap disabled'
    : 'btn btn-secondary ms-3 text-nowrap';

  const labelText = inProgress ? t('import.uploadInProgress') : t('import.buttonText');

  return (
    <div className="text-nowrap btn-group" role="group">
      <label className={labelClassName}>
        <Icon name="upload" className="me-2" />
        {labelText}
        <input
          disabled={inProgress}
          multiple
          style={{ display: 'none' }}
          type="file"
          accept="text/csv,.xlsx,.xls"
          onChange={onChange}
        />
      </label>
      <button className="btn btn-secondary" onClick={() => setVisible(true)}>
        <Icon name="clock-history" />
      </button>
      {utmFiles && (
        <UTMPrefixModal onImport={prefix => onUTMImport(prefix, utmFiles)} onClose={() => setUtmFiles(null)} />
      )}
      {visible && (
        <Suspense fallback={<></>}>
          <ImportsModal onClose={() => setVisible(false)} />
        </Suspense>
      )}
    </div>
  );
}
