﻿import { ProducerData, Responsible } from '../../../api';
import { isCustomer, isCustomers, isProducer, isSupplier } from './helpers';
import { expectNever } from '../../../helpers';
import { useSuppliers } from '../../suppliers/suppliers';
import { useProducers } from '../../producers/Producers';

function getCompareString(
  producers: Record<string, ProducerData>,
  suppliers: Record<string, string>,
  responsible: Responsible
) {
  if (isProducer(responsible)) {
    return `0:${producers[responsible.Fields[0]]?.name ?? responsible.Fields[0]}`;
  }

  if (isSupplier(responsible)) {
    return `1:${suppliers[responsible.Fields[0]] ?? responsible.Fields[0]}`;
  }

  if (isCustomer(responsible)) {
    return `2:${responsible.Fields[0]}`;
  }

  if (isCustomers(responsible)) {
    return `3:`;
  }

  return expectNever(responsible);
}

/**
 * Compares two responsibles.
 */
export function compareResponsible(
  producers: Record<string, ProducerData>,
  suppliers: Record<string, string>,
  a: Responsible,
  b: Responsible
): number {
  const astr = getCompareString(producers, suppliers, a);
  const bstr = getCompareString(producers, suppliers, b);
  return astr.localeCompare(bstr);
}

/**
 * Returns a function that compares two responsibles.
 */
export function useCompareResponsible() {
  const producers = useProducers();
  const suppliers = useSuppliers();
  return (a: Responsible, b: Responsible) => compareResponsible(producers.all, suppliers, a, b);
}
