﻿export interface Props {
  index: number;
  quantity: number;
  error: string;
  branchId: string | null;
  articleId: string | null;
  supplierId: string | null;
}

export function DeclarationErrorRow(props: Props) {
  return (
    <tr>
      <td className="text-end">{props.index}</td>
      <td>{props.error}</td>
      <td>{props.branchId}</td>
      <td>{props.articleId}</td>
      <td>{props.supplierId}</td>
      <td className="text-end">{props.quantity}</td>
    </tr>
  );
}
