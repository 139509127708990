﻿import { StateOf } from '../../forms';
import { BranchFormDefinition } from './form';
import { Row } from '../../shared/components/Row';
import { CountryField } from '../../shared/components/CountryField';
import { TextField } from '../../shared/components/TextField';
import { ProducerField } from '../../shared/components/ProducerField';
import { useTranslation } from 'react-i18next';

export interface Props {
  form: StateOf<BranchFormDefinition>;
  newRecord: boolean;
}

export function BranchForm({ form, newRecord }: Props) {
  const { t } = useTranslation('branches');

  return (
    <>
      <Row>
        <TextField id="branchId" label={t('branchForm.branchId')} field={form.fields.branchId} disabled={!newRecord} />
      </Row>
      <Row>
        <TextField id="name" label={t('branchForm.name')} field={form.fields.name} />
      </Row>
      <Row>
        <ProducerField id="producerId" label={t('branchForm.producer')} field={form.fields.producerId} />
      </Row>
      <Row>
        <CountryField id="country" label={t('branchForm.country')} field={form.fields.country} />
      </Row>
    </>
  );
}
