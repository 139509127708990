import { invokePublic, invokePrivate, invokeTenant } from "./remote";

export type FSharpResult<T, TError> =
  | { Case: "Ok"; Fields: [T]; }
  | { Case: "Error"; Fields: [TError]; };

export type ClientAuthState = 
  | { Case: "Authenticated"; Fields: [Item: OAuthResult]; }
  | { Case: "Challenge"; Fields: [Item: AuthChallenge]; }
  | { Case: "PasswordResetRequired"; Fields: [username: string]; }

export type AuthError = 
  | "NotAuthorized"
  | "InvalidPassword"
  | "ExpiredCode"
  | "CodeMismatch"
  | "UserNotFound"
  | "LimitExceeded"

export const AuthErrorEnumerable : AuthError[] = [
  "NotAuthorized",
  "InvalidPassword",
  "ExpiredCode",
  "CodeMismatch",
  "UserNotFound",
  "LimitExceeded",
];

export interface ChallengeContext {
  username: string;
  session: string;
}

export interface BrowserAuthOptions {
  clientId: string;
  authorizeUrl: string;
  logoutUrl: string;
  redirectUri: string;
}

export interface OAuthResult {
  idToken: string;
  expiresAt: string;
  user: User;
}

export type DeclarationCountry = 
  | "Germany"
  | "Austria"
  | "France"

export const DeclarationCountryEnumerable : DeclarationCountry[] = [
  "Germany",
  "Austria",
  "France",
];

export interface SystemData {
  id: number;
  name: string;
  country: DeclarationCountry;
}

export interface ListRequest<TFilter, TField> {
  filter: TFilter;
  order: Order<TField>;
  page: Page;
}

export type TenantOrderField = 
  | "Name"
  | "LastActionAt"

export const TenantOrderFieldEnumerable : TenantOrderField[] = [
  "Name",
  "LastActionAt",
];

export interface ListResponse<TItem> {
  items: TItem[];
  hasMore: boolean;
}

export interface Tenant {
  id: number;
  name: string;
  hint: string | null;
  countries: string[];
  permissions: TenantPermissions;
}

export interface ProducerData {
  id: string;
  name: string;
  address: Address;
  contact: Contact;
  lucidNumber: string | null;
  active: boolean;
}

export type Period = 
  | "Year"
  | "Q1"
  | "Q2"
  | "Q3"
  | "Q4"
  | "Jan"
  | "Feb"
  | "Mar"
  | "Apr"
  | "May"
  | "Jun"
  | "Jul"
  | "Aug"
  | "Sep"
  | "Oct"
  | "Nov"
  | "Dec"
  | "FirstHalfYear"
  | "SecondHalfYear"

export const PeriodEnumerable : Period[] = [
  "Year",
  "Q1",
  "Q2",
  "Q3",
  "Q4",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
  "FirstHalfYear",
  "SecondHalfYear",
];

export type LongRunningTask<T, E> =
  | "Running"
  | { Case: "Finished"; Fields: [T]; }
  | { Case: "Failed"; Fields: [E]; };

export interface DeclarationResultGermany {
  declarationId: number;
  kind: DeclarationKind;
  responsibilities: Responsibility[];
  errors: DeclarationErrorInfo[];
}

export type DeclarationResultError = 
  | "NotYetCreated"
  | "NotYetReleased"

export const DeclarationResultErrorEnumerable : DeclarationResultError[] = [
  "NotYetCreated",
  "NotYetReleased",
];

export interface DeclarationResultFrance {
  declarationId: number;
  kind: DeclarationKind;
  membershipCosts: [string, number][];
  totalCosts: number;
  failedImportId: number | null;
}

export interface DeclarationResultAustria {
  declarationId: number;
  kind: DeclarationKind;
  responsibilities: ResponsibilityAustria[];
  errors: DeclarationErrorInfo[];
}

export type DeclarationResultErrorAustria = 
  | "NotYetCreated"
  | "NotYetReleased"

export const DeclarationResultErrorAustriaEnumerable : DeclarationResultErrorAustria[] = [
  "NotYetCreated",
  "NotYetReleased",
];

export interface DeclarationType {
  country: DeclarationCountry;
  year: number;
  period: Period;
}

export interface WithdrawDeclarationRequest {
  declarationId: number;
  declarationType: DeclarationType;
}

export interface ThirdPartyLicensing {
  producerId: string;
  licensee: ThirdPartyLicensee;
  year: number;
  systemId: number;
  period: Period;
  weights: PackagingWeights | null;
}

export type ThirdPartyLicensee = 
  | { Case: "Supplier"; Fields: [supplierId: string]; }
  | { Case: "Customer"; Fields: [customerId: string]; }

export interface PackagingWeights {
  glass: number;
  fiber: number;
  ferrousMetal: number;
  aluminum: number;
  beverageCarton: number;
  otherComposite: number;
  plastic: number;
  other: number;
}

export interface ContractValue {
  contractId: number | null;
  producerId: string;
  year: number;
  systemId: number;
  prices: ContractPrices | null;
  country: DeclarationCountry;
  interval: DeclarationInterval;
  deadlines: ContractDeadlines;
}

export interface AvailabilityOfData {
  purchase: number[];
  sale: number[];
  dropshipping: number[];
}

export type PeriodStatus = 
  | { Case: "Declaration"; Fields: [declarationId: number, status: PeriodDeclarationStatus]; }
  | "NotYetCreated"
  | "NotYetReleased"

export interface PlacingData {
  producerId: string;
  description: string;
  period: Period;
  packagingType: PackagingType;
  weights: PackagingWeights;
}

export interface ImportInfo {
  importId: number;
  filename: string;
  startedAt: string;
  status: ImportStatus;
}

export interface ImportError {
  tenantId: number;
  importId: number;
  kind: ImportErrorKind;
}

export interface ReportItem {
  declarationId: number;
  producerId: string;
  lucidReportedAt: string | null;
  proReportedAt: string | null;
}

export interface ReportRequest {
  declarationId: number;
  year: number;
  period: Period;
  reportType: ReportType;
  producerId: string;
  state: boolean;
}

export interface Branch {
  branchId: string;
  producerId: string | null;
  name: string;
  country: Country;
}

export interface User {
  id: string;
  email: string;
  locale: string;
  permissions: UserPermissions;
}

export interface UserPermissions {
  leko: boolean;
}

export type AuthChallenge = 
  | { Case: "NewPasswordRequired"; Fields: [Item1: ChallengeContext, Item2: string[]]; }
  | { Case: "SmsMfa"; Fields: [Item1: ChallengeContext, codeDeliveryDestination: string]; }

export interface Order<T> {
  field: T;
  direction: OrderDirection;
}

export type OrderDirection = 
  | "Asc"
  | "Desc"

export const OrderDirectionEnumerable : OrderDirection[] = [
  "Asc",
  "Desc",
];

export interface Page {
  limit: number;
  offset: number;
}

export interface TenantPermissions {
  central: boolean;
  local: string[];
}

export interface Address {
  country: string | null;
  city: string | null;
  postCode: string | null;
  street: string | null;
}

export interface Contact {
  name: string | null;
  eMail: string | null;
  phone: string | null;
  language: Locale;
}

export type Locale = 
  | "DE_de"
  | "EN_us"

export const LocaleEnumerable : Locale[] = [
  "DE_de",
  "EN_us",
];

export type DeclarationKind = 
  | "Preview"
  | "Release"

export const DeclarationKindEnumerable : DeclarationKind[] = [
  "Preview",
  "Release",
];

export interface Responsibility {
  decision: AggregatedDecision;
  reporting: Responsible | null;
  licensing: Responsible | null;
  payment: Responsible | null;
  weights: PackagingWeights;
}

export type AggregatedDecision = 
  | { Case: "Yes"; Fields: [Item: ResponsibleReason]; }
  | { Case: "No"; Fields: [Item: NotResponsibleReason]; }
  | "Failed"

export type ResponsibleReason = 
  | "PurchaseToNonBrandOwnerFromManufacturer"
  | "SaleFromBrandOwner"
  | "SaleFromManufacturerToNonBrandOwner"
  | "ProducerIsImporter"
  | "DelegationFromCustomer"
  | "Shipping"
  | "AdditionalPlacing"

export const ResponsibleReasonEnumerable : ResponsibleReason[] = [
  "PurchaseToNonBrandOwnerFromManufacturer",
  "SaleFromBrandOwner",
  "SaleFromManufacturerToNonBrandOwner",
  "ProducerIsImporter",
  "DelegationFromCustomer",
  "Shipping",
  "AdditionalPlacing",
];

export type NotResponsibleReason = 
  | "ExportToOtherCountry"
  | "NoDelegationFromCustomer"
  | "PurchaseButDomestic"
  | "PurchaseButFromLabelOwner"
  | "ImportSupplierBearsRisk"
  | "ImportPurchaserBearsRisk"
  | "ImportButToOtherCountry"
  | "ForbiddenDeductionInCaseOfReturn"
  | "DangerousGood"
  | "NoPackaging"
  | "PreLicensed"
  | "Industrial"
  | "TakeBackOneWay"
  | "TakeBackMultiWay"
  | "Transport"
  | "Resell"
  | "SaleToBrandOwner"

export const NotResponsibleReasonEnumerable : NotResponsibleReason[] = [
  "ExportToOtherCountry",
  "NoDelegationFromCustomer",
  "PurchaseButDomestic",
  "PurchaseButFromLabelOwner",
  "ImportSupplierBearsRisk",
  "ImportPurchaserBearsRisk",
  "ImportButToOtherCountry",
  "ForbiddenDeductionInCaseOfReturn",
  "DangerousGood",
  "NoPackaging",
  "PreLicensed",
  "Industrial",
  "TakeBackOneWay",
  "TakeBackMultiWay",
  "Transport",
  "Resell",
  "SaleToBrandOwner",
];

export type Responsible = 
  | { Case: "Producer"; Fields: [producerId: string]; }
  | { Case: "Supplier"; Fields: [supplierId: string]; }
  | "Customers"
  | { Case: "Customer"; Fields: [customerId: string]; }

export interface DeclarationErrorInfo {
  error: EvaluationError;
  quantity: number;
}

export type EvaluationError = 
  | { Case: "BranchRequired"; Fields: [branchId: string]; }
  | { Case: "ProducerIdRequired"; Fields: [branchId: string]; }
  | { Case: "ArticleRequired"; Fields: [articleId: string]; }
  | { Case: "SupplierCountryRequired"; Fields: [articleId: string]; }
  | { Case: "SalePackagingRequired"; Fields: [articleId: string]; }
  | { Case: "SalePackagingWeightsRequired"; Fields: [articleId: string]; }
  | { Case: "DelegationOfLicensingRequired"; Fields: [articleId: string]; }
  | { Case: "DelegationOfPaymentRequired"; Fields: [articleId: string]; }
  | { Case: "ServicePackagingRequired"; Fields: [articleId: string]; }
  | { Case: "ServicePackagingWeightsRequired"; Fields: [articleId: string]; }
  | { Case: "ArticleQuantityRequired"; Fields: [articleId: string]; }
  | { Case: "ProducerOnPackagingRequired"; Fields: [articleId: string]; }
  | { Case: "ManufacturersRequired"; Fields: [articleId: string]; }
  | { Case: "HouseholdPackagingRequired"; Fields: [articleId: string]; }
  | { Case: "HouseholdPackagingWeightsRequired"; Fields: [articleId: string]; }
  | { Case: "CommercialPackagingRequired"; Fields: [articleId: string]; }
  | { Case: "CommercialPackagingWeightsRequired"; Fields: [articleId: string]; }
  | { Case: "ShipToCountryRequired"; Fields: [branchId: string, articleId: string]; }
  | { Case: "CustomerDelegatesObligationRequired"; Fields: [branchId: string, articleId: string]; }
  | { Case: "CompanyRequired"; Fields: [branchId: string, articleId: string]; }
  | { Case: "SupplierIdRequired"; Fields: [branchId: string, articleId: string]; }
  | { Case: "OriginCountryRequired"; Fields: [branchId: string, articleId: string]; }
  | { Case: "CustomerIdRequired"; Fields: [branchId: string, articleId: string]; }
  | { Case: "SupplierRequired"; Fields: [supplierId: string]; }
  | { Case: "SupplierIncoTermRequired"; Fields: [supplierId: string]; }
  | "Deprecated"

export interface Tuple<T1, T2> {
  item1: T1;
  item2: T2;
}

export interface ResponsibilityAustria {
  decision: AggregatedDecisionAustria;
  licensing: Responsible | null;
  weights: AggregatedPackagingWeightsAustria;
}

export type AggregatedDecisionAustria = 
  | "Yes"
  | "No"
  | "Failed"

export const AggregatedDecisionAustriaEnumerable : AggregatedDecisionAustria[] = [
  "Yes",
  "No",
  "Failed",
];

export interface AggregatedPackagingWeightsAustria {
  glassHouseHold: number;
  fiberHouseHold: number;
  ferrousMetalHouseHold: number;
  aluminumHouseHold: number;
  plasticHouseHold: number;
  beverageCartonHouseHold: number;
  compositeHouseHold: number;
  ceramicHouseHold: number;
  woodHouseHold: number;
  textileHouseHold: number;
  organicHouseHold: number;
  fiberCommercial: number;
  ferrousMetalCommercial: number;
  aluminumCommercial: number;
  foilsCommercial: number;
  hollowBodiesCommercial: number;
  epsCommercial: number;
  compositeCommercial: number;
  ceramicCommercial: number;
  woodCommercial: number;
  textileCommercial: number;
  organicCommercial: number;
}

export type ContractPrices = 
  | { Case: "Germany"; Fields: [Item: ContractPricesGermany]; }
  | { Case: "Austria"; Fields: [Item: ContractPricesAustria]; }

export interface ContractPricesGermany {
  glass: number;
  fiber: number;
  ferrousMetal: number;
  aluminum: number;
  plastic: number;
  beverageCarton: number;
  otherComposite: number;
  other: number;
}

export interface ContractPricesAustria {
  glassHousehold: number;
  fiberHousehold: number;
  ferrousMetalHousehold: number;
  aluminumHousehold: number;
  plasticHousehold: number;
  beverageCartonHousehold: number;
  compositeHousehold: number;
  ceramicHousehold: number;
  woodHousehold: number;
  textileHousehold: number;
  organicHousehold: number;
  fiberCommercial: number;
  ferrousMetalCommercial: number;
  aluminumCommercial: number;
  foilsCommercial: number;
  hollowBodiesCommercial: number;
  epsCommercial: number;
  compositeCommercial: number;
  ceramicCommercial: number;
  woodCommercial: number;
  textileCommercial: number;
  organicCommercial: number;
}

export type DeclarationInterval = 
  | "Monthly"
  | "Quarterly"
  | "BiYearly"
  | "Yearly"

export const DeclarationIntervalEnumerable : DeclarationInterval[] = [
  "Monthly",
  "Quarterly",
  "BiYearly",
  "Yearly",
];

export interface ContractDeadlines {
  annual: string | null;
  periodical: number | null;
}

export type PeriodDeclarationStatus = 
  | "Creating"
  | "InPreview"
  | "Released"

export const PeriodDeclarationStatusEnumerable : PeriodDeclarationStatus[] = [
  "Creating",
  "InPreview",
  "Released",
];

export type PackagingType = 
  | "Sale"
  | "Service"
  | "Outer"
  | "Shipping"

export const PackagingTypeEnumerable : PackagingType[] = [
  "Sale",
  "Service",
  "Outer",
  "Shipping",
];

export type ImportStatus = 
  | "Pending"
  | { Case: "Succeeded"; Fields: [finishedAt: string]; }
  | { Case: "Failed"; Fields: [finishedAt: string, error: string]; }

export type ImportErrorKind = 
  | "Unexpected"
  | "SheetNotFound"
  | "Empty"
  | { Case: "Row"; Fields: [Item: ImportRowError]; }

export interface ImportRowError {
  row: number;
  field: string | null;
  message: ImportRowErrorMessage;
}

export type ImportRowErrorMessage = 
  | "FrUnexpectedDecimalValue"
  | "FrUnexpectedStringValue"
  | "FrUnexpectedIntValue"
  | "FrUnexpectedBoolValue"
  | "FrRecycledPetRequiresPercentage"
  | "FrRecycledPetTraysRequiresPercentage"
  | "FrRecycledFlexiblePeRequiresPercentage"
  | "FrRecycledRigidPeRequiresPercentage"
  | "FrRecycledRigidPpRequiresPercentage"
  | "FrRecycledRigidPsRequiresPercentage"
  | "FrRecycledPetExceedsTotal"
  | "FrRecycledFlexiblePeHouseholdPercentageExceedsTotal"
  | "FrRecycledFlexiblePeExceedsTotal"
  | "FrRecycledRigidPeExceedsTotal"
  | "FrRecycledRigidPpExceedsTotal"
  | "FrRecycledRigidPsExceedsTotal"
  | "FrPackagingElementRequired"
  | "FrSumOfWeightsIsZero"
  | "FrSumOfWeightsExceedsMaximumPossibleWeight"
  | "FrRecycledCardboardRequiresCardboard"
  | "FrPenaltyRequiresPet"
  | "FrPenaltyRequiresGlassAndPet"
  | "FrPenaltyRequiresCardboard"
  | "FrPenaltyRequiresRigidPlastic"
  | "FrPenaltyRequiresGlassAndSteel"
  | "FrPenaltyRequiresPvc"
  | "FrPenaltyRequiresOpaquePet"
  | "FrPenaltyRequiresRigidPetAndAluPvcOrSilicone"
  | "FrPenaltyRequiresGlass"
  | "FrPenaltyRequiresGlassAndCeramics"
  | "FrPenaltyInvalid"
  | "FrReusedExceedsTotalQuantity"
  | "FrAwarenessRaisingMeasureInvalid"
  | "FrReductionMeasureInvalid"
  | "FrPenaltyCategoryInvalid"
  | "FrProductCodeInvalid"
  | "FrProductDescriptionRequired"
  | "FrProductCategoryCodeRequired"
  | "FrCsuPutOnMarketRequired"
  | "FrPackagingElementAboveThresholdRequired"
  | "FrPercentageOutOfRange"
  | "FrPenaltyRequiresPetPePp"
  | "FrRenewableResourcesInvalid"

export const ImportRowErrorMessageEnumerable : ImportRowErrorMessage[] = [
  "FrUnexpectedDecimalValue",
  "FrUnexpectedStringValue",
  "FrUnexpectedIntValue",
  "FrUnexpectedBoolValue",
  "FrRecycledPetRequiresPercentage",
  "FrRecycledPetTraysRequiresPercentage",
  "FrRecycledFlexiblePeRequiresPercentage",
  "FrRecycledRigidPeRequiresPercentage",
  "FrRecycledRigidPpRequiresPercentage",
  "FrRecycledRigidPsRequiresPercentage",
  "FrRecycledPetExceedsTotal",
  "FrRecycledFlexiblePeHouseholdPercentageExceedsTotal",
  "FrRecycledFlexiblePeExceedsTotal",
  "FrRecycledRigidPeExceedsTotal",
  "FrRecycledRigidPpExceedsTotal",
  "FrRecycledRigidPsExceedsTotal",
  "FrPackagingElementRequired",
  "FrSumOfWeightsIsZero",
  "FrSumOfWeightsExceedsMaximumPossibleWeight",
  "FrRecycledCardboardRequiresCardboard",
  "FrPenaltyRequiresPet",
  "FrPenaltyRequiresGlassAndPet",
  "FrPenaltyRequiresCardboard",
  "FrPenaltyRequiresRigidPlastic",
  "FrPenaltyRequiresGlassAndSteel",
  "FrPenaltyRequiresPvc",
  "FrPenaltyRequiresOpaquePet",
  "FrPenaltyRequiresRigidPetAndAluPvcOrSilicone",
  "FrPenaltyRequiresGlass",
  "FrPenaltyRequiresGlassAndCeramics",
  "FrPenaltyInvalid",
  "FrReusedExceedsTotalQuantity",
  "FrAwarenessRaisingMeasureInvalid",
  "FrReductionMeasureInvalid",
  "FrPenaltyCategoryInvalid",
  "FrProductCodeInvalid",
  "FrProductDescriptionRequired",
  "FrProductCategoryCodeRequired",
  "FrCsuPutOnMarketRequired",
  "FrPackagingElementAboveThresholdRequired",
  "FrPercentageOutOfRange",
  "FrPenaltyRequiresPetPePp",
  "FrRenewableResourcesInvalid",
];

export type ReportType = 
  | "Lucid"
  | "Pro"

export const ReportTypeEnumerable : ReportType[] = [
  "Lucid",
  "Pro",
];

export type Country = 
  | "Germany"
  | "Poland"
  | "France"
  | "Austria"
  | "CzechRepublic"
  | "Belgium"
  | "Luxembourg"
  | "BosniaAndHerzegovina"
  | "Bulgaria"
  | "China"
  | "Denmark"
  | "Finland"
  | "Greece"
  | "UnitedKingdom"
  | "HongKong"
  | "Indonesia"
  | "Ireland"
  | "Italy"
  | "Canada"
  | "Croatia"
  | "Latvia"
  | "Netherlands"
  | "Norway"
  | "Sweden"
  | "Switzerland"
  | "Serbia"
  | "Singapore"
  | "Slovakia"
  | "Slovenia"
  | "Spain"
  | "Turkey"
  | "Hungary"
  | "USA"
  | "Portugal"
  | "Unknown"

export const CountryEnumerable : Country[] = [
  "Germany",
  "Poland",
  "France",
  "Austria",
  "CzechRepublic",
  "Belgium",
  "Luxembourg",
  "BosniaAndHerzegovina",
  "Bulgaria",
  "China",
  "Denmark",
  "Finland",
  "Greece",
  "UnitedKingdom",
  "HongKong",
  "Indonesia",
  "Ireland",
  "Italy",
  "Canada",
  "Croatia",
  "Latvia",
  "Netherlands",
  "Norway",
  "Sweden",
  "Switzerland",
  "Serbia",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Turkey",
  "Hungary",
  "USA",
  "Portugal",
  "Unknown",
];


export function initiateAuth(request: { password: string; username: string; }, signal?: AbortSignal) : Promise<FSharpResult<ClientAuthState,AuthError>> {
  return invokePublic<{ password: string; username: string; }, FSharpResult<ClientAuthState,AuthError>>("initiateAuth", request, signal);
}

export function challengeNewPasswordRequired(request: { context: ChallengeContext; newPassword: string; }, signal?: AbortSignal) : Promise<FSharpResult<ClientAuthState,AuthError>> {
  return invokePublic<{ context: ChallengeContext; newPassword: string; }, FSharpResult<ClientAuthState,AuthError>>("challengeNewPasswordRequired", request, signal);
}

export function challengeSmsMfa(request: { context: ChallengeContext; smsMfaCode: string; }, signal?: AbortSignal) : Promise<FSharpResult<ClientAuthState,AuthError>> {
  return invokePublic<{ context: ChallengeContext; smsMfaCode: string; }, FSharpResult<ClientAuthState,AuthError>>("challengeSmsMfa", request, signal);
}

export function forgotPassword(request: string, signal?: AbortSignal) : Promise<FSharpResult<ClientAuthState,AuthError>> {
  return invokePublic<string, FSharpResult<ClientAuthState,AuthError>>("forgotPassword", request, signal);
}

export function confirmForgotPassword(request: { confirmationCode: string; password: string; username: string; }, signal?: AbortSignal) : Promise<FSharpResult<ClientAuthState,AuthError>> {
  return invokePublic<{ confirmationCode: string; password: string; username: string; }, FSharpResult<ClientAuthState,AuthError>>("confirmForgotPassword", request, signal);
}

export function getAuthOptions(request: void, signal?: AbortSignal) : Promise<BrowserAuthOptions> {
  return invokePublic<void, BrowserAuthOptions>("getAuthOptions", request, signal);
}

export function refreshToken(request: void, signal?: AbortSignal) : Promise<FSharpResult<OAuthResult,string>> {
  return invokePublic<void, FSharpResult<OAuthResult,string>>("refreshToken", request, signal);
}

export function setUserLanguage(request: string, signal?: AbortSignal) : Promise<void> {
  return invokePrivate<string, void>("setUserLanguage", request, signal);
}

export function getAllSystems(request: DeclarationCountry, signal?: AbortSignal) : Promise<Record<string, SystemData>> {
  return invokePrivate<DeclarationCountry, Record<string, SystemData>>("getAllSystems", request, signal);
}

export function getTenantsByUser(request: ListRequest<string,TenantOrderField>, signal?: AbortSignal) : Promise<ListResponse<Tenant>> {
  return invokePrivate<ListRequest<string,TenantOrderField>, ListResponse<Tenant>>("getTenantsByUser", request, signal);
}

export function createTenant(request: string, signal?: AbortSignal) : Promise<void> {
  return invokePrivate<string, void>("createTenant", request, signal);
}

export function trackTenantAction(request: { tenantId: number; data: void; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<void, void>("trackTenantAction", request, signal);
}

export function updateTenant(request: { tenantId: number; data: string; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<string, void>("updateTenant", request, signal);
}

export function deleteTenant(request: { tenantId: number; data: void; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<void, void>("deleteTenant", request, signal);
}

export function tryGetTenantById(request: { tenantId: number; data: void; }, signal?: AbortSignal) : Promise<Tenant> {
  return invokeTenant<void, Tenant>("tryGetTenantById", request, signal);
}

export function getAllProducers(request: { tenantId: number; data: void; }, signal?: AbortSignal) : Promise<Record<string, ProducerData>> {
  return invokeTenant<void, Record<string, ProducerData>>("getAllProducers", request, signal);
}

export function upsertProducer(request: { tenantId: number; data: ProducerData; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<ProducerData, void>("upsertProducer", request, signal);
}

export function deleteProducer(request: { tenantId: number; data: string; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<string, void>("deleteProducer", request, signal);
}

export function tryGetDeclarationGermany(request: { tenantId: number; data: { period: Period; year: number; }; }, signal?: AbortSignal) : Promise<LongRunningTask<DeclarationResultGermany,DeclarationResultError>> {
  return invokeTenant<{ period: Period; year: number; }, LongRunningTask<DeclarationResultGermany,DeclarationResultError>>("tryGetDeclarationGermany", request, signal);
}

export function tryGetDeclarationFrance(request: { tenantId: number; data: number; }, signal?: AbortSignal) : Promise<LongRunningTask<DeclarationResultFrance,[number, string]> | null> {
  return invokeTenant<number, LongRunningTask<DeclarationResultFrance,[number, string]> | null>("tryGetDeclarationFrance", request, signal);
}

export function tryGetDeclarationAustria(request: { tenantId: number; data: { period: Period; year: number; }; }, signal?: AbortSignal) : Promise<LongRunningTask<DeclarationResultAustria,DeclarationResultErrorAustria>> {
  return invokeTenant<{ period: Period; year: number; }, LongRunningTask<DeclarationResultAustria,DeclarationResultErrorAustria>>("tryGetDeclarationAustria", request, signal);
}

export function createDeclaration(request: { tenantId: number; data: { country: DeclarationCountry; period: Period; year: number; }; }, signal?: AbortSignal) : Promise<number> {
  return invokeTenant<{ country: DeclarationCountry; period: Period; year: number; }, number>("createDeclaration", request, signal);
}

export function createDeclarationExport(request: { tenantId: number; data: number; }, signal?: AbortSignal) : Promise<[string, string]> {
  return invokeTenant<number, [string, string]>("createDeclarationExport", request, signal);
}

export function createDeclarationExportAustria(request: { tenantId: number; data: number; }, signal?: AbortSignal) : Promise<[string, string]> {
  return invokeTenant<number, [string, string]>("createDeclarationExportAustria", request, signal);
}

export function downloadDeclaration(request: { tenantId: number; data: { year: number; }; }, signal?: AbortSignal) : Promise<[string, string]> {
  return invokeTenant<{ year: number; }, [string, string]>("downloadDeclaration", request, signal);
}

export function release(request: { tenantId: number; data: { declaration: DeclarationType; sendNotifications: boolean; }; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<{ declaration: DeclarationType; sendNotifications: boolean; }, void>("release", request, signal);
}

export function withdraw(request: { tenantId: number; data: WithdrawDeclarationRequest; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<WithdrawDeclarationRequest, void>("withdraw", request, signal);
}

export function getAllThirdPartyLicensings(request: { tenantId: number; data: { period: Period; year: number; }; }, signal?: AbortSignal) : Promise<Record<string, ThirdPartyLicensing>> {
  return invokeTenant<{ period: Period; year: number; }, Record<string, ThirdPartyLicensing>>("getAllThirdPartyLicensings", request, signal);
}

export function upsertThirdPartyLicensing(request: { tenantId: number; data: { licensee: ThirdPartyLicensee; period: Period; producerId: string; systemId: number | null; weights: PackagingWeights | null; year: number; }; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<{ licensee: ThirdPartyLicensee; period: Period; producerId: string; systemId: number | null; weights: PackagingWeights | null; year: number; }, void>("upsertThirdPartyLicensing", request, signal);
}

export function deleteThirdPartyLicensing(request: { tenantId: number; data: { licensee: ThirdPartyLicensee; period: Period; producerId: string; year: number; }; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<{ licensee: ThirdPartyLicensee; period: Period; producerId: string; year: number; }, void>("deleteThirdPartyLicensing", request, signal);
}

export function getContracts(request: { tenantId: number; data: { country: DeclarationCountry; year: number; }; }, signal?: AbortSignal) : Promise<Record<string, ContractValue>> {
  return invokeTenant<{ country: DeclarationCountry; year: number; }, Record<string, ContractValue>>("getContracts", request, signal);
}

export function upsertContract(request: { tenantId: number; data: ContractValue; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<ContractValue, void>("upsertContract", request, signal);
}

export function deleteContract(request: { tenantId: number; data: { contractId: number; producerId: string; }; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<{ contractId: number; producerId: string; }, void>("deleteContract", request, signal);
}

export function getPresignedUploadUrl(request: { tenantId: number; data: string; }, signal?: AbortSignal) : Promise<string> {
  return invokeTenant<string, string>("getPresignedUploadUrl", request, signal);
}

export function getPresignedUploadUrlFrance(request: { tenantId: number; data: { fileName: string; year: number; }; }, signal?: AbortSignal) : Promise<string> {
  return invokeTenant<{ fileName: string; year: number; }, string>("getPresignedUploadUrlFrance", request, signal);
}

export function getAvailability(request: { tenantId: number; data: number; }, signal?: AbortSignal) : Promise<AvailabilityOfData> {
  return invokeTenant<number, AvailabilityOfData>("getAvailability", request, signal);
}

export function getPeriods(request: { tenantId: number; data: { country: DeclarationCountry; year: number; }; }, signal?: AbortSignal) : Promise<[Period, PeriodStatus][]> {
  return invokeTenant<{ country: DeclarationCountry; year: number; }, [Period, PeriodStatus][]>("getPeriods", request, signal);
}

export function getPlacings(request: { tenantId: number; data: number; }, signal?: AbortSignal) : Promise<Record<string, PlacingData>> {
  return invokeTenant<number, Record<string, PlacingData>>("getPlacings", request, signal);
}

export function upsertPlacing(request: { tenantId: number; data: { data: PlacingData; placingId: string; year: number; }; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<{ data: PlacingData; placingId: string; year: number; }, void>("upsertPlacing", request, signal);
}

export function deletePlacing(request: { tenantId: number; data: string; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<string, void>("deletePlacing", request, signal);
}

export function getLatestImports(request: { tenantId: number; data: void; }, signal?: AbortSignal) : Promise<ImportInfo[]> {
  return invokeTenant<void, ImportInfo[]>("getLatestImports", request, signal);
}

export function getImportErrors(request: { tenantId: number; data: number; }, signal?: AbortSignal) : Promise<ImportError[]> {
  return invokeTenant<number, ImportError[]>("getImportErrors", request, signal);
}

export function getImportErrorsFr(request: { tenantId: number; data: number; }, signal?: AbortSignal) : Promise<ImportError[]> {
  return invokeTenant<number, ImportError[]>("getImportErrorsFr", request, signal);
}

export function getAllReports(request: { tenantId: number; data: DeclarationType; }, signal?: AbortSignal) : Promise<ReportItem[]> {
  return invokeTenant<DeclarationType, ReportItem[]>("getAllReports", request, signal);
}

export function markAsReported(request: { tenantId: number; data: ReportRequest; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<ReportRequest, void>("markAsReported", request, signal);
}

export function getAllSuppliers(request: { tenantId: number; data: number; }, signal?: AbortSignal) : Promise<Record<string, string>> {
  return invokeTenant<number, Record<string, string>>("getAllSuppliers", request, signal);
}

export function getAllBranches(request: { tenantId: number; data: void; }, signal?: AbortSignal) : Promise<Record<string, Branch>> {
  return invokeTenant<void, Record<string, Branch>>("getAllBranches", request, signal);
}

export function upsertBranch(request: { tenantId: number; data: Branch; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<Branch, void>("upsertBranch", request, signal);
}

export function deleteBranch(request: { tenantId: number; data: string; }, signal?: AbortSignal) : Promise<void> {
  return invokeTenant<string, void>("deleteBranch", request, signal);
}
