{
  "footer" : {
    "address" : "SynoptiCons Deutschland GmbH - Austraße 34 - 35745 Herborn - Allemagne"
  },
  "InitiateAuth" : {
    "title" : " Se connecter à Calculate ",
    "description" : "La solution complète pour remplir les obligations des entreprises en matière de REP",
    "username" : "Nom d'utilisateur",
    "password" : "Mot de passe",
    "signIn" : "Connexion",
    "forgotPassword" : "Mot de passe oublié ?",
    "signInWithGoogle" : "Se connecter avec Google"
  },
  "ForgotPassword" : {
    "title" : "Réinitialiser le mot de passe",
    "description" : "Veuillez entrer votre nom d'utilisateur et demander un code pour la réinitialisation du mot de passe.",
    "username" : "Nom d'utilisateur",
    "getCode" : "Obtenir le code pour la réinitialisation du mot de passe"
  },
  "ConfirmForgotPassword" : {
    "title" : " Réinitialisation du mot de passe ",
    "description" : "Un code de confirmation vous a été envoyé. Veuillez saisir le code de vérification et votre nouveau mot de passe.",
    "code" : "Code",
    "newPassword" : "Nouveau mot de passe",
    "setPassword" : "Définir le mot de passe"
  },
  "NewPasswordRequiredChallenge" : {
    "title" : "Nouveau mot de passe requis",
    "description" : "Un nouveau mot de passe est requis. Veuillez saisir votre nouveau mot de passe.",
    "newPassword" : "Nouveau mot de passe",
    "setPassword" : "Définir le mot de passe"
  },
  "SmsMfaChallenge" : {
    "title" : "Entrez le code de vérification",
    "description" : "Un code de vérification a été envoyé à {{codeDeliveryDestination}}. Veuillez entrer le code pour continuer.",
    "code" : "Code",
    "verify" : "Verification"
  },
  "AuthErrorView" : {
    "NotAuthorized" : "Erreur de connexion",
    "InvalidPassword" : "Le nouveau mot de passe ne respecte pas la politique en matière de mots de passe.",
    "CodeMismatch" : "Le code de vérification est incorrect.",
    "UserNotFound" : "Compte non trouvé.",
    "ExpiredCode" : "Le code de vérification n'est plus valide.",
    "LimitExceeded": "Limite dépassée. Veuillez réessayer plus tard."
  },
  "LogOutButton" : {
    "label" : "Se déconnecter"
  },
  "passwordPolicy" : "Au moins 8 caractères, au moins un chiffre, un caractère spécial, une lettre majuscule, une lettre minuscule et aucun espace."
}