﻿import { Navigate, Outlet, RouteObject, useOutletContext } from 'react-router';
import { DeclarationResultGermany, getPeriods, Period, tryGetDeclarationGermany } from '../../../api';
import { createRoute } from './create/CreateStep';
import { issuesRoute } from './issues/IssuesStep';
import { adjustStepRoute as adjustRoute } from './adjust/AdjustStep';
import { reportRoute } from './report/ReportStep';
import { useTenant } from '../../tenants/TenantDetail';
import { expectNever } from '../../../helpers';
import { cache } from '../../../shared/hooks/useQuery';
import { usePolling } from '../../../shared/hooks/usePolling';
import { Steps } from './Steps';
import { Suspense } from 'react';
import { Fallback } from '../../../Fallback';
import { licenseRoute } from './license/LicenseStep';
import { finishRoute } from './finish/FinishStep';
import { useTenantQuery } from '../../../shared/hooks/useTenantQuery';
import { useTenantRevalidate } from '../../../shared/hooks/useTenantRevalidate';
import { useQueryValue } from '../../../shared/hooks/useQueryValue';
import { PeriodNavigation } from '../PeriodNavigation';
import { useExtendedDeclaration } from './useExtendedDeclaration';
import { usePeriodParam, useYearParam } from '../../../shared/hooks/useParam';
import { reviewRoutes } from './review/ReviewStep';
import { thirdPartyLicensingRoute } from './thirdPartyLicensing/ThirdPartLicensingStep';
import { ThirdPartyLicensing } from './thirdPartyLicensing/ThirdPartyLicensing';
import { Reports } from '../Reports';

function NavigateToInitialStep() {
  const declaration = useOutletContext<DeclarationResultGermany>();
  const tenant = useTenant();

  function getRedirectTo() {
    if (!tenant.permissions.central) {
      return './adjust';
    }

    if (declaration === null) {
      return './create';
    }

    if (declaration.kind === 'Preview') {
      if (declaration.errors.length > 0) {
        return './issues';
      }

      return './review';
    }

    if (declaration.kind === 'Release') {
      return './license';
    }

    expectNever(declaration.kind);
  }

  return <Navigate to={getRedirectTo()} replace />;
}

export const declarationDetailRoute: RouteObject = {
  path: ':period',
  element: <DeclarationDetail />,
  children: [
    { index: true, element: <NavigateToInitialStep /> },
    createRoute,
    issuesRoute,
    thirdPartyLicensingRoute,
    ...reviewRoutes,
    adjustRoute,
    reportRoute,
    licenseRoute,
    finishRoute,
  ],
  loader: ({ params }) => {
    const tenantId = parseInt(params.tenantId || '');
    const year = parseInt(params.year || '');
    const period = params.period as Period;
    const resource = cache.getResource(tryGetDeclarationGermany, { tenantId, data: { year, period } });
    if (resource.state.status !== 'pending' && resource.state.status !== 'revalidating') {
      resource.revalidate(false).then();
    }
  },
};

export function DeclarationDetail() {
  const [year, period] = [useYearParam(), usePeriodParam()];

  const revalidatePeriods = useTenantRevalidate(getPeriods, { country: 'Germany', year });
  const declarationQuery = useTenantQuery(tryGetDeclarationGermany, { year, period }, false);
  const declarationValue = useQueryValue(declarationQuery);

  async function revalidate() {
    await Promise.all([revalidatePeriods(), declarationQuery.revalidate()]);
  }

  usePolling(declarationValue, revalidate);

  let declaration =
    declarationValue !== 'Running' && declarationValue.Case !== 'Failed' ? declarationValue.Fields[0] : null;

  declaration = useExtendedDeclaration(year, period, declaration);

  return (
    <Reports country="Germany" year={year} period={period}>
      <ThirdPartyLicensing year={year} period={period}>
        <div className="row mx-4 align-items-start">
          <div className="col-2 list-group list-group-flush sticky-top">
            <PeriodNavigation year={year} />
            <Steps declaration={declaration} year={year} period={period} />
          </div>
          <div className="col-10">
            <Suspense fallback={<Fallback />}>
              <Outlet context={declaration} />
            </Suspense>
          </div>
        </div>
      </ThirdPartyLicensing>
    </Reports>
  );
}
