import { getPlacings, PlacingData, upsertPlacing } from '../../../../../api';
import { useTranslation } from 'react-i18next';
import { useForm } from '../../../../../forms';
import { placingForm } from './form';
import { useTenantMutation } from '../../../../../remote';
import { Icon } from '../../../../../shared/components/Icon';
import { Modal } from '../../../../../shared/components/Modal';
import { PlacingForm } from './PlacingForm';
import { RouteObject, useNavigate, useOutletContext } from 'react-router';
import { useTenantRevalidate } from '../../../../../shared/hooks/useTenantRevalidate';
import { useStringParams, useYearParam } from '../../../../../shared/hooks/useParam';

export const editModalRoute: RouteObject = {
  path: 'edit/:placingId',
  element: <EditModal />,
};

export function EditModal() {
  const { t } = useTranslation('placings');

  const year = useYearParam();
  const { placingId } = useStringParams<'placingId'>();

  const placings = useOutletContext<Record<string, PlacingData>>();

  const data = placings[placingId];

  const form = useForm(placingForm, data);

  const mutation = useTenantMutation(upsertPlacing);

  const revalidate = useTenantRevalidate(getPlacings, year);

  const navigate = useNavigate();

  function onClose() {
    navigate('..');
  }

  function onSave() {
    if (form.status.valid) {
      mutation.start(
        { year, placingId, data: { ...data, ...form.status.value } },
        {
          async onSuccess() {
            await revalidate();
            onClose();
          },
        }
      );
    }
  }

  const footer = (
    <>
      <button
        disabled={mutation.isPending || !form.status.valid}
        className="btn btn-primary text-white"
        onClick={onSave}>
        <Icon name="check-lg" /> {t('editButton.save')}
      </button>
    </>
  );

  return (
    <Modal visible={true} onClose={onClose} title={data.description} footer={footer} size="lg">
      <PlacingForm disabled={mutation.isPending} field={form} />
    </Modal>
  );
}
