import { DeclarationResultGermany, Period } from '../../../../api';
import { useContractFilterFunctions } from '../useContractFilterFunctions';
import { isProducer } from '../helpers';
import { useAccessibleProducers } from '../../../producers/useAccessibleProducers';

/**
 * Returns the list of IDs of the producers that have to report in the specified period.
 * @param period The period.
 * @param declaration The declaration.
 * @param includeUnclearCases Should we include the cases where we don't have enough information if reporting is required.
 */
export function useProducersThatHaveToReport(
  period: Period,
  declaration: DeclarationResultGermany,
  includeUnclearCases: boolean
) {
  const { hasContractForPeriod } = useContractFilterFunctions(period);

  const producerIds = new Set<string>();

  // Add all producers that have to report from the declaration
  for (const responsibility of declaration.responsibilities) {
    if (responsibility.reporting != null && isProducer(responsibility.reporting)) {
      const reportingProducerId = responsibility.reporting.Fields[0];
      // We can only clearly decide if a producer has to report in the specified period
      // by having a look at the contract of the licensing responsible.
      if (responsibility.licensing != null && isProducer(responsibility.licensing)) {
        const licensingProducerId = responsibility.licensing.Fields[0];
        if (hasContractForPeriod(licensingProducerId)) {
          producerIds.add(reportingProducerId);
        }
      }
      if (includeUnclearCases) {
        producerIds.add(reportingProducerId);
      }
    }
  }

  return useAccessibleProducers(Array.from(producerIds.values()));
}
