{
  "contractRow": {
    "noContracts": "Kein Vertrag mit Systembetreiber"
  },
  "contractsTable": {
    "producer": "Inverkehrbringer",
    "systemProvider": "Systembetreiber",
    "interval": "Abgabeinterval"
  },
  "contractForm": {
    "producer": "Inverkehrbringer",
    "systemProvider": "Systembetreiber",
    "fees": "Preise",
    "deadlines": "Fristen",
    "annualReportingDueBy": "Abgabe der Jahresabschlussmeldung bis",
    "periodicalReportingDueBy": "Abgabe der unterjährigen Meldungen bis",
    "daysAfterEndOfPeriod": "Tage nach Abschluss des Zeitraums",
    "declarationPeriod": "Abgabeinterval",
    "declarationPeriodDescription": "Abgabeinterval der unterjährigen Meldungen"
  },
  "editButton": {
    "save": "Speichern",
    "delete": "Löschen",
    "contractWithSystemProvider": "Vertrag mit Systembetreiber",
    "confirmDeletion": "Wollen Sie diesen Vertrag wirklich löschen?"
  }
}