{
  "global": {
    "yes": "Yes",
    "no":  "No",
    "languages": {
      "en": "English",
      "de": "German",
      "fr": "French"
    },
    "fractions": {
      "de": {
        "glass": "Glass",
        "fiber": "Fiber",
        "ferrousMetal": "Ferrous metal",
        "aluminum": "Aluminum",
        "plastic": "Plastic",
        "beverageCarton": "Beverage carton",
        "other": "Other",
        "otherComposite": "Other composite"
      },
      "at": {
        "fiberHouseHold": "Paper household",
        "glassHouseHold": "Glass",
        "ferrousMetalHouseHold": "Ferrous metals household",
        "aluminumHouseHold": "Aluminum household",
        "plasticHouseHold": "Plastic household",
        "beverageCartonHouseHold": "Beverage carton household",
        "compositeHouseHold": "Other composite household excluding beverage carton",
        "ceramicHouseHold": "Ceramic household",
        "woodHouseHold": "Wood household",
        "textileHouseHold": "Textile household",
        "organicHouseHold": "Biogenic packaging household",
        "fiberCommercial": "Paper commercial",
        "ferrousMetalCommercial": "Ferrous metal commercial",
        "aluminumCommercial": "Aluminum commercial",
        "foilsCommercial": "Foils commercial, strapping and duct tape made from plastic",
        "hollowBodiesCommercial": "Hollow bodies commercial",
        "epsCommercial": "EPS commercial",
        "woodCommercial": "Wood commercial",
        "compositeCommercial": "Other composite commercial",
        "ceramicCommercial": "Ceramic commercial",
        "textileCommercial": "Textile commercial",
        "organicCommercial": "Biogenic packaging commercial"
      }
    },
    "error": {
      "errorTitle": "Oops, an error occurred",
      "errorSubtitle": "We are already working on a solution. Please try again shortly. <br/>\nIf the error persists don't hesitate to contact us.",
      "errorOfflineTitle": "No internet connection",
      "errorOfflineSubtitle": "We could not proceed because there is no internet connection.",
      "errorForbidden": "You do not have the necessary permissions to perform this action.",
      "errorUnexpected": "The action could not be performed. An unexpected error occured.",
      "retry": "Try again",
      "goBack": "Go back"
    },
    "forbidden": {
      "forbiddenTitle": "Missing permissions",
      "forbiddenSubtitle": "You don't have the necessary permissions to perform this action."
    },
    "notFound": {
      "notFoundTitle": "Not found",
      "notFoundSubtitle": "The requested information could not be found."
    },
    "month": "Month",
    "quarter": "Quarter",
    "halfYear": "Half Year",
    "year": "Year",
    "endOfMonth": "end of month",
    "endOfQuarter": "end of quarter",
    "endOfHalfYear": "end of half-year",
    "salesPackagings": "Sales packaging",
    "servicePackagings": "Service packaging",
    "search": "Search",
    "periods": {
      "q1": "1st Quarter",
      "q2": "2nd Quarter",
      "q3": "3rd Quarter",
      "q4": "4th Quarter",
      "year": "Year",
      "firstHalfOfYear": "1st Half-Year",
      "secondHalfOfYear": "2nd Half-Year",
      "jan": "January",
      "feb": "February",
      "mar": "March",
      "apr": "April",
      "may": "May",
      "jun": "June",
      "jul": "July",
      "aug": "August",
      "sep": "September",
      "oct": "October",
      "nov": "November",
      "dec": "December"
    },
    "shortPeriod": {
      "Q1": "Q1",
      "Q2": "Q2",
      "Q3": "Q3",
      "Q4": "Q4",
      "Year": "Year",
      "FirstHalfYear": "H1",
      "SecondHalfYear": "H2",
      "Jan": "Jan",
      "Feb": "Feb",
      "Mar": "Mar",
      "Apr": "Apr",
      "May": "May",
      "Jun": "Jun",
      "Jul": "Jul",
      "Aug": "Aug",
      "Sep": "Sep",
      "Oct": "Oct",
      "Nov": "Nov",
      "Dec": "Dec"
    },
    "declarationIntervals": {
      "monthly": "Monthly",
      "quarterly": "Quarterly",
      "biYearly": "Bi-Yearly (Half year/ Whole year)",
      "yearly": "No declarations during the year"
    },
    "movementTypes": {
      "purchase": "Goods in",
      "sale": "Goods out",
      "dropshipping": "Drop-shipping"
    },
    "months": {
      "jan": "Jan",
      "feb": "Feb",
      "mar": "Mar",
      "apr": "Apr",
      "may": "May",
      "jun": "Jun",
      "jul": "Jul",
      "aug": "Aug",
      "sep": "Sep",
      "oct": "Oct",
      "nov": "Nov",
      "dec": "Dec"
    },
    "countries": {
      "Germany": "Germany",
      "Poland": "Poland",
      "France": "France",
      "Austria": "Austria",
      "CzechRepublic": "Czech Republic",
      "Belgium": "Belgium",
      "Luxembourg": "Luxembourg",
      "BosniaAndHerzegovina": "Bosnia-Herzegovina",
      "Bulgaria": "Bulgaria",
      "China": "China",
      "Denmark": "Denmark",
      "Finland": "Finland",
      "Greece": "Greece",
      "UnitedKingdom": "United Kingdom",
      "HongKong": "Hong Kong",
      "Indonesia": "Indonesia",
      "Ireland": "Ireland",
      "Italy": "Italy",
      "Canada": "Canada",
      "Croatia": "Croatia",
      "Latvia": "Latvia",
      "Netherlands": "Netherlands",
      "Norway": "Norway",
      "Sweden": "Sweden",
      "Switzerland": "Switzerland",
      "Serbia": "Serbia",
      "Singapore": "Singapore",
      "Slovakia": "Slovakia",
      "Slovenia": "Slovenia",
      "Spain": "Spain",
      "Turkey": "Turkey",
      "Hungary": "Hungary",
      "USA": "USA",
      "Unknown": "Unknown"
    },
    "locales": {
      "DE_de": "German",
      "EN_us": "English",
      "FR_fr": "French"
    },
    "packagingTypes": {
      "salesPackaging": "Sales packaging",
      "servicePackaging": "Service packaging",
      "outerPackaging": "Outer packaging",
      "shippingPackaging": "Shipping packaging",
      "transportPackaging": "Transport packaging"
    },
    "forms": {
      "errors": {
        "required": "Input is required",
        "invalid": "Input is invalid",
        "alreadyExists": "Entry with same ID exists already"
      }
    },
    "supplier": "Supplier",
    "customer": "Customer",
    "customers": "Customers",  
    "footer": {
      "privacy": "Privacy Policy",
      "imprint": "Legal Notice",
      "support": "Help & Support",
      "copyright": "©SynoptiCons",
      "supportModal": {
        "message": "If you have any questions, concerns or feedback about Calculate, please don't hesitate to reach out to us: <br />"
      }
    }
  }
}