import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';

type FooterLink = {
  name: string;
  url: string;
};

function LoginFooter({ links }: { links: FooterLink[] }) {
  const { t } = useTranslation('global');

  const logoUrl = new URL('../../../assets/synopticons.png', import.meta.url);

  return (
    <footer className="container-fluid text-center py-2 ps-4 pe-3" style={{ background: 'rgba(255, 255, 255, 0.3)' }}>
      <div className="row justify-content-center justify-content-lg-end align-items-center">
        <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center gap-5 py-2">
          {links.map(({ name, url }, index) => (
            <div key={index} className="fw-semibold">
              <a
                href={url}
                className="text-decoration-none text-nowrap text-black fs-14"
                target="_blank"
                rel="noreferrer">
                {t(name)}
              </a>
            </div>
          ))}
          <HelpDialog linkCssClasses={['text-black', 'text-nowrap']} />
          <div className="fw-semibold text-black fs-14">{t('footer.copyright')}</div>
        </div>
        <div className="d-none d-lg-flex col-lg-4 justify-content-end">
          <img height={40} src={logoUrl.toString()} alt="logo" />
        </div>
      </div>
    </footer>
  );
}

function MutedFooter({ links }: { links: FooterLink[] }) {
  const { t } = useTranslation('global');

  return (
    <footer className="d-flex align-items-center mt-3 py-3 ps-4 pe-3 bg-light">
      <div className="d-flex flex-wrap w-100 gap-5 justify-content-start align-items-center">
        {links.map(({ name, url }, index) => (
          <div key={index} className="fw-semibold">
            <a href={url} className="text-decoration-none text-muted fs-14" target="_blank" rel="noreferrer">
              {t(name)}
            </a>
          </div>
        ))}
        <HelpDialog linkCssClasses={['text-muted']} />
        <div className="fw-semibold text-muted fs-14">{t('footer.copyright')}</div>
      </div>
    </footer>
  );
}

export function Footer({ muted }: { muted?: boolean }) {
  const footerLinks: FooterLink[] = [
    { name: 'footer.privacy', url: 'https://www.synopticons.com/privacy-policy/' },
    { name: 'footer.imprint', url: 'https://www.synopticons.com/impressum/' },
  ];

  return muted ? <MutedFooter links={footerLinks} /> : <LoginFooter links={footerLinks} />;
}

function HelpDialog({ linkCssClasses }: { linkCssClasses: string[] }) {
  const { t } = useTranslation('global');
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <a
        role="button"
        className={`text-decoration-none fw-semibold fs-14 ${linkCssClasses.join(' ')}`}
        onClick={() => handleShow()}>
        {t('footer.support')}
      </a>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('footer.support')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <Trans t={t}>{t('footer.supportModal.message')}</Trans>
          </p>
          <a href="mailto:support@calculate.eco" target="_blank" rel="noreferrer">
            support@calculate.eco
          </a>
        </Modal.Body>
      </Modal>
    </>
  );
}
