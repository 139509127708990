import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { DeclarationResultFrance, LongRunningTask } from '../../../../api';
import { Trans, useTranslation } from 'react-i18next';
import { expectNever } from '../../../../helpers';

interface Props {
  task: LongRunningTask<DeclarationResultFrance, [number, string]> | null;
}

export function ImportInfoBox({ task }: Props) {
  const { t } = useTranslation('reports');

  if (task == null || task === 'Running') {
    return (
      <InfoScreen color="info" icon="info-circle-fill" bgColor="info" title={t('importStep.noDataTitle')}>
        <Trans t={t}>{t('importStep.noData')}</Trans>
      </InfoScreen>
    );
  }

  if (task.Case === 'Failed' || (task.Case === 'Finished' && task.Fields[0].failedImportId !== null)) {
    return (
      <>
        <InfoScreen color="danger" icon="x-circle-fill" bgColor="danger" title={t('importStep.failedTitle')}>
          <Trans t={t}>{t('importStep.failed')}</Trans>
        </InfoScreen>
      </>
    );
  }

  if (task.Case === 'Finished') {
    return (
      <InfoScreen color="success" icon="check-circle-fill" bgColor="success" title={t('importStep.successTitle')}>
        <Trans t={t}>{t('importStep.success')}</Trans>
      </InfoScreen>
    );
  }

  expectNever(task);
}
