import { Navigate, Outlet, RouteObject, useParams } from 'react-router';
import { getInitialPeriod } from './helpers';
import { Suspense } from 'react';
import { Fallback } from '../../../Fallback';
import { declarationDetailRoute } from './DeclarationDetail';
import { Suppliers } from '../../suppliers/suppliers';
import { useContracts } from '../../contracts/Contracts';
import { useYearParam } from '../../../shared/hooks/useParam';

function NavigateToInitialPeriod() {
  const { year } = useParams<{ year: string }>();
  const contracts = useContracts();

  const intervals = Object.values(contracts.all).map(contract => contract.interval);

  return <Navigate to={`./${getInitialPeriod(parseInt(year ?? ''), intervals)}`} replace />;
}

export const declarationIndexRoute: RouteObject = {
  path: 'declarations',
  element: <DeclarationIndex />,
  children: [{ index: true, element: <NavigateToInitialPeriod /> }, declarationDetailRoute],
};

export function DeclarationIndex() {
  const year = useYearParam();

  return (
    <>
      <Suspense fallback={<Fallback />}>
        <Suppliers year={year}>
          <Outlet />
        </Suppliers>
      </Suspense>
    </>
  );
}
