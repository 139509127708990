import { InfoScreen } from '../../shared/components/InfoScreen';
import { useTranslation } from 'react-i18next';

export function NoResultItem() {
  const { t } = useTranslation('tenants');
  return (
    <div className="list-group-item">
      <InfoScreen title={t('noResult.title')} icon="search">
        <p className="text-muted">{t('noResult.description')}</p>
      </InfoScreen>
    </div>
  );
}
