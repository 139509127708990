import { useReports } from '../Reports';
import { ReportItem, ReportType } from '../../../api';
import { Modal } from '../../../shared/components/Modal';
import { booleanParam, useSearchParams } from '../../../shared/hooks/useSearchParams';
import { useTenant } from '../../tenants/TenantDetail';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

const timestampFormat = Intl.DateTimeFormat(undefined, { dateStyle: 'medium', timeStyle: 'short' });

export interface Props {
  producerId: string;
  type: ReportType;
  renderDownloadButton: (declarationId: number) => ReactNode;
}

/**
 * Shows a history of all declaration reports to LUCID or PRO.
 * As a central user, there is also a button to download the export for the auditor.
 */
export function ReportHistory({ producerId, type, renderDownloadButton }: Props) {
  const { t } = useTranslation('reports');

  const [showHistory, setShowHistory] = useSearchParams('history', booleanParam(false));

  const reports = useReports();

  const tenant = useTenant();

  const selectDate = type === 'Lucid' ? (r: ReportItem) => r.lucidReportedAt : (r: ReportItem) => r.proReportedAt;

  const reportsOfProducer = reports.all
    .filter(r => r.producerId === producerId)
    .map(r => ({ declarationId: r.declarationId, date: selectDate(r) }))
    .filter((r): r is { declarationId: number; date: string } => r.date != null);

  // We don't show anything if there are no entries in the history
  if (reportsOfProducer.length === 0) {
    return <></>;
  }

  reportsOfProducer.sort((a, b) => (a.date < b.date ? 1 : a.date > b.date ? -1 : 0));

  return (
    <>
      <button className="btn btn-sm btn-outline-secondary" onClick={() => setShowHistory(true)}>
        {t('reportHistory.showButtonTitle')}
      </button>
      <Modal
        title={t('reportHistory.modalTitle')}
        size="lg"
        visible={showHistory}
        onClose={() => setShowHistory(false)}>
        <ul className="list-group list-group-flush">
          {reportsOfProducer.map(r => (
            <li className="list-group-item d-flex flex-row align-items-center" key={r.declarationId}>
              <div className="flex-grow-1">{timestampFormat.format(new Date(r.date))}</div>
              {tenant.permissions.central && (
                <div className="text-end text-nowrap">{renderDownloadButton(r.declarationId)}</div>
              )}
            </li>
          ))}
        </ul>
      </Modal>
    </>
  );
}
