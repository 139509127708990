﻿import { Trans, useTranslation } from 'react-i18next';
import { Navigate, RouteObject, useOutletContext } from 'react-router';
import { ImportButton } from './ImportButton';
import { ImportInfoBox } from './ImportInfoBox';
import { DeclarationResultFrance, LongRunningTask, tryGetDeclarationFrance } from '../../../../api';
import { useTenantQuery } from '../../../../shared/hooks/useTenantQuery';
import { ImportErrors } from './ImportErrors';
import { PollingScreen } from '../../../../shared/components/PollingScreen';
import { Downloads } from './Downloads';
import { expectNever } from '../../../../helpers';
import { useYearParam } from '../../../../shared/hooks/useParam';

export const importRoute: RouteObject = {
  path: 'import',
  element: <ImportStep />,
};

export function ImportStep() {
  const { t } = useTranslation('reports');

  const year = useYearParam();

  const taskQuery = useTenantQuery(tryGetDeclarationFrance, year, false);
  const task = useOutletContext<LongRunningTask<DeclarationResultFrance, [number, string]> | null>();

  if (task === 'Running') {
    return (
      <PollingScreen title={t('createStep.pollingTitle')}>
        <Trans t={t}>{t('createStep.csuPolling')}</Trans>
      </PollingScreen>
    );
  }

  if (task !== null && task.Case !== 'Failed' && task.Case === 'Finished') {
    const kind = task.Fields[0].kind;

    if (year === 2022) {
      if (kind === 'Preview') {
        return <Navigate replace to="../validate" />;
      }

      if (kind === 'Release') {
        return <Navigate replace to="../finish" />;
      }

      expectNever(kind);
    }
  }

  async function onImport() {
    taskQuery.setValue('Running');
  }

  return (
    <>
      <ImportInfoBox task={task} />
      <div className="card mx-4 mb-3 p-4">
        <div className="card-body">
          <div className="d-flex justify-content-center">
            <div className="text-center">
              <ImportButton callback={onImport} />
              <Downloads />
            </div>
          </div>
        </div>
      </div>
      {task && task.Case === 'Failed' && <ImportErrors importId={task.Fields[0][0]} limit={30} />}
      {task && task.Case === 'Finished' && task.Fields[0].failedImportId !== null && (
        <ImportErrors importId={task.Fields[0].failedImportId} limit={30} />
      )}
    </>
  );
}
