﻿import { group, numberField, textField } from '../../../../../forms';
import { periodField } from '../../../../../shared/components/PeriodInput';
import { packagingTypeField } from '../../../../../shared/components/PackagingTypeInput';

export const placingForm = group({
  description: textField,
  period: periodField,
  packagingType: packagingTypeField,
  weights: group({
    glass: numberField,
    fiber: numberField,
    ferrousMetal: numberField,
    aluminum: numberField,
    plastic: numberField,
    beverageCarton: numberField,
    otherComposite: numberField,
    other: numberField,
  }),
});
