{
  "producersTable": {
    "name": "Name",
    "id": "ID",
    "lucidNumber": "LUCID",
    "contact": "Kontakt",
    "address": "Adresse (Land)",
    "active": "Aktiv"
  },
  "producerForm": {
    "name": "Name",
    "id": "ID",
    "lucidNumber": "LUCID",
    "active": "Aktiv",
    "contact": {
      "name": "Name des Kontakts",
      "email": "E-Mail",
      "phone": "Telefon",
      "language": "Sprache"
    },
    "address": {
      "street": "Straße",
      "postCode": "PLZ",
      "city": "Stadt",
      "country": "Land"
    }
  },
  "producerModal": {
    "title": "Neuer Inverkehrbringer",
    "delete": "Löschen",
    "save": "Speichern",
    "confirmDeletion": "Wollen Sie den Inverkehrbringer wirklich löschen?"
  },
  "newProducer": "Neuer Inverkehrbringer"
}