﻿import { Outlet, RouteObject } from 'react-router';
import { contractEditRoute } from './ContractEdit';
import { ContractsTable } from '../ContractsTable';
import { useYearParam } from '../../../shared/hooks/useParam';

export const contractIndexRoute: RouteObject = {
  path: 'contracts',
  element: <ContractIndex />,
  children: [contractEditRoute],
};

export function ContractIndex() {
  const year = useYearParam();

  return (
    <div className="mx-4">
      <ContractsTable year={year} />
      <Outlet />
    </div>
  );
}
