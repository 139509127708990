import { AuthError } from '../../api';
import { useTranslation } from 'react-i18next';

export function AuthErrorView({ error }: { error: AuthError }) {
  const { t } = useTranslation('auth');
  const message = t('AuthErrorView.' + error);
  return (
    <div className="alert alert-danger" role="alert">
      {message}
    </div>
  );
}
