﻿import { Icon } from '../../shared/components/Icon';
import { NavLink } from 'react-router-dom';
import * as React from 'react';
import { ContractValue } from '../../api';

export interface Props {
  producerId: string;
  value: ContractValue | null;
  children?: React.ReactNode;
  pathPrefix?: string;
}

export function EditButton({ producerId, value, children, pathPrefix }: Props) {
  pathPrefix = pathPrefix !== undefined ? `./${pathPrefix}` : '.';

  return (
    <>
      <NavLink
        to={{ pathname: `${pathPrefix}/edit/${producerId}`, search: location.search }}
        className="btn btn-outline-secondary">
        <Icon name={value ? 'pencil' : 'plus-lg'} /> {children}
      </NavLink>
    </>
  );
}
