import { NavLink } from 'react-router-dom';
import { Tenant, trackTenantAction } from '../../api';
import { Icon } from '../../shared/components/Icon';
import { useModifiedPathname } from '../../shared/hooks/useModifiedPathname';

export interface Props {
  tenant: Tenant;
  country: string;
  isLekoUser: boolean;
}

export function TenantItem({ tenant, country, isLekoUser }: Props) {
  const toEdit = useModifiedPathname(`./edit/${tenant.id}`);

  function onLinkClick() {
    trackTenantAction({ tenantId: tenant.id, data: void 0 });
  }

  return (
    <div className="list-group-item d-flex align-items-center justify-content-between">
      <NavLink to={`./${tenant.id}/${country}`} className="text-decoration-none" onClick={onLinkClick}>
        {tenant.name}
      </NavLink>
      {isLekoUser && tenant.permissions.central && (
        <div className="ps-2">
          <NavLink className="btn btn-outline-secondary" to={toEdit}>
            <Icon name="pencil" />
          </NavLink>
        </div>
      )}
    </div>
  );
}
