import { useTenant } from '../../private/tenants/TenantDetail';
import { cache, Loader } from './useQuery';

export function useTenantRevalidate<TRequest, TResponse>(
  loader: Loader<{ tenantId: number; data: TRequest }, TResponse>,
  request: TRequest
) {
  const tenant = useTenant();
  const resource = cache.getResource(loader, { tenantId: tenant.id, data: request });

  return () => {
    return resource.revalidate(false);
  };
}
