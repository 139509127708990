import { StateOf, textField, optionalTextField } from '../../forms';
import { TextInput } from './TextInput';
import { ColSize } from './Col';
import { Field } from './Field';

export interface Props {
  id: string;
  label: string;
  field: StateOf<typeof textField> | StateOf<typeof optionalTextField>;
  focusOnMount?: boolean;
  disabled?: boolean;
  maxLength?: number;
  size?: ColSize;
  type?: 'text' | 'password';
  autoComplete?: string;
  hideErrorUntilTouched?: boolean;
}

export function TextField(props: Props) {
  return (
    <Field size={props.size}>
      <label className="form-label" htmlFor={props.id}>
        {props.label}
      </label>
      <TextInput
        id={props.id}
        focusOnMount={props.focusOnMount}
        disabled={props.disabled}
        field={props.field}
        maxLength={props.maxLength}
        type={props.type}
        autoComplete={props.autoComplete}
        hideErrorUntilTouched={props.hideErrorUntilTouched}
      />
    </Field>
  );
}
