{
  "overview": {
    "heading": "Inverkehrbringer",
    "hint": "Wählen Sie einen vorhandenen Inverkehrbringer oder fügen Sie einen neuen hinzu, um eine Meldung zu erstellen."
  },
  "navigation": {
    "declarations": "Meldungen",
    "manageContracts": "Verträge",
    "manageBranches": "Niederlassungen",
    "manageProducers": "Inverkehrbringer"
  },
  "import": {
    "imports": "Importe",
    "refresh": "Aktualisieren",
    "startedOn": "gestartet am",
    "statedAt": "um",
    "finishedAt": "abgeschlossen um",
    "success": "Erfolgreich",
    "failure": "Fehlgeschlagen",
    "inProgress": "In Arbeit",
    "uploadFailedForFile": "Die Datei konnte nicht hochgeladen werden.",
    "uploadFailedForFiles": "Die Dateien konnten nicht hochgeladen werden.",
    "uploadFailedForTheseFiles": "Die folgenden Dateien konnten nicht hochgeladen werden:\n {{files}}",
    "importStarts": "Die Dateien wurden hochgeladen. Der Import beginnt jetzt, dies kann ein paar Minuten dauern.",
    "uploadInProgress": "Wird hochgeladen...",
    "buttonText": "Importieren"
  },
  "tenantModal": {
    "add": "Inverkehrbringer hinzufügen",
    "title": "Neuer Inverkehrbringer",
    "delete": "Löschen",
    "save": "Speichen",
    "confirmDeletion": "Wollen Sie den Inverkehrbringer wirklich löschen?"
  },
  "form": {
    "name": "Name"
  },
  "noResult": {
    "title": "Keine Inverkehrbringer gefunden",
    "description": "Passen Sie bitte Ihre Suchkriterien an oder fügen Sie einn Inverkehrbringer hinzu."
  },
  "showMore": "Mehr anzeigen",
  "switchTenant": "Alle Inverkehrbringer",
  "moreInformation": {
    "buttonLabel": "Mehr Informationen",
    "modalTitle": "Informationen",
    "contentTitle": "Fahren Sie wie folgt fort, um eine Meldung zu erstellen:",
    "contentBody": "<ol><li><strong>Wählen</strong> Sie einen <strong>vorhandenen Inverkehrbringer</strong>.<br>Ist der gesuchte Inverkehrbringer nicht vorhanden, <strong>fügen Sie einen neuen Inverkehrbringer hinzu</strong>, mit einem Namen, den Sie sich gut merken können (z.B. Name des Unternehmen)</i>.</li><li><strong>Öffnen Sie</strong> den neuen <strong>Inverkehrbringer</strong> in dem Sie auf den Namen klicken.</li><li><strong>Erstellen</strong> Sie eine <strong>Meldung</strong> in dem Sie den Anweisungen in der nächsten Ansicht folgen.</li><li><strong>Weitere Meldungen</strong> können erstellt werden, in dem <strong>weitere Inverkehrbringer</strong> angelegt werden. Für jeden Inverkehrbringer ist ein separater Datenimport erforderlich und so kann ein anderes Ergebnis erzielt werden.</li></ol>"
  },
  "utmPrefixModal": {
    "title": "Zeitraum festlegen",
    "description": "Geben Sie bitte den Zeitraum an, für den die Daten importiert werden sollen.",
    "importButtonLabel": "Importieren",
    "choose": "Bitte wählen",
    "wholeYear": "Ganzes Jahr",
    "warnWholeYear": "Stellen Sie bitte sicher, dass die Absatzzahlen für alle Monate importiert wurden, bevor Sie Absatzzahlen für das ganze Jahr importieren."
  },
  "countrySwitch": {
    "countries": {
      "de": "Deutschland",
      "fr": "Frankreich",
      "at": "Österreich"
    }
  }
}