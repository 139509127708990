﻿import { weightFormat } from '../../../helpers';
import { AggregatedPackagingWeightsAustria } from '../../../api';

export function WeightsRowCells(props: { weights: AggregatedPackagingWeightsAustria }) {
  return (
    <>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.fiberHouseHold)} kg</td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.glassHouseHold)} kg</td>
      <td className="align-middle text-end text-nowrap">
        {weightFormat.format(props.weights.ferrousMetalHouseHold)} kg
      </td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.aluminumHouseHold)} kg</td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.plasticHouseHold)} kg</td>
      <td className="align-middle text-end text-nowrap">
        {weightFormat.format(props.weights.beverageCartonHouseHold)} kg
      </td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.compositeHouseHold)} kg</td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.ceramicHouseHold)} kg</td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.woodHouseHold)} kg</td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.textileHouseHold)} kg</td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.organicHouseHold)} kg</td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.fiberCommercial)} kg</td>
      <td className="align-middle text-end text-nowrap">
        {weightFormat.format(props.weights.ferrousMetalCommercial)} kg
      </td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.aluminumCommercial)} kg</td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.foilsCommercial)} kg</td>
      <td className="align-middle text-end text-nowrap">
        {weightFormat.format(props.weights.hollowBodiesCommercial)} kg
      </td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.epsCommercial)} kg</td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.woodCommercial)} kg</td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.compositeCommercial)} kg</td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.ceramicCommercial)} kg</td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.textileCommercial)} kg</td>
      <td className="align-middle text-end text-nowrap">{weightFormat.format(props.weights.organicCommercial)} kg</td>
    </>
  );
}
