type Props = {
  name?: string;
  lucidNumber?: string;
  companyId: string;
  badge?: string;
};

export function CompanyInfo({ companyId, name, lucidNumber, badge }: Props) {
  const typeBadge = badge ? (
    <>
      <span className="badge bg-secondary">{badge}</span>
    </>
  ) : (
    <></>
  );

  const info =
    name === undefined ? (
      <>
        <div>{typeBadge}</div>
        <div className="text-nowrap text-muted fs-6">ID : {companyId}</div>
      </>
    ) : (
      <>
        <div>{typeBadge}</div>
        <div className="">{name}</div>
        <div className="text-nowrap text-muted">ID: {companyId}</div>
        {lucidNumber != null && <div className="text-nowrap text-muted">LUCID: {lucidNumber}</div>}
      </>
    );

  return <div>{info}</div>;
}
