import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import { setUserLanguage } from './api';

const browserLanguage = navigator.language || (navigator as any).userLanguage;
export const languages = ['en', 'de', 'fr'];
const defaultLanguage = languages.includes(browserLanguage) ? browserLanguage : languages[0];

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const language = sessionStorage.getItem('LANGUAGE') ?? defaultLanguage;

const additionalFiles = {
  tenants: {
    en: require('./private/tenants/locales/en.json'),
    de: require('./private/tenants/locales/de.json'),
    fr: require('./private/tenants/locales/fr.json'),
  },
  auth: {
    en: require('./public/auth/locales/en.json'),
    de: require('./public/auth/locales/de.json'),
    fr: require('./public/auth/locales/fr.json'),
  },
  reports: {
    en: require('./private/reports/locales/en.json'),
    de: require('./private/reports/locales/de.json'),
    fr: require('./private/reports/locales/fr.json'),
  },
  placings: {
    en: require('./private/reports/de/adjust/placings/locales/en.json'),
    de: require('./private/reports/de/adjust/placings/locales/de.json'),
  },
  contracts: {
    en: require('./private/contracts/locales/en.json'),
    de: require('./private/contracts/locales/de.json'),
  },
  branches: {
    en: require('./private/branches/locales/en.json'),
    de: require('./private/branches/locales/de.json'),
  },
  producers: {
    en: require('./private/producers/locales/en.json'),
    de: require('./private/producers/locales/de.json'),
  },
};

i18n
  .use(initReactI18next) // passes locales down to react-i18next
  .init({
    ns: ['global'],
    defaultNS: 'global',
    resources: {
      de: require('./shared/locales/de.json'),
      en: require('./shared/locales/en.json'),
      fr: require('./shared/locales/fr.json'),
    },
    lng: language, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the locales.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackNS: 'global',
    fallbackLng: languages[0],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

Object.entries(additionalFiles).forEach(([ns, resources]) => {
  Object.entries(resources).forEach(([lng, resource]) => {
    i18n.addResourceBundle(lng, ns, resource, true, true);
  });
});

i18n.on('languageChanged', lng => {
  setUserLanguage(lng)
    .then(() => {
      sessionStorage.setItem('LANGUAGE', lng);
    })
    .catch(error => {
      console.log(error);
    });
});

export default i18n;
