﻿import { getAvailability, AvailabilityOfData, Period } from '../../../../api';
import { expectNever } from '../../../../helpers';
import { Icon } from '../../../../shared/components/Icon';
import { useTranslation } from 'react-i18next';
import { useTenantQuery } from '../../../../shared/hooks/useTenantQuery';
import { useQueryState } from '../../../../shared/hooks/useQueryState';

function getMonthsOfPeriod(period: Period) {
  switch (period) {
    case 'Q1':
      return [1, 2, 3];
    case 'Q2':
      return [4, 5, 6];
    case 'Q3':
      return [7, 8, 9];
    case 'Q4':
      return [10, 11, 12];
    case 'Year':
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    case 'FirstHalfYear':
      return [1, 2, 3, 4, 5, 6];
    case 'SecondHalfYear':
      return [7, 8, 9, 10, 11, 12];
    case 'Jan':
      return [1];
    case 'Feb':
      return [2];
    case 'Mar':
      return [3];
    case 'Apr':
      return [4];
    case 'May':
      return [5];
    case 'Jun':
      return [6];
    case 'Jul':
      return [7];
    case 'Aug':
      return [8];
    case 'Sep':
      return [9];
    case 'Oct':
      return [10];
    case 'Nov':
      return [11];
    case 'Dec':
      return [12];
  }

  expectNever(period);
}

const monthKeys = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
const itemStyle = { width: '3rem', lineHeight: '2.5rem', maxHeight: '2.5rem' };

function AvailabilityItem({
  availability,
  month,
  source,
}: {
  availability: AvailabilityOfData;
  month: number;
  source: keyof AvailabilityOfData;
}) {
  const available = availability[source].includes(month);
  return (
    <div style={itemStyle} className={`rounded me-2 mt-2 bg-${available ? 'success' : 'secondary'}`}>
      <Icon name={available ? 'check' : 'x'} className="text-white fs-5" />
    </div>
  );
}

export interface Props {
  year: number;
  period: Period;
}

export function AvailabilityView({ year, period }: Props) {
  const { t } = useTranslation('reports');
  const months = getMonthsOfPeriod(period);

  const [availability] = useQueryState(useTenantQuery(getAvailability, year));

  return (
    <section className="d-inline-block border rounded p-4">
      <h1 className="h6">{t('availabilityView.heading')}</h1>
      <div className="d-flex p-1 justify-content-center text-center">
        <div className="me-2">
          <div style={{ lineHeight: '2.5rem' }}>&nbsp;</div>
          <div style={{ lineHeight: '2.5rem' }} className="text-end mt-2">
            {t('global:movementTypes.purchase')}
          </div>
          <div style={{ lineHeight: '2.5rem' }} className="text-end mt-2">
            {t('global:movementTypes.sale')}
          </div>
          <div style={{ lineHeight: '2.5rem' }} className="text-end mt-2">
            {t('global:movementTypes.dropshipping')}
          </div>
        </div>
        {months.map(m => (
          <div key={m}>
            <div style={itemStyle} className="rounded me-2">
              {t(`global:months.${monthKeys[m - 1]}`)}
            </div>
            <AvailabilityItem availability={availability} month={m} source="purchase" />
            <AvailabilityItem availability={availability} month={m} source="sale" />
            <AvailabilityItem availability={availability} month={m} source="dropshipping" />
          </div>
        ))}
      </div>
    </section>
  );
}
