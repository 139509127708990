﻿import { DeclarationResultGermany, Period } from '../../../api';
import { Step } from './Step';
import { useTranslation } from 'react-i18next';
import { useTenant } from '../../tenants/TenantDetail';
import { useContractFilterFunctions } from './useContractFilterFunctions';
import { useProducers } from '../../producers/Producers';
import { isProducer } from './helpers';
import { useReports } from '../Reports';

export interface Props {
  declaration: DeclarationResultGermany | null;
  year: number;
  period: Period;
}

function StepsWithoutDeclaration() {
  const { t } = useTranslation('reports');
  const tenant = useTenant();
  const isCentralUser = tenant.permissions.central;
  return (
    <div className="list-group list-group-flush">
      {isCentralUser && (
        <>
          <Step name="create" title={t('steps.de.calculate')} />
          <Step disabled name="issues" title={t('steps.de.issues')} />
        </>
      )}
      <Step name="adjust" title={t('steps.de.adjust')} />
      {isCentralUser && <Step disabled name="review" title={t('steps.de.review')} />}
      <Step disabled name="license" title={t('steps.de.license')} />
      <Step disabled name="report" title={t('steps.de.report')} />
      <Step disabled name="finish" title={t('steps.de.finish')} />
    </div>
  );
}

function useReportsStatus(year: number, period: Period, declaration: DeclarationResultGermany) {
  const reports = useReports();

  const producersThatHaveToLicense = Object.keys(useProducers().accessible());

  const { hasContractForPeriod, hasContractButNotForPeriod, hasNoContract } = useContractFilterFunctions(period);

  const reported =
    declaration.kind == 'Release' &&
    declaration.responsibilities.every(r => {
      return (
        typeof r.reporting === 'string' ||
        r.reporting?.Case !== 'Producer' ||
        reports.get(declaration.declarationId, r.reporting.Fields[0])?.lucidReportedAt != null
      );
    });

  const licensed =
    declaration.kind == 'Release' &&
    producersThatHaveToLicense.every(producerId => {
      return (
        hasNoContract(producerId) ||
        hasContractButNotForPeriod(producerId) ||
        (hasContractForPeriod(producerId) && reports.get(declaration.declarationId, producerId)?.proReportedAt != null)
      );
    });

  return { reported, licensed };
}

function hasThirdPartyLicensing(declaration: DeclarationResultGermany) {
  return declaration.responsibilities.some(
    r => r.reporting != null && isProducer(r.reporting) && r.licensing != null && !isProducer(r.licensing)
  );
}

function StepsWithDeclaration({
  declaration,
  year,
  period,
}: {
  declaration: DeclarationResultGermany;
  year: number;
  period: Period;
}) {
  const status = useReportsStatus(year, period, declaration);
  const { t } = useTranslation('reports');

  const tenant = useTenant();
  const isCentralUser = tenant.permissions.central;

  const isThirdPartyLicensingStepRequired = hasThirdPartyLicensing(declaration);

  return (
    <div className="list-group list-group-flush">
      {isCentralUser && (
        <>
          <Step disabled name="create" title={t('steps.de.calculate')} />
          <Step
            disabled={declaration.kind !== 'Preview' && declaration.kind !== 'Release'}
            name="issues"
            title={t('steps.de.issues')}
          />
        </>
      )}
      <Step
        disabled={declaration.kind !== 'Preview' && declaration.kind !== 'Release'}
        name="adjust"
        title={t('steps.de.adjust')}
      />
      {isCentralUser && isThirdPartyLicensingStepRequired && (
        <Step
          disabled={declaration.kind !== 'Preview' && declaration.kind !== 'Release'}
          name="thirdPartyLicensing"
          title={t('steps.de.thirdPartyLicensing')}
        />
      )}
      {isCentralUser && (
        <Step
          disabled={declaration.kind !== 'Preview' && declaration.kind !== 'Release'}
          done={declaration.kind === 'Release'}
          name="review"
          title={t('steps.de.review')}
        />
      )}
      <Step
        done={status.licensed}
        disabled={declaration.kind !== 'Release'}
        name="license"
        title={t('steps.de.license')}
      />
      <Step
        done={status.reported}
        disabled={declaration.kind !== 'Release'}
        name="report"
        title={t('steps.de.report')}
      />
      <Step
        done={status.reported && status.licensed}
        disabled={declaration.kind !== 'Release' || !status.reported || !status.licensed}
        name="finish"
        title={t('steps.de.finish')}
      />
    </div>
  );
}

export function Steps({ declaration, year, period }: Props) {
  return declaration ? (
    <StepsWithDeclaration declaration={declaration} year={year} period={period} />
  ) : (
    <StepsWithoutDeclaration />
  );
}
