﻿import { useProducers } from '../producers/Producers';
import { useBranches } from './Branches';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../shared/components/Icon';
import { Branch, Country } from '../../api';
import { stringParam, useSearchParams } from '../../shared/hooks/useSearchParams';
import { useSorting } from '../../shared/hooks/useSorting';
import { SortableHeaderCell } from '../../shared/components/SortableHeaderCell';
import { BranchesTableRow, Row } from './BranchesTableRow';

export function BranchesTable() {
  const branches = useBranches();
  const producers = useProducers();

  const { t } = useTranslation('branches');

  const producerName = (producerId: string | null) =>
    producerId !== null
      ? producers.get(producerId)
        ? `${producers.get(producerId)?.name} (${producerId})`
        : producerId
      : '--';

  const countryName = (country: Country) => t(`countries.${country}`);

  const rows: [string, Row][] = Object.entries(branches.all).map(([id, branch]: [string, Branch]) => [
    id,
    {
      ...branch,
      countryName: countryName(branch.country),
      producerName: producerName(branch.producerId),
    },
  ]);

  const [search, setSearch] = useSearchParams<string>('search', stringParam(''));

  const compareString = (field: keyof Row) => (a: [string, Row], b: [string, Row]) =>
    (a[1][field] || '').localeCompare(b[1][field] || '');

  const branchIncludes = ([, row]: [string, Row]) => {
    const lowerCaseSearch = search.toLocaleLowerCase();
    return (
      row.branchId.toLowerCase().includes(lowerCaseSearch) ||
      row.name.toLocaleLowerCase().includes(lowerCaseSearch) ||
      row.producerName.toLocaleLowerCase().includes(lowerCaseSearch) ||
      row.countryName.toLocaleLowerCase().includes(lowerCaseSearch)
    );
  };

  const sorting = useSorting<[string, Row], 'branchId' | 'name' | 'producerName' | 'countryName'>(
    rows,
    {
      name: compareString('name'),
      branchId: compareString('branchId'),
      producerName: compareString('producerName'),
      countryName: compareString('countryName'),
    },
    { key: 'name', direction: 'asc' }
  );

  return (
    <>
      <div className="row my-4">
        <div className="col-4">
          <input
            placeholder={t('global:search')}
            type="search"
            className="form-control"
            value={search}
            onChange={e => setSearch(e.currentTarget.value)}
          />
        </div>
      </div>
      <table className="table table-striped table-hover">
        <thead className="sticky-top bg-white">
          <tr>
            <SortableHeaderCell className="col-4" sorting={sorting} sortKey="name">
              {t('branchesTable.name')}
            </SortableHeaderCell>
            <SortableHeaderCell className="col-2" sorting={sorting} sortKey="branchId">
              {t('branchesTable.branchId')}
            </SortableHeaderCell>
            <SortableHeaderCell className="col-4" sorting={sorting} sortKey="producerName">
              {t('branchesTable.producers')}
            </SortableHeaderCell>
            <SortableHeaderCell className="col-2" sorting={sorting} sortKey="countryName">
              {t('branchesTable.country')}
            </SortableHeaderCell>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sorting.sorted.filter(branchIncludes).map(([, row]) => (
            <BranchesTableRow key={row.branchId} row={row} />
          ))}
        </tbody>
      </table>

      <NavLink to={{ pathname: `./add`, search: location.search }} className="btn btn-outline-secondary">
        <>
          <Icon name={'plus-lg'} /> {t('newBranch')}
        </>
      </NavLink>
    </>
  );
}
