import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTenant } from './TenantDetail';
import { useQueryValueOrLatest } from '../../shared/hooks/useQueryValueOrLatest';
import { useQuery } from '../../shared/hooks/useQuery';
import { getTenantsByUser } from '../../api';
import { useUser } from '../../public/auth/shared';

export function TenantSwitcher() {
  const { t } = useTranslation('tenants');
  const tenant = useTenant();

  const user = useUser();

  const tenantsList = useQueryValueOrLatest(
    useQuery(getTenantsByUser, {
      filter: '',
      page: { limit: 1, offset: 0 },
      order: { field: 'Name', direction: 'Desc' },
    })
  );

  const AllGroupsButton = () => {
    if (!tenantsList.hasMore && !user.permissions.leko) {
      return <></>;
    }

    return (
      <Link to="/" className="btn btn-secondary" style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
        {t('switchTenant')}
      </Link>
    );
  };

  return (
    <div className="rounded d-flex align-items-center bg-primary">
      <div className="px-3 text-white">{tenant.name}</div>
      <AllGroupsButton />
    </div>
  );
}
