import { ChallengeContext, challengeNewPasswordRequired } from '../../api';
import { useMutation } from '../../remote';
import { group, textField, useForm } from '../../forms';
import { AuthStateView } from './AuthStateView';
import { TextField } from '../../shared/components/TextField';
import { AuthErrorView } from './AuthErrorView';
import { useTranslation } from 'react-i18next';
import { FormEvent } from 'react';

export interface Props {
  context: ChallengeContext;
  requiredAttributes: string[];
}

const definition = group({
  newPassword: textField,
});

export function NewPasswordRequiredChallenge({ context }: Props) {
  const { t } = useTranslation('auth');
  const mutation = useMutation(challengeNewPasswordRequired);

  const form = useForm(definition, { newPassword: '' });

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (form.status.valid) {
      mutation.start({ ...form.status.value, context });
    }
  }

  if (mutation.isResolved) {
    const result = mutation.data;
    if (result.Case === 'Ok') {
      const state = result.Fields[0];
      return <AuthStateView state={state} />;
    }
  }

  return (
    <>
      <h1 className="h5 text-center text-secondary">{t('NewPasswordRequiredChallenge.title')}</h1>
      <p className="my-4 alert alert-primary">{t('NewPasswordRequiredChallenge.description')}</p>
      <form onSubmit={onSubmit} className="mb-3">
        <TextField
          focusOnMount
          id="newPassword"
          label={t('NewPasswordRequiredChallenge.newPassword')}
          field={form.fields.newPassword}
          type="password"
          autoComplete="new-password"
        />
        <p className="text-muted">{t('passwordPolicy')}</p>
        <button disabled={!form.status.valid} className="btn btn-primary" type="submit">
          {t('NewPasswordRequiredChallenge.setPassword')}
        </button>
      </form>
      {mutation.isResolved && mutation.data.Case === 'Error' && <AuthErrorView error={mutation.data.Fields[0]} />}
    </>
  );
}
