﻿import { AggregatedPackagingWeightsAustria } from '../../../../api';
import { weightFormat } from '../../../../helpers';
import { Badge } from 'react-bootstrap';

export function ReviewWeightsRowCells(props: {
  weights: AggregatedPackagingWeightsAustria;
  comparison: AggregatedPackagingWeightsAustria | null;
}) {
  const badge = (a: number, b: number) => {
    if (b === 0 || Math.abs(a - b) < 0.001) {
      return <Badge bg="secondary">+0%</Badge>;
    } else if (a > b) {
      return <Badge bg="secondary">+{Math.round(((a - b) / b) * 100)}%</Badge>;
    } else {
      return <Badge bg="secondary">-{Math.round(((b - a) / b) * 100)}%</Badge>;
    }
  };

  return (
    <>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.fiberHouseHold)} kg</span>
        {props.comparison ? badge(props.weights.fiberHouseHold, props.comparison.fiberHouseHold) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.glassHouseHold)} kg</span>
        {props.comparison ? badge(props.weights.glassHouseHold, props.comparison.glassHouseHold) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.ferrousMetalHouseHold)} kg</span>
        {props.comparison ? badge(props.weights.ferrousMetalHouseHold, props.comparison.ferrousMetalHouseHold) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.aluminumHouseHold)} kg</span>
        {props.comparison ? badge(props.weights.aluminumHouseHold, props.comparison.aluminumHouseHold) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.plasticHouseHold)} kg</span>
        {props.comparison ? badge(props.weights.plasticHouseHold, props.comparison.plasticHouseHold) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.beverageCartonHouseHold)} kg</span>
        {props.comparison ? (
          badge(props.weights.beverageCartonHouseHold, props.comparison.beverageCartonHouseHold)
        ) : (
          <></>
        )}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.compositeHouseHold)} kg</span>
        {props.comparison ? badge(props.weights.compositeHouseHold, props.comparison.compositeHouseHold) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.ceramicHouseHold)} kg</span>
        {props.comparison ? badge(props.weights.ceramicHouseHold, props.comparison.ceramicHouseHold) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.woodHouseHold)} kg</span>
        {props.comparison ? badge(props.weights.woodHouseHold, props.comparison.woodHouseHold) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.textileHouseHold)} kg</span>
        {props.comparison ? badge(props.weights.textileHouseHold, props.comparison.textileHouseHold) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.organicHouseHold)} kg</span>
        {props.comparison ? badge(props.weights.organicHouseHold, props.comparison.organicHouseHold) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.fiberCommercial)} kg</span>
        {props.comparison ? badge(props.weights.fiberCommercial, props.comparison.fiberCommercial) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.ferrousMetalCommercial)} kg</span>
        {props.comparison ? (
          badge(props.weights.ferrousMetalCommercial, props.comparison.ferrousMetalCommercial)
        ) : (
          <></>
        )}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.aluminumCommercial)} kg</span>
        {props.comparison ? badge(props.weights.aluminumCommercial, props.comparison.aluminumCommercial) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.foilsCommercial)} kg</span>
        {props.comparison ? badge(props.weights.foilsCommercial, props.comparison.foilsCommercial) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.hollowBodiesCommercial)} kg</span>
        {props.comparison ? (
          badge(props.weights.hollowBodiesCommercial, props.comparison.hollowBodiesCommercial)
        ) : (
          <></>
        )}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.epsCommercial)} kg</span>
        {props.comparison ? badge(props.weights.epsCommercial, props.comparison.epsCommercial) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.woodCommercial)} kg</span>
        {props.comparison ? badge(props.weights.woodCommercial, props.comparison.woodCommercial) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.compositeCommercial)} kg</span>
        {props.comparison ? badge(props.weights.compositeCommercial, props.comparison.compositeCommercial) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.ceramicCommercial)} kg</span>
        {props.comparison ? badge(props.weights.ceramicCommercial, props.comparison.ceramicCommercial) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.textileCommercial)} kg</span>
        {props.comparison ? badge(props.weights.textileCommercial, props.comparison.textileCommercial) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.organicCommercial)} kg</span>
        {props.comparison ? badge(props.weights.organicCommercial, props.comparison.organicCommercial) : <></>}
      </td>
    </>
  );
}
