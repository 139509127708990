import { Field } from './Field';
import { StateOf, textField, optionalTextField } from '../../forms';
import { ProducerInput } from './ProducerInput';
import { ColSize } from './Col';

export interface Props {
  id: string;
  label: string;
  field: StateOf<typeof textField> | StateOf<typeof optionalTextField>;
  focusOnMount?: boolean;
  disabled?: boolean;
  size?: ColSize;
}

export function ProducerField(props: Props) {
  return (
    <Field size={props.size}>
      <label className="form-label" htmlFor={props.id}>
        {props.label}
      </label>
      <ProducerInput id={props.id} disabled={props.disabled} field={props.field} />
    </Field>
  );
}
