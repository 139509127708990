import { useEffect, useState } from 'react';
import { getImportErrors, getImportErrorsFr, ImportErrorKind } from '../../../../api';
import { useTenantQuery } from '../../../../shared/hooks/useTenantQuery';
import { Icon } from '../../../../shared/components/Icon';
import { useTranslation } from 'react-i18next';
import { useQueryValue } from '../../../../shared/hooks/useQueryValue';
import { createEncodedFilename, exportAsCsvUtf8 } from '../../../../helpers';
import { useTenant } from '../../../tenants/TenantDetail';
import { Pagination } from '../../../../shared/components/Pagination';
import { useYearParam } from '../../../../shared/hooks/useParam';

interface Props {
  importId: number;
  limit: number;
}

function ImportErrorRow({ error }: { error: ImportErrorKind }) {
  const { t } = useTranslation('reports');

  const row = typeof error === 'object' ? error.Fields[0].row : null;

  const message =
    typeof error === 'string' ? t(`importErrors.${error}`) : t(`importErrors.Row.${error.Fields[0].message}`);

  const field = typeof error === 'object' ? error.Fields[0].field : null;

  return (
    <li className="list-group-item d-flex justify-content-between align-items-start">
      <div className="me-auto d-flex align-items-center">
        <Icon className="align-self-center text-danger fs-4 me-2" name="x-circle" />
        {row !== null && (
          <div className="fw-bold me-2">
            {t('importErrors.row')} {row}:
          </div>
        )}
        {message}
        {field !== null ? ` ${t('importErrors.inColumn')} ${field}` : ''}
      </div>
    </li>
  );
}

function getErrorListHint(numberOfErrors: number, limit: number) {
  if (numberOfErrors <= limit) {
    return 'importStep.shortErrorListHint';
  }

  return 'importStep.longErrorListHint';
}

export function ImportErrors({ importId, limit }: Props) {
  const { t } = useTranslation('reports');
  const tenant = useTenant();
  const year = useYearParam();
  const [downloading, setDownloading] = useState(false);
  const importErrorsQuery = useTenantQuery(year === 2022 ? getImportErrors : getImportErrorsFr, importId, false);
  const importErrors = useQueryValue(importErrorsQuery);

  function download() {
    setDownloading(true);
  }

  useEffect(() => {
    if (!downloading) {
      return;
    }
    const rows = importErrors.map(error => {
      const row = typeof error.kind === 'string' ? '' : error.kind.Fields[0].row;

      const field = typeof error.kind === 'object' ? error.kind.Fields[0].field : '';

      const message =
        typeof error.kind === 'string'
          ? t(`reports:importErrors.${error.kind}`)
          : `${t(`reports:importErrors.Row.${error.kind.Fields[0].message}`)} ${
              field ? `${t('importErrors.inColumn')} ${field}` : ''
            }`;

      return [row.toString(), message];
    });

    const fileName = createEncodedFilename(`errors_${tenant.name}`, '.csv');
    const header = [t('reports:importErrors.headers.row'), t('reports:importErrors.headers.description')];

    exportAsCsvUtf8(fileName, header, rows);
    setDownloading(false);
  }, [downloading]);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const itemsPerPage = 20;

  const displayedErrors = importErrors.slice(currentPage * itemsPerPage, currentPage * itemsPerPage + itemsPerPage);

  return (
    <div className="card mx-4 p-4 mb-3">
      <div className="d-flex justify-content-between align-items-center pb-3">
        <span></span>
        <div className="text-end">
          <button className="btn btn-secondary" disabled={downloading} onClick={() => download()}>
            <span className={downloading ? 'spinner-border spinner-border-sm me-1' : 'd-none'}></span>
            <Icon className={downloading ? 'd-none' : 'me-1'} name="download" />{' '}
            {t('reports:importErrors.downloadButtonText')}
          </button>
        </div>
      </div>
      <ul className="list-group list-group-flush">
        {displayedErrors.map((error, index) => (
          <ImportErrorRow key={index} error={error.kind} />
        ))}
      </ul>
      <div className={'mt-3'}>
        <Pagination
          totalItems={importErrors.length}
          itemsPerPage={itemsPerPage}
          pagesToShow={6}
          currentPageIndex={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
}
