import { DeclarationCountry, getAllReports, markAsReported, Period, ReportItem, ReportType } from '../../api';
import { createContext, ReactNode, useContext } from 'react';
import { useTenant } from '../tenants/TenantDetail';
import { useTenantQuery } from '../../shared/hooks/useTenantQuery';
import { useQueryState } from '../../shared/hooks/useQueryState';

export interface ReportsService {
  get(declarationId: number, producerId: string): ReportItem | null;

  report(declarationId: number, producerId: string, type: ReportType, value: boolean): Promise<void>;

  all: ReportItem[];
}

const ReportsContext = createContext<ReportsService | null>(null);

function getFromReports(reports: ReportItem[], declarationId: number, producerId: string) {
  return reports.find(r => r.declarationId === declarationId && r.producerId === producerId) ?? null;
}

// Keeps the previous value if condition is not met or sets the value based on newValue
const temporalValue = (condition: boolean, prevValue: string | null, newValue: boolean) =>
  condition ? (newValue ? new Date().toISOString() : null) : prevValue;

export function Reports(props: { country: DeclarationCountry; year: number; period: Period; children?: ReactNode }) {
  const query = useTenantQuery(getAllReports, { country: props.country, year: props.year, period: props.period });
  const [reports, setReports] = useQueryState(query);

  const tenant = useTenant();

  const get = (declarationId: number, producerId: string) => getFromReports(reports, declarationId, producerId);

  const report = async (declarationId: number, producerId: string, type: ReportType, value: boolean) => {
    // Mutate local
    setReports(prev => {
      const original = getFromReports(prev, declarationId, producerId);

      function update(prev: ReportItem) {
        return {
          ...prev,
          lucidReportedAt: temporalValue(type === 'Lucid', prev.lucidReportedAt, value),
          proReportedAt: temporalValue(type === 'Pro', prev.proReportedAt, value),
        };
      }

      return original == null
        ? [...prev, update({ declarationId, producerId, lucidReportedAt: null, proReportedAt: null })]
        : prev.map(r => (r !== original ? r : update(original)));
    });

    try {
      // Mutate remote
      await markAsReported({
        tenantId: tenant.id,
        data: {
          declarationId: declarationId,
          period: props.period,
          year: props.year,
          producerId,
          reportType: type,
          state: value,
        },
      });
    } finally {
      // Revalidate
      await query.revalidate();
    }
  };

  return <ReportsContext.Provider value={{ get, report, all: reports }}>{props.children}</ReportsContext.Provider>;
}

export const useReports = (): ReportsService => useContext(ReportsContext) as ReportsService;
