import { StateOf } from '../../../../forms';
import { useTranslation } from 'react-i18next';
import { Row } from '../../../../shared/components/Row';
import { Col } from '../../../../shared/components/Col';
import { SystemField } from '../../../../shared/components/SystemField';
import { FormDefinition } from './form';
import { NumberField } from '../../../../shared/components/NumberField';
import * as React from 'react';

export interface Props {
  form: StateOf<FormDefinition>;
}

function getNullWeights() {
  return {
    glass: null,
    fiber: null,
    ferrousMetal: null,
    aluminum: null,
    plastic: null,
    beverageCarton: null,
    otherComposite: null,
    other: null,
  };
}

export function ThirdPartyLicensingForm({ form }: Props) {
  const { t } = useTranslation('reports');

  function onChange(e: React.FormEvent<HTMLInputElement>) {
    if (form.fields.weights.value === null && e.currentTarget.checked) {
      form.fields.weights.setValue(getNullWeights());
    } else if (form.fields.weights.value !== null && !e.currentTarget.checked) {
      form.fields.weights.setValue(null);
    }
  }

  return (
    <>
      <Row>
        <Col>
          <SystemField id="systemId" label={t('thirdPartyLicensingForm.systemProvider')} field={form.fields.systemId} />
        </Col>
      </Row>
      <div className="alert alert-secondary" role="alert">
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="hasWeights"
            checked={form.fields.weights.value !== null}
            onChange={onChange}
          />
          <label className="form-check-label" htmlFor="hasWeights">
            {t('thirdPartyLicensingForm.hasWeightsLabel')}
          </label>
        </div>
      </div>
      {form.fields.weights.fields && (
        <>
          <Row>
            <NumberField
              label={t('global:fractions.de.glass')}
              unit="kg"
              id="weights.glass"
              field={form.fields.weights.fields.glass}
            />
            <NumberField
              label={t('global:fractions.de.fiber')}
              unit="kg"
              id="weights.fiber"
              field={form.fields.weights.fields.fiber}
            />
          </Row>
          <Row>
            <NumberField
              label={t('global:fractions.de.ferrousMetal')}
              unit="kg"
              id="weights.ferrousMetal"
              field={form.fields.weights.fields.ferrousMetal}
            />
            <NumberField
              label={t('global:fractions.de.aluminum')}
              unit="kg"
              id="weights.aluminum"
              field={form.fields.weights.fields.aluminum}
            />
          </Row>
          <Row>
            <NumberField
              label={t('global:fractions.de.plastic')}
              unit="kg"
              id="weights.plastic"
              field={form.fields.weights.fields.plastic}
            />
            <NumberField
              label={t('global:fractions.de.beverageCarton')}
              unit="kg"
              id="weights.beverageCarton"
              field={form.fields.weights.fields.beverageCarton}
            />
          </Row>
          <Row>
            <NumberField
              label={t('global:fractions.de.otherComposite')}
              unit="kg"
              id="weights.otherComposite"
              field={form.fields.weights.fields.otherComposite}
            />
            <NumberField
              label={t('global:fractions.de.other')}
              unit="kg"
              id="weights.other"
              field={form.fields.weights.fields.other}
            />
          </Row>
        </>
      )}
    </>
  );
}
