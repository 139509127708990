﻿import { NavLink } from 'react-router-dom';
import { Icon } from '../../../shared/components/Icon';

export interface Props {
  disabled?: boolean;
  name: string;
  num: number | string;
  title: string;
  done?: boolean;
}

export function Step(props: Props) {
  return (
    <NavLink
      to={`./${props.name}`}
      className={({ isActive }) =>
        `rounded py-4 d-flex align-items-center list-group-item${isActive ? ' active' : ''}${
          props.disabled ? ' disabled opacity-50' : ''
        }`
      }>
      {({ isActive }) => {
        return (
          <>
            <div className="me-3">
              <div
                style={{ width: '3rem', border: '1px solid currentColor' }}
                className={
                  isActive
                    ? 'text-center rounded bg-primary border fs-4 lh-lg'
                    : 'text-center rounded bg-white fs-4 lh-lg'
                }>
                {props.done ? <Icon name="check-lg" /> : props.num}
              </div>
            </div>
            <div className="flex-grow-1">
              <h1 className="h5">{props.title}</h1>
            </div>
          </>
        );
      }}
    </NavLink>
  );
}
