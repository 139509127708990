﻿import { Period, PeriodStatus } from '../../api';
import { expectNever, getNameOfPeriod } from '../../helpers';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router';

function isDisabledPeriod(year: number, period: Period) {
  const now = new Date();

  if (year <= 2019) {
    return true;
  }

  if (year > now.getFullYear()) {
    return true;
  }

  if (year === now.getFullYear()) {
    if (period === 'Q4' || period === 'Year' || period === 'SecondHalfYear' || period === 'Dec') {
      return true;
    }

    if (period === 'Q3' && now.getMonth() < 9) {
      return true;
    }

    if ((period === 'FirstHalfYear' || period === 'Q2') && now.getMonth() < 6) {
      return true;
    }

    if (period === 'Q1' && now.getMonth() < 3) {
      return true;
    }

    const periodToMonth = (p: Period) => {
      switch (p) {
        case 'Jan':
          return 0;
        case 'Feb':
          return 1;
        case 'Mar':
          return 2;
        case 'Apr':
          return 3;
        case 'May':
          return 4;
        case 'Jun':
          return 5;
        case 'Jul':
          return 6;
        case 'Aug':
          return 7;
        case 'Sep':
          return 8;
        case 'Oct':
          return 9;
        case 'Nov':
          return 10;
        case 'Dec':
          return 11;
        default:
          return 0;
      }
    };

    if (periodToMonth(period) >= now.getMonth()) {
      return true;
    }
  }

  return false;
}

export interface Props {
  year: number;
  period: Period;
  status: PeriodStatus;
}

function getStatusClassName(status: PeriodStatus): string {
  if (typeof status === 'object' && status.Case === 'Declaration') {
    const declarationStatus = status.Fields[1];

    if (declarationStatus === 'Creating') {
      return 'bg-secondary';
    }

    if (declarationStatus === 'InPreview') {
      return 'bg-primary';
    }

    if (declarationStatus === 'Released') {
      return 'bg-success';
    }

    expectNever(declarationStatus);
  }

  return 'invisible';
}

const statusStyle = {
  lineHeight: '1.5rem',
  borderRadius: '2px',
  width: '0.5rem',
  height: '0.5rem',
};

export function PeriodNavigationItem({ year, period, status }: Props) {
  const navigate = useNavigate();

  return (
    <Dropdown.Item
      className={`d-flex align-items-center`}
      onClick={() => navigate(`../${period}`)}
      disabled={isDisabledPeriod(year, period)}>
      <div style={statusStyle} className={getStatusClassName(status)}></div>
      <span className="ms-2">{getNameOfPeriod(period)}</span>
    </Dropdown.Item>
  );
}
