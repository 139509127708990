{
  "global": {
    "languages": {
      "en": "Anglais",
      "de": "Allemand",
      "fr": "Français"
    },
    "fractions": {
      "de": {
        "glass": "Verre",
        "fiber": "Fibre",
        "ferrousMetal": "Métaux ferreux",
        "aluminum": "Aluminum",
        "plastic": "Plastique",
        "beverageCarton": "Carton pour boisson",
        "other": "Autre",
        "otherComposite": "Autre composé"
      },
      "at": {
        "fiberHouseHold": "Papier ménager",
        "glassHouseHold": "Verre",
        "ferrousMetalHouseHold": "Ménage des métaux ferreux",
        "aluminumHouseHold": "Ménage en aluminium",
        "plasticHouseHold": "Ménage en plastique",
        "beverageCartonHouseHold": "Carton à boisson ménager",
        "compositeHouseHold": "Autre ménage composite hors carton à boisson",
        "organicHouseHold": "Ménage en céramique",
        "woodHouseHold": "Ménage bois",
        "textileHouseHold": "Textiles household",
        "organicHouseHold": "Ménage textile",
        "fiberCommercial": "Papier commercial",
        "ferrousMetalCommercial": "Métier ferreux commercial",
        "aluminumCommercial": "Commercial en aluminium",
        "foilsCommercial": "Film commercial, cerclage et ruban adhésif en plastique",
        "hollowBodiesCommercial": "Commercial corps creux",
        "epsCommercial": "Commercial EPS",
        "woodCommercial": "Bois commercial",
        "compositeCommercial": "Autre composite commercial",
        "organicCommercial": "Céramique commerciale",
        "textileCommercial": "Textile commercial",
        "organicCommercial": "Commercial emballage biogénique"
      }
    },
    "error": {
      "errorTitle": "Oops, une erreur est survenue",
      "errorSubtitle": "Nous travaillons déjà à la résolution du problème. Veuillez réessayer. <br/>\n Si l'erreur persiste, contactez-nous.",
      "errorOfflineTitle": "Pas de connexion à Internet",
      "errorOfflineSubtitle": "Nous n'avons pas pu continuer car il n'y a pas de connexion Internet.",
      "errorForbidden": "Vous n'êtes pas autorisé à effectuer cette action.",
      "errorUnexpected": "L'action n'a pas pu être effectuée. Une erreur inattendue est apparue.",
      "retry": "Essayer à nouveau",
      "goBack": "Retourner en arrière"
    },
    "forbidden": {
      "forbiddenTitle": "Permissions manquantes",
      "forbiddenSubtitle": "Vous n'avez pas les autorisations nécessaires pour effectuer cette action."
    },
    "notFound": {
      "notFoundTitle": "Non trouvé",
      "notFoundSubtitle": "L'information demandée n'a pas pu être trouvée."
    },
    "month": "Mois",
    "quarter": "Trimestre",
    "halfYear": "Semestre",
    "year": "Année",
    "endOfMonth": "Fin du mois",
    "endOfQuarter": "Fin du trimestre",
    "endOfHalfYear": "Fin du semestre",
    "salesPackagings": "Emballages de vente",
    "servicePackagings": "Emballage de sercie et d'économat",
    "search": "Recherche",
    "periods": {
      "q1": "1er trimestre",
      "q2": "2ème trimestre",
      "q3": "3ème trimestre",
      "q4": "4ème trimestre",
      "year": "Année",
      "firstHalfOfYear": "Premier semestre",
      "secondHalfOfYear": "Deuxième semestre",
      "jan": "Janvier",
      "feb": "Février",
      "mar": "Mars",
      "apr": "Avril",
      "may": "Mai",
      "jun": "Juin",
      "jul": "Juillet",
      "aug": "Août",
      "sep": "Septembre",
      "oct": "Octobre",
      "nov": "Novembre",
      "dec": "Décembre"
    },
    "shortPeriod": {
      "Q1": "T1",
      "Q2": "T2",
      "Q3": "T3",
      "Q4": "T4",
      "Year": "Année",
      "FirstHalfYear": "H1",
      "SecondHalfYear": "H2",
      "Jan": "Jan",
      "Feb": "Fev",
      "Mar": "Mar",
      "Apr": "Avr",
      "May": "Mai",
      "Jun": "Jun",
      "Jul": "Jlt",
      "Aug": "Aou",
      "Sep": "Sep",
      "Oct": "Oct",
      "Nov": "Nov",
      "Dec": "Dec"
    },
    "declarationIntervals": {
      "monthly": "Mensuel",
      "quarterly": "Trimestriel",
      "biYearly": "Bisannuel",
      "yearly": "Aucun rapport au cours de l'année"
    },
    "months": {
      "jan": "Jan",
      "feb": "Fev",
      "mar": "Mar",
      "apr": "Avr",
      "may": "Mai",
      "jun": "Jun",
      "jul": "Jlt",
      "aug": "Aou",
      "sep": "Sep",
      "oct": "Oct",
      "nov": "Nov",
      "dec": "Dec"
    },
    "countries": {
      "Germany": "Allemagne",
      "Poland": "Pologne",
      "France": "France",
      "Austria": "Autriche",
      "CzechRepublic": "République tchèque",
      "Belgium": "Belgique",
      "Luxembourg": "Luxembourg",
      "BosniaAndHerzegovina": "Bosnie-Herzégovine",
      "Bulgaria": "Bulgarie",
      "China": "Chine",
      "Denmark": "Danemark",
      "Finland": "Finlande",
      "Greece": "Grèce",
      "UnitedKingdom": "Royaume-Uni",
      "HongKong": "Hong Kong",
      "Indonesia": "Indonésie",
      "Ireland": "Irlande",
      "Italy": "Italie",
      "Canada": "Canada",
      "Croatia": "Croatie",
      "Latvia": "Lettonie",
      "Netherlands": "Pays-Bas",
      "Norway": "Norvège",
      "Sweden": "Suède",
      "Switzerland": "Suisse",
      "Serbia": "Serbie",
      "Singapore": "Singapour",
      "Slovakia": "Slovaquie",
      "Slovenia": "Slovénie",
      "Spain": "Espagne",
      "Turkey": "Turquie",
      "Hungary": "Hongrie",
      "USA": "USA",
      "Unknown": "Inconnu"
    },
    "local": {
      "DE_de": "Allemand",
      "EN_us": "Anglais",
      "FR_fr": "Français"
    },
    "packagingTypes": {
      "salesPackaging": "Emballage de vente",
      "servicePackaging": "Emballage de service et d'économat",
      "outerPackaging": "Emballage extérieur",
      "shippingPackaging": "Emballage d'expédition",
      "transportPackaging": "Emballage de transport"
    },
    "forms": {
      "errors": {
        "required": "Donnée recquise",
        "invalid": "Donnée invalide",
        "alreadyExists": "L'entrée avec le même ID existe déjà"
      }
    },
    "supplier": "Fournissuer",
    "customer": "Client",
    "customers": "Clients",
    "footer": {
      "privacy": "Politique de confidentialité",
      "imprint": "Mentions légales",
      "support": "Aide & Support",
      "copyright": "©SynoptiCons",
      "supportModal": {
        "message": "Si vous avez des questions, des préoccupations ou des commentaires à propos de Calculate, n'hésitez pas à nous contacter: <br />"
      }
    }
  }
}