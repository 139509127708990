{
  "navigation": {
    "notCreated": "nicht berechnet",
    "calculationPending": "werden berechnet",
    "inReview": "in Prüfung",
    "released": "veröffentlicht",
    "notReleased": "nicht freigegeben"
  },
  "support": {
    "hint": "Benötigen Sie Hilfe?"
  },
  "releaseDialog": {
    "notifyAboutRelease": "Verantwortliche über die Veröffentlichung benachrichtigen",
    "button": {
      "confirm": "Freigeben",
      "cancel": "Abbrechen"
    }
  },
  "releaseButton": {
    "de": {
      "issueHint": "Es wurden noch nicht alle Probleme behoben.<br />Wollen Sie die Mengen trotzdem freigeben?",
      "hint": "Wollen Sie diese Mengen freigeben?",
      "release": "Freigeben"
    },
    "at": {
      "issueHint": "Es wurden noch nicht alle Probleme behoben.<br />Wollen Sie die Mengen trotzdem freigeben?",
      "hint": "Wollen Sie diese Mengen freigeben?",
      "release": "Freigeben"
    }
  },
  "csuReleaseButton": {
    "release": "Weiter"
  },
  "declarationLoader": {
    "loadingFailedHint": "Die Mengen können nicht angezeigt werden,<br/> da diese noch nicht freigegeben wurden.",
    "notReleased": "Nicht Freigegeben"
  },
  "responsibleView": {
    "customers": "Kunden",
    "supplier": "Lieferant",
    "customer": "Kunde"
  },
  "steps": {
    "de": {
      "import": "Importieren",
      "importAndCalculate": "Importieren und berechnen",
      "thirdPartyLicensing": "Drittlizenzierung",
      "calculate": "Berechnen",
      "issues": "Probleme",
      "review": "Überprüfen",
      "adjust": "Sonstige Mengen",
      "report": "An LUCID melden",
      "license": "An Systembetreiber melden",
      "finish": "Abschließen"
    },
    "at": {
      "import": "Importieren",
      "importAndCalculate": "Importieren und berechnen",
      "calculate": "Berechnen",
      "issues": "Probleme",
      "review": "Überprüfen",
      "adjust": "Sonstige Mengen",
      "license": "An Systembetreiber melden",
      "finish": "Abschließen"
    },
    "fr": {
      "importAndCalculate": "Importieren und berechnen",
      "review": "Überprüfen",
      "finish": "Abschließen"
    }
  },
  "systemInfo": {
    "unknownProviderHint": "Unbekannter Systembetreiber mit der ID {{id}}"
  },
  "systemOfProducerInfo": {
    "company": "Unternehmen",
    "noContract": "Für <strong>{{producer}}</strong> besteht noch kein Vertrag mit einem Systembetreiber. Fügen Sie bitte einen hinzu.",
    "addContract": "Hinzufügen",
    "editContract": "Ändern"
  },
  "systemOfSupplierInfo": {
    "noThirdPartyLicensing": "Es wurden noch keine Informationen vom <strong>Lieferanten (ID: {{supplier}})</strong> geteilt.",
    "noThirdPartyLicensingWithName": "Es wurden noch keine Informationen vom Lieferant <strong>{{supplierName}}</strong> geteilt.",
    "addThirdPartyLicensing": "Hinzufügen",
    "editThirdPartyLicensing": "Ändern"
  },
  "systemOfCustomerInfo": {
    "noThirdPartyLicensing": "Es wurden noch keine Informationen vom <strong>Kunden (ID: {{customer}})</strong> geteilt.",
    "addThirdPartyLicensing": "Hinzufügen",
    "editThirdPartyLicensing": "Ändern"
  },
  "systemsOfCustomerInfo": {
    "unknownSystemProvider": "Nicht bekannte Systembetreiber von Kunden"
  },
  "withdrawButton": {
    "confirm": "Wollen Sie die freigegebenen Mengen wirklich verwerfen?<br />Die Mengen gehen verloren und werden basierend auf den aktuellen Daten neu berechnet.",
    "csuConfirm": "Wollen Sie die Meldung wirklich verwerfen?<br />Die berechneten Kosten gehen verloren und die Daten müssen erneut importiert werden.",
    "withdraw": "Verwerfen"
  },
  "withdrawDialog": {
    "button": {
      "confirm": "Verwerfen",
      "cancel": "Abbrechen"
    }
  },
  "availabilityView": {
    "heading": "Verfügbarkeit der Daten"
  },
  "createStep": {
    "pollingTitle": "Wird berechnet...",
    "polling": "Die Mengen werden berechnet.<br/>Dieser Vorgang kann eine kurze Zeit in Anspruch nehmen.",
    "csuPolling": "Die Kosten werden berechnet.<br/>Dieser Vorgang kann eine kurze Zeit in Anspruch nehmen.",
    "noDataTitle": "Keine Daten",
    "noData": "Die Mengen können nicht berechnet werden,\n da keine vollständigen Daten für diese Periode vorliegen.",
    "notReleasedTitle": "Nicht freigegeben",
    "notReleased": "Die Meldungen können nicht angezeigt werden, da diese noch nicht freigegeben wurden.",
    "resultOk": "Die Meldung wurde erstellt."
  },
  "importStep": {
    "downloadText": "Sie können hier eine leere Vorlage herunterladen:",
    "downloadFrench": "Französische Sprache",
    "downloadEnglish": "Englische Sprache",
    "noDataTitle": "Keine Daten",
    "noData": "Bitte laden Sie die CSU Excel Datei hoch.<br />Die Berechnung startet automatisch nach dem Hochladen.",
    "pendingTitle": "In Bearbeitung",
    "pending": "Ihre Daten werden verarbeitet.",
    "successTitle": "Abgeschlossen",
    "success": "Ihre Daten wurden erfolgreich importiert.<br/> Bitte fahren Sie mit Schritt 2 fort.",
    "failedTitle": "Import fehlgeschlagen",
    "failed": "Ihre Daten konnten nicht importiert werden, da diese Fehler enthalten.<br/> Bitte beheben Sie die Fehler und Importieren Sie die Daten erneut.",
    "shortErrorListHint": "Es wurden insgesamt {{numberOfErrors}} Fehler erkannt.",
    "longErrorListHint": "Es werden nur die ersten {{limit}} von insgesamt {{numberOfErrors}} Fehlern angezeigt."
  },
  "importButton": {
    "pendingLabel": "In Bearbeitung",
    "label": "Excel-Datei hochladen"
  },
  "importErrors": {
    "headers": {
      "row": "Zeile",
      "description": "Beschreibung",
      "column": "Spalte"
    },
    "downloadButtonText": "Eine Liste aller Fehler herunterladen (CSV)",
    "row": "Zeile",
    "inColumn": "in der Spalte",
    "Unexpected": "Die importierte Datei enthält nicht den erwarteten Inhalt, benutzen Sie bitte unsere Vorlagen.",
    "SheetNotFound": "Die importierte Datei enthält nicht den erwarteten Inhalt, benutzen Sie bitte unsere Vorlagen.",
    "Empty": "Es wurden nicht alle erwarteten Felder ausgefüllt.",
    "Row": {
      "FrUnexpectedDecimalValue": "Unerwarteter Wert für Dezimalfeld",
      "FrUnexpectedStringValue": "Unerwarteter Wert für Textfeld",
      "FrUnexpectedIntValue": "Unerwarteter Wert für Zahlenfeld",
      "FrUnexpectedBoolValue": "Unerwarteter Wert für Ja/Nein Feld",
      "FrRecycledPetRequiresPercentage": "Fehlender Prozentwert für recycletes PET",
      "FrRecycledPetTraysRequiresPercentage": "Fehlender Prozentwert für recycletes PET aus Schälchen und Tablets",
      "FrRecycledFlexiblePeRequiresPercentage": "Fehlender Prozentwert für recycletes flexibles PE",
      "FrRecycledRigidPeRequiresPercentage": "Fehlender Prozentwert für recycletes PE",
      "FrRecycledRigidPpRequiresPercentage": "Fehlender Prozentwert für recycletes PP",
      "FrRecycledRigidPsRequiresPercentage": "Fehlender Prozentwert für recycletes PS",
      "FrRecycledPetExceedsTotal": "Die Menge von recycletem PET übersteigt die Gesamtmenge von PET in der Verpackung",
      "FrRecycledFlexiblePeHouseholdPercentageExceedsTotal": "Die Menge von recycletem flexiblem PE aus Haushalten übersteigt die Gesamtmenge von PE in der Verpackung",
      "FrRecycledFlexiblePeExceedsTotal": "Die Menge von recycletem flexiblem PE übersteigt die Gesamtmenge von PE in der Verpackung",
      "FrRecycledRigidPeExceedsTotal": "Die Menge von recycletem PE übersteigt die Gesamtmenge von PE in der Verpackung",
      "FrRecycledRigidPpExceedsTotal": "Die Menge von recycletem PP übersteigt die Gesamtmenge von PP in der Verpackung",
      "FrRecycledRigidPsExceedsTotal": "Die Menge von recycletem PS übersteigt die Gesamtmenge von PS in der Verpackung",
      "FrPackagingElementRequired": "Es ist mindestens ein Verpackungselement erforderlich",
      "FrSumOfWeightsIsZero": "Das Gesamtgewicht der Verpackung kann nicht 0 sein",
      "FrSumOfWeightsExceedsMaximumPossibleWeight": "Das Gesamtgewicht der Materialien übersteigt das maximal mögliche Gewicht für die angegebenen Verpackungselemente",
      "FrRecycledCardboardRequiresCardboard": "Der Bonus für recyclete Pappe erfordert ein Gewicht für Pappe",
      "FrPenaltyRequiresPet": "Der gewählte Malus erfordert ein Gewicht für PET",
      "FrPenaltyRequiresGlassAndPet": "Der gewählte Malus erfordert ein Gewicht für Glas und PET",
      "FrPenaltyRequiresCardboard": "Der gewählte Malus erfordert ein Gewicht für Pappe",
      "FrPenaltyRequiresRigidPlastic": "Der gewählte Malus erfordert ein Gewicht für festes Plastik",
      "FrPenaltyRequiresGlassAndSteel": "Der gewählte Malus erfordert ein Gewicht für Glas und Stahl",
      "FrPenaltyRequiresPvc": "Der gewählte Malus erfordert ein Gewicht für PVC",
      "FrPenaltyRequiresOpaquePet": "Der gewählte Malus erfordert ein Gewicht für undurchsichtiges PET",
      "FrPenaltyRequiresRigidPetAndAluPvcOrSilicone": "Der gewählte Malus erfordert ein Gewicht für festes PET und für Aluminium, PVC oder Silikon",
      "FrPenaltyRequiresGlass": "Der gewählte Malus erfordert ein Gewicht für Glas",
      "FrPenaltyRequiresGlassAndCeramics": "Der gewählte Malus erfordert ein Gewicht für Glas und Keramik",
      "FrPenaltyInvalid": "Der gewählte Malus ist nicht bekannt",
      "FrPenaltyCategoryInvalid": "Der gewählte Malus ist für die gewählte Kategorie unzulässig",
      "FrAwarenessRaisingMeasureInvalid": "Der gewählte Bonus für Bewusstseinsförderung ist nicht bekannt",
      "FrReductionMeasureInvalid": "Der gewählte Bonus für Reduktionsmaßnahmen ist nicht bekannt",
      "FrProductCodeInvalid": "Der ausgewählte Produktcode ist nicht bekannt",
      "FrReusedExceedsTotalQuantity": "Die Anzahl der wiederverwendeten Verpackungen übersteigt die Gesamtmenge an UVC",
      "FrProductDescriptionRequired": "Fehlende Produktbeschreibung",
      "FrProductCategoryCodeRequired": "Fehlender Produktkategorieschlüssel",
      "FrCsuPutOnMarketRequired": "Die Menge an in Verkehr gebrachten Einheiten darf nicht 0 sein",
      "FrPackagingElementAboveThresholdRequired": "Es ist mindestens ein Verpackungselement über 0,1g erforderlich",
      "FrPercentageOutOfRange": "Prozentwerte für recyclete Materialien müssen zwischen 0% und 100% liegen",
      "FrPenaltyRequiresPetPePp": "Der gewählte Malus erfordert mindestens ein Gewicht für PET, PE oder PP",
      "FrRenewableResourcesInvalid": "Der gewählte Bonus für erneuerbare Resourcen ist nicht bekannt"
    }
  },
  "calculateStep": {
    "pollingTitle": "Wird berechnet...",
    "polling": "Die Mengen werden berechnet.<br/>Dieser Vorgang kann eine kurze Zeit in Anspruch nehmen.",
    "notReleasedTitle": "Nicht freigegeben",
    "notReleased": "Die Meldungen können nicht angezeigt werden, da diese noch nicht freigegeben wurden.",
    "failedTitle": "Berechnung fehlgeschlagen",
    "failed": "Bei der Berechnung ist ein Fehler aufgetreten.<br/>Bitte behen Sie die Fehler und Importieren Sie die Daten erneut.",
    "successTitle": "Berechnung abgeschlossen",
    "success": "Bitte fahren Sie mit Schritt 3 fort und überprüfen Sie Ihre Meldung."
  },
  "notYetCreatedScreen": {
    "title": "Die Mengen wurden noch nicht berechnet",
    "hint": "Sie können die Mengen jetzt berechnen lassen. Überprüfen Sie bitte vorher, ob die dafür benötigten Daten verfügbar sind.",
    "calculate": "Jetzt berechnen"
  },
  "auditorExport": {
    "title": "Zusammenfassung für Wirtschaftsprüfer",
    "subtitle": "Alle in Verkehr gesetzten Mengen, auch nicht systembeteiligungspflichtige, zusammengefasst nach",
    "responsibility": "Verantwortlichkeit",
    "article": "Artikel",
    "obligation": "Systembeteiligungspflicht",
    "movementType": "Art der Warenbewegung",
    "packagingType": "Art der Verpackung",
    "reason": "Grund der Inverkehrsetzung",
    "creationPending": "Wird erstellt ...",
    "download": "Herunterladen"
  },
  "declarationExport": {
    "file": {
      "systemParticipation": {
        "header": "Systembeteiligung",
        "values": {
          "Yes": "Ja",
          "No": "Nein",
          "Failed": "Fehler"
        }
      },
      "reason": {
        "header": "Grund",
        "values": {
          "ProducerOnPackaging": "Erstinverkehrsetzung",
          "ProducerIsImporter": "Import (Käufer trägt Risiko)",
          "DelegationFromCustomer": "Serviceverpackung (Delegation)",
          "Shipping": "Versandverpackung",
          "AdditionalPlacing": "Sonstige Mengen",
          "SaleFromBrandOwner": "Verkauf von Markeninhaber"
        }
      },
      "reportingType": "Meldung (Typ)",
      "reportingName": "Meldung (Name)",
      "reportingId": "Meldung (ID)",
      "reportingLUCID": "Meldung (LUCID)",
      "licensingType": "Lizenzierung (Typ)",
      "licensingName": "Lizenzierung (Name)",
      "licensingId": "Lizenzierung (ID)",
      "licensingLUCID": "Lizenzierung (LUCID)",
      "paymentType": "Kosten (Typ)",
      "paymentName": "Kosten (Name)",
      "paymentId": "Kosten (ID)",
      "paymentLUCID": "Kosten (LUCID)",
      "systemProvider": "Systembetreiber",
      "glass": "Glas (kg)",
      "fiber": "PPK (kg)",
      "ferrousMetal": "Eisenmetalle (kg)",
      "aluminum": "Aluminium (kg)",
      "plastic": "Kunststoffe (kg)",
      "beverageCarton": "Getränkekarton-Verpackungen (kg)",
      "other": "Andere Materialien (kg)",
      "otherComposite": "Sonstige Verbund-Verpackungen (kg)",
      "placings": "Mengen",
      "preview": "[in Prüfung]",
      "responsible_type": {
        "Customers": "Kunden",
        "Producer": "Unternehmen",
        "Supplier": "Lieferant",
        "Customer": "Kunde"
      }
    },
    "title": "Zusammenfassung nach Verantwortlichkeit",
    "subtitle": "Alle in Verkehr gesetzten Mengen, zusammengefasst nach",
    "producer": "Wer hat die Mengen in Verkehr gesetzt?",
    "licenser": "Wer lizenziert die Mengen?",
    "payment": "Wer trägt die Kosten der Lizenzierung?",
    "reason": "Was ist der Grund der Inverkehrsetzung?",
    "download": "Herunterladen",
    "volumes": "Mengen"
  },
  "exportDialog": {
    "title": "Exportieren",
    "buttonText": "Exportieren"
  },
  "finishStep": {
    "title": "Abgeschlossen",
    "successHint": "Der Meldeprozess wurde abgeschlossen.<br /> Zur Auswertung der gemeldeten Mengen können Sie diese exportieren und weiter verarbeiten.",
    "thankYou": "Vielen Dank!"
  },
  "csuFinishStep": {
    "title": "Abgeschlossen",
    "successHint": "Die Berechnung Ihrer detaillierten Meldung ist abgeschlossen.\n\nBitte laden Sie die Ergebnisse herunter und fahren Sie in Ihrem MyLéko-Portal fort.\nIhre heruntergeladene Datei zeigt auch die Ergebnisse für jede Zeile Ihrer Erklärung.\n\nHinweis: Um eine weitere Meldung zu erstellen, klicken Sie auf \"Alle Inverkehrbringer\" und fügen Sie einen neuen Inverkehrbringer hinzu oder ändern Sie einen bestehenden.\n\nVielen Dank, dass Sie Calculate nutzen!",
    "download": "Herunterladen"
  },
  "licenseDetail": {
    "reportInfoTitle": "Melden Sie bitte die Mengen an die Systembetreiber",
    "reportInfoText": "<strong>{{producer}}</strong> übernimmt für folgende Mengen die Lizenzierung. Die Mengen werden bei einem oder mehreren Systembetreibern lizenziert.",
    "markAsReported": "Als gemeldet markieren",
    "systemProvider": "Systembetreiber",
    "distributedBy": "In Verkehr gesetzt von"
  },
  "licenseList": {
    "licensedBy": "Unternehmen",
    "status": "Status"
  },
  "licenseListRow": {
    "reported": "gemeldet",
    "notReported": "nicht gemeldet",
    "showReport": "Meldung anzeigen",
    "reportToSystemProvider": "An Systembetreiber melden",
    "noReportRequired": "Meldung nicht benötigt",
    "addContract": "Vertrag hinzufügen"
  },
  "licenseStep": {
    "showAllDistributors": "Alle Unternehmen anzeigen"
  },
  "thirdPartyLicensingForm": {
    "systemProvider": "Systembetreiber",
    "delete": "Löschen",
    "save": "Speichern",
    "confirmDeletion": "Wollen Sie den Eintrag wirklich löschen?",
    "hasWeightsLabel": "Der Lizenznehmer hat die lizenzierten Mengen mitgeteilt"
  },
  "thirdPartyLicensingStep": {
    "reporting": "Meldung",
    "licensing": "Lizenzierung",
    "systemProvider": "Systembetreiber",
    "source": "Quelle",
    "licensee": "Lizenznehmer",
    "calculate": "Calculate",
    "noSystem": "Kein Systembetreiber ausgewählt",
    "noValues": "Es wurden keine Informationen vom Lizenznehmer hinterlegt",
    "title": "Vergleichen Sie die errechneten Mengen bitte mit denen, die vom Lizenznehmer gemeldet wurden",
    "subtitle": "Passen Sie die errechneten Werte basierend auf den, vom Lizenznehmer gemeldeten Werten an und überprüfen Sie bitte Abweichungen auf Plausibilität.\n\nDie angepassten Werte werden für die Berechnungen in späteren Schritten des Prozesses weiterverwendet.",
    "thirdPartyLicensingReportingIntervalHint": "Bitte beachten Sie, dass sich das Meldeintervall dieser Gewichte aus den Verträgen der lizenzierenden Unternehmen mit den Systembetreibern ergibt."
  },
  "reviewStep": {
    "title": "Überprüfen Sie bitte, ob die in Verkehr gesetzten Mengen die errechnet wurden plausibel sind",
    "subtitle":{
      "de": "Sind die Mengen nicht plausibel, so können Sie die Meldungen jetzt verwerfen, die Datenbasis anpassen und die Meldungen neu erstellen.\nSind die Mengen plausibel, so können Sie diese jetzt freigeben.\nDurch die Freigabe werden die Mengen festgeschrieben.\nAlle für die Meldung an LUCID und die Systembetreiber Verantwortlichen der einzelnen Inverkehrsetzer werden über die Freigabe der Meldungen informiert",
      "at": "Sind die Mengen nicht plausibel, so können Sie die Meldungen jetzt verwerfen, die Datenbasis anpassen und die Meldungen neu erstellen.\nSind die Mengen plausibel, so können Sie diese jetzt freigeben.\nDurch die Freigabe werden die Mengen festgeschrieben."
    },
    "company": "Unternehmen",
    "notObligated": "Keine Abgabepflicht für den gewählten Zeitraum",
    "compareWith": "Vergleichen mit",
    "responsibilities": {
      "reporting": "Meldung",
      "licensing": "Lizenzierung",
      "payment": "Kosten"
    }
  },
  "reportDetail": {
    "showDetails": "Details anzeigen"
  },
  "reportDetailPerLicenser": {
    "licensedBy": "Lizenziert von",
    "systemProvider": "Systembetreiber"
  },
  "reportDetailPerSystem": {
    "title": "Melden Sie bitte die Mengen an LUCID",
    "subtitle": "<strong>{{producer}}</strong> hat folgende Mengen in Verkehr gesetzt.<br />\nDie Mengen werden bei einem oder mehreren Systembetreibern lizenziert und auch pro Systembetreiber and LUCID\ngemeldet.",
    "thirdPartyLicensingReportingIntervalHint": "Bitte beachten Sie, dass sich das Meldeintervall dieser Gewichte aus den Verträgen der lizenzierenden Unternehmen mit den Systembetreibern ergibt.",
    "markAsReported": "Als gemeldet markieren",
    "systemProvider": "Systembetreiber"
  },
  "adjustStep": {
    "showAllProducers": "Alle Unternehmen anzeigen"
  },
  "adjustList": {
    "producer": "Unternehmen",
    "packagingType": "Verpackungsart"
  },
  "adjustListRow": {
    "showPlacings": "Mengen verwalten",
    "noReportRequired": "Meldung nicht benötigt",
    "addContract": "Vertrag hinzufügen"
  },
  "reportList": {
    "distributedBy": "Unternehmen",
    "status": "Status"
  },
  "reportListRow": {
    "reported": "gemeldet",
    "notReported": "nicht gemeldet",
    "showReport": "Meldung anzeigen",
    "reportToLUCID": "An LUCID melden",
    "noReportRequired": "Meldung nicht benötigt",
    "addContract": "Vertrag hinzufügen"
  },
  "reportStep": {
    "showAllDistributors": "Alle Unternehmen anzeigen"
  },
  "declarationErrors": {
    "download": {
      "description": "Beschreibung",
      "branchId": "Niederlassung ID",
      "articleId": "Artikel ID",
      "supplierId": "Lieferant ID",
      "quantity": "Menge",
      "issues": "Probleme"
    },
    "highPriorityTitle": "Probleme mit der höchsten Priorität",
    "highPrioritySubtitle": "Die {{number}} Probleme mit der höchsten Priorität.",
    "downloadTitle": "Alle herunterladen",
    "downloadSubtitle": "Sie können die komplette Liste der Probleme als CSV-Datei (UTF-8) herunterladen.",
    "downloadButtonText": "Herunterladen als CSV (UTF-8)"
  },
  "declarationErrorsTable": {
    "issues": {
      "branchRequired": "Die Niederlassung kann nicht gefunden werden.",
      "producerIdRequired": "An der Niederlassung ist die ID des Inverkehrbringers ist nicht definiert.",
      "articleRequired": "Artikel kann nicht gefunden werden.",
      "servicePackagingRequired": "Serviceverpackung kann nicht gefunden werden.",
      "supplierCountryRequired": "Das Land der Herkunft des Artikels kann nicht ermittelt werden.",
      "supplierIdRequired": "Lieferant kann nicht ermittelt werden.",
      "supplierIncoTermRequired": "Vereinbarter INCO Term mit dem Lieferanten kann nicht ermittelt werden.",
      "delegationOfLicensingRequired": "Vereinbarung bezüglich der Lizenzierung kann nicht ermittelt werden.",
      "delegationOfPaymentRequired": "Vereinbarung bezüglich der Kosten für die Lizenzierung kann nicht ermittelt werden.",
      "salePackagingRequired": "Verkaufsverpackung kann nicht gefunden werden.",
      "salePackagingWeightsRequired": "Gewicht der Verkaufsverpackung beträgt 0 kg.",
      "servicePackagingWeightsRequired": "Gewicht der Serviceverpackung beträgt 0 kg.",
      "articleQuantityRequired": "Menge die ein Artikel umfasst ist nicht definiert.",
      "shipToCountryRequired": "Das Land, in das geliefert wird, fehlt bei mindestens einer Warenbewegung.",
      "customerDelegatesObligationRequired": "Information, ob mit dem Kunden eine Delegationsvereinbarung bezüglich Serviceverpackungen besteht, fehlt bei mindestens einer Warenbewegung.",
      "companyRequired": "Information, welches Unternehmen für die Warenbewegung verantwortlich ist, fehlt.",
      "producerOnPackagingRequired": "Die ID des Herstellers auf der Verpackung ist nicht definiert.",
      "manufacturersRequired": "Informationen über die Hersteller des Artikels fehlen, um die erste Inverkehrsetzung auf dem Markt erkennen zu können.",
      "supplierRequired": "Lieferant kann nicht gefunden werden.",
      "householdPackagingRequired": "Haushaltsverpackung kann nicht gefunden werden.",
      "householdPackagingWeightsRequired": "Gewicht der Haushaltsverpackung beträgt 0 kg.",
      "commercialPackagingRequired": "Gewerbeverpackung kann nicht gefunden werden.",
      "commercialPackagingWeightsRequired": "Gewicht der Gewerbeverpackung beträgt 0 kg."
    },
    "description": "Beschreibung",
    "branchId": "Niederlassung ID",
    "articleId": "Artikel ID",
    "supplierId": "Lieferant ID",
    "quantity": "Menge"
  },
  "issuesStep": {
    "issuesTitle": "Es wurden Probleme erkannt, die eine vollständige Ermittlung der in Verkehr gesetzten Mengen verhindern",
    "issuesSubtitle": "Die Probleme wurden für Sie priorisiert. Nehmen Sie bitte Anpassungen an der Datenbasis vor, um die Probleme zu beheben.<br />\nDie Behebung der Probleme wird erst dann wirksam, wenn die Mengen verworfen und neu berechnet wurden.",
    "noIssuesTitle": "Es wurden keine Probleme erkannt",
    "noIssuesSubtitle": "Sie können mit der Freigabe fortfahren.",
    "totalCosts": "Gesamtkosten"
  },
  "csuValidateStep": {
    "noIssuesTitle": "Die detaillierte Meldung wurde erfolgreich berechnet.",
    "noIssuesSubtitle": "Um den Prozess abzuschließen, bitte auf Weiter drücken. Verwerfen, wenn Sie erneut importieren wollen.",
    "totalCosts": "Total cost"
  },
  "validationTable": {
    "membershipId": "Unternehmen",
    "costs": "Kosten"
  },
  "reportHistory": {
    "showButtonTitle": "Verlauf der Meldungen anzeigen",
    "modalTitle": "Verlauf der Meldungen"
  }
}
