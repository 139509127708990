{
  "footer": {
    "address": "SynoptiCons Deutschland GmbH · Austraße 34 · 35745 Herborn · Germany"
  },
  "InitiateAuth": {
    "title": "Sign in to Calculate",
    "description": "The comprehensive solution for fulfilment of corporate EPR obligations.",
    "username": "Username",
    "password": "Password",
    "signIn": "Sign in",
    "forgotPassword": "Forgot password?",
    "signInWithGoogle": "Sign in with Google"
  },
  "ForgotPassword": {
    "title": "Reset password",
    "description": "Please enter your username and request a code for password reset.",
    "username": "Username",
    "getCode": "Get code for password reset"
  },
  "ConfirmForgotPassword": {
    "title": "Reset password",
    "description": "A confirmation code has been sent to you. Please enter the verification code and your new password.",
    "code": "Code",
    "newPassword": "New password",
    "setPassword": "Set password"
  },
  "NewPasswordRequiredChallenge": {
    "title": "New password required",
    "description": "A new password is required. Please enter your new password.",
    "newPassword": "New password",
    "setPassword": "Set password"
  },
  "SmsMfaChallenge": {
    "title": "Enter verification code",
    "description": "A verification code has been sent to {{codeDeliveryDestination}}. Please enter the code to proceed.",
    "code": "Code",
    "verify": "Verify"
  },
  "AuthErrorView": {
    "NotAuthorized": "Sign in failed.",
    "InvalidPassword": "The new password does not meet the password policy.",
    "CodeMismatch": "Verification code is wrong.",
    "UserNotFound": "Account not found.",
    "ExpiredCode": "Verification code is not valid anymore.",
    "LimitExceeded": "Limit exceeded. Please try again later."
  },
  "LogOutButton": {
    "label": "Sign out"
  },
  "passwordPolicy": "At least 8 characters, at least one number, one special character, one uppercase letter, one lowercase letter and no spaces."
}