import * as React from 'react';
import { optionalTextField, StateOf, textField } from '../../forms';
import { InputGroup } from './InputGroup';
import { useState } from 'react';
import { Icon } from './Icon';

export interface Props {
  id: string;
  field: StateOf<typeof textField> | StateOf<typeof optionalTextField>;
  focusOnMount?: boolean;
  disabled?: boolean;
  maxLength?: number;
  type?: 'text' | 'password';
  autoComplete?: string;
  hideErrorUntilTouched?: boolean;
}

export function TextInput(props: Props) {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const inputRef = React.useRef<HTMLInputElement>();

  React.useLayoutEffect(() => {
    if (props.focusOnMount) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 200);
    }
  }, []);

  let inputClassName = 'form-control';

  if (!props.field.status.valid && (!props.hideErrorUntilTouched || props.field.touched)) {
    inputClassName += ' is-invalid';
  }

  const togglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <InputGroup field={props.field}>
      <input
        disabled={props.disabled}
        className={inputClassName}
        maxLength={props.maxLength}
        id={props.id}
        ref={(e: HTMLInputElement) => (inputRef.current = e)}
        type={props.type === 'password' ? (passwordVisible ? 'text' : 'password') : props.type}
        value={props.field.value ?? ''}
        onInput={e => props.field.setValue(e.currentTarget.value)}
        onFocus={() => props.field.setTouched(true)}
        autoComplete={props.autoComplete || 'off'}
      />
      {props.type === 'password' ? (
        <button id="toggle-password" type="button" className="btn btn-outline-secondary" onClick={togglePassword}>
          {passwordVisible ? <Icon name="eye-slash" /> : <Icon name="eye" />}
        </button>
      ) : (
        <></>
      )}
    </InputGroup>
  );
}
