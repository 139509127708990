﻿import { useProducers } from '../../private/producers/Producers';
import { ChangeEvent } from 'react';
import { StateOf, textField, optionalTextField } from '../../forms';
import { InvalidFeedback } from './InvalidFeedback';
import { InputGroup } from './InputGroup';

export interface Props {
  id?: string;
  field: StateOf<typeof textField> | StateOf<typeof optionalTextField>;
  disabled?: boolean;
}

export function ProducerInput(props: Props) {
  const producers = useProducers();
  const accessibleProducers = producers.accessible();

  function onChange(e: ChangeEvent<HTMLSelectElement>) {
    props.field.setValue(e.currentTarget.value);
  }

  let className = 'form-select';

  if (!props.field.status.valid) {
    className += ' is-invalid';
  }

  return (
    <InputGroup field={props.field}>
      <select
        id={props.id}
        disabled={props.disabled}
        className={className}
        value={props.field.value ?? ''}
        onChange={onChange}>
        <option value=""></option>
        {Object.entries(accessibleProducers).map(([id, producer]) => (
          <option key={id} value={id}>
            {producer.name} ({id})
          </option>
        ))}
        {props.field.status.valid &&
          props.field.status.value !== null &&
          producers.get(props.field.status.value) == undefined && (
            <option value={props.field.status.value}>{props.field.status.value}</option>
          )}
      </select>
      <InvalidFeedback status={props.field.status} />
    </InputGroup>
  );
}
