﻿import { useTranslation } from 'react-i18next';
import { Icon } from '../../../../shared/components/Icon';
import { useYearParam } from '../../../../shared/hooks/useParam';

export function Downloads() {
  const { t } = useTranslation('reports');
  const year = useYearParam();

  const [frenchExcel, englishExcel] = (() => {
    switch (year) {
      case 2022:
        return [
          new URL('../../../../../assets/downloads/leko_declaration_detaillee_2022.xlsx', import.meta.url),
          new URL('../../../../../assets/downloads/leko_detailed_declaration_2022.xlsx', import.meta.url),
        ];
      default:
        return [
          new URL('../../../../../assets/downloads/leko_detailed_declaration_2023.xlsx', import.meta.url),
          new URL('../../../../../assets/downloads/leko_detailed_declaration_2023.xlsx', import.meta.url),
        ];
    }
  })();

  return (
    <div className="text-center d-block mt-4">
      <div className="mb-2">{t('importStep.downloadText')}</div>
      <a
        className="mx-2 btn btn-outline-secondary btn-sm"
        download={`Léko - Declaration détaillée ${year}.xlsx`}
        target="_blank"
        href={frenchExcel.href}
        rel="noreferrer">
        <Icon name="download" className="me-2" /> {t('importStep.downloadFrench')}
      </a>
      <a
        className="mx-2 btn btn-outline-secondary btn-sm"
        download={`Léko - Detailed declaration ${year}.xlsx`}
        target="_blank"
        href={englishExcel.href}
        rel="noreferrer">
        <Icon name="download" className="me-2" /> {t('importStep.downloadEnglish')}
      </a>
    </div>
  );
}
