﻿import { createDeclarationExportAustria } from '../../../../api';
import { useTenant } from '../../../tenants/TenantDetail';
import { useTranslation } from 'react-i18next';
import { BucketDownloadButton } from '../../../../shared/components/BucketDownloadButton';

export function AuditorExportButton({ declarationId }: { declarationId: number }) {
  const tenant = useTenant();
  const { t } = useTranslation('reports');

  function getUrls() {
    return createDeclarationExportAustria({ tenantId: tenant.id, data: declarationId });
  }

  return (
    <BucketDownloadButton
      getUrls={getUrls}
      label={t('auditorExport.download')}
      downloadingLabel={t('auditorExport.creationPending')}
    />
  );
}

export function AuditorExport({ declarationId }: { declarationId: number }) {
  const { t } = useTranslation('reports');

  return (
    <section>
      <h1 className="h5">{t('auditorExport.title')}</h1>
      <p className="fw-light fs-5 mt-2">{t('auditorExport.subtitle')}</p>
      <ul>
        <li>{t('auditorExport.responsibility')}</li>
        <li>{t('auditorExport.article')}</li>
        <li>{t('auditorExport.obligation')}</li>
        <li>{t('auditorExport.movementType')}</li>
        <li>{t('auditorExport.packagingType')}</li>
        <li>{t('auditorExport.reason')}</li>
      </ul>
      <div className="mt-4 mb-2 text-end">
        <AuditorExportButton declarationId={declarationId} />
      </div>
    </section>
  );
}
