import { useTenant } from '../../private/tenants/TenantDetail';
import { Loader, useQuery } from './useQuery';

export function useTenantQuery<TRequest, TResponse>(
  loader: Loader<
    {
      tenantId: number;
      data: TRequest;
    },
    TResponse
  >,
  request: TRequest,
  automaticRevalidation = true
) {
  const tenant = useTenant();
  return useQuery(loader, { tenantId: tenant.id, data: request }, automaticRevalidation);
}
