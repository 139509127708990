﻿import { group, textField, optionalTextField, InputOf, countryField, TextField } from '../../forms';

export function branchForm(idField: TextField) {
  return group({
    branchId: idField,
    name: textField,
    producerId: optionalTextField,
    country: countryField,
  });
}

export type BranchFormDefinition = ReturnType<typeof branchForm>;

export type BranchFormInput = InputOf<BranchFormDefinition>;

export const emptyForm: BranchFormInput = {
  branchId: '',
  name: '',
  producerId: null,
  country: null,
};
