import { ChallengeContext, challengeSmsMfa } from '../../api';
import { useMutation } from '../../remote';
import { group, textField, useForm } from '../../forms';
import { AuthStateView } from './AuthStateView';
import { TextField } from '../../shared/components/TextField';
import { AuthErrorView } from './AuthErrorView';
import { useTranslation } from 'react-i18next';
import { FormEvent } from 'react';

export interface Props {
  context: ChallengeContext;
  codeDeliveryDestination: string;
}

const definition = group({
  smsMfaCode: textField,
});

export function SmsMfaChallenge({ context, codeDeliveryDestination }: Props) {
  const { t } = useTranslation('auth');

  const mutation = useMutation(challengeSmsMfa);

  const form = useForm(definition, { smsMfaCode: '' });

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (form.status.valid) {
      mutation.start({ ...form.status.value, context });
    }
  }

  if (mutation.isResolved) {
    const result = mutation.data;
    if (result.Case === 'Ok') {
      const state = result.Fields[0];
      return <AuthStateView state={state} />;
    }
  }

  return (
    <>
      <h1 className="h5 text-center text-secondary">{t('SmsMfaChallenge.title')}</h1>
      <p className="my-4 alert alert-primary">{t('SmsMfaChallenge.description', { codeDeliveryDestination })}</p>
      <form onSubmit={onSubmit} className="mb-3">
        <TextField
          focusOnMount
          id="code"
          label={t('SmsMfaChallenge.code')}
          field={form.fields.smsMfaCode}
          type="password"
          autoComplete="one-time-code"
        />
        <button disabled={!form.status.valid} className="btn btn-primary" type="submit">
          {t('SmsMfaChallenge.verify')}
        </button>
      </form>
      {mutation.isResolved && mutation.data.Case === 'Error' && <AuthErrorView error={mutation.data.Fields[0]} />}
    </>
  );
}
