{
  "overview": {
    "heading": "Producers",
    "hint": "To create a new declaration, select an existing producer or add one."
  },
  "navigation": {
    "declarations": "Declarations",
    "manageContracts": "Contracts",
    "manageBranches": "Branches",
    "manageProducers": "Producers"
  },
  "import": {
    "imports": "Imports",
    "refresh": "Refresh",
    "startedOn": "started on",
    "statedAt": "at",
    "finishedAt": "finished at",
    "success": "Successful",
    "failure": "Failure",
    "inProgress": "In Progress",
    "uploadFailedForFile": "The file could not be uploaded.",
    "uploadFailedForFiles": "The files could not be uploaded.",
    "uploadFailedForTheseFiles": "The following files could not be uploaded:\n {{files}}",
    "importStarts": "The files have been uploaded and are now being imported. This can take several minutes.",
    "uploadInProgress": "Uploading...",
    "buttonText": "Import"
  },
  "tenantModal": {
    "add": "Add producer",
    "title": "New producer",
    "delete": "Delete",
    "save": "Save",
    "confirmDeletion": "Do you really want to delete this producer?"
  },
  "form": {
    "name": "Name"
  },
  "noResult": {
    "title": "No producers fround",
    "description": "Please adjust your search criteria or add a producer"
  },
  "showMore": "Show more",
  "switchTenant": "All producers",
  "moreInformation": {
    "buttonLabel": "More information",
    "modalTitle": "Information",
    "contentTitle": "To create a declaration proceed as follows:",
    "contentBody": "<ol><li><strong>Search</strong> for an <strong>existing producer</strong>.<br>If there is no existing producer, <strong>add a new producer</strong> with an easy-to-rember name <i>(eg CompanyName)</i>.</li><li><strong>Open</strong> the new <strong>producer</strong> by clicking on the name.</li><li><strong>Create</strong> a new <strong>declaration</strong> by simply following the instructions on the next screens.</li><li><strong>Alternative declarations</strong> can be created by adding <strong>additional producers</strong>. For each producer a separate data import is required and so a different result can be obtained.</li></ol>"
  },
  "utmPrefixModal": {
    "title": "Set time period",
    "description": "Please set the time period for which the data should be imported.",
    "importButtonLabel": "Import",
    "choose": "Please choose",
    "wholeYear": "Whole year",
    "warnWholeYear": "Please make sure that the sales for all months have been imported before importing the sales for the whole year."
  },
  "countrySwitch": {
    "countries": {
      "de": "Germany",
      "fr": "France",
      "at": "Austria"
    }
  }
}
