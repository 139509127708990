﻿import { Navigate, Outlet, RouteObject } from 'react-router';
import { tryGetDeclarationFrance } from '../../../api';
import { Suspense } from 'react';
import { importRoute } from './import/ImportStep';
import { validateRoute } from './validate/ValidateStep';
import { Steps } from './Steps';
import { Fallback } from '../../../Fallback';
import { finishRoute } from './finish/FinishStep';
import { useTenantQuery } from '../../../shared/hooks/useTenantQuery';
import { usePolling } from '../../../shared/hooks/usePolling';
import { useQueryValue } from '../../../shared/hooks/useQueryValue';
import { SupportHint } from './SupportHint';
import { useYearParam } from '../../../shared/hooks/useParam';

function NavigateToInitialStep() {
  return <Navigate to="./import" replace />;
}

export const declarationDetailRoute: RouteObject = {
  path: 'declarations',
  element: <DeclarationDetail />,
  children: [{ index: true, element: <NavigateToInitialStep /> }, importRoute, validateRoute, finishRoute],
};

export function DeclarationDetail() {
  const year = useYearParam();

  const taskQuery = useTenantQuery(tryGetDeclarationFrance, year, false);
  const task = useQueryValue(taskQuery);

  function revalidate() {
    return taskQuery.revalidate();
  }

  usePolling(task, revalidate, 4000);

  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="d-flex flex-row mx-4 flex-grow-1 justify-content-start align-items-stretch">
        <div className="col-3 list-group list-group-flush sticky-top justify-content-start flex-grow-1">
          <Steps task={task} />
          <div className="mt-auto">
            <SupportHint />
          </div>
        </div>
        <div className="col-9">
          <Suspense fallback={<Fallback />}>
            <Outlet context={task} />
          </Suspense>
        </div>
      </div>
    </div>
  );
}
