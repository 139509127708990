﻿import { getAllReports, getPeriods, Period, tryGetDeclarationAustria, withdraw } from '../../../api';
import { useTenantMutation } from '../../../remote';
import { Icon } from '../../../shared/components/Icon';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useTenantRevalidate } from '../../../shared/hooks/useTenantRevalidate';
import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';

export interface Props {
  declarationId: number;
  year: number;
  period: Period;
}

export function WithdrawButton({ declarationId, year, period }: Props) {
  const { t } = useTranslation('reports');
  const revalidatePeriods = useTenantRevalidate(getPeriods, { country: 'Austria', year });
  const revalidateDeclaration = useTenantRevalidate(tryGetDeclarationAustria, { year, period });
  const revalidateReports = useTenantRevalidate(getAllReports, { country: 'Austria', year, period });

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  function revalidate() {
    return Promise.all([revalidatePeriods(), revalidateDeclaration(), revalidateReports()]);
  }

  const navigate = useNavigate();

  const mutation = useTenantMutation(withdraw);

  if (mutation.isPending) {
    throw mutation.promise;
  }
  function startWithdraw() {
    mutation.start(
      { declarationId: declarationId, declarationType: { country: 'Austria', year, period } },
      {
        async onSuccess() {
          handleClose();
          await revalidate();
          navigate('../create');
        },
      }
    );
  }

  return (
    <>
      <button className="btn btn-danger text-white me-2" onClick={() => handleShow()} disabled={mutation.isPending}>
        <Icon name="trash" /> {t('withdrawButton.withdraw')}
      </button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('withdrawButton.withdraw')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trans t={t}>{t('withdrawButton.confirm')}</Trans>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('withdrawDialog.button.cancel')}
          </Button>
          <Button variant="primary" onClick={() => startWithdraw()}>
            {t('withdrawDialog.button.confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
