﻿import { DeclarationCountry, getAllSystems, SystemData } from '../../api';
import { createContext, ReactNode, useContext } from 'react';
import { useQueryState } from '../../shared/hooks/useQueryState';
import { useQuery } from '../../shared/hooks/useQuery';

export interface SystemsService {
  get(id: number): SystemData | null;

  all: Record<number, SystemData>;
}

const SystemsContext = createContext<SystemsService | null>(null);

export function Systems({ country, children }: { country: DeclarationCountry; children?: ReactNode }) {
  const systemsQuery = useQuery(getAllSystems, country, false);
  const [systems] = useQueryState(systemsQuery);

  const service: SystemsService = {
    get(id: number): SystemData | null {
      return systems[id];
    },
    all: systems,
  };

  return <SystemsContext.Provider value={service}>{children}</SystemsContext.Provider>;
}

export function useSystems(): SystemsService {
  const service = useContext(SystemsContext);

  if (service == null) {
    throw new Error('Systems must be defined higher in tree.');
  }

  return service;
}
