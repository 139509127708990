import { Icon } from '../../shared/components/Icon';
import { useTranslation } from 'react-i18next';

export function LoadMoreItem({ onLoadMore, disabled }: { onLoadMore?: () => void; disabled?: boolean }) {
  const { t } = useTranslation('tenants');
  return (
    <div className="list-group-item text-center">
      <button disabled={disabled} className="btn btn-outline-secondary" onClick={onLoadMore}>
        <Icon name="arrow-bar-down" className="me-2" />
        {t('showMore')}
      </button>
    </div>
  );
}
