﻿import { FallbackAnimation } from '../../Fallback';
import * as React from 'react';
import { InfoScreen } from './InfoScreen';

export interface Props {
  title: string;
  children?: React.ReactNode;
}

export function PollingScreen({ title, children }: Props) {
  return (
    <InfoScreen title={title} icon="clock-history">
      <p>{children}</p>
      <div className="p-4">
        <FallbackAnimation />
      </div>
    </InfoScreen>
  );
}
