import * as React from 'react';

export type ColSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface Props {
  children?: React.ReactNode;
  size?: ColSize;
}

export function Col(props: Props) {
  const className = props.size == null ? 'col' : `col-${props.size}`;
  return <div className={className}>{props.children}</div>;
}
