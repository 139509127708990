﻿import { Icon } from '../../../shared/components/Icon';
import { useTranslation } from 'react-i18next';

export function SystemsOfCustomersInfo() {
  const { t } = useTranslation('reports');
  return (
    <div className="d-flex flex-row text-danger align-items-center">
      <Icon name="exclamation-diamond" className="fs-4 me-3" />
      <span>{t('systemsOfCustomersInfo.unknownSystemProvider')}</span>
    </div>
  );
}
