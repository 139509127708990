﻿import { AggregatedPackagingWeightsAustria, DeclarationResultAustria, Responsible } from '../../../../api';
import { Outlet, RouteObject, useOutletContext } from 'react-router';
import {
  Aggregation,
  filterAndAggregateWeights,
  filterAndGroupResponsibilities,
  getEmptyWeights,
  tryGetIdOfProducerThatHasToLicense,
} from '../helpers';
import { useProducers } from '../../../producers/Producers';
import { compareResponsible } from '../CompareResponsible';
import { useSorting } from '../../../../shared/hooks/useSorting';
import { Icon } from '../../../../shared/components/Icon';
import { ResponsibleView } from '../ResponsibleView';
import { selectAndNumericCompare } from '../selectAndNumericCompare';
import { PackagingWeightsKeys } from '../PackagingWeightsKeys';
import { useReports } from '../../Reports';
import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { Trans, useTranslation } from 'react-i18next';
import { ContractEdit } from '../../../contracts/at/ContractEdit';
import { Actions } from '../Actions';
import { NavLink } from 'react-router-dom';
import { usePeriodParam, useStringParams, useYearParam } from '../../../../shared/hooks/useParam';
import { SortableHeaderCell } from '../SortableHeaderCell';
import { SortableWeightsHeaderCells } from '../SortableWeightsHeaderCells';
import { WeightsRowCells } from '../WeightsRowCells';
import { ReportHistory } from '../../de/ReportHistory';
import { AuditorExportButton } from '../export/AuditorExport';

function LicenseDetailRow({
  weights,
  reporting,
}: {
  producerId: string;
  weights: AggregatedPackagingWeightsAustria;
  reporting: Responsible;
}) {
  return (
    <tr>
      <td className="align-middle">
        <ResponsibleView responsible={reporting} />
      </td>
      <WeightsRowCells weights={weights} />
    </tr>
  );
}

type Row = Aggregation<{ licensing: Responsible }>;

export const licenseDetailRoute: RouteObject = {
  path: ':producerId',
  element: <LicenseDetail />,
  children: [
    {
      path: 'contracts/edit/:contractProducerId',
      element: <ContractEdit />,
    },
  ],
};

export function LicenseDetail() {
  const { t } = useTranslation('reports');

  const [period, year] = [usePeriodParam(), useYearParam()];
  const { producerId } = useStringParams<'producerId'>();
  const declaration = useOutletContext<DeclarationResultAustria>();

  const groups = filterAndGroupResponsibilities(tryGetIdOfProducerThatHasToLicense, declaration.responsibilities);

  const responsibilities = groups.find(g => g.key === producerId)?.responsibilities ?? [];

  const rows: Row[] = filterAndAggregateWeights(row => {
    if (row.licensing === null) {
      return null;
    }

    return {
      licensing: row.licensing,
    };
  }, responsibilities);

  const producers = useProducers().all;

  const reports = useReports();
  const report = reports.get(declaration.declarationId, producerId);

  function compareLicensing(a: Row, b: Row) {
    return compareResponsible(producers, a.key.licensing, b.key.licensing);
  }

  const emptyResponsible: Responsible = {
    Case: 'Producer',
    Fields: [producerId],
  };

  const emptyWeights: AggregatedPackagingWeightsAustria = getEmptyWeights();

  const sorting = useSorting<Row, 'licensing' | PackagingWeightsKeys>(
    rows,
    {
      licensing: compareLicensing,
      glassHouseHold: selectAndNumericCompare<Row>(r => r.weights.glassHouseHold),
      fiberHouseHold: selectAndNumericCompare<Row>(r => r.weights.fiberHouseHold),
      ferrousMetalHouseHold: selectAndNumericCompare<Row>(r => r.weights.ferrousMetalHouseHold),
      aluminumHouseHold: selectAndNumericCompare<Row>(r => r.weights.aluminumHouseHold),
      plasticHouseHold: selectAndNumericCompare<Row>(r => r.weights.plasticHouseHold),
      beverageCartonHouseHold: selectAndNumericCompare<Row>(r => r.weights.beverageCartonHouseHold),
      compositeHouseHold: selectAndNumericCompare<Row>(r => r.weights.compositeHouseHold),
      ceramicHouseHold: selectAndNumericCompare<Row>(r => r.weights.ceramicHouseHold),
      woodHouseHold: selectAndNumericCompare<Row>(r => r.weights.woodHouseHold),
      textileHouseHold: selectAndNumericCompare<Row>(r => r.weights.textileHouseHold),
      organicHouseHold: selectAndNumericCompare<Row>(r => r.weights.organicHouseHold),
      fiberCommercial: selectAndNumericCompare<Row>(r => r.weights.fiberCommercial),
      ferrousMetalCommercial: selectAndNumericCompare<Row>(r => r.weights.ferrousMetalCommercial),
      aluminumCommercial: selectAndNumericCompare<Row>(r => r.weights.aluminumCommercial),
      foilsCommercial: selectAndNumericCompare<Row>(r => r.weights.foilsCommercial),
      hollowBodiesCommercial: selectAndNumericCompare<Row>(r => r.weights.hollowBodiesCommercial),
      epsCommercial: selectAndNumericCompare<Row>(r => r.weights.epsCommercial),
      compositeCommercial: selectAndNumericCompare<Row>(r => r.weights.compositeCommercial),
      ceramicCommercial: selectAndNumericCompare<Row>(r => r.weights.ceramicCommercial),
      woodCommercial: selectAndNumericCompare<Row>(r => r.weights.woodCommercial),
      textileCommercial: selectAndNumericCompare<Row>(r => r.weights.textileCommercial),
      organicCommercial: selectAndNumericCompare<Row>(r => r.weights.organicCommercial),
    },
    { key: 'licensing', direction: 'asc' }
  );

  const producer = producers[producerId];

  return (
    <>
      {groups.length === 1 ? (
        <Actions year={year} period={period} declaration={declaration} />
      ) : (
        <div className="my-4">
          <NavLink className="btn btn-outline-secondary" to=".." end>
            <>
              <Icon name="chevron-left" />
              {t('reportStep.showAllDistributors')}
            </>
          </NavLink>
        </div>
      )}
      <div className="my-3 d-flex align-items-center">
        <div style={{ width: '5rem', lineHeight: '4rem' }} className="text-center">
          <Icon name="building" className="fs-1" />
        </div>
        <div className="flex-grow-1">
          <h1 className="h2 my-0">{producer?.name ?? producerId}</h1>
          {producer && <div className="text-muted">ID: {producer.id}</div>}
        </div>
      </div>
      <div className="bg-light rounded mb-4">
        <InfoScreen icon="send" color="primary" title={t('licenseDetail.reportInfoTitle')}>
          <p>
            <Trans t={t}>{t('licenseDetail.reportInfoText', { producer: producer?.name ?? producerId })}</Trans>
          </p>
          <div className="form-check form-switch d-inline-block ms-4">
            <input
              className="form-check-input"
              id="cb-report"
              type="checkbox"
              checked={!!report?.proReportedAt}
              onChange={e => reports.report(declaration.declarationId, producerId, 'Pro', e.target.checked)}
            />
            <label className="form-check-label" htmlFor="cb-report">
              {t('licenseDetail.markAsReported')}
            </label>
          </div>
          <div className="mt-2">
            <ReportHistory
              producerId={producerId}
              type="Pro"
              renderDownloadButton={declarationId => <AuditorExportButton declarationId={declarationId} />}
            />
          </div>
        </InfoScreen>
      </div>
      <div className="table-responsive">
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <SortableHeaderCell sorting={sorting} sortKey="licensing" className="text-nowrap">
                {t('licenseDetail.distributedBy')}
              </SortableHeaderCell>
              <SortableWeightsHeaderCells sorting={sorting} />
            </tr>
          </thead>
          <tbody>
            {sorting.sorted.length > 0 ? (
              sorting.sorted.map(row => (
                <LicenseDetailRow
                  key={row.keyAsString}
                  reporting={row.key.licensing}
                  producerId={producerId}
                  weights={row.weights}
                />
              ))
            ) : (
              <LicenseDetailRow reporting={emptyResponsible} producerId={producerId} weights={emptyWeights} />
            )}
          </tbody>
        </table>
      </div>
      <Outlet />
    </>
  );
}
