import * as React from 'react';
import { optionalDateField, StateOf } from '../../forms';
import { InputGroup } from './InputGroup';

export interface Props {
  id: string;
  field: StateOf<typeof optionalDateField>;
  focusOnMount?: boolean;
  minDate?: string;
  maxDate?: string;
  disabled?: boolean;
}

export function DateInput(props: Props) {
  const inputRef = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    if (props.focusOnMount) {
      inputRef.current?.focus();
    }
  }, [props.focusOnMount]);

  let inputClassName = 'form-control';

  if (!props.field.status.valid) {
    inputClassName += ' is-invalid';
  }

  function onChange(e: React.FormEvent<HTMLInputElement>) {
    const value = !e.currentTarget.value ? null : e.currentTarget.value;

    props.field.setValue(value);
  }

  return (
    <InputGroup field={props.field}>
      <input
        disabled={props.disabled}
        className={inputClassName}
        id={props.id}
        ref={(e: any) => (inputRef.current = e)}
        type="date"
        value={props.field.value ?? ''}
        onChange={onChange}
        min={props.minDate}
        max={props.maxDate}
      />
    </InputGroup>
  );
}
