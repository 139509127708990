﻿import { getAllBranches, upsertBranch, deleteBranch, Branch } from '../../api';
import { createContext, ReactNode, useContext } from 'react';
import { useTenant } from '../tenants/TenantDetail';
import { useTenantQuery } from '../../shared/hooks/useTenantQuery';
import { useQueryValue } from '../../shared/hooks/useQueryValue';

export interface BranchesService {
  get(branchId: string): Branch | null;

  upsert(value: Branch): Promise<void>;

  delete(branchId: string): Promise<void>;

  all: Record<string, Branch>;
}

const BranchesContext = createContext<BranchesService | null>(null);

export function Branches(props: { children?: ReactNode }) {
  const branchesQuery = useTenantQuery(getAllBranches, void 0);
  const branches = useQueryValue(branchesQuery);

  const tenant = useTenant();

  const service: BranchesService = {
    get(branchId: string): Branch | null {
      return branches[branchId];
    },
    async upsert(data: Branch) {
      try {
        await upsertBranch({ tenantId: tenant.id, data });
      } finally {
        await branchesQuery.revalidate();
      }
    },
    async delete(branchId: string) {
      try {
        await deleteBranch({ tenantId: tenant.id, data: branchId });
      } finally {
        await branchesQuery.revalidate();
      }
    },
    all: branches,
  };

  return <BranchesContext.Provider value={service}>{props.children}</BranchesContext.Provider>;
}

export function useBranches() {
  const service = useContext(BranchesContext);

  if (service == null) {
    throw new Error('Cannot use branches. Please add BranchesService somewhere up in the component tree.');
  }

  return service;
}
