﻿import { PackagingWeights } from './api';
import { weightDiffFormat } from './helpers';

export interface Props {
  weights: PackagingWeights;
}

function formatWeight(weight: number) {
  return weight === 0 ? '' : `${weightDiffFormat.format(weight)} kg`;
}

export function WeightsDiffRowCells(props: Props) {
  const className = 'align-middle text-end text-nowrap text-muted opacity-50';

  return (
    <>
      <td className={className}>{formatWeight(props.weights.glass)}</td>
      <td className={className}>{formatWeight(props.weights.fiber)}</td>
      <td className={className}>{formatWeight(props.weights.ferrousMetal)}</td>
      <td className={className}>{formatWeight(props.weights.aluminum)}</td>
      <td className={className}>{formatWeight(props.weights.plastic)}</td>
      <td className={className}>{formatWeight(props.weights.beverageCarton)}</td>
      <td className={className}>{formatWeight(props.weights.otherComposite)}</td>
      <td className={className}>{formatWeight(props.weights.other)}</td>
    </>
  );
}
