import { numberParam, useSearchParams } from './useSearchParams';
import { useState } from 'react';

export function usePaging(defaultIndex: number) {
  const [maxPageIndex, setMaxPageIndex] = useSearchParams('index', numberParam(defaultIndex));
  const [initialMaxPageIndex] = useState(maxPageIndex);

  return Array.from(Array(maxPageIndex + 1).keys()).map(index => ({
    index,
    initial: index <= initialMaxPageIndex,
    onLoadMore: index >= maxPageIndex ? () => setMaxPageIndex(maxPageIndex + 1) : undefined,
  }));
}
