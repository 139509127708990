﻿import { stringParam, useSearchParams } from '../../../../shared/hooks/useSearchParams';
import { useSorting } from '../../../../shared/hooks/useSorting';
import { SortableHeaderCell } from '../../../../shared/components/SortableHeaderCell';
import { useTranslation } from 'react-i18next';
import { Period, ProducerData } from '../../../../api';
import { AdjustListRow } from './AdjustListRow';
import { useContractFilterFunctions } from '../useContractFilterFunctions';
import { useProducers } from '../../../producers/Producers';

export interface Props {
  year: number;
  period: Period;
  producerIds: string[];
}

interface ProducerInfo {
  id: string;
  data: ProducerData | null;
}

export function AdjustList({ year, period, producerIds }: Props) {
  const { t } = useTranslation('reports');

  const [search, setSearch] = useSearchParams<string>('search', stringParam(''));

  const lowerCasedSearch = search.toLocaleLowerCase();

  const producersService = useProducers();

  const producers: ProducerInfo[] = producerIds.map(id => ({ id, data: producersService.get(id) ?? null }));

  function containsSearch(value: string | null) {
    return value?.toLocaleLowerCase().includes(lowerCasedSearch) ?? false;
  }

  const { hasContractForPeriod } = useContractFilterFunctions(period);

  const filtered = producers.filter(p => {
    return (
      containsSearch(p.id) || (p.data != null && (containsSearch(p.data.name) || containsSearch(p.data.lucidNumber)))
    );
  });

  function compareName(a: ProducerInfo, b: ProducerInfo) {
    return (a.data?.name ?? '').localeCompare(b.data?.name ?? '');
  }

  const sorting = useSorting<ProducerInfo, 'name'>(
    filtered,
    {
      name: compareName,
    },
    { key: 'name', direction: 'asc' }
  );

  const data = sorting.sorted.map(p => p.id);

  return (
    <>
      <div className="row my-4">
        <div className="col-4">
          <input
            placeholder="Suche"
            type="search"
            className="form-control"
            value={search}
            onChange={e => setSearch(e.currentTarget.value)}
          />
        </div>
      </div>
      <table className="table table-striped">
        <thead className="sticky-top bg-white">
          <tr>
            <SortableHeaderCell sorting={sorting} sortKey="name">
              {t('adjustList.producer')}
            </SortableHeaderCell>
            <th>{t('adjustList.packagingType')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data
            .filter(producerId => hasContractForPeriod(producerId))
            .map(producerId => (
              <AdjustListRow key={producerId} producerId={producerId} year={year} />
            ))}

          {data
            .filter(producerId => !hasContractForPeriod(producerId))
            .map(producerId => (
              <AdjustListRow key={producerId} producerId={producerId} year={year} />
            ))}
        </tbody>
      </table>
    </>
  );
}
