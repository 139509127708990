﻿import { StateOf } from '../../forms';
import { FormDefinition } from './form';
import { LocaleField } from '../../shared/components/LocaleField';
import { TextField } from '../../shared/components/TextField';
import { useTranslation } from 'react-i18next';
import { Row } from '../../shared/components/Row';
import { Col } from '../../shared/components/Col';
import { BooleanField } from '../../shared/components/BooleanField';

export interface Props {
  form: StateOf<FormDefinition>;
  newRecord: boolean;
}

export function ProducerForm({ form, newRecord }: Props) {
  const { t } = useTranslation('producers');

  return (
    <>
      <Row>
        <Col size={5}>
          <TextField id="id" label={t('producerForm.id')} field={form.fields.id} disabled={!newRecord} />
        </Col>
        <Col size={5}>
          <TextField id="lucidNumber" label={t('producerForm.lucidNumber')} field={form.fields.lucidNumber} />
        </Col>
        <Col>
          <BooleanField id="active" label={t('producerForm.active')} field={form.fields.active} />
        </Col>
      </Row>
      <Row>
        <TextField id="name" label={t('producerForm.name')} field={form.fields.name} />
      </Row>
      <hr />
      <Row>
        <Col size={8}>
          <TextField
            id="addressStreet"
            label={t('producerForm.address.street')}
            field={form.fields.address.fields.street}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            id="addressPostCode"
            label={t('producerForm.address.postCode')}
            field={form.fields.address.fields.postCode}
          />
        </Col>
        <Col>
          <TextField id="addressCity" label={t('producerForm.address.city')} field={form.fields.address.fields.city} />
        </Col>
        <Col>
          <TextField
            id="addressCountry"
            label={t('producerForm.address.country')}
            field={form.fields.address.fields.country}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col size={8}>
          <TextField id="contactName" label={t('producerForm.contact.name')} field={form.fields.contact.fields.name} />
        </Col>
        <Col size={4}>
          <LocaleField
            id="contactLanguage"
            label={t('producerForm.contact.language')}
            field={form.fields.contact.fields.language}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            id="contactEmail"
            label={t('producerForm.contact.email')}
            field={form.fields.contact.fields.eMail}
          />
        </Col>
        <Col>
          <TextField
            id="contactPhone"
            label={t('producerForm.contact.phone')}
            field={form.fields.contact.fields.phone}
          />
        </Col>
      </Row>
    </>
  );
}
