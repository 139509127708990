import { Outlet, RouteObject } from 'react-router';
import { tenantIndexRoute } from './tenants/TenantIndex';
import { tenantDetailRoute } from './tenants/TenantDetail';
import { notFoundRoute } from '../shared/components/NotFoundInfo';
import { Authenticated } from '../public/auth/Authenticated';
import { ForbiddenInfo } from '../shared/components/ForbiddenInfo';
import { Header } from '../Header';
import { Footer } from '../shared/components/Footer';

function ForbiddenRoute() {
  return (
    <>
      <Header />
      <div className="pt-4 pb-2 container d-flex flex-column align-items-center">
        <ForbiddenInfo />
      </div>
    </>
  );
}

export const privateRoute: RouteObject = {
  path: '/*',
  element: <Private />,
  children: [tenantIndexRoute, { path: 'forbidden', element: <ForbiddenRoute /> }, tenantDetailRoute, notFoundRoute],
};

export function Private() {
  return (
    <Authenticated>
      <div className="d-flex flex-grow-1 flex-column">
        <Outlet />
      </div>
      <Footer muted />
    </Authenticated>
  );
}
