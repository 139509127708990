{
  "branchesTable": {
    "branchId": "ID",
    "name": "Name",
    "producers": "Producer",
    "country": "Country"
  },
  "branchForm": {
    "branchId": "ID",
    "name": "Name",
    "producer": "Producer",
    "country": "Country"
  },
  "branchModal": {
    "title": "New Branch",
    "delete": "Delete",
    "save": "Save",
    "confirmDeletion": "Are you sure to delete this branch?"
  },
  "newBranch": "New Branch"
}