import { useMutation } from '../../remote';
import { confirmForgotPassword } from '../../api';
import { group, textField, useForm } from '../../forms';
import { TextField } from '../../shared/components/TextField';
import { AuthErrorView } from './AuthErrorView';
import { AuthStateView } from './AuthStateView';
import { useTranslation } from 'react-i18next';

const definition = group({
  confirmationCode: textField,
  password: textField,
});

export function ConfirmForgotPassword({ username }: { username: string }) {
  const { t } = useTranslation('auth');

  const mutation = useMutation(confirmForgotPassword);

  const form = useForm(definition, { confirmationCode: '', password: '' });

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (form.status.valid) {
      mutation.start({ ...form.status.value, username });
    }
  }

  if (mutation.isResolved) {
    const result = mutation.data;
    if (result.Case === 'Ok') {
      const state = result.Fields[0];
      return <AuthStateView state={state} />;
    }
  }

  return (
    <>
      <h1 className="h5 text-center text-secondary">{t('ConfirmForgotPassword.title')}</h1>
      <p className="my-4 alert alert-primary">{t('ConfirmForgotPassword.description')}</p>
      <form onSubmit={onSubmit} className="mb-3">
        <TextField
          focusOnMount
          type="password"
          id="confirmationCode"
          label={t('ConfirmForgotPassword.code')}
          field={form.fields.confirmationCode}
          autoComplete="one-time-code"
        />
        <TextField
          id="newPassword"
          label={t('ConfirmForgotPassword.newPassword')}
          field={form.fields.password}
          type="password"
          autoComplete="new-password"
        />
        <p className="text-muted">{t('passwordPolicy')}</p>
        <button disabled={!form.status.valid} className="btn btn-primary" type="submit">
          {t('ConfirmForgotPassword.setPassword')}
        </button>
      </form>
      {mutation.isResolved && mutation.data.Case === 'Error' && <AuthErrorView error={mutation.data.Fields[0]} />}
    </>
  );
}
