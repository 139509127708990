import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../shared/components/Icon';
import { ProducerData } from '../../api';
import { useTranslation } from 'react-i18next';

export type Row = ProducerData & {
  fullAddress: string;
  fullContact: string;
};

function formatLines(lines: (string | null)[]) {
  const filtered = lines.filter(s => s != null && s !== '');

  if (filtered.length === 0) {
    return <>--</>;
  }

  return filtered
    .map<React.ReactNode>((line, index) => <span key={index}>{line}</span>)
    .reduce((prev, curr) => [prev, ' | ', curr]);
}

export function ProducersTableRow({ row }: { row: Row }) {
  const { t } = useTranslation('global');

  function formatBoolean(value: boolean) {
    return value ? t('yes') : t('no');
  }

  return (
    <tr key={row.id}>
      <td>{row.name}</td>
      <td>{row.id ?? '--'}</td>
      <td>{row.lucidNumber ?? '--'}</td>
      <td>{formatLines([row.contact.name, row.contact.eMail, row.contact.phone])}</td>
      <td>{formatLines([row.address.country])}</td>
      <td>{formatBoolean(row.active)}</td>
      <td className="text-end">
        <NavLink to={{ pathname: `./edit/${row.id}`, search: location.search }} className="btn btn-outline-secondary">
          <Icon name={'pencil'} />
        </NavLink>
      </td>
    </tr>
  );
}
