﻿import { ReactNode, useEffect } from 'react';
import { ErrorFallback } from './shared/components/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';
import { RemoteError } from './remote';
import i18n from './i18n';

const forbiddenError = 'error.errorForbidden';
const unexpectedError = 'error.errorUnexpected';

export function getErrorMessage(error: unknown) {
  return error instanceof RemoteError && error.response.status === 403 ? forbiddenError : unexpectedError;
}

export function showError(error: unknown) {
  if (error instanceof Error && error.name === 'AbortError') {
    return;
  }
  const message = i18n.t(getErrorMessage(error));

  alert(message);
}

/**
 * Error boundary that catches error from react and global errors that occured.
 */
export function GlobalErrorBoundary(props: { children?: ReactNode }) {
  useEffect(() => {
    function handleError(e: ErrorEvent) {
      showError(e.error);
    }

    function handleUnhandledRejection(e: PromiseRejectionEvent) {
      e.promise.catch(e => {
        showError(e);
      });
    }

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, []);

  return <ErrorBoundary FallbackComponent={ErrorFallback}>{props.children}</ErrorBoundary>;
}
