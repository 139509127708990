﻿import { Sorting } from '../../../shared/hooks/useSorting';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../../shared/components/Icon';

type Props<K extends string> = {
  className?: string;
  sorting: Sorting<any, K>;
  sortKey: K;
  children?: React.ReactNode;
};

export function SortableHeaderCell<K extends string>({ className, children, sorting, sortKey }: Props<K>) {
  const active = sorting.option.key === sortKey;

  return (
    <th className={className}>
      <NavLink
        className={`text-decoration-none text-${active ? 'primary' : 'secondary'}`}
        to={sorting.getOptionTo({
          key: sortKey,
          direction: active && sorting.option.direction === 'asc' ? 'desc' : 'asc',
        })}
        replace>
        <div className="d-inline-flex align-items-center">
          {active && <Icon className="me-1 fs-6" name={sorting.option.direction === 'asc' ? 'sort-up' : 'sort-down'} />}
          <div>{children}</div>
        </div>
      </NavLink>
    </th>
  );
}
