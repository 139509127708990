﻿import { ProducerInfo } from '../../../producers/ProducerInfo';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../../../shared/components/Icon';
import { useReports } from '../../Reports';
import { useTranslation } from 'react-i18next';

export interface Props {
  declarationId: number;
  producerId: string;
}

export function ReportListRow({ declarationId, producerId }: Props) {
  const { t } = useTranslation('reports');

  const reports = useReports();
  const report = reports.get(declarationId, producerId);

  const reported = report?.lucidReportedAt ? (
    <div className="badge bg-success">{t('reportListRow.reported')}</div>
  ) : (
    <div className="badge bg-secondary">{t('reportListRow.notReported')}</div>
  );

  return (
    <tr>
      <td>
        <ProducerInfo producerId={producerId} />
      </td>
      <td className="text-nowrap align-middle text-start">
        <div>{reported}</div>
      </td>
      <td className="text-nowrap align-middle text-end">
        <NavLink to={`./${producerId}`} className="btn btn-outline-secondary">
          <>
            {report?.lucidReportedAt ? t('reportListRow.showReport') : t('reportListRow.reportToLUCID')}
            <Icon name="chevron-right" className="ms-1" />
          </>
        </NavLink>
      </td>
    </tr>
  );
}
