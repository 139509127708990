import { group, numberField, optionalGroup } from '../../../../forms';

export const thirdPartyLicensingForm = group({
  systemId: numberField,
  weights: optionalGroup({
    glass: numberField,
    fiber: numberField,
    ferrousMetal: numberField,
    aluminum: numberField,
    plastic: numberField,
    beverageCarton: numberField,
    otherComposite: numberField,
    other: numberField,
  }),
});

export type FormDefinition = typeof thirdPartyLicensingForm;
