import { PeriodStatus } from '../../api';
import { useTranslation } from 'react-i18next';
import { expectNever } from '../../helpers';

export function PeriodStatusBadge({ status }: { status: PeriodStatus }) {
  const { t } = useTranslation('reports');

  if (status === 'NotYetCreated') {
    return <div className="mt-1 badge bg-secondary">{t('navigation.notCreated')}</div>;
  }

  if (status === 'NotYetReleased') {
    return <div className="mt-1 badge bg-secondary">{t('navigation.notReleased')}</div>;
  }

  if (typeof status === 'object') {
    if (status.Case === 'Declaration') {
      const [, declarationStatus] = status.Fields;

      if (declarationStatus === 'Creating') {
        return <div className="mt-1 badge bg-secondary">{t('navigation.calculationPending')}</div>;
      }

      if (declarationStatus === 'InPreview') {
        return <div className="mt-1 badge bg-primary">{t('navigation.inReview')}</div>;
      }

      if (declarationStatus === 'Released') {
        return <div className="mt-1 badge bg-success">{t('navigation.released')}</div>;
      }

      expectNever(declarationStatus);
    }

    expectNever(status.Case);
  }

  expectNever(status);
}
