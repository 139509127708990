{
  "global": {
    "yes": "Ja",
    "no":  "Nein",
    "languages": {
      "en": "Englisch",
      "de": "Deutsch",
      "fr": "Französisch"
    },
    "fractions": {
      "de": {
        "glass": "Glas",
        "fiber": "PPK",
        "ferrousMetal": "Eisenmetalle",
        "aluminum": "Aluminium",
        "plastic": "Kunststoffe",
        "beverageCarton": "Getränkekarton-Verpackungen",
        "other": "Andere Materialien",
        "otherComposite": "Sonstige Verbund-Verpackungen"
      },
      "at": {
        "fiberHouseHold": "Papier Haushalt",
        "glassHouseHold": "Glas",
        "ferrousMetalHouseHold": "Eisenmetalle Haushalt",
        "aluminumHouseHold": "Aluminium Haushalt",
        "plasticHouseHold": "Kunststoff Haushalt",
        "beverageCartonHouseHold": "Getränkeverbundkarton Haushalt",
        "compositeHouseHold": "Verbundverpackungen Haushalt, ausgenommen Getränkeverbundkarton",
        "ceramicHouseHold": "Keramik Haushalt",
        "woodHouseHold": "Holz Haushalt",
        "textileHouseHold": "Textile Faserstroffe Haushalt",
        "organicHouseHold": "Biogene Packstoffe Haushalt",
        "fiberCommercial": "Papier Gewerblich",
        "ferrousMetalCommercial": "Eisenmetalle Gewerblich",
        "aluminumCommercial": "Aluminium Gewerblich",
        "foilsCommercial": "Folien gewerblich, Umreifungsbänder und Klebebänder aus Kunststoff",
        "hollowBodiesCommercial": "Hohlkörper gewerblich",
        "epsCommercial": "EPS gewerblich",
        "woodCommercial": "Holz gewerblich",
        "compositeCommercial": "Verbundverpackungen gewerblich",
        "ceramicCommercial": "Keramik gewerblich",
        "textileCommercial": "Textile Faserstoffe gewerblich",
        "organicCommercial": "Biogene Packstoffe gewerblich"
      }
    },
    "error": {
      "errorTitle": "Oops, es ist ein Fehler aufgetreten",
      "errorSubtitle": "Wir arbeiten bereits an einer Lösung, versuchen Sie es bitte erneut. <br/>\nSollte der Fehler weiterhin auftreten, zögern Sie nicht uns zu kontaktieren.",
      "errorOfflineTitle": "Keine Internetverbindung",
      "errorOfflineSubtitle": "Die Aktion konnte nicht ausgeführt werden, da keine Internetverbindung besteht.",
      "errorForbidden": "Sie sind nicht berechtigt diese Aktion auszuführen.",
      "errorUnexpected": "Die Aktion konnte nicht ausgeführt werden. Ein unerwarteter Fehler ist aufgetreten.",
      "retry": "Erneut versuchen",
      "goBack": "Zurück"
    },
    "forbidden": {
      "forbiddenTitle": "Keine Berechtigung",
      "forbiddenSubtitle": "Leider sind Sie nicht berechtigt weiter zu machen."
    },
    "notFound": {
      "notFoundTitle": "Nicht gefunden",
      "notFoundSubtitle": "Leider konnten wir die gewünschte Information nicht finden."
    },
    "month": "Monat",
    "quarter": "Quartal",
    "halfYear": "Halbjahr",
    "year": "Jahr",
    "endOfMonth": "Monatsende",
    "endOfQuarter": "Quartalsabschluss",
    "endOfHalfYear": "Halbjahresabschluss",
    "salesPackagings": "Verkaufsverpackungen",
    "servicePackagings": "Serviceverpackungen",
    "search": "Suche",
    "periods": {
      "q1": "1. Quartal",
      "q2": "2. Quartal",
      "q3": "3. Quartal",
      "q4": "4. Quartal",
      "year": "Jahr",
      "firstHalfOfYear": "1. Jahreshälfte",
      "secondHalfOfYear": "2. Jahreshälfte",
      "jan": "Januar",
      "feb": "Februar",
      "mar": "März",
      "apr": "April",
      "may": "Mai",
      "jun": "Juni",
      "jul": "Juli",
      "aug": "August",
      "sep": "September",
      "oct": "Oktober",
      "nov": "November",
      "dec": "Dezember"
    },
    "shortPeriod": {
      "Q1": "Q1",
      "Q2": "Q2",
      "Q3": "Q3",
      "Q4": "Q4",
      "Year": "Jahr",
      "FirstHalfYear": "H1",
      "SecondHalfYear": "H2",
      "Jan": "Jan",
      "Feb": "Feb",
      "Mar": "Mär",
      "Apr": "Apr",
      "May": "Mai",
      "Jun": "Jun",
      "Jul": "Jul",
      "Aug": "Aug",
      "Sep": "Sep",
      "Oct": "Okt",
      "Nov": "Nov",
      "Dec": "Dez"
    },
    "declarationIntervals": {
      "monthly": "Monatlich",
      "quarterly": "Quartalsweise",
      "biYearly": "Halbjährlich",
      "yearly": "Keine unterjährige Meldungen"
    },
    "movementTypes": {
      "purchase": "Wareneingang",
      "sale": "Warenausgang",
      "dropshipping": "Streckengeschäft"
    },
    "months": {
      "jan": "Jan",
      "feb": "Feb",
      "mar": "Mrz",
      "apr": "Apr",
      "may": "Mai",
      "jun": "Jun",
      "jul": "Jul",
      "aug": "Aug",
      "sep": "Sep",
      "oct": "Okt",
      "nov": "Nov",
      "dec": "Dez"
    },
    "countries": {
      "Germany": "Deutschland",
      "Poland": "Polen",
      "France": "Frankreich",
      "Austria": "Österreich",
      "CzechRepublic": "Tschechische Republik",
      "Belgium": "Belgien",
      "Luxembourg": "Luxemburg",
      "BosniaAndHerzegovina": "Bosnien-Herzegowina",
      "Bulgaria": "Bulgarien",
      "China": "China",
      "Denmark": "Dänemark",
      "Finland": "Finnland",
      "Greece": "Griechenland",
      "UnitedKingdom": "Vereinigtes Königreich",
      "HongKong": "Hongkong",
      "Indonesia": "Indonesien",
      "Ireland": "Irland",
      "Italy": "Italien",
      "Canada": "Kanada",
      "Croatia": "Kroatien",
      "Latvia": "Lettland",
      "Netherlands": "Niederlande",
      "Norway": "Norwegen",
      "Sweden": "Schweden",
      "Switzerland": "Schweiz",
      "Serbia": "Serbien",
      "Singapore": "Singapur",
      "Slovakia": "Slowakai",
      "Slovenia": "Slowenien",
      "Spain": "Spanien",
      "Turkey": "Türkei",
      "Hungary": "Ungarn",
      "USA": "USA",
      "Unknown": "Unbekannt"
    },
    "locales": {
      "DE_de": "Deutsch",
      "EN_us": "Englisch",
      "FR_fr": "Französisch"
    },
    "packagingTypes": {
      "salesPackaging": "Verkaufsverpackung",
      "servicePackaging": "Serviceverpackung",
      "outerPackaging": "Umverpackung",
      "shippingPackaging": "Versandverpackung",
      "transportPackaging": "Transportverpackung"
    },
    "forms": {
      "errors": {
        "required": "Eingabe wird erwartet",
        "invalid": "Eingabe ist ungültig",
        "alreadyExists": "Eintrag mit der selben ID existiert bereits"
      }
    },
    "supplier": "Lieferant",
    "customer": "Kunde",
    "customers": "Kunden",
    "footer": {
      "privacy": "Datenschutzerklärung",
      "imprint": "Impressum",
      "support": "Hilfe & Kontakt",
      "copyright": "©SynoptiCons",
      "supportModal": {
        "message": "Sollten Sie Fragen, Probleme oder Anmerkungen zu Calculate haben, zögern Sie bitte nicht uns zu kontaktieren: <br />"
      }
    }
  }
}
