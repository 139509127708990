import * as React from 'react';
import { booleanField, StateOf } from '../../forms';
import { InputGroup } from './InputGroup';
import { ReactNode } from 'react';

export interface Props {
  id: string;
  field: StateOf<typeof booleanField>;
  disabled?: boolean;
  children?: ReactNode;
}

export function BooleanInput(props: Props) {
  const inputRef = React.useRef<HTMLInputElement>();

  let inputClassName = 'form-check-input';

  if (!props.field.status.valid && props.field.touched) {
    inputClassName += ' is-invalid';
  }

  return (
    <InputGroup field={props.field}>
      <div className="form-check form-switch">
        <input
          disabled={props.disabled}
          className={inputClassName}
          id={props.id}
          ref={(e: HTMLInputElement) => (inputRef.current = e)}
          type="checkbox"
          checked={props.field.value ?? ''}
          onChange={e => props.field.setValue(e.currentTarget.checked)}
        />
        <label className="form-check-label" htmlFor={props.id}>
          {props.children}
        </label>
      </div>
    </InputGroup>
  );
}
