﻿import { PollingScreen } from '../../../../shared/components/PollingScreen';
import { NotYetCreatedScreen } from './NotYetCreatedScreen';
import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { expectNever } from '../../../../helpers';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, RouteObject } from 'react-router';
import { useTenantQuery } from '../../../../shared/hooks/useTenantQuery';
import { useQueryValue } from '../../../../shared/hooks/useQueryValue';
import { usePeriodParam, useYearParam } from '../../../../shared/hooks/useParam';
import { tryGetDeclarationAustria } from '../../../../api';

export const createRoute: RouteObject = {
  path: 'create',
  element: <CreateStep />,
};

export function CreateStep() {
  const { t } = useTranslation('reports');

  const [year, period] = [useYearParam(), usePeriodParam()];
  const taskQuery = useTenantQuery(tryGetDeclarationAustria, { year, period }, false);
  const task = useQueryValue(taskQuery);

  if (task === 'Running') {
    return (
      <PollingScreen title={t('createStep.pollingTitle')}>
        <Trans t={t}>{t('createStep.polling')}</Trans>
      </PollingScreen>
    );
  }

  if (task.Case === 'Failed') {
    const [error] = task.Fields;

    if (error === 'NotYetCreated') {
      return (
        <>
          <NotYetCreatedScreen year={year} period={period} />
        </>
      );
    }

    if (error === 'NotYetReleased') {
      return (
        <InfoScreen title={t('createStep.notReleasedTitle')} icon="x-circle" color="info">
          {t('createStep.notReleased')}
        </InfoScreen>
      );
    }

    expectNever(error);
  }

  if (task.Case === 'Finished') {
    return <Navigate to="../issues" replace />;
  }

  expectNever(task);
}
