import {
  DeclarationResultGermany,
  getPlacings,
  PackagingType,
  Period,
  PlacingData,
  Responsibility,
} from '../../../api';
import { expectNever, getPeriodContains, notNullOrUndefined } from '../../../helpers';
import { useQueryValue } from '../../../shared/hooks/useQueryValue';
import { useTenantQuery } from '../../../shared/hooks/useTenantQuery';
import { useMemo } from 'react';

function isSubjectToSystemParticipation(packagingType: PackagingType) {
  switch (packagingType) {
    case 'Sale':
    case 'Outer':
    case 'Service':
    case 'Shipping':
      return true;
  }
  expectNever(packagingType);
}

function toResponsibility(declarationPeriod: Period, placing: PlacingData): Responsibility | null {
  if (!isSubjectToSystemParticipation(placing.packagingType) || !getPeriodContains(declarationPeriod, placing.period)) {
    return null;
  }

  return {
    decision: { Case: 'Yes', Fields: ['AdditionalPlacing'] },
    reporting: { Case: 'Producer', Fields: [placing.producerId] },
    licensing: { Case: 'Producer', Fields: [placing.producerId] },
    payment: { Case: 'Producer', Fields: [placing.producerId] },
    weights: placing.weights,
  };
}

/**
 * Returns the relevant placings of the specified period as responsibilities.
 */
function getPlacingsAsResponsibilities(period: Period, placings: Record<string, PlacingData>) {
  return Object.values(placings)
    .map(placing => toResponsibility(period, placing))
    .filter(notNullOrUndefined);
}

/**
 * Extends the declaration with data from the client like other placings.
 */
export function useExtendedDeclaration(year: number, period: Period, declaration: DeclarationResultGermany | null) {
  const placings = useQueryValue(useTenantQuery(getPlacings, year));

  return useMemo(() => {
    if (declaration) {
      const placingsAsResponsibilities = getPlacingsAsResponsibilities(period, placings);
      return { ...declaration, responsibilities: [...declaration.responsibilities, ...placingsAsResponsibilities] };
    }

    return null;
  }, [period, declaration, placings]);
}
