{
  "footer": {
    "address": "SynoptiCons Deutschland GmbH · Austraße 34 · 35745 Herborn · Deutschland"
  },
  "InitiateAuth": {
    "title": "Melden Sie sich bei Calculate an",
    "description": "Die umfassende Lösung zur Erfüllung betrieblicher EPR-Pflichten.",
    "username": "Anmeldename",
    "password": "Passwort",
    "signIn": "Anmelden",
    "forgotPassword": "Passwort vergessen?",
    "signInWithGoogle": "Mit Google anmelden"
  },
  "ForgotPassword": {
    "title": "Passwort zurücksetzen",
    "description": "Geben Sie bitte Ihren Anmeldenamen an und fordern Sie einen Verifizierungs-Code an, um Ihr Passwort zu ändern.",
    "username": "Anmeldename",
    "getCode": "Code anfordern"
  },
  "ConfirmForgotPassword": {
    "title": "Passwort zurücksetzen",
    "description": "Ein Verifizierungs-Code wurde an Sie verschickt. Geben Sie bitte den Verifizierungs-Code und Ihr neues Passwort ein.",
    "code": "Code",
    "newPassword": "Neues Passwort",
    "setPassword": "Passwort setzen"
  },
  "NewPasswordRequiredChallenge": {
    "title": "Neues Passwort ist erforderlich",
    "description": "Ein neues Passwort ist erforderlich. Geben Sie bitte Ihr neues Passwort ein.",
    "newPassword": "Neues Passwort",
    "setPassword": "Passwort setzen"
  },
  "SmsMfaChallenge": {
    "title": "Verifizierungs-Code eingeben",
    "description": "Ein Verifizierungs-Code wurde an {{codeDeliveryDestination}} gesendet. Geben Sie den Code bitte ein, um fortzufahren.",
    "code": "Code",
    "verify": "Verifizieren"
  },
  "AuthErrorView": {
    "NotAuthorized": "Anmeldung fehlgeschlagen.",
    "InvalidPassword": "Das neue Passwort erfüllt nicht die Passwortrichtlinie.",
    "CodeMismatch": "Verifizierungs-Code ist falsch.",
    "UserNotFound": "Der Zugang konnte nicht gefunden werden.",
    "ExpiredCode": "Verifizierungs-Code ist nicht mehr gültig.",
    "LimitExceeded": "Anfragelimit überschritten. Bitte versuchen Sie es später erneut."
  },
  "LogOutButton": {
    "label": "Abmelden"
  },
  "passwordPolicy": "Mindestend 8 Zeichen, eine Zahl, ein Sonderzeichen, ein großer Buchstabe, ein kleiner Buchstabe und keine Leerzeichen."
}