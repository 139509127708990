﻿import { Producers } from './Producers';
import { ProducersTable } from './ProducersTable';
import { Outlet, RouteObject } from 'react-router';
import { producerEditRoute } from './ProducerEdit';
import { producerAddRoute } from './ProducerAdd';

export const producerIndexRoute: RouteObject = {
  path: 'producers',
  element: <ProducerIndex />,
  children: [producerEditRoute, producerAddRoute],
};

export function ProducerIndex() {
  return (
    <div className="mx-4">
      <Producers>
        <ProducersTable />
        <Outlet />
      </Producers>
    </div>
  );
}
