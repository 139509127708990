﻿import { useProducers } from './Producers';
import { ProducerForm } from './ProducerForm';
import { producerForm as FormDecl, getInitialInput } from './form';
import { uniqueTextField, useForm } from '../../forms';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../shared/components/Modal';
import { RouteObject, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export const producerAddRoute: RouteObject = {
  path: 'add',
  element: <ProducerAdd />,
};

export function ProducerAdd() {
  const producers = useProducers();
  const { t } = useTranslation('producers');

  const producerForm = useForm(FormDecl(uniqueTextField(Object.keys(producers.all))), getInitialInput(null));

  const navigate = useNavigate();
  const [search] = useSearchParams();

  function onClose() {
    navigate({ pathname: '..', search: search.toString() });
  }

  const onSubmit = async () => {
    if (producerForm.status.valid) {
      onClose();
      await producers.upsert(producerForm.status.value);
    }
  };

  const footer = (
    <>
      <button
        className={'btn btn-primary text-white'}
        type="submit"
        disabled={!producerForm.status.valid}
        onClick={onSubmit}>
        {t('producerModal.save')}
      </button>
    </>
  );

  return (
    <Modal visible={true} title={t('producerModal.title')} onClose={onClose} footer={footer} size="lg">
      <ProducerForm form={producerForm} newRecord={true} />
    </Modal>
  );
}
