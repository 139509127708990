import { PackagingWeights, ThirdPartyLicensee } from '../../../../api';
import { ProducerInfo } from '../../../producers/ProducerInfo';
import { SupplierInfo } from '../../SupplierInfo';
import { CustomerInfo } from '../../CustomerInfo';
import { SystemInfo } from '../SystemInfo';
import { WeightsRowCells } from '../../../../WeightsRowCells';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../../../shared/components/Icon';
import { useTranslation } from 'react-i18next';
import { WeightsDiffRowCells } from '../../../../WeightsDiffRowCells';

type Props = {
  producerId: string;
  licensee: ThirdPartyLicensee;
  originalWeights: PackagingWeights;
  systemId: number | null;
  thirdPartyWeights: PackagingWeights | null;
};

/**
 * Returns the diff of two packaging weights.
 */
function getDiff(a: PackagingWeights, b: PackagingWeights): PackagingWeights {
  return {
    glass: a.glass - b.glass,
    fiber: a.fiber - b.fiber,
    ferrousMetal: a.ferrousMetal - b.ferrousMetal,
    aluminum: a.aluminum - b.aluminum,
    beverageCarton: a.beverageCarton - b.beverageCarton,
    otherComposite: a.otherComposite - b.otherComposite,
    plastic: a.plastic - b.plastic,
    other: a.other - b.other,
  };
}

export function ThirdPartyLicensingRow({ originalWeights, thirdPartyWeights, systemId, licensee, producerId }: Props) {
  const { t } = useTranslation('reports');
  return (
    <>
      <tr>
        <td rowSpan={thirdPartyWeights != null ? 3 : 2}>
          <ProducerInfo producerId={producerId} />
        </td>
        <td rowSpan={thirdPartyWeights != null ? 3 : 2}>
          {licensee.Case === 'Supplier' ? <SupplierInfo supplierId={licensee.Fields[0]} /> : <></>}
          {licensee.Case === 'Customer' ? <CustomerInfo name={licensee.Fields[0]} /> : <></>}
        </td>
        <td className="text-muted opacity-50" valign={'middle'}>
          {t('thirdPartyLicensingStep.calculate')}
        </td>
        <td className="text-muted opacity-50" valign={'middle'}>
          --
        </td>
        <WeightsRowCells weights={originalWeights} muted />
        <td rowSpan={thirdPartyWeights != null ? 3 : 2} valign={'middle'} align={'right'}>
          <NavLink
            to={{
              pathname: `./edit/${producerId}/${licensee.Case}/${licensee.Fields[0]}`,
            }}
            className="btn btn-outline-secondary">
            <Icon name={'pencil'} />
          </NavLink>
        </td>
      </tr>
      <tr>
        <td valign={'middle'}>{t('thirdPartyLicensingStep.licensee')}</td>
        {systemId === null && thirdPartyWeights === null ? (
          <>
            <td colSpan={10} className="align-middle text-danger text-center text-nowrap">
              {t('thirdPartyLicensingStep.noValues')}
            </td>
          </>
        ) : (
          <>
            <td valign={'middle'}>
              {systemId !== null ? (
                <SystemInfo systemId={systemId} count={1} />
              ) : (
                <span className="text-danger">--</span>
              )}
            </td>
            {thirdPartyWeights ? (
              <WeightsRowCells weights={thirdPartyWeights} />
            ) : (
              <>
                <td className="align-middle text-center text-nowrap">--</td>
                <td className="align-middle text-center text-nowrap">--</td>
                <td className="align-middle text-center text-nowrap">--</td>
                <td className="align-middle text-center text-nowrap">--</td>
                <td className="align-middle text-center text-nowrap">--</td>
                <td className="align-middle text-center text-nowrap">--</td>
                <td className="align-middle text-center text-nowrap">--</td>
                <td className="align-middle text-center text-nowrap">--</td>
              </>
            )}
          </>
        )}
      </tr>
      {thirdPartyWeights && (
        <tr>
          <td colSpan={2}></td>
          <WeightsDiffRowCells weights={getDiff(thirdPartyWeights, originalWeights)} />
        </tr>
      )}
      <tr>
        <td className="bg-light" colSpan={13}>
          &nbsp;
        </td>
      </tr>
    </>
  );
}
