import { Trans, useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { TrackJS } from 'trackjs';

export interface Props {
  error: unknown | object | string | Error;
}

function historyBack() {
  history.back();
}

function locationReload() {
  location.reload();
}

/**
 * Checks if the error is a network error, i.e. no internet connection could be the reason.
 * @param error The error
 */
function isNetworkError(error: unknown) {
  return error instanceof TypeError && error.message === 'Failed to fetch';
}

/**
 * The default error message.
 */
function DefaultError() {
  const { t } = useTranslation();

  return (
    <div className="p-2">
      <p className="h5">{t('global:error.errorTitle')}</p>
      <p className="text-danger my-4">
        <Trans t={t}>{t('global:error.errorSubtitle')}</Trans>
      </p>
    </div>
  );
}

/**
 * The error message in case of a network related error and a missing internet connection.
 */
function OfflineError() {
  const { t } = useTranslation();

  return (
    <div className="p-2">
      <p className="h5">{t('global:error.errorOfflineTitle')}</p>
      <p className="text-danger my-4">
        <Trans t={t}>{t('global:error.errorOfflineSubtitle')}</Trans>
      </p>
    </div>
  );
}

export function ErrorFallback({ error }: Props) {
  const { t } = useTranslation();

  useEffect(() => {
    if (
      navigator.onLine &&
      TrackJS.isInstalled() &&
      (error instanceof Error || error instanceof String || error instanceof Object)
    ) {
      TrackJS.track(error);
    }
  }, [error]);

  const content = isNetworkError(error) && !navigator.onLine ? <OfflineError /> : <DefaultError />;

  return (
    <div className="alert alert-danger mx-auto my-4 w-50">
      {content}
      <div>
        <button className="btn btn-danger mx-2" onClick={locationReload}>
          {t('global:error.retry')}
        </button>
        <button className="btn btn-secondary mx-2" onClick={historyBack}>
          {t('global:error.goBack')}
        </button>
      </div>
    </div>
  );
}
