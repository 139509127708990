﻿import { useProducers } from './Producers';
import { ProducerForm } from './ProducerForm';
import { producerForm as FormDecl, getInitialInput } from './form';
import { textField, useForm } from '../../forms';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../shared/components/Icon';
import { Modal } from '../../shared/components/Modal';
import { RouteObject, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useStringParams } from '../../shared/hooks/useParam';

export const producerEditRoute: RouteObject = {
  path: 'edit/:producerId',
  element: <ProducerEdit />,
};

export function ProducerEdit() {
  const { producerId } = useStringParams<'producerId'>();

  const producers = useProducers();
  const producer = producers.get(producerId);

  const { t } = useTranslation('producers');

  const producerForm = useForm(FormDecl(textField), getInitialInput(producer));

  const navigate = useNavigate();
  const [search] = useSearchParams();

  function onClose() {
    navigate({ pathname: '..', search: search.toString() });
  }

  const onSubmit = async () => {
    if (producerForm.status.valid) {
      onClose();
      await producers.upsert(producerForm.status.value);
    }
  };

  const onDelete = async (producerId: string) => {
    if (confirm(t('producerModal.confirmDeletion'))) {
      onClose();
      await producers.delete(producerId);
    }
  };

  const footer = (
    <>
      <button className={'mx-2 btn btn-outline-danger'} type="button" onClick={() => onDelete(producerId)}>
        <Icon name="trash" /> {t('producerModal.delete')}
      </button>
      <button
        className={'btn btn-primary text-white'}
        type="submit"
        disabled={!producerForm.status.valid}
        onClick={onSubmit}>
        {t('producerModal.save')}
      </button>
    </>
  );

  return (
    <Modal
      visible={true}
      title={producer ? `${producer.id} - ${producer.name}` : t('branchModal.title')}
      onClose={onClose}
      footer={footer}
      size="lg">
      <ProducerForm form={producerForm} newRecord={false} />
    </Modal>
  );
}
