﻿import { DeclarationKind, Period, release, tryGetDeclarationFrance } from '../../../api';
import { useTenantMutation } from '../../../remote';
import { Icon } from '../../../shared/components/Icon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useTenantRevalidate } from '../../../shared/hooks/useTenantRevalidate';

export interface Props {
  year: number;
  period: Period;
  kind: DeclarationKind;
}

export function ReleaseButton({ year, period, kind }: Props) {
  const revalidateDeclaration = useTenantRevalidate(tryGetDeclarationFrance, year);

  const { t } = useTranslation('reports');

  async function revalidate() {
    await revalidateDeclaration();
  }

  const navigate = useNavigate();

  const mutation = useTenantMutation(release);

  if (mutation.isPending) {
    throw mutation.promise;
  }

  function startRelease() {
    if (kind !== 'Release') {
      mutation.start(
        { declaration: { country: 'France', year, period }, sendNotifications: false },
        {
          async onSuccess() {
            await revalidate();
            navigate('../finish');
          },
        }
      );
    } else {
      navigate('../finish');
    }
  }

  return (
    <button className="btn btn-success text-white me-2" onClick={startRelease} disabled={mutation.isPending}>
      <Icon name="check-lg" /> {t('csuReleaseButton.release')}
    </button>
  );
}
