﻿import { createDeclaration, getPeriods, Period, tryGetDeclarationAustria } from '../../../../api';
import { useTenantMutation } from '../../../../remote';
import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { AvailabilityView } from './AvailabilityView';
import { Icon } from '../../../../shared/components/Icon';
import { useTranslation } from 'react-i18next';
import { useTenant } from '../../../tenants/TenantDetail';
import { useTenantQuery } from '../../../../shared/hooks/useTenantQuery';

export interface Props {
  year: number;
  period: Period;
}

export function NotYetCreatedScreen({ year, period }: Props) {
  const { t } = useTranslation('reports');

  const periodsQuery = useTenantQuery(getPeriods, { country: 'Austria', year }, false);

  const declarationQuery = useTenantQuery(tryGetDeclarationAustria, { year, period }, false);

  const tenant = useTenant();

  const mutation = useTenantMutation(createDeclaration);

  function onClick() {
    mutation.start(
      { country: 'Austria', year, period },
      {
        async onSuccess() {
          declarationQuery.setValue('Running');
          await Promise.all([periodsQuery.revalidate(), declarationQuery.revalidate()]);
        },
      }
    );
  }

  return (
    <InfoScreen title={t('notYetCreatedScreen.title')} icon="info-circle" color="primary">
      <p>{t('notYetCreatedScreen.hint')}</p>
      <br />
      {tenant.permissions.central && <AvailabilityView year={year} period={period} />}
      <div className="mt-2">
        <button disabled={mutation.isPending} className="btn btn-primary text-white mt-4" onClick={onClick}>
          <Icon name="calculator" /> {t('notYetCreatedScreen.calculate')}
        </button>
      </div>
    </InfoScreen>
  );
}
