﻿import { ProducerInfo } from '../../../producers/ProducerInfo';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../../../shared/components/Icon';
import { useReports } from '../../Reports';
import { useTranslation } from 'react-i18next';
import { EditButton } from '../../../contracts/EditButton';
import { expectNever } from '../../../../helpers';

export interface Props {
  declarationId: number;
  producerId: string;
  obligated: boolean | 'nocontract';
}

function Columns({ declarationId, producerId, obligated }: Props) {
  const { t } = useTranslation('reports');

  const reports = useReports();
  const report = reports.get(declarationId, producerId);

  if (obligated === 'nocontract') {
    return (
      <td colSpan={2} className="text-nowrap align-middle text-end">
        <EditButton pathPrefix="contracts" producerId={producerId} value={null}>
          {t('licenseListRow.addContract')}
        </EditButton>
      </td>
    );
  }

  if (obligated) {
    const reported = report?.proReportedAt ? (
      <div className="badge bg-success">{t('licenseListRow.reported')}</div>
    ) : (
      <div className="badge bg-secondary">{t('licenseListRow.notReported')}</div>
    );

    return (
      <>
        <td className="align-middle">{reported}</td>
        <td className="text-nowrap align-middle text-end">
          <NavLink to={`./${producerId}`} className="btn btn-outline-secondary">
            <>
              {report?.proReportedAt ? t('licenseListRow.showReport') : t('licenseListRow.reportToSystemProvider')}
              <Icon name="chevron-right" />
            </>
          </NavLink>
        </td>
      </>
    );
  }

  if (!obligated) {
    return (
      <td colSpan={2} className="text-nowrap align-middle text-center">
        <span className="text-secondary">{t('licenseListRow.noReportRequired')}</span>
      </td>
    );
  }

  expectNever(obligated);
}

export function LicenseListRow({ declarationId, producerId, obligated }: Props) {
  return (
    <tr>
      <td>
        <ProducerInfo producerId={producerId} country={'Austria'} />
      </td>
      <Columns declarationId={declarationId} producerId={producerId} obligated={obligated} />
    </tr>
  );
}
