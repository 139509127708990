import { QueryResponse } from './useQuery';

/**
 * Returns the data of a query if possible.
 * - In case of initial loading, reacts suspense feature is triggered
 * - In case of an error, the error is thrown
 */
export function useQueryValue<D>(query: QueryResponse<D>): D {
  if (query.status === 'pending') {
    throw query.promise;
  }

  if (query.status === 'rejected') {
    throw query.error;
  }

  return query.data;
}
