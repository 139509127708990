﻿import { PackagingWeights } from '../../../../api';
import { weightFormat } from '../../../../helpers';
import { Badge } from 'react-bootstrap';

export function ReviewWeightsRowCells(props: { weights: PackagingWeights; comparison: PackagingWeights | null }) {
  const badge = (a: number, b: number) => {
    if (Math.abs(a - b) < 0.001) {
      return <Badge bg="secondary">+0%</Badge>;
    } else if (a > b) {
      return b === 0 ? (
        <Badge bg="secondary">+∞%</Badge>
      ) : (
        <Badge bg="secondary">+{Math.round(((a - b) / b) * 100)}%</Badge>
      );
    } else {
      return b === 0 ? (
        <Badge bg="secondary">-∞%</Badge>
      ) : (
        <Badge bg="secondary">-{Math.round(((b - a) / b) * 100)}%</Badge>
      );
    }
  };

  return (
    <>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.glass)} kg</span>
        {props.comparison ? badge(props.weights.glass, props.comparison.glass) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.fiber)} kg</span>
        {props.comparison ? badge(props.weights.fiber, props.comparison.fiber) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.ferrousMetal)} kg</span>
        {props.comparison ? badge(props.weights.ferrousMetal, props.comparison.ferrousMetal) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.aluminum)} kg</span>
        {props.comparison ? badge(props.weights.aluminum, props.comparison.aluminum) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.plastic)} kg</span>
        {props.comparison ? badge(props.weights.plastic, props.comparison.plastic) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.beverageCarton)} kg</span>
        {props.comparison ? badge(props.weights.beverageCarton, props.comparison.beverageCarton) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.otherComposite)} kg</span>
        {props.comparison ? badge(props.weights.otherComposite, props.comparison.otherComposite) : <></>}
      </td>
      <td className="align-start text-end text-nowrap">
        <span className="d-block">{weightFormat.format(props.weights.other)} kg</span>
        {props.comparison ? badge(props.weights.other, props.comparison.other) : <></>}
      </td>
    </>
  );
}
