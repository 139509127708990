﻿import { DeclarationResultGermany } from '../../../../api';
import { Outlet, RouteObject, useOutletContext } from 'react-router';
import { reportDetailRoute } from './ReportDetail';
import { NotFoundInfo } from '../../../../shared/components/NotFoundInfo';
import { reportIndexRoute } from './ReportIndex';

export const reportRoute: RouteObject = {
  path: 'report',
  element: <ReportStep />,
  children: [reportIndexRoute, reportDetailRoute],
};

export function ReportStep() {
  const declaration = useOutletContext<DeclarationResultGermany | null>();

  if (declaration === null) {
    return <NotFoundInfo />;
  }

  return <Outlet context={declaration} />;
}
