﻿import { useTranslation } from 'react-i18next';

export function WeightsHeaderCells() {
  const { t } = useTranslation('global');

  return (
    <>
      <th className="col-1 text-end">{t('fractions.de.glass')}</th>
      <th className="col-1 text-end">{t('fractions.de.fiber')}</th>
      <th className="col-1 text-end">{t('fractions.de.ferrousMetal')}</th>
      <th className="col-1 text-end">{t('fractions.de.aluminum')}</th>
      <th className="col-1 text-end">{t('fractions.de.plastic')}</th>
      <th className="col-1 text-end">{t('fractions.de.beverageCarton')}</th>
      <th className="col-1 text-end">{t('fractions.de.otherComposite')}</th>
      <th className="col-1 text-end">{t('fractions.de.other')}</th>
    </>
  );
}
