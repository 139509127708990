﻿import { PackagingWeights } from '../../../../../api';
import { weightFormat } from '../../../../../helpers';

type Props = {
  weights: PackagingWeights;
  cellClassName?: string;
};

export function PlacingWeightsRowCells({ weights, cellClassName }: Props) {
  function getWeightRowContent(weight: number) {
    return (
      <>
        {weightFormat.format(weight)} kg <br />
      </>
    );
  }

  return (
    <>
      <td className={`align-middle text-end text-nowrap ${cellClassName}`}>{getWeightRowContent(weights.glass)}</td>
      <td className={`align-middle text-end text-nowrap ${cellClassName}`}>{getWeightRowContent(weights.fiber)}</td>
      <td className={`align-middle text-end text-nowrap ${cellClassName}`}>
        {getWeightRowContent(weights.ferrousMetal)}
      </td>
      <td className={`align-middle text-end text-nowrap ${cellClassName}`}>{getWeightRowContent(weights.aluminum)}</td>
      <td className={`align-middle text-end text-nowrap ${cellClassName}`}>{getWeightRowContent(weights.plastic)}</td>
      <td className={`align-middle text-end text-nowrap ${cellClassName}`}>
        {getWeightRowContent(weights.beverageCarton)}
      </td>
      <td className={`align-middle text-end text-nowrap ${cellClassName}`}>
        {getWeightRowContent(weights.otherComposite)}
      </td>
      <td className={`align-middle text-end text-nowrap ${cellClassName}`}>{getWeightRowContent(weights.other)}</td>
    </>
  );
}
