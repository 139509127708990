﻿import { DeclarationKind, Period, ResponsibilityAustria } from '../../../../api';
import { useState } from 'react';
import { DeclarationExport } from './DeclarationExport';
import { AuditorExport } from './AuditorExport';
import { useTenant } from '../../../tenants/TenantDetail';
import { Modal } from '../../../../shared/components/Modal';
import { Icon } from '../../../../shared/components/Icon';
import { useTranslation } from 'react-i18next';

export interface Props {
  declarationId: number;
  year: number;
  period: Period;
  kind: DeclarationKind;
  responsibilities: ResponsibilityAustria[];
}

export function ExportDialog({ declarationId, year, period, kind, responsibilities }: Props) {
  const tenant = useTenant();

  const { t } = useTranslation('reports');
  const [show, setShow] = useState(false);

  return (
    <>
      <button onClick={() => setShow(true)} className="btn btn-outline-secondary me-2">
        <Icon name="download" /> {t('exportDialog.buttonText')}
      </button>
      <Modal title={t('exportDialog.title')} size="lg" visible={show} onClose={() => setShow(false)}>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <DeclarationExport year={year} period={period} kind={kind} responsibilities={responsibilities} />
          </li>
          {tenant.permissions.central && (
            <li className="list-group-item">
              <AuditorExport declarationId={declarationId} />
            </li>
          )}
        </ul>
      </Modal>
    </>
  );
}
