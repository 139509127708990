import { useQuery } from '../../shared/hooks/useQuery';
import { getTenantsByUser, Order, Tenant, TenantOrderField } from '../../api';
import { TenantItem } from './TenantItem';
import { LoadMoreItem } from './LoadMoreItem';
import { NoResultItem } from './NoResultItem';
import { Navigate } from 'react-router';
import { useQueryValueOrLatest } from '../../shared/hooks/useQueryValueOrLatest';

const pageSize = 10;

const defaultOrder: Order<TenantOrderField> = { field: 'LastActionAt', direction: 'Desc' };

function getDefaultCountry(tenant: Tenant) {
  return tenant.countries.length === 0 || tenant.countries.includes('de') ? 'de' : tenant.countries[0];
}

export interface Props {
  pageIndex: number;
  onLoadMore?: () => void;
  search: string;
  isLekoUser: boolean;
}

export function TenantListPage({ pageIndex, onLoadMore, search, isLekoUser }: Props) {
  const list = useQueryValueOrLatest(
    useQuery(getTenantsByUser, {
      filter: search,
      page: { limit: pageSize, offset: pageSize * pageIndex },
      order: defaultOrder,
    })
  );

  if (pageIndex === 0) {
    if (!isLekoUser && search === '') {
      // If there no permissions at all, we redirect to a forbidden page
      if (list.items.length === 0) {
        return <Navigate replace to="./forbidden" />;
      }

      // If we are on the first page, there is only one tenant, there is no filter applied and we can't manage tenants,
      // we can redirect to the one tenant directly.
      if (list.items.length === 1) {
        return <Navigate replace to={`./${list.items[0].id}`} />;
      }
    }

    if (list.items.length === 0) {
      return <NoResultItem />;
    }
  }

  return (
    <>
      {list.items.map(tenant => (
        <TenantItem key={tenant.id} tenant={tenant} country={getDefaultCountry(tenant)} isLekoUser={isLekoUser} />
      ))}
      {list.hasMore && onLoadMore && <LoadMoreItem onLoadMore={onLoadMore} />}
    </>
  );
}
