import { RouteObject, useNavigate, useOutletContext } from 'react-router';
import { useForm } from '../../forms';
import { createTenant, getTenantsByUser } from '../../api';
import { Modal } from '../../shared/components/Modal';
import { definition, TenantForm } from './TenantForm';
import { useModifiedPathname } from '../../shared/hooks/useModifiedPathname';
import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useRevalidateAll } from '../../shared/hooks/useRevalidateAll';

export const addRoute: RouteObject = {
  path: 'add',
  element: <AddDialog />,
};

export function AddDialog() {
  const { t } = useTranslation('tenants');

  const navigate = useNavigate();
  const toParent = useModifiedPathname('..');

  const form = useForm(definition, { name: '' });

  const revalidate = useRevalidateAll(getTenantsByUser);

  const clearSearch = useOutletContext<() => void>();

  function onClose() {
    navigate(toParent);
  }

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (form.status.valid) {
      try {
        await createTenant(form.status.value.name);
        clearSearch();
        onClose();
      } finally {
        await revalidate();
      }
    }
  }

  const footer = (
    <button className={'btn btn-primary text-white'} type="submit" disabled={!form.status.valid} form="form">
      {t('tenantModal.save')}
    </button>
  );

  return (
    <Modal visible title={t('tenantModal.title')} footer={footer} onClose={onClose}>
      <form id="form" onSubmit={onSubmit}>
        <TenantForm form={form} />
      </form>
    </Modal>
  );
}
