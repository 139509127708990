import { FieldState } from '../../forms';
import * as React from 'react';
import { InvalidFeedback } from './InvalidFeedback';

export function InputGroup({ field, children }: { field: FieldState<any, any>; children?: React.ReactNode }) {
  return (
    <div className="input-group has-validation">
      {children}
      <InvalidFeedback status={field.status} />
    </div>
  );
}
