﻿import { PackagingWeights, Responsibility, Responsible } from '../../../../api';
import { useContracts } from '../../../contracts/Contracts';
import { useThirdPartyLicensing } from '../thirdPartyLicensing/ThirdPartyLicensing';
import { filterAndAggregateWeights, getEmptyWeights, getSystemForLicensing, SystemForLicensing } from '../helpers';
import { ReportDetailPerLicenserRow } from './ReportDetailPerLicenserRow';
import { useSorting } from '../../../../shared/hooks/useSorting';
import { SortableHeaderCell } from '../../../../shared/components/SortableHeaderCell';
import { SortableWeightsHeaderCells } from '../../../../shared/components/SortableWeightsHeaderCells';
import { useCompareResponsible } from '../CompareResponsible';
import { selectAndNumericCompare } from '../selectAndNumericCompare';
import { selectAndLocaleCompare } from '../selectAndLocaleCompare';
import { PackagingWeightsKeys } from '../PackagingWeightsKeys';
import { useTranslation } from 'react-i18next';
import { useSystems } from '../../../systems/Systems';

export interface Props {
  producerId: string;
  responsibilities: Responsibility[];
}

interface Row {
  keyAsString: string;
  licensing: Responsible;
  system: SystemForLicensing;
  weights: PackagingWeights;
}

export function ReportDetailPerLicenser({ responsibilities, producerId }: Props) {
  const { t } = useTranslation('reports');
  const contracts = useContracts();
  const thirdPartyLicensing = useThirdPartyLicensing();

  const groups: Row[] = filterAndAggregateWeights(row => row.licensing, responsibilities).map(aggregation => ({
    keyAsString: aggregation.keyAsString,
    licensing: aggregation.key,
    system: getSystemForLicensing(
      contracts.get,
      thirdPartyLicensing.get,
      {
        Case: 'Producer',
        Fields: [producerId],
      },
      aggregation.key
    ),
    weights: aggregation.weights,
  }));

  const systems = useSystems();

  function tryGetSystemName(systemForLicensing: SystemForLicensing) {
    if (systemForLicensing.type === 'System') {
      const system = systems.get(systemForLicensing.systemId);
      return system?.name ?? null;
    }

    return null;
  }

  const compareResponsible = useCompareResponsible();

  function compareLicensing(a: Row, b: Row) {
    return compareResponsible(a.licensing, b.licensing);
  }

  const sorting = useSorting<Row, 'licensing' | 'system' | PackagingWeightsKeys>(
    groups,
    {
      licensing: compareLicensing,
      system: selectAndLocaleCompare<Row>(r => tryGetSystemName(r.system)),
      glass: selectAndNumericCompare<Row>(r => r.weights.glass),
      fiber: selectAndNumericCompare<Row>(r => r.weights.fiber),
      ferrousMetal: selectAndNumericCompare<Row>(r => r.weights.ferrousMetal),
      aluminum: selectAndNumericCompare<Row>(r => r.weights.aluminum),
      plastic: selectAndNumericCompare<Row>(r => r.weights.plastic),
      beverageCarton: selectAndNumericCompare<Row>(r => r.weights.beverageCarton),
      otherComposite: selectAndNumericCompare<Row>(r => r.weights.otherComposite),
      other: selectAndNumericCompare<Row>(r => r.weights.other),
    },
    { key: 'licensing', direction: 'asc' }
  );

  const emptyResponsible: Responsible = {
    Case: 'Producer',
    Fields: [producerId],
  };

  const emptySystem: SystemForLicensing = {
    type: 'SystemOfProducer',
    producerId: producerId,
  };

  const emptyWeights: PackagingWeights = getEmptyWeights();

  return (
    <table className="table table-hover  table-striped">
      <thead className="sticky-top bg-white">
        <tr>
          <SortableHeaderCell sorting={sorting} sortKey="licensing">
            {t('reportDetailPerLicenser.licensedBy')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="system">
            {t('reportDetailPerLicenser.systemProvider')}
          </SortableHeaderCell>
          <SortableWeightsHeaderCells sorting={sorting} />
        </tr>
      </thead>
      <tbody>
        {sorting.sorted.length > 0 ? (
          sorting.sorted.map(a => (
            <ReportDetailPerLicenserRow
              key={a.keyAsString}
              producerId={producerId}
              licensing={a.licensing}
              system={a.system}
              weights={a.weights}
            />
          ))
        ) : (
          <ReportDetailPerLicenserRow
            producerId={producerId}
            licensing={emptyResponsible}
            system={emptySystem}
            weights={emptyWeights}
          />
        )}
      </tbody>
    </table>
  );
}
