﻿import { SystemForLicensing } from './helpers';
import { SystemsOfCustomersInfo } from './SystemsOfCustomersInfo';
import { SystemOfSupplierInfo } from './SystemOfSupplierInfo';
import { SystemOfProducerInfo } from './SystemOfProducerInfo';
import { expectNever } from '../../../helpers';
import { SystemInfo } from './SystemInfo';
import { SystemOfCustomerInfo } from './SystemOfCustomerInfo';

export interface Props {
  producerId: string;
  system: SystemForLicensing;
}

export function SystemForLicensingInfo({ producerId, system }: Props) {
  if (system.type === 'SystemsOfCustomers') {
    return <SystemsOfCustomersInfo />;
  }

  // System not defined for a supplier
  if (system.type === 'SystemOfSupplier') {
    return <SystemOfSupplierInfo producerId={producerId} supplierId={system.supplierId} allowUpdate />;
  }

  // System not defined for a customer
  if (system.type === 'SystemOfCustomer') {
    return <SystemOfCustomerInfo producerId={producerId} customerId={system.customerId} allowUpdate />;
  }

  // System not defined for a producer
  if (system.type === 'SystemOfProducer') {
    return <SystemOfProducerInfo producerId={system.producerId} allowUpdate />;
  }

  if (system.type === 'System') {
    return <SystemInfo systemId={system.systemId} count={1} />;
  }

  expectNever(system);
}
