{
  "overview": {
    "heading": "Producteurs",
    "hint": "Pour créer une nouvelle déclaration, sélectionnez un producteur existant ou ajoutez-en un"
  },
  "navigation": {
    "declarations": "Declarations",
    "manageContracts": "Contracts",
    "manageBranches": "Branches",
    "manageProducers": "Producers"
  },
  "import": {
    "imports": "Imports",
    "refresh": "Refresh",
    "startedOn": "started on",
    "statedAt": "at",
    "finishedAt": "finished at",
    "success": "Successful",
    "failure": "Failure",
    "inProgress": "In Progress",
    "uploadFailedForFile": "The file could not be uploaded.",
    "uploadFailedForFiles": "The files could not be uploaded.",
    "uploadFailedForTheseFiles": "The following files could not be uploaded:\n {{files}}",
    "importStarts": "The files have been uploaded and are now being imported. This can take several minutes.",
    "uploadInProgress": "Uploading...",
    "buttonText": "Import"
  },
  "tenantModal": {
    "add": "Ajouter un producteur",
    "title": "Nouveau producteur",
    "delete": "Effacer",
    "save": "Enregistrer",
    "confirmDeletion": "Voulez-vous vraiment supprimer ce producteur ?"
  },
  "form": {
    "name": "Nom"
  },
  "noResult": {
    "title": "Aucun producteur trouvé",
    "description": "Veuillez ajuster votre recherche ou ajouter un producteur"
  },
  "showMore": "Voir plus",
  "switchTenant": "Tous les producteurs",
  "moreInformation": {
    "buttonLabel": "Plus d'informations",
    "modalTitle": "Informations",
    "contentTitle": "Pour créer une déclaration, procédez comme suit :",
    "contentBody": "<ol><li><strong>Recherchez</strong> un <strong>producteur existant</strong>.<br>S'il n'y a pas de producteur existant, <strong>ajoutez un nouveau producteur</strong> avec un nom facile à retenir <i>(exemple NomEntreprise)</i>.</li><li><strong>Ouvriez</strong> la déclaration d’un nouveau <strong>producteur</strong> en cliquant sur son nom.</li><li><strong>Créez</strong> une nouvelle <strong>déclaration</strong> en suivant simplement les instructions des écrans suivants.</li><li>Des <strong>déclarations alternatives</strong> peuvent être créées en ajoutant des <strong>producteurs supplémentaires</strong>. Pour chaque producteur, une importation de données distincte est nécessaire, ce qui permet d'obtenir un résultat différent.</li></ol>"
  },
  "countrySwitch": {
    "countries": {
      "de": "Allemagne",
      "fr": "France",
      "at": "Autriche"
    }
  }
}
