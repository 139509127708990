﻿import {
  field,
  group,
  InputOf,
  numberField,
  optionalDateField,
  optionalNumberField,
  OutputOf,
  valid,
} from '../../../forms';
import { ContractPricesAustria, ContractValue, DeclarationInterval } from '../../../api';

const pricesGroup = group({
  aluminumCommercial: numberField,
  aluminumHousehold: numberField,
  beverageCartonHousehold: numberField,
  ceramicCommercial: numberField,
  ceramicHousehold: numberField,
  compositeCommercial: numberField,
  compositeHousehold: numberField,
  epsCommercial: numberField,
  ferrousMetalCommercial: numberField,
  ferrousMetalHousehold: numberField,
  fiberCommercial: numberField,
  fiberHousehold: numberField,
  foilsCommercial: numberField,
  glassHousehold: numberField,
  hollowBodiesCommercial: numberField,
  organicCommercial: numberField,
  organicHousehold: numberField,
  plasticHousehold: numberField,
  textileCommercial: numberField,
  textileHousehold: numberField,
  woodCommercial: numberField,
  woodHousehold: numberField,
});

export const contractForm = group({
  systemId: numberField,
  hasPrices: field<boolean>(valid),
  prices: pricesGroup,
  interval: field<DeclarationInterval>(valid),
  deadlines: group({
    annual: optionalDateField,
    periodical: optionalNumberField,
  }),
});

export type ContractForm = InputOf<typeof contractForm>;
export type ContractFormOutput = OutputOf<typeof contractForm>;

export const zeroPrices: ContractPricesAustria = {
  aluminumCommercial: 0,
  aluminumHousehold: 0,
  beverageCartonHousehold: 0,
  ceramicCommercial: 0,
  ceramicHousehold: 0,
  compositeCommercial: 0,
  compositeHousehold: 0,
  epsCommercial: 0,
  ferrousMetalCommercial: 0,
  ferrousMetalHousehold: 0,
  fiberCommercial: 0,
  fiberHousehold: 0,
  foilsCommercial: 0,
  glassHousehold: 0,
  hollowBodiesCommercial: 0,
  organicCommercial: 0,
  organicHousehold: 0,
  plasticHousehold: 0,
  textileCommercial: 0,
  textileHousehold: 0,
  woodCommercial: 0,
  woodHousehold: 0,
};

export const emptyForm: ContractForm = {
  systemId: null,
  hasPrices: false,
  prices: zeroPrices,
  interval: 'Yearly',
  deadlines: {
    annual: null,
    periodical: null,
  },
};

export function toForm(contractValue: ContractValue): ContractForm {
  if (!contractValue.prices) {
    return { ...contractValue, prices: zeroPrices, hasPrices: false };
  } else if (contractValue.prices.Case === 'Austria') {
    return { ...contractValue, prices: contractValue.prices.Fields[0], hasPrices: true };
  }

  throw new Error('Invalid case for prices, must be Germany');
}

export function toValue(
  contractForm: ContractFormOutput,
  year: number,
  contractId: number | null,
  producerId: string
): ContractValue {
  return {
    ...contractForm,
    year,
    contractId,
    producerId,
    country: 'Austria',
    prices: contractForm.hasPrices ? { Case: 'Austria', Fields: [contractForm.prices] } : null,
  };
}
