﻿import { Icon } from './Icon';
import * as React from 'react';

export interface Props {
  title?: string;
  children?: React.ReactNode;
  icon?: string;
  color?: 'success' | 'danger' | 'info' | 'primary';
  bgColor?: 'success' | 'danger' | 'info' | 'primary';
  className?: string;
}

export function InfoScreen({ title, children, icon, color, bgColor, className }: Props) {
  return (
    <div
      className={`mx-4 p-4 d-flex flex-column text-center ${bgColor ? `alert alert-${bgColor}` : ''} ${
        className ?? ''
      }`}>
      <div className="text-muted">
        {icon != undefined && <Icon className={color ? `text-${color}` : ''} name={`${icon} fs-1`} />}
        {title && <h1 className="h5 mt-3">{title}</h1>}
        {children}
      </div>
    </div>
  );
}
