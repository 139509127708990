﻿import { useProducers } from '../producers/Producers';
import { useContracts } from './Contracts';
import { ContractRow } from './ContractRow';
import { useTranslation } from 'react-i18next';
import { stringParam, useSearchParams } from '../../shared/hooks/useSearchParams';
import { ContractValue, ProducerData } from '../../api';
import { useSorting } from '../../shared/hooks/useSorting';
import { SortableHeaderCell } from '../../shared/components/SortableHeaderCell';
import { getIndexOfInterval } from '../../helpers';
import { useSystems } from '../systems/Systems';
import { useProducerIdsFromContracts } from './useProducerIdsFromContracts';
import { useAccessibleProducers } from '../producers/useAccessibleProducers';
import { useActiveProducers } from '../producers/useActiveProducers';

interface Row {
  producerId: string;
  producer: ProducerData | null;
  contract: ContractValue | null;
  systemName: string | null;
}

export function ContractsTable({ year }: { year: number }) {
  const producers = useProducers();
  const contracts = useContracts();
  const systems = useSystems();

  const { t } = useTranslation('contracts');

  const producerIdsFromProducers = useActiveProducers();

  const producerIdsWithContracts = useProducerIdsFromContracts();

  const producerIds = useAccessibleProducers(
    Array.from(new Set([...producerIdsFromProducers, ...producerIdsWithContracts]).values())
  );

  const rows: [string, Row][] = producerIds.map(producerId => {
    const producer = producers.get(producerId);
    const contract = contracts.get(producerId);
    return [
      producerId,
      {
        producerId,
        producer,
        contract: contract,
        systemName: contract ? systems.get(contract.systemId)?.name || null : null,
      },
    ];
  });

  const [search, setSearch] = useSearchParams<string>('search', stringParam(''));

  const compareProducer = (a: [string, Row], b: [string, Row]) => {
    return (a[1].producer?.name ?? '').localeCompare(b[1].producer?.name ?? '');
  };

  const compareSystem = (a: [string, Row], b: [string, Row]) => {
    return (a[1].systemName ?? '').localeCompare(b[1].systemName ?? '');
  };

  const compareInterval = (a: [string, Row], b: [string, Row]) => {
    const x = a[1].contract?.interval ? getIndexOfInterval(a[1].contract?.interval) : -1;
    const y = b[1].contract?.interval ? getIndexOfInterval(b[1].contract?.interval) : -1;
    return x === y ? 0 : x > y ? 1 : -1;
  };

  const contractIncludes = ([, row]: [string, Row]) => {
    const lowerCaseSearch = search.toLocaleLowerCase();
    return (
      (row.producer?.name ?? '').toLowerCase().includes(lowerCaseSearch) ||
      (row.systemName ?? '').toLocaleLowerCase().includes(lowerCaseSearch)
    );
  };

  const sorting = useSorting<[string, Row], 'producer' | 'system' | 'interval'>(
    rows,
    {
      producer: compareProducer,
      system: compareSystem,
      interval: compareInterval,
    },
    { key: 'producer', direction: 'asc' }
  );

  return (
    <>
      <div className="row my-4">
        <div className="col-4">
          <input
            placeholder={t('global:search')}
            type="search"
            className="form-control"
            value={search}
            onChange={e => setSearch(e.currentTarget.value)}
          />
        </div>
      </div>
      <table className="table table-striped table-hover">
        <thead className="sticky-top bg-white">
          <tr>
            <SortableHeaderCell className="col-4" sorting={sorting} sortKey="producer">
              {t('contractsTable.producer')}
            </SortableHeaderCell>
            <SortableHeaderCell className="col-4" sorting={sorting} sortKey="system">
              {t('contractsTable.systemProvider')}
            </SortableHeaderCell>
            <SortableHeaderCell className="col-4" sorting={sorting} sortKey="interval">
              {t('contractsTable.interval')}
            </SortableHeaderCell>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sorting.sorted.filter(contractIncludes).map(([, row]) => (
            <ContractRow
              key={row.producerId}
              year={year}
              producerId={row.producerId}
              systemName={row.systemName}
              contract={row.contract}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}
