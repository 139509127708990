import { createContext, useContext } from 'react';
import { Navigate, Outlet, RouteObject, useParams, useRoutes } from 'react-router';
import { getInitialYear } from '../reports/helpers';
import { yearRoute as yearRouteDe } from './de/YearDetail';
import { yearRoute as yearRouteAt } from './at/YearDetail';
import { yearRoute as yearRouteFr } from './fr/YearDetail';
import { NotFoundInfo } from '../../shared/components/NotFoundInfo';
import { useQuery } from '../../shared/hooks/useQuery';
import { tryGetTenantById, Tenant } from '../../api';
import { useQueryValue } from '../../shared/hooks/useQueryValue';
import { useStringParams } from '../../shared/hooks/useParam';
import { TrackJS } from 'trackjs';
import { useUser } from '../../public/auth/shared';

const TenantContext = createContext<Tenant | null>(null);

function NavigateToInitialYear(props: { country: string }) {
  if (props.country === 'fr') {
    //@TODO: fix this once year is changeable for FR
    return <Navigate to="./2022" replace />;
  }
  return <Navigate to={`./${getInitialYear()}`} replace />;
}

function NavigateToDefaultCountry() {
  const tenant = useTenant();

  const country = tenant.countries.includes('de') ? 'de' : tenant.countries[0];

  return <Navigate to={`./${country}`} />;
}

function CountryRouteSwitch() {
  const { country } = useStringParams<'country'>();

  const initialYearRoute = <NavigateToInitialYear country={country} />;

  switch (country) {
    case 'de':
      return useRoutes([yearRouteDe, { index: true, element: initialYearRoute }]);
    case 'at':
      return useRoutes([yearRouteAt, { index: true, element: initialYearRoute }]);
    case 'fr':
      return useRoutes([yearRouteFr, { index: true, element: initialYearRoute }]);
    default:
      return null;
  }
}

export const tenantDetailRoute: RouteObject = {
  path: ':tenantId',
  element: <TenantDetail />,
  children: [
    { index: true, element: <NavigateToDefaultCountry /> },
    {
      path: ':country/*',
      element: <CountryRouteSwitch />,
    },
  ],
};

/**
 * Renders the UI of a tenant.
 */
function TenantDetail() {
  const params = useParams<{ tenantId: string }>();
  const tenantId = parseInt(params.tenantId ?? '');
  const tenant = useQueryValue(useQuery(tryGetTenantById, { tenantId, data: void 0 }));
  const user = useUser();

  TrackJS.isInstalled() &&
    TrackJS.configure({
      userId: user.id,
    });

  TrackJS.isInstalled() && TrackJS.addMetadata('tenantId', params.tenantId ?? '');

  return tenant ? (
    <TenantContext.Provider value={tenant}>
      <Outlet />
    </TenantContext.Provider>
  ) : (
    <NotFoundInfo />
  );
}

/** Hook that returns the ID of the current tenant. */
export function useTenant() {
  const tenant = useContext(TenantContext);

  if (tenant == null) {
    throw new Error('Tenant is not defined.');
  }

  return tenant;
}
