import { useState } from 'react';
import { Icon } from './Icon';

interface Props {
  getUrls: () => Promise<[string, string]>;
  label: string;
  downloadingLabel: string;
}

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function BucketDownloadButton({ getUrls, label, downloadingLabel }: Props) {
  const [downloading, setDownloading] = useState(false);

  async function downloadForAuditor() {
    setDownloading(true);

    try {
      const [headUrl, getUrl] = await getUrls();

      let timeout = false;
      let status = 404;

      delay(1000 * 60 * 5).then(() => (timeout = true));

      while (!timeout && status === 404) {
        const response = await fetch(headUrl, { method: 'HEAD' });
        status = response.status;
        await delay(5000);
      }

      if (timeout) {
        throw new Error('Timeout exceeded.');
      }

      const link = document.createElement('a');
      link.setAttribute('href', getUrl);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } finally {
      setDownloading(false);
    }
  }

  return (
    <button disabled={downloading} className="btn btn-secondary" onClick={downloadForAuditor}>
      <Icon name="download" />
      {downloading ? <> {downloadingLabel} </> : <> {label} </>}
    </button>
  );
}
