import { Icon } from '../../../../shared/components/Icon';
import { getPresignedUploadUrlFrance } from '../../../../api';
import { useTenant } from '../../../tenants/TenantDetail';
import { useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useYearParam } from '../../../../shared/hooks/useParam';

type Props = {
  callback: () => void;
};

export function ImportButton({ callback }: Props) {
  const tenant = useTenant();
  const year = useYearParam();
  const { t } = useTranslation('reports');

  const [inProgress, setInProgress] = useState(false);

  const uploadFile = async (file: File) => {
    try {
      const url = await getPresignedUploadUrlFrance({ tenantId: tenant.id, data: { fileName: file.name, year: year } });
      await fetch(url, { method: 'PUT', body: file, headers: { 'Content-Type': 'application/octet-stream' } });
    } catch (err) {
      console.error(err);
    }
  };

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.currentTarget.files ?? [];
    if (files.length > 0) {
      setInProgress(true);
      uploadFile(files[0]).then(
        () => {
          setInProgress(false);
          callback();
        },
        () => setInProgress(false)
      );
    }

    e.currentTarget.value = '';
  }

  const labelClassName = inProgress
    ? 'btn btn-primary text-nowrap disabled d-block mb-2'
    : 'btn btn-primary text-nowrap d-block mb-2';

  const labelText = inProgress ? t('importButton.pendingLabel') : t('importButton.label');

  return (
    <label className={labelClassName}>
      <Icon name="upload" className="me-2" />
      {labelText}
      <input disabled={inProgress} style={{ display: 'none' }} type="file" accept=".xlsx,.xlsm" onChange={onChange} />
    </label>
  );
}
