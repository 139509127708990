import { useState } from 'react';
import { Modal } from '../../shared/components/Modal';
import { useTranslation } from 'react-i18next';

/**
 * Returns ths initial month that should be used.
 */
function getInitialYear() {
  const date = new Date();
  // The previous year in January, otherwise the current year
  return date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
}

const yearRange = [-3, -2, -1, 0, 1];

/**
 * Shows an input of a year and a month to specify the prefix that has to be used for the upload of the files.
 * This is currently used for UTM only and shouldn't be required in the future.
 * Because of this, a translation is currently not required.
 */
export function UTMPrefixModal({ onImport, onClose }: { onImport: (prefix: string) => void; onClose: () => void }) {
  const { t } = useTranslation('tenants');

  const initialYear = getInitialYear();

  const [year, setYear] = useState(initialYear.toString());
  const [month, setMonth] = useState<string | ''>('');

  function onImportClick() {
    onImport(month === 'YEAR' ? `${year}_` : `${year}_${month}_`);
  }

  const footer = (
    <>
      {month === 'YEAR' && (
        <div className="alert alert-warning mb-2" role="alert">
          {t('utmPrefixModal.warnWholeYear')}
        </div>
      )}
      <button disabled={month === ''} className="btn btn-primary" onClick={onImportClick}>
        {t('utmPrefixModal.importButtonLabel')}
      </button>
    </>
  );

  return (
    <Modal title={t('utmPrefixModal.title')} visible onClose={onClose} footer={footer}>
      <p>{t('utmPrefixModal.description')}</p>
      <div className="row">
        <div className="col-4">
          <select className="form-select" value={year.toString()} onChange={e => setYear(e.currentTarget.value)}>
            {yearRange.map(n => (
              <option key={initialYear + n} value={initialYear + n}>
                {initialYear + n}
              </option>
            ))}
          </select>
        </div>
        <div className="col-8">
          <select className="form-select" value={month} onChange={e => setMonth(e.currentTarget.value)}>
            <option>{t('utmPrefixModal.choose')}</option>
            <option>01</option>
            <option>02</option>
            <option>03</option>
            <option>04</option>
            <option>05</option>
            <option>06</option>
            <option>07</option>
            <option>08</option>
            <option>09</option>
            <option>10</option>
            <option>11</option>
            <option>12</option>
            <option value="YEAR">{t('utmPrefixModal.wholeYear')}</option>
          </select>
        </div>
      </div>
    </Modal>
  );
}
