import { StrictMode, Suspense } from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Fallback } from './Fallback';
import { GlobalErrorBoundary } from './GlobalErrorBoundary';
import { createRoot } from 'react-dom/client';
import { TrackJS } from 'trackjs';

if (process.env.TRACKJS_TOKEN) {
  TrackJS.install({
    token: process.env.TRACKJS_TOKEN,
    application: process.env.TRACKJS_APP,
  });
}

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <StrictMode>
    <Suspense fallback={<Fallback />}>
      <GlobalErrorBoundary>
        <App />
      </GlobalErrorBoundary>
    </Suspense>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
