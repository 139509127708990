import { Navigate, Outlet, RouteObject, useOutletContext } from 'react-router';
import { DeclarationResultGermany } from '../../../../api';
import { Actions } from '../Actions';
import { LicenseList } from './LicenseList';
import { ContractEdit } from '../../../contracts/de/ContractEdit';
import { usePeriodParam, useYearParam } from '../../../../shared/hooks/useParam';
import { useProducersThatHaveToLicense } from './useProducersThatHaveToLicense';

export const licenseIndexRoute: RouteObject = {
  element: <LicenseIndex />,
  children: [{ index: true }, { path: 'contracts/edit/:contractProducerId', element: <ContractEdit /> }],
};

function LicenseIndex() {
  const [period, year] = [usePeriodParam(), useYearParam()];
  const declaration = useOutletContext<DeclarationResultGermany>();

  const producerIds = useProducersThatHaveToLicense(period, declaration, true);

  // If there is only a on producer,
  // we can redirect to this producer and we don't have to show the overview.
  if (producerIds.length === 1) {
    return <Navigate to={`./${producerIds[0]}`} replace />;
  }

  return (
    <>
      <Actions year={year} period={period} declaration={declaration} />
      <LicenseList producerIds={producerIds} declarationId={declaration.declarationId} />
      <Outlet />
    </>
  );
}
